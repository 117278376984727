import React from 'react';
import LoadingListTile from 'components/LoadingListTile';
import './loadingListTiles.css';

export interface LoadingListTilesProps {
  count: number;
}
const LoadingListTiles = (props: LoadingListTilesProps) => (
  <div className="loadingListTiles">
    {Array.from({ length: props.count }).map((_, i: number) => (
      // eslint-disable-next-line react/no-array-index-key -- is fine here to use index as key
      <LoadingListTile key={i} />
    ))}
  </div>
);

export default LoadingListTiles;
