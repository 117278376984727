import Bingo from 'modules/Bingo';
import Wallet from 'modules/Wallet';
import Modals from 'modules/Modals';
import Dialog from 'modules/Dialog';
import Notifications from 'modules/Notifications';
import Chat from 'modules/Chat';
import spread from 'lib/spread';
import { types } from 'lib/constants';
import {
  getAmount,
  getRefName,
  getWinners,
  getWinningUsernames
} from 'modules/Bingo/lib/action-utils';

const resetGame = (dispatch, action) => {
  dispatch(Bingo.actions.refresh(dispatch, getRefName(action), { skipSession: true }));
};

// TODO: Move to Wallet constants after MRQ-1078
const braintreeDepositType = {
  [types.CARD]: Wallet.actionTypes.AT.DEPOSIT_CARD_BRAINTREE,
  [types.PAYPAL]: Wallet.actionTypes.AT.DEPOSIT_PAYPAL
};
const braintreeCreationType = {
  [types.CARD]: Wallet.actionTypes.AT.CREATE_PAYMENT_METHOD,
  [types.PAYPAL]: Wallet.actionTypes.AT.CREATE_PAYPAL
};

/**
 * THE PAYLOAD DATA ARE THE JUST DEFAULT VALUES. The (pre-translated) action's payload is MERGED
 * INTO the translation ONLY add mappings for internal <-> external actions. Never external <->
 * external or internal <-> internal keyMapping defines the **bidirectional** translation of the
 * event/action keys.
 */
export default {
  whitelist: false,
  keyMapping: {
    type: 'type',
    payload: 'payload',
    flattenPayload: false,
    meta: 'meta',
    error: 'success'
  },
  entities: {
    RESET_GAME: resetGame,
    END_GAME: (dispatch, action) => {
      dispatch(Bingo.actions.rollover(getRefName(action)));
      resetGame(dispatch, action);
    },
    LOADING_GAME: (dispatch) => dispatch(Modals.actions.open('gamePreloader')),
    START_GAME: (dispatch, action) =>
      dispatch(Bingo.actions.startGame(dispatch, getRefName(action))),
    DRAW_BALL: (dispatch, action) => {
      const number = action.payload && action.payload.drawnBall && action.payload.drawnBall.number;
      if (number) {
        dispatch({
          type: 'Bingo/DRAW_BALL',
          payload: [number],
          meta: action.meta
        });
      }
    },
    MESSAGE: {
      type: Chat.T.GET_MESSAGE
    },
    UPDATE_USERS: {
      type: Chat.T.UPDATE_USERS
    },
    SUBSCRIBE_USER: {
      type: Chat.T.SUBSCRIBE_USER
    },
    UNSUBSCRIBE_USER: {
      type: Chat.T.UNSUBSCRIBE_USER
    },
    END_PRIVATE_CHAT: {
      type: Chat.AT.CLOSE_PRIVATE_ROOM.PENDING
    },
    ONE_LINE_WINNER: { type: Bingo.actionTypes.T.ONE_LINE_WINNER },
    TWO_LINES_WINNER: { type: Bingo.actionTypes.T.TWO_LINES_WINNER },
    BINGO_GAME_JACKPOT_WINNER: (dispatch, action) => {
      dispatch(
        Bingo.actions.gameWon(
          ...spread([getRefName, getWinningUsernames, getAmount, getWinners], action)
        )
      );
      resetGame(dispatch, action);
    },
    BINGO_PROGRESSIVE_WINNER: (dispatch, action) => {
      dispatch(
        Bingo.actions.progressiveGameWon(
          ...spread([getRefName, getWinningUsernames, getAmount, getWinners], action)
        )
      );
      resetGame(dispatch, action);
    },
    UPDATE_JACKPOTS: {
      type: Bingo.actionTypes.AT.JACKPOT.FULFILLED
    },
    UPDATE_BALANCE: {
      type: Wallet.actionTypes.AT.UPDATE_BALANCE.FULFILLED
    },
    UPDATE_PAYMENT_CARD: (dispatch, action) => {
      // If error, close and re-open the addCard modal ?
      // TODO: Error: success, that's a bit confusing. No way around it, needs to be documented
      const { error: success, payload } = action;
      if (success && payload) {
        dispatch({
          type: Wallet.actionTypes.AT.UPDATE_PAYMENT_CARD.FULFILLED,
          payload: payload
        });
      } else {
        dispatch({
          ...action,
          type: Wallet.actionTypes.AT.UPDATE_PAYMENT_CARD.REJECTED,
          error: !success
        });
      }
    },
    FONIX_DEPOSIT_CONFIRMATION: (dispatch, action) => {
      // TODO: Error: success, that's a bit confusing. No way around it, needs to be documented
      // FIXME: ASK BACKEND
      const { error: success, payload } = action;
      if (success && payload) {
        dispatch(Modals.actions.close('deposit'));
        // Dispatching doesn't seem to work, why?
        return {
          FONIX_DEPOSIT_CONFIRMATION: { type: Wallet.actionTypes.AT.DEPOSIT_MOBILE.FULFILLED }
        };
      } else {
        return {
          FONIX_DEPOSIT_CONFIRMATION: { type: Wallet.actionTypes.AT.DEPOSIT_MOBILE.REJECTED }
        };
      }
    },
    BRAINTREE_DEPOSIT_CONFIRMATION: (dispatch, action) => {
      // TODO: Error: success, that's a bit confusing. No way around it, needs to be documented
      // FIXME: ASK BACKEND
      // ^ Awkward
      const { error: success, payload } = action;
      const paymentMethodType = payload?.paymentMethodType;
      const type = braintreeDepositType[paymentMethodType];
      if (type) {
        const translation = {
          BRAINTREE_DEPOSIT_CONFIRMATION: { type: success ? type.FULFILLED : type.REJECTED }
        };
        if (success) dispatch(Modals.actions.close('deposit'));
        return translation;
      }
    },
    BRAINTREE_ADD_PAYMENT_METHOD: (dispatch, action) => {
      const { error: success, payload } = action;
      const paymentMethodType = payload?.paymentMethodType;
      const type = braintreeCreationType[paymentMethodType];
      if (type) {
        const translation = {
          BRAINTREE_ADD_PAYMENT_METHOD: { type: success ? type.FULFILLED : type.REJECTED }
        };
        if (success) {
          dispatch(Modals.actions.close('addPayment'));
          dispatch(Modals.actions.open('deposit'));
        }
        return translation;
      }
    },
    NEW_PENDING_POPUP: {
      type: Dialog.actionTypes.AT.LIST_SINGLE.FULFILLED
    },
    PENDING_POPUPS: {
      type: Dialog.actionTypes.AT.LIST.FULFILLED
    },
    ECOSPEND_DEPOSIT_CONFIRMATION: (dispatch, action) => {
      // TODO: Needs to be checked why we map the success as error in the
      // keyMappings above and then the same success value is sent as error
      const { error: success, payload, meta } = action;
      const { paymentId } = payload;
      const { msg } = meta;
      const severity = success ? 'SUCCESS' : 'ERROR';
      dispatch(
        Notifications.actions.create(paymentId, msg, {
          severity: severity,
          paths: ['/secure/user/account', '/secure/lobby', '/login', '/signup']
        })
      );
      dispatch(Wallet.actions.setHasDeposited(true));
      dispatch(Modals.actions.close('depositEcospendFlow'));
    },
    PCI_PROXY_ADD_PAYMENT_METHOD: (dispatch, action) => {
      const { error: success, payload, meta } = action;
      const { msg } = meta;
      if (success && payload) {
        dispatch(Modals.actions.close('addPayment'));
        dispatch(Modals.actions.open('deposit'));
        return {
          PCI_PROXY_ADD_PAYMENT_METHOD: { type: Wallet.actionTypes.AT.ADD_NEW_CARD.FULFILLED }
        };
      }
      dispatch(Wallet.actions.handlePciProxyError(true, msg, ['addPayment', 'changeCard']));
      return {
        PCI_PROXY_ADD_PAYMENT_METHOD: { type: Wallet.actionTypes.AT.ADD_NEW_CARD.REJECTED }
      };
    },
    PCI_PROXY_3D_SECURE_URL: (dispatch, action) => {
      const { error: success, payload, meta } = action;
      const { url, cvvTransactionId } = payload;
      const { msg } = meta;
      if (success) {
        dispatch(Modals.actions.openPci3dChallengeModal(cvvTransactionId, url));
      } else {
        dispatch(Wallet.actions.handlePciProxyError(true, msg, ['deposit']));
      }
    },
    PCI_PROXY_DEPOSIT_CONFIRMATION: (dispatch, action) => {
      const { error: success, meta } = action;
      const { msg } = meta;
      if (success) {
        dispatch(Wallet.actions.setHasDeposited(true));
        dispatch(Modals.actions.close('deposit'));
        return {
          PCI_PROXY_DEPOSIT_CONFIRMATION: {
            type: Wallet.actionTypes.AT.DEPOSIT_CARD_PCI_PROXY.FULFILLED
          }
        };
      }
      dispatch(Wallet.actions.handlePciProxyError(true, msg, ['deposit']));
      return {
        PCI_PROXY_DEPOSIT_CONFIRMATION: {
          type: Wallet.actionTypes.AT.DEPOSIT_CARD_PCI_PROXY.REJECTED
        }
      };
    }
  }
};
