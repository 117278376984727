export enum TypographyVariant {
  HeroHeaderXl = 'HeroHeaderXl',
  HeroHeaderLg = 'HeroHeaderLg',
  HeroHeaderMd = 'HeroHeaderMd',
  HeroHeaderSm = 'HeroHeaderSm',
  HeaderXxl = 'HeaderXxl',
  HeaderXl = 'HeaderXl',
  HeaderLg = 'HeaderLg',
  HeaderMd = 'HeaderMd',
  HeaderSm = 'HeaderSm',
  HeaderXs = 'HeaderXs',
  HeaderXxs = 'HeaderXxs',
  BodyXl = 'BodyXl',
  BodyXlStrong = 'BodyXlStrong',
  BodyLg = 'BodyLg',
  BodyLgStrong = 'BodyLgStrong',
  BodyMd = 'BodyMd',
  BodyMdStrong = 'BodyMdStrong',
  BodySm = 'BodySm',
  BodySmStrong = 'BodySmStrong',
  BodyXs = 'BodyXs',
  BodyXsStrong = 'BodyXsStrong',
  BodyXxs = 'BodyXxs',
  BodyXxsStrong = 'BodyXxsStrong',
  ButtonXl = 'ButtonXl',
  ButtonLg = 'ButtonLg',
  ButtonMd = 'ButtonMd',
  ButtonSm = 'ButtonSm',
  ButtonXs = 'ButtonXs'
}

export const classMap = {
  [TypographyVariant.HeroHeaderXl]: 'hero-header-xl',
  [TypographyVariant.HeroHeaderLg]: 'hero-header-lg',
  [TypographyVariant.HeroHeaderMd]: 'hero-header-md',
  [TypographyVariant.HeroHeaderSm]: 'hero-header-sm',
  [TypographyVariant.HeaderXxl]: 'header-xxl',
  [TypographyVariant.HeaderXl]: 'header-xl',
  [TypographyVariant.HeaderLg]: 'header-lg',
  [TypographyVariant.HeaderMd]: 'header-md',
  [TypographyVariant.HeaderSm]: 'header-sm',
  [TypographyVariant.HeaderXs]: 'header-xs',
  [TypographyVariant.HeaderXxs]: 'header-xxs',
  [TypographyVariant.BodyXl]: 'body-xl',
  [TypographyVariant.BodyXlStrong]: 'body-xl-strong',
  [TypographyVariant.BodyLg]: 'body-lg',
  [TypographyVariant.BodyLgStrong]: 'body-lg-strong',
  [TypographyVariant.BodyMd]: 'body-md',
  [TypographyVariant.BodyMdStrong]: 'body-md-strong',
  [TypographyVariant.BodySm]: 'body-sm',
  [TypographyVariant.BodySmStrong]: 'body-sm-strong',
  [TypographyVariant.BodyXs]: 'body-xs',
  [TypographyVariant.BodyXsStrong]: 'body-xs-strong',
  [TypographyVariant.BodyXxs]: 'body-xxs',
  [TypographyVariant.BodyXxsStrong]: 'body-xxs-strong',
  [TypographyVariant.ButtonXl]: 'button-xl',
  [TypographyVariant.ButtonLg]: 'button-lg',
  [TypographyVariant.ButtonMd]: 'button-md',
  [TypographyVariant.ButtonSm]: 'button-sm',
  [TypographyVariant.ButtonXs]: 'button-xs'
};

export const defaultVariant = TypographyVariant.BodyMd;
export const defaultClassName = classMap[defaultVariant];
