import { createContext } from 'react';
import type { Section } from 'modules/Lobby';

type DynamicLobbyContextProps = {
  category: string;
  section: Section;
};

const DynamicLobbyContext = createContext<DynamicLobbyContextProps>({} as DynamicLobbyContextProps);

export default DynamicLobbyContext;
