import React, { useCallback, memo } from 'react';
import { useAppDispatch, useAppSelector } from 'hooks/redux';
import { isLoading } from 'lib/redux-utils';
import { formatCurrency } from 'lib/formatters';
import Api from 'services/Api';
import Modals from 'modules/Modals';
import Wallet from 'modules/Wallet';
import Button from 'components/Button';
import './pendingWithdrawal.css';

interface PendingWithdrawalProps {
  amount: number;
  goToScreen: (screen: string) => void;
}

const PendingWithdrawal = ({ amount, goToScreen }: PendingWithdrawalProps) => {
  const dispatch = useAppDispatch();
  const cancellableBankTransactionRefs = useAppSelector(
    Wallet.selectors.getCancellableBankTransactionRefsDeposit
  );
  const isLoadingData = useAppSelector((state) =>
    isLoading(state, Wallet.actionTypes.AT.CANCEL_MULTIPLE_WITHDRAWS._)
  );

  const handleReverseAllWithdrawalsClick = useCallback(async () => {
    await Api.actions.wallet.cancelMultipleWithdraws(null, {
      ids: cancellableBankTransactionRefs
    })(dispatch);
    dispatch(Modals.actions.close('deposit'));
    dispatch(Modals.actions.open('reversalSuccess', { amount }));
  }, [amount, cancellableBankTransactionRefs, dispatch]);

  const handleSelectWithdrawalsClick = useCallback(() => {
    dispatch(Modals.actions.close('deposit'));
    dispatch(Modals.actions.open('selectReversible'));
  }, [dispatch]);

  const handleDepositFundsClick = useCallback(() => {
    goToScreen('depositForm');
  }, [goToScreen]);

  return (
    <div className="pendingWithdrawal">
      <p>
        Hold your horses! Did you know you have{' '}
        <strong className="pendingWithdrawal__amount">{formatCurrency(amount)}</strong> reversible
        withdrawals that haven’t been processed yet? Simply reverse all or part of your reversible
        withdrawals and add the funds straight into your account, saving you time.
      </p>
      <div className="pendingWithdrawal__buttons">
        <Button
          buttonText="Reverse all withdrawals"
          onClick={handleReverseAllWithdrawalsClick}
          name="reverseAllWithdrawals"
          loading={isLoadingData}
        />
        <Button
          buttonText="Select withdrawals to reverse"
          name="selectWithdrawals"
          onClick={handleSelectWithdrawalsClick}
        />
        <Button buttonText="Deposit funds" name="depositFunds" onClick={handleDepositFundsClick} />
      </div>
    </div>
  );
};

export default memo(PendingWithdrawal);
