import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import UserBox from 'components/UserBox';
import makeModal from 'components/Modals';
import Iframe from 'components/Iframe';
import Api from 'services/Api';
import User from 'modules/User';

const YotiIframe = ({ docScanUrl, close, finishDocScanSession }) => {
  const handleReceiveMessage = (event) => {
    if (event.origin.includes('https://api.yoti.com') && event.data.eventType === 'SUCCESS') {
      finishDocScanSession();
      close();
    }
  };

  return (
    <UserBox className="docScan modal" title="Verify with Yoti" closeCallback={close}>
      <Iframe
        title="Scan your ID"
        src={docScanUrl}
        width="100%"
        height="600"
        frameBorder="0"
        allow="camera"
        onReceiveMessage={handleReceiveMessage}
      />
    </UserBox>
  );
};

YotiIframe.propTypes = {
  docScanUrl: PropTypes.string.isRequired,
  close: PropTypes.func.isRequired,
  finishDocScanSession: PropTypes.func.isRequired
};

const mapDispatchToProps = (dispatch) => ({
  finishDocScanSession: async () => {
    const res = await Api.actions.user.finishDocScanSession()(dispatch);
    if (res) {
      User.actions.getKYC()(dispatch);
    }
  }
});

export default compose(
  makeModal('docScan', { name: 'modal-fade', timeout: 200 }, { className: 'docScan modal' }),
  connect(null, mapDispatchToProps)
)(YotiIframe);
