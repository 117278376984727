/* eslint-disable react/no-multi-comp,max-classes-per-file -- Legacy component, might be refactored at some point */
import React, { Component, forwardRef } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import cc from 'classcat';
import { isEmpty, isNil } from 'lodash';
import { Helmet } from 'react-helmet-async';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import ClickOutside from 'components/ClickOutside';
import Modals from 'modules/Modals';
import { applyContainerQuery } from 'lib/react-container-query';
import Swipeable from 'components/Swipeable';
import { constants as routerConstants } from 'modules/Router';
import { PANEL_SIDE, PANEL_SIDE_OPPOSITE, PANEL_CORNER_SIDE, PANEL_CORNERS } from 'lib/edgePanel';
import Analytics from 'modules/Analytics';
import './modal.css';

const { ACTION } = routerConstants;

const query = {
  'max-width-600': {
    maxWidth: 600
  },
  'max-width-505': {
    maxWidth: 505
  }
};

const noOp = () => {};

const withRouterForwardRef = (BaseComponent) => {
  const WithRouter = withRouter(({ forwardedRef, ...props }) => (
    <BaseComponent ref={forwardedRef} {...props} />
  ));

  const result = forwardRef((props, ref) => <WithRouter {...props} forwardedRef={ref} />);
  result.displayName = WithRouter.displayName || WithRouter.name;

  return result;
};

const makeModal = (name, transition, opts) => (ComposedComponent) => {
  class _ModalBase extends Component {
    constructor(props) {
      super(props);
      const className = opts?.className || '';
      // Owing these classes here instead of expecting them to be passed in because we own them in CSS,
      // and they are expected to be present when edgePanel option is present. (In other words they derive from edgePanel option.)
      // We should not ask/expect from the consumer to sync edgePanel option with these classes.
      const internalClassName = cc(
        opts?.edgePanel ? ['edgePanel', `edgePanel--${opts?.edgePanel}`] : []
      );
      this.timeout = null;
      this.mounted = false;
      this.state = {
        hovering: false,
        expired: false,
        className: cc([className, internalClassName]),
        id: (opts && opts.id) || ''
      };

      // For now, ignoring dynamic modals, as they lack meaningful names and can't be mapped via spark/pq
      // without developer intervention. This will likely be revisited in the future.
      // Notification modals are also ignored, as they are not intended to be tracked at this time.
      // The "method" is an optional prop, utilized by the "addPayment" modal to pre-select a payment method.
      // In amplitude, we want to ignore those use cases.
      // It will require a much bigger effort to decouple the "addPayment" modal.
      if (!this.props?.dialog && !this.props?.notification) {
        this.props.trackOpen({ name, method: this?.props.method });
      }
    }

    componentDidMount() {
      const { expiration, exists, create } = this.props;
      if (!exists) create();
      this.mounted = true;
      if (expiration) {
        this.timeout = setTimeout(() => {
          if (this.mounted) {
            this.setState({ expired: true });
          }
        }, expiration);
      }
      document.addEventListener('keydown', this.detectEsc, false);
    }

    componentDidUpdate(prevProps, prevState) {
      if (
        prevState.expired !== this.state.expired ||
        (prevState.hovering !== this.state.hovering && this.state.expired)
      ) {
        this.closeModal();
        return;
      }

      const previous = prevProps.location.pathname || this.props.initialPathname;
      const navigatedAway =
        this.props.closeOnNavigation &&
        this.props.history.action !== ACTION.REPLACE &&
        !isNil(prevProps.location.pathname) &&
        !isNil(this.props.location.pathname) &&
        this.props.location.pathname !== previous;

      if (navigatedAway) this.close();
    }

    componentWillUnmount() {
      this.mounted = false;
      clearTimeout(this.timeout);
      document.removeEventListener('keydown', this.detectEsc, false);
      if (!this.props?.dialog && !this.props?.notification) {
        this.props.trackClose({ name, method: this?.props.method });
      }
    }

    setClass = (className) => {
      if (this.mounted) this.setState({ className });
    };

    // Only a blocking modal can affect the page title
    getTitle = () =>
      this.props.title &&
      this.props.blocking && (
        <Helmet>
          <title>{this.props.title} | MrQ</title>
        </Helmet>
      );

    getComposedComponent = () => (
      <ComposedComponent
        {...this.props}
        close={this.close}
        setClass={this.setClass}
        handleMouseEnter={this.handleMouseEnter}
        handleMouseLeave={this.handleMouseLeave}
      />
    );

    getCompOutside = (className = '') => (
      <ClickOutside
        onClickOutside={
          // Close only the last blocking modal
          !this.props.blocking || this.props.blockList?.[this.props.blockList.length - 1] === name
            ? this.close
            : noOp
        }
        className={cc([this.state.className, className || this.props.className])}
        id={this.state.id || this.props.id}
      >
        {this.getComposedComponent()}
        {this.getTitle()}
      </ClickOutside>
    );

    closeModal = () => {
      const { hovering, expired } = this.state;
      if (!hovering && expired && this.props.visible) {
        this.close();
      }
    };

    close = () => {
      const { onCloseOpen, openOther, close } = this.props;
      if (onCloseOpen) {
        openOther(onCloseOpen);
      }
      close();
    };

    detectEsc = (e) => {
      if (e.keyCode === 27 && this.props.blocking && !this.props.pinned) {
        this.close();
      }
    };

    handleMouseEnter = () => {
      this.setState({ hovering: true });
    };

    handleMouseLeave = () => {
      this.setState({ hovering: false });
    };

    render() {
      const { visible, pinned, exists, blocking } = this.props;
      const transitionName = transition ? transition.name : '';
      if (visible && exists) {
        // overflow-wrap family is not relevant for an EdgePanel
        if (opts?.edgePanel) {
          if (pinned) {
            return (
              <>
                <div className={cc([this.state.className, this.props.className])}>
                  {this.getComposedComponent()}
                </div>
                {this.getTitle()}
              </>
            );
          } else {
            return this.getCompOutside();
          }
        } else if (pinned) {
          return blocking ? (
            <div className={cc(['overflow-wrap', 'overflow-wrap--modal', transitionName])}>
              <div className={cc([this.state.className, this.props.className])}>
                {this.getComposedComponent()}
              </div>
              {this.getTitle()}
            </div>
          ) : (
            <>
              {this.getComposedComponent()}
              {this.getTitle()}
            </>
          );
        } else {
          return blocking ? (
            <div className={cc(['overflow-wrap', 'overflow-wrap--modal', transitionName])}>
              {this.getCompOutside()}
            </div>
          ) : (
            this.getCompOutside()
          );
        }
      } else {
        return null;
      }
    }
  }

  _ModalBase.propTypes = {
    exists: PropTypes.bool,
    blocking: PropTypes.bool,
    blockList: PropTypes.arrayOf(PropTypes.string),
    visible: PropTypes.bool,
    pinned: PropTypes.bool,
    closeOnNavigation: PropTypes.bool,
    location: PropTypes.object.isRequired,
    initialPathname: PropTypes.string,
    title: PropTypes.string,
    onCloseOpen: PropTypes.string,
    expiration: PropTypes.number,
    className: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
    openOther: PropTypes.func,
    trackOpen: PropTypes.func,
    trackClose: PropTypes.func,
    close: PropTypes.func
  };

  _ModalBase.defaultProps = {
    exists: false,
    blocking: false,
    blockList: [],
    visible: false,
    pinned: false,
    closeOnNavigation: false,
    expiration: 0,
    className: '',
    id: '',
    title: null,
    onCloseOpen: null,
    initialPathname: null,
    openOther: null,
    trackOpen: null,
    trackClose: null,
    close: null
  };

  const ModalBase = applyContainerQuery(_ModalBase, query);

  // eslint-disable-next-line react/prefer-stateless-function -- requires refactor
  class AnimatedModal extends Component {
    componentDidMount() {
      if (!this.props.exists) this.props.create();
    }

    render() {
      // Transitions are static. If we ever want them to be dynamic we can move them inside
      // props, like `this.props.transition`
      const transitionName = transition?.name ?? 'noTransition';
      // When swipeable, we don't rely on CSS animations, but on Swipeable's spring animation.
      // We need though CSSTransition to keep it in DOM until Swipeable's animation is finished.
      const transitionTimeout = opts?.swipeable
        ? 400 /* in sync with Swipeable springConfig.duration */
        : transition?.timeout ?? 0;
      const transitionComponent = opts?.edgePanel ? 'div' : transition?.component || 'span'; // "span" for backwards compatibility,
      // but for HTML validity it should be "div" in many cases
      const unmountOnExit = transition?.unmountOnExit; // TODO: rename
      const { visible } = this.props;
      return (
        <TransitionGroup component={transitionComponent} appear enter exit>
          {visible ? (
            <CSSTransition
              key={name}
              classNames={transitionName}
              timeout={transitionTimeout}
              mountOnEnter={unmountOnExit}
              unmountOnExit={unmountOnExit}
            >
              <ModalBase
                {...this.props}
                key={name}
                className={cc([this.props.className, transitionName])}
              />
            </CSSTransition>
          ) : null}
        </TransitionGroup>
      );
    }
  }

  AnimatedModal.propTypes = {
    visible: PropTypes.bool,
    exists: PropTypes.bool
  };

  AnimatedModal.defaultProps = {
    visible: false,
    exists: false
  };

  const axisMap = {
    [PANEL_SIDE.LEFT]: 'x-reverse',
    [PANEL_SIDE.RIGHT]: 'x',
    [PANEL_SIDE.TOP]: 'y-reverse',
    [PANEL_SIDE.BOTTOM]: 'y'
  };

  // eslint-disable-next-line react/prefer-stateless-function -- requires large refactor
  class SwipeableModal extends Component {
    render() {
      const { visible, close } = this.props;
      const { edgePanel, swipeableIgnoreNativeScroll, swipeableHysteresis } = opts;
      // if transition.component is "div" swipeableIgnoreNativeScroll might be needed to be set to true
      const panelSide = PANEL_CORNER_SIDE[edgePanel]; // might be undefined which is fine
      const sideLock = PANEL_SIDE_OPPOSITE[panelSide]; // might be undefined which is fine
      const axis = axisMap[panelSide]; // might be undefined which is fine
      const className = PANEL_CORNERS.includes(edgePanel)
        ? cc(['swipeableEdgePanel', `swipeableEdgePanel--${edgePanel}`])
        : 'swipeable';

      return (
        <Swipeable
          onSwipe={close}
          className={className}
          index={visible || !sideLock ? 1 : 0}
          sideLock={sideLock}
          axis={axis}
          ignoreNativeScroll={swipeableIgnoreNativeScroll}
          hysteresis={swipeableHysteresis}
        >
          <AnimatedModal {...this.props} />
        </Swipeable>
      );
    }
  }

  const mapStateToProps = (state, { location }) => {
    const modal = Modals.selectors.getModal(state, name);
    const exists = !isEmpty(modal) && !isNil(modal.expiration);
    return {
      ...modal,
      exists: exists,
      visible: exists && modal.visible,
      pinned: exists && modal.pinned,
      blocking: exists && modal.blocking,
      blockList: Modals.selectors.getBlockingModals(state),
      className: exists && modal.className,
      onCloseOpen: exists && modal.onCloseOpen,
      id: exists && modal.id,
      pathname: location.pathname,
      expiration: (exists && modal.expiration) || 0
    };
  };

  const mapDispatchToProps = (dispatch) => ({
    open: () => dispatch(Modals.actions.open(name)),
    close: () => dispatch(Modals.actions.close(name)),
    toggle: () => dispatch(Modals.actions.toggle(name)),
    openOther: (otherModalName) => dispatch(Modals.actions.open(otherModalName)),
    trackOpen: (payload) => dispatch(Analytics.actions.modalOpened(payload)),
    trackClose: (payload) => dispatch(Analytics.actions.modalClosed(payload)),
    create: () => dispatch(Modals.actions.create(name, opts))
  });

  if (opts?.swipeable) {
    return compose(
      withRouterForwardRef,
      connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })
    )(SwipeableModal);
  }

  if (!transition) {
    return compose(withRouterForwardRef, connect(mapStateToProps, mapDispatchToProps))(ModalBase);
  } else {
    return compose(
      withRouterForwardRef,
      connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })
    )(AnimatedModal);
  }
};

export default makeModal;
