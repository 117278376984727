import { getExperimentData } from 'lib/analytics';

const variants = {
  CONTROL: 'current-design',
  TREATMENT: 'new-design'
};

const experiment = getExperimentData('first-deposit-redesign');
const isControl = experiment.variant === variants.CONTROL;
const isTreatment = experiment.variant === variants.TREATMENT;

export default { ...experiment, isControl, isTreatment, variantTreatment: variants.TREATMENT };
