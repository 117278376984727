const scriptRegistry = {};

function importScript(url, namespace, position = 'head') {
  scriptRegistry[url] =
    scriptRegistry[url] ||
    new Promise((resolve, reject) => {
      if (typeof window[namespace] === 'undefined') {
        const script = document.createElement('script');
        script.src = url;
        script.async = true;
        script.onload = () => resolve(window[namespace]);
        script.onerror = (message, source, lineno, colno, error) => {
          console.error(message);
          delete scriptRegistry[url];
          reject(error);
        };

        document[position].appendChild(script);
      } else {
        resolve(window[namespace]);
      }
    });

  return scriptRegistry[url];
}

export default importScript;
