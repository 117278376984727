import Api from 'services/Api';
import { T, AT } from './actionTypes';
import { NAME } from './constants';

// Expiration and callbacks set in middleware
export const open = (refName, message, { expiration, title, dismissible, severity = 'info' }) => ({
  type: T.OPEN,
  payload: { refName, message, title, dismissible, severity }
});

export const subscribe = {
  type: AT.WS_SUBSCRIBE._,
  payload: {}
};

export const unsubscribe = {
  type: AT.WS_UNSUBSCRIBE._,
  payload: {}
};

export const update = (
  refName,
  message,
  { expiration, title, dismissible, severity = 'info' }
) => ({
  type: T.UPDATE,
  payload: { refName, message, title, dismissible, severity }
});

export const create = (refName, message, { title, dismissible, severity = 'info', paths }) => ({
  type: T.CREATE,
  payload: { refName, message, title, dismissible, severity, paths }
});

export const pause = (refName) => ({
  type: T.PAUSE,
  payload: { refName }
});

export const close = (refName) => ({
  type: T.CLOSE,
  payload: { refName }
});

export const dynamicAction = (actionRef, dialogRef) => (dispatch, getState) => {
  if (actionRef) {
    const pendingDispatch = getState()[NAME].actions?.[dialogRef]?.[actionRef];
    if (!pendingDispatch) return; // Action was evicted

    if (Array.isArray(pendingDispatch)) {
      pendingDispatch.forEach(dispatch);
    } else {
      dispatch(pendingDispatch);
    }
    Api.request.dialog.track({ actionRef, dialogRef });
  }
};
