import type { ReactNode } from 'react';
import React from 'react';
import './overlay.css';

interface OverlayProps {
  children: ReactNode;
}

const Overlay = ({ children }: OverlayProps) => (
  <div className="bingoCardOverlay">
    <div className="bingoCardOverlay__backdrop">
      <div className="bingoCardOverlay__content">{children}</div>
    </div>
  </div>
);

Overlay.displayName = 'BingoCard.Overlay';

export default Overlay;
