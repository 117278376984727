import { handleActions } from 'redux-actions';
import update from 'update-immutable';
import type { FluxStandardAction } from 'redux-promise-middleware';
import module from 'lib/module';
import { NAME } from './constants';
import type { LobbyState } from './types';
import { initialState } from './model';
import { AT, T } from './actionTypes';

const updateSearchResult = (state: LobbyState, action: FluxStandardAction) =>
  update(state, {
    searchResult: { $set: action.payload }
  });

const updateSearchPopularResult = (state: LobbyState, action: FluxStandardAction) => {
  const { section } = action.payload;
  return update(state, {
    searchPopularResult: {
      [section]: { $set: action.payload }
    }
  });
};

const updateQuery = (state: LobbyState, action: FluxStandardAction) =>
  update(state, {
    query: { $set: action.payload }
  });

const updateCategoryContent = (state: LobbyState, action: FluxStandardAction) =>
  // use $merge for shallow merge, replacing the previous values (if any)
  // data not in the payload remain in the state
  update(state, {
    entities: { $merge: action.payload }
  });

const reducer = module(
  handleActions(
    {
      [AT.SEARCH.FULFILLED]: updateSearchResult,
      [AT.SEARCH_POPULAR.FULFILLED]: updateSearchPopularResult,
      [AT.GET_CATEGORY_CONTENT.FULFILLED]: updateCategoryContent,
      [AT.GET_COMPONENT_CONTENT.FULFILLED]: updateCategoryContent,
      [T.CLEAR_SEARCH_RESULT]: updateSearchResult,
      [T.UPDATE_QUERY]: updateQuery
    },
    initialState
  ),
  NAME
);

export default reducer;
