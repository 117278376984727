import { actions as routerActions } from 'modules/Router';
import Api from 'services/Api';
import { T } from './actionTypes';

const { push } = routerActions;

export const frameResized = (width, height, provider) => ({
  type: T.FRAME_RESIZED,
  payload: { width, height },
  meta: {
    provider: provider,
    incoming: false
  }
});

export const frameOrientationChanged = (provider) => ({
  type: T.FRAME_ORIENTATION_CHANGED,
  meta: {
    provider: provider,
    incoming: false
  }
});

export const stopAutoplay = (provider) => ({
  type: T.STOP_AUTOPLAY,
  meta: {
    provider: provider,
    incoming: false
  }
});

export const startAutoplay = (provider) => ({
  type: T.START_AUTOPLAY,
  meta: {
    provider: provider,
    incoming: false
  }
});

export const closeGame = () => (dispatch) => dispatch(push('/secure/lobby'));

export const gameReady = (slotRef) => ({
  type: T.GAME_READY,
  payload: { refName: slotRef }
});

/**
 * Subscribe to game events. Some providers like Evolution require us to explicitely subscribe to events.
 * This is a thunk in order to control dispatching, preventing middleware (eg. Translator) from modifying it.
 *
 * @param {string} provider - Game provider, eg. `'EVOLUTION'`
 */
export const subscribe = (provider) => (dispatch) =>
  dispatch({
    type: T.SUBSCRIBE,
    meta: { provider }
  });

export const getSlots = (args) => (dispatch) => Api.actions.slot.list(args)(dispatch);
