import React from 'react';
import UserBox from 'components/UserBox';
import makeModal from 'components/Modals';

const _ServicePolicies = ({ close }) => (
  <UserBox
    id="servicePolicies"
    className="servicePolicies modal"
    title="Service Policies"
    closeCallback={close}
  >
    <ul>
      <li>
        All bingo games are played over the internet and disconnections can happen beyond our
        control.
        <ul>
          <li>
            If a user disconnects during a bingo game, the game will continue to play and the user
            will be awarded any winnings if applicable.
          </li>
          <li>
            If a user disconnects before a bingo game starts but they&apos;ve already purchased
            tickets, the game will proceed as normal and the user will be awarded if they won
            anything.
          </li>
        </ul>
      </li>
      <li>
        We reserve the right to limit the minimum and maximum number of players allowed at the same
        time in a bingo room:
        <ul>
          <li>
            When a bingo room doesn&apos;t have sufficient players to start a game, all existing
            players are refunded for their tickets and the scheduled game is cancelled. The users
            may now proceed to buy tickets for the next scheduled game.
          </li>
          <li>
            When the maximum number of players is reached, all further ticket purchases from new
            players are rejected. Sometimes it can happen for bingo rooms to end up with too many
            players because of various technical reasons. In that case, all existing players are
            refunded for their tickets and the scheduled game is cancelled.
          </li>
        </ul>
      </li>
      <li>
        When a bingo game malfunctions or there&apos;s a server error, all players are refunded for
        their purchased tickets and all jackpot values are rollbacked if the tickets sold
        contributed to that jackpot&apos;s amount. If the bingo service is down, the refunds will
        happen at the next service startup.
      </li>
      <li>
        Bet and win transactions are subject to wallet service availability:
        <ul>
          <li>If the wallet service is down, all ticket purchase transactions are rejected.</li>
          <li>
            If the wallet service is down, all winning transactions are automatically backed up and
            will be processed automatically when the wallet service is restored or credited manually
          </li>
        </ul>
      </li>
      <li>
        Interruption of Service/ Time Critical Events:
        <ul>
          <li>
            When you access our service you should be aware that you may be using equipment or a
            connection which is not as fast as that used by other Customers. This may have an impact
            on your performance in time critical events offered on the service.
          </li>
        </ul>
      </li>
    </ul>
  </UserBox>
);

export default makeModal(
  'servicePolicies',
  { name: 'modal-fade', timeout: 200 },
  { className: 'servicePolicies modal' }
)(_ServicePolicies);
