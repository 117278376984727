import { handleActions } from 'redux-actions';
import update from 'update-immutable';
import type { FluxStandardAction } from 'redux-promise-middleware';
import module from 'lib/module';
import { NAME } from './constants';
import type { CmsContentState } from './types';
import { T } from './actionTypes';
import { initialState } from './model';

const cacheContent = (state: CmsContentState, { payload }: FluxStandardAction) => {
  if (!payload || !payload.key || !payload.data) {
    return state;
  }
  return update(state, {
    [payload.key]: { $set: payload.data }
  });
};

const reducer = module(
  handleActions(
    {
      [T.CACHE_CONTENT]: cacheContent
    },
    initialState
  ),
  NAME
);

export default reducer;
