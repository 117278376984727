import type { FluxStandardAction } from 'redux-promise-middleware';
import Api from 'services/Api';
import type { AiRecommendationsExperimentVariant, Section } from 'modules/Lobby';
import type { constants as CarouselConstants } from 'modules/Carousel';
import type { AppDispatch } from '../../../../store';
import { T } from './actionTypes';

type SearchPopularArgs = {
  section: CarouselConstants.TAB;
  offset: number;
  limit: number;
};

type SearchArgs = SearchPopularArgs & {
  query: string;
};

type GetCategoryContentArgs = {
  offset: number;
  limit: number;
  sort: string;
  section: Section;
  category: string;
  variant: AiRecommendationsExperimentVariant;
};

type GetComponentContentArgs = {
  offset: number;
  limit: number;
  sort: string;
  section: Section;
  category: string;
  componentRef: string;
  variant: AiRecommendationsExperimentVariant;
};

export const search = (args: SearchArgs) => (dispatch: AppDispatch) =>
  Api.actions.lobby.search(args)(dispatch);

export const searchPopular = (args: SearchPopularArgs) => (dispatch: AppDispatch) =>
  Api.actions.lobby.searchPopular(args)(dispatch);

export const clearSearchResult: () => FluxStandardAction = () => ({
  type: T.CLEAR_SEARCH_RESULT,
  payload: {}
});

export const updateQuery: (query: string) => FluxStandardAction = (query) => ({
  type: T.UPDATE_QUERY,
  payload: query
});

export const getCategoryContent = (args: GetCategoryContentArgs) => (dispatch: AppDispatch) =>
  Api.actions.lobby.getCategoryContent(args)(dispatch);

export const getComponentContent = (args: GetComponentContentArgs) => (dispatch: AppDispatch) =>
  Api.actions.lobby.getComponentContent(args)(dispatch);
