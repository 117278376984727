import React, { Component } from 'react';
import { createPortal } from 'react-dom';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import cc from 'classcat';
import { generatePath, Redirect } from 'react-router-dom';
import { applyContainerQuery } from 'lib/react-container-query';
import CarouselModule from 'modules/Carousel';
import { constants } from 'modules/Router';
import TopTabBar from './TopTabBar';
import BottomTabBar from './BottomTabBar';
import CarouselViews from './CarouselViews';

const { selectors } = CarouselModule;
const { NO_SCROLL } = constants;

const baseQuery = {
  'min-width-800': { minWidth: 800 },
  'max-width-670': { maxWidth: 670 },
  'max-width-570': { maxWidth: 570 },
  'max-width-470': { maxWidth: 470 }
};

export class _CarouselContent extends Component {
  componentDidMount() {
    const {
      portalTop,
      match: {
        params: { urlTopTab, urlBottomTab }
      },
      name,
      selectTop,
      activeTopRef,
      activeBottomRef,
      selectBottom
    } = this.props;
    this.portalTopElement = portalTop && document.getElementById(portalTop);
    if (urlTopTab !== activeTopRef && urlTopTab) selectTop(name, urlTopTab);
    if (urlBottomTab !== activeBottomRef && urlBottomTab) {
      selectBottom(name, urlBottomTab);
    }
  }

  // TODO: This can be a little slow because it triggers cascading updates.
  //  We need to refactor the Carousel redux architecture
  componentDidUpdate({
    match: {
      params: { urlTopTab: prevUrlTopTab, urlBottomTab: prevUrlBottomTab }
    },
    activeTopRef: prevActiveTopRef,
    bottomTabRefs: prevBottomTabRefs,
    activeBottomRef: prevActiveBottomRef
  }) {
    const {
      match: {
        path,
        params: { urlTopTab, urlBottomTab, ...rest }
      },
      history,
      name,
      onSelectBottom,
      activeTopRef,
      activeBottomRef,
      bottomTabRefs,
      bottomTabs,
      location,
      topTabs,
      selectTop
    } = this.props;

    const bottomActiveChanged = prevActiveBottomRef !== activeBottomRef && activeBottomRef;
    const topActiveChanged = prevActiveTopRef !== activeTopRef && activeTopRef;
    const topActiveRemained = prevActiveTopRef === activeTopRef && activeTopRef;
    const bottomTabRefsForTop = topTabs[activeTopRef]?.bottomTabs;
    // Tab refs changed eg. when dynamic categories are loaded
    const bottomTabRefsChanged = bottomTabRefs !== prevBottomTabRefs;

    if (prevUrlTopTab !== urlTopTab && urlTopTab !== activeTopRef && topTabs[urlTopTab]) {
      // Top route changed and activeTopRef needs to be updated
      selectTop(name, urlTopTab);
    }

    if (bottomTabRefsChanged) {
      // Is bottom tab already selected for the active top tab?
      const active = bottomTabRefsForTop?.some((tab) => bottomTabs[tab].active);
      if (bottomTabRefsForTop && !active) {
        // The first _untouched_ bottom tab
        const firstNullishActiveBottomTab = bottomTabRefsForTop?.find(
          (tab) => bottomTabs[tab].active ?? true
        );
        this.handleSelectBottom(
          name,
          bottomTabRefsForTop?.includes(urlBottomTab) ? urlBottomTab : firstNullishActiveBottomTab
        );
      }
    } else if (
      prevUrlBottomTab !== urlBottomTab &&
      urlBottomTab !== activeBottomRef &&
      bottomTabRefsForTop?.includes(urlBottomTab)
    ) {
      // Bottom route changed and activeBottomRef needs to be updated
      this.handleSelectBottom(name, urlBottomTab);
    } else if (!activeBottomRef && !urlBottomTab) {
      // There is no bottom tab selected in the state nor in the URL
      // redirect to first nullish tab
      const firstNullishActiveBottomTab = bottomTabRefsForTop?.find(
        (tab) => bottomTabs[tab].active ?? true
      );
      if (firstNullishActiveBottomTab) this.handleSelectBottom(name, firstNullishActiveBottomTab);
    }

    // START SYNC HISTORY
    const mustSyncBottom = bottomActiveChanged && activeBottomRef !== urlBottomTab;
    const mustSyncTop = topActiveChanged && activeTopRef !== urlTopTab;

    if (mustSyncBottom || mustSyncTop) {
      // "Replace" is intentional, only user navigation should be in history
      history.replace({
        ...location,
        state: this.swiped ? NO_SCROLL : undefined, // opts out of scroll restoration
        pathname: generatePath(path, {
          ...rest,
          urlTopTab: mustSyncTop ? activeTopRef : urlTopTab,
          urlBottomTab: mustSyncBottom ? activeBottomRef : urlBottomTab
        })
      });
      this.swiped = false;
    }
    // END SYNC HISTORY

    if (bottomActiveChanged && topActiveRemained && onSelectBottom) {
      // Bottom tab change callback
      onSelectBottom(name, activeBottomRef);
    }
  }

  handleChange = (index) => {
    // Swipe handler
    const { topTabs, name, activeTopRef } = this.props;
    this.swiped = true;
    this.handleSelectBottom(name, topTabs[activeTopRef].bottomTabs[index]);
  };

  handleSelectTop = (name, tabRef) => {
    const { topTabs, onSelectTop } = this.props;
    const tab = topTabs[tabRef];
    if (!tab) {
      return false;
    }
    if (onSelectTop) onSelectTop(name, tabRef);
  };

  handleSelectBottom = (name, tabRef) => {
    const { bottomTabs, selectBottom } = this.props;
    const tab = bottomTabs[tabRef];
    if (!tab) {
      return false;
    }
    selectBottom(name, tabRef);
  };

  render() {
    const {
      name,
      match: { path, params },
      location: { search, hash },
      dynamicHeight,
      topTabs,
      topTabRefs,
      bottomTabs,
      bottomTabRefs,
      activeTopRef,
      activeBottomRef,
      getView,
      className,
      overrideTop,
      overrideBottom,
      containerQuery,
      getBottomPrependTab
    } = this.props;

    const carouselClass = cc([
      'carousel',
      containerQuery,
      name,
      className,
      dynamicHeight && 'carousel--dynamicHeight'
    ]);
    const topTabBar = (
      <TopTabBar
        topTabs={topTabs}
        topTabRefs={topTabRefs}
        selectTop={this.handleSelectTop}
        name={name}
        override={overrideTop}
        path={path}
        matchParams={params}
      />
    );

    return (
      <div className={carouselClass}>
        {topTabBar}
        {/* Why render the portal additionally instead of conditionally? */}
        {/* We render both and depend on CSS to display the correct version because it is faster */}
        {/* despite the duplication. A render prop + Media wrapper would be slower to update. */}
        {this.portalTopElement && createPortal(topTabBar, this.portalTopElement)}
        {!topTabs[activeTopRef]?.hideBottomTabBar && (
          <BottomTabBar
            topTabs={topTabs}
            topTabRefs={topTabRefs}
            bottomTabs={bottomTabs}
            bottomTabRefs={bottomTabRefs}
            activeTop={activeTopRef}
            name={name}
            override={overrideBottom}
            path={path}
            matchParams={params}
            getBottomPrependTab={getBottomPrependTab}
          />
        )}
        <div className={`mainView ${activeTopRef}`}>
          {topTabs[activeTopRef]?.bottomTabs ? (
            <CarouselViews
              activeTop={activeTopRef}
              bottomRefs={topTabs[activeTopRef].bottomTabs}
              activeBottomRef={activeBottomRef}
              getView={getView}
              handleChange={this.handleChange}
            />
          ) : null}
        </div>
        {!params.urlBottomTab && activeTopRef === params.urlTopTab ? (
          // Prevents empty urlBotttomTab
          // activeTopRef === urlTopTab checks for consistent state before redirecting
          <Redirect
            to={{
              pathname: generatePath(path, {
                ...params,
                urlBottomTab: activeBottomRef
              }),
              search,
              hash
            }}
          />
        ) : null}
      </div>
    );
  }
}

_CarouselContent.propTypes = {
  name: PropTypes.string,
  dynamicHeight: PropTypes.bool,
  topTabs: PropTypes.object,
  bottomTabs: PropTypes.object,
  topTabRefs: PropTypes.arrayOf(PropTypes.string),
  bottomTabRefs: PropTypes.arrayOf(PropTypes.string),
  activeTopRef: PropTypes.string,
  activeBottomRef: PropTypes.string,
  selectTop: PropTypes.func,
  selectBottom: PropTypes.func,
  onSelectTop: PropTypes.func,
  onSelectBottom: PropTypes.func,
  /** ADDITIONALLY render TopTabs to a portal, is not mutable. Element id, passed to getElementById() */
  portalTop: PropTypes.string,
  getView: PropTypes.object,
  className: PropTypes.string,
  containerQuery: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  overrideTop: PropTypes.oneOfType([PropTypes.object, PropTypes.bool]),
  overrideBottom: PropTypes.oneOfType([PropTypes.object, PropTypes.bool])
};

_CarouselContent.defaultProps = {
  name: null,
  dynamicHeight: false,
  topTabs: null,
  bottomTabs: null,
  topTabRefs: null,
  bottomTabRefs: null,
  activeTopRef: undefined,
  activeBottomRef: undefined,
  selectTop: null,
  selectBottom: null,
  onSelectTop: null,
  onSelectBottom: null,
  portalTop: null,
  getView: null,
  className: null,
  overrideTop: null,
  overrideBottom: null
};

const mapStateToProps = (state, ownProps) => ({
  topTabs: selectors.getTopTabs(state, ownProps.name),
  topTabRefs: selectors.getTopTabRefs(state, ownProps.name),
  bottomTabs: selectors.getBottomTabs(state, ownProps.name),
  bottomTabRefs: selectors.getBottomTabRefs(state, ownProps.name),
  activeTopRef: selectors.getActiveTopRef(state, ownProps.name),
  activeBottomRef: selectors.getActiveBottomRef(state, ownProps.name)
});

const mapDispatchToProps = (dispatch) => ({
  selectTop: (name, ref) => {
    dispatch(CarouselModule.actions.selectTop(name, ref));
  },
  selectBottom: (name, ref) => {
    dispatch(CarouselModule.actions.selectBottom(name, ref));
  }
});

export default compose(connect(mapStateToProps, mapDispatchToProps), applyContainerQuery)(
  _CarouselContent,
  baseQuery
);
