export const NUMBER_LIST_TYPE = {
  ROULETTE: 'roulette',
  BLACKJACK: 'blackjack',
  JACKPOT: 'jackpot',
  UNIFORM_SQUARES: 'uniformSquares'
};

export const TILE_TYPE = {
  BLACKJACK: 'BLACKJACK_TILE',
  ROULETTE: 'ROULETTE_TILE',
  ROULETTE_MULTIPLIER: 'ROULETTE_MULTIPLIER_TILE',
  DOUBLE_BALL_ROULETTE: 'DOUBLE_BALL_ROULETTE_TILE',
  DICE: 'DICE_TILE',
  MONEYWHEEL: 'MONEYWHEEL_TILE',
  MONOPOLY: 'MONOPOLY_TILE',
  CRAZY_TIME: 'CRAZY_TIME_TILE',
  BACCARAT: 'BACCARAT_TILE',
  DRAGON_TIGER: 'DRAGON_TIGER_TILE',
  TOP_CARD: 'TOP_CARD_TILE',
  MEGABALL: 'MEGABALL_TILE',
  DEFAULT: 'DEFAULT_TILE',
  CASHORCRASH: 'CASHORCRASH_TILE',
  CRAPS: 'CRAPS_TILE'
};

export const ROULETTE_NUMBERS = {
  // eslint-disable-next-line no-magic-numbers
  RED: [32, 19, 21, 25, 34, 27, 36, 30, 23, 5, 16, 1, 14, 9, 18, 7, 12, 3],
  // eslint-disable-next-line no-magic-numbers
  BLACK: [15, 4, 2, 17, 6, 13, 11, 8, 10, 24, 33, 20, 31, 22, 29, 28, 35, 26]
};
