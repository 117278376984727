import type { SelectHTMLAttributes } from 'react';
import React, { memo } from 'react';
import cc from 'classcat';
import './simpleSelect.css';

type SelectValue = string | number | null | undefined;

interface SimpleSelectProps extends Omit<SelectHTMLAttributes<HTMLSelectElement>, 'value'> {
  name: string;
  value?: SelectValue;
  placeholder?: string;
  options: SelectValue[];
  numberFormatter?: (x: number) => string;
}

export const VALUE_NOT_SET = 'VALUE_NOT_SET';

const toString = (value: SelectValue) => value?.toString() || VALUE_NOT_SET;
const defaultFormatter = (value: SelectValue) => toString(value);

const SimpleSelect = ({
  name,
  id = name,
  className = '',
  value = VALUE_NOT_SET,
  placeholder = 'Please select',
  options,
  disabled = false,
  numberFormatter = defaultFormatter,
  onFocus,
  onChange,
  title = 'Please select',
  ...rest
}: SimpleSelectProps) => {
  const stringifiedOptions = options.map(toString);
  const valueAsString = toString(value);
  const parsed = Number(valueAsString);
  return (
    <select
      id={id}
      name={name}
      title={title}
      className={cc(['simpleSelect', className])}
      tabIndex={0}
      disabled={disabled}
      value={valueAsString}
      onChange={onChange}
      onFocus={onFocus}
      {...rest}
    >
      <option disabled key={VALUE_NOT_SET} value={VALUE_NOT_SET}>
        {placeholder}
      </option>
      {/* extra option for value not being from set of possible values */}
      {!stringifiedOptions.includes(valueAsString) && valueAsString !== VALUE_NOT_SET && (
        <option disabled key={valueAsString} value={valueAsString}>
          {Number.isFinite(parsed) ? numberFormatter(parsed) : valueAsString}
        </option>
      )}
      {stringifiedOptions.map((_valueAsString) => {
        const _parsed = Number(_valueAsString);
        return (
          <option key={_valueAsString} value={_valueAsString}>
            {Number.isFinite(_parsed) ? numberFormatter(_parsed) : _valueAsString}
          </option>
        );
      })}
    </select>
  );
};

export default memo(SimpleSelect);
