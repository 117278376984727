import { types } from 'lib/constants';

export interface PaymentMethodOption {
  type: string;
  title: string;
  icon: string;
  tag?: string;
  value: PaymentMethodMapValues;
}

// This is for mapping the relevant deposit modal component to the payment method type.
export const paymentMethodMap = {
  [types.PCI_PROXY]: 'pci-proxy',
  [types.BRAINTREE]: 'braintree',
  [types.MOBILE]: 'mobile',
  [types.PAYPAL]: 'paypal',
  [types.BANK_ACCOUNT]: 'ecospend'
} as const;

export type PaymentMethodMapKeys = keyof typeof paymentMethodMap;
export type PaymentMethodMapValues = (typeof paymentMethodMap)[PaymentMethodMapKeys];

// TODO: Fix me, described in MQPV-556
export const paymentMethodTypeRemap = {
  ECOSPEND: 'BANK_ACCOUNT',
  FONIX: 'MOBILE'
} as const;

export type PaymentMethodTypeRemapKeys = keyof typeof paymentMethodTypeRemap;
