import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import cc from 'classcat';
import { isMobile, isIOS, isBrowser } from 'react-device-detect';
import { pwaConnect } from 'lib/pwa';
import AndroidNotification from './components/AndroidNotification';
import IPhoneNotification from './components/IPhoneNotification';
import DesktopNotification from './components/DesktopNotification';

const PWA_MODAL_POPUP_DELAY_MS = 500;

const PWAModal = ({ PWAInfo }) => {
  const [showNotification, setShowNotification] = useState(false);
  const [fading, setFading] = useState(false);
  const { installPrompt, showModal, updateLastPopUpTimestamp } = PWAInfo || {};
  const pwaInstallable = Boolean(installPrompt) && showModal;

  const renderModal = pwaInstallable && showNotification;

  const dismiss = useCallback(() => {
    setFading(true);
    updateLastPopUpTimestamp();
  }, [setFading, updateLastPopUpTimestamp]);
  const handleInstall = () => {
    if (installPrompt) {
      installPrompt();
      dismiss();
    }
  };

  useEffect(() => {
    const timeoutId = setTimeout(() => setShowNotification(true), PWA_MODAL_POPUP_DELAY_MS);

    return () => clearTimeout(timeoutId);
  }, []);

  useEffect(() => {
    if (fading) {
      setTimeout(() => {
        setShowNotification(false);
      }, 300);
    }
  }, [fading]);

  const pwaModalClasses = cc([
    'pwaModal',
    isBrowser && !isMobile && 'pwaModal--desktop',
    !fading && 'fadeInAnimation',
    fading && `fadeOutAnimation`
  ]);
  const pwaNotificationClasses = cc([
    isMobile && (fading ? 'slideUpAnimation' : 'slideDownAnimation'),
    !isMobile && (fading ? 'slideDownAnimation' : 'slideUpAnimation')
  ]);

  const modalNotification = (
    <div className={pwaModalClasses} onClick={dismiss}>
      <div className={pwaNotificationClasses}>
        {isIOS && isMobile && <IPhoneNotification onTrigger={handleInstall} />}
        {!isIOS && isMobile && <AndroidNotification onTrigger={handleInstall} />}
        {isBrowser && !isMobile && <DesktopNotification onTrigger={handleInstall} />}
      </div>
    </div>
  );
  return renderModal && modalNotification;
};

PWAModal.propTypes = {
  PWAInfo: PropTypes.object.isRequired
};
export default pwaConnect(PWAModal);
