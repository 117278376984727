
      var exported = {};

      import API from "!../../../../node_modules/style-loader/dist/runtime/injectStylesIntoStyleTag.js";
      import domAPI from "!../../../../node_modules/style-loader/dist/runtime/styleDomAPI.js";
      
      import setAttributes from "!../../../../node_modules/style-loader/dist/runtime/setAttributesWithoutAttributes.js";
      import insertStyleElement from "!../../../../node_modules/style-loader/dist/runtime/insertStyleElement.js";
      import styleTagTransformFn from "!../../../../node_modules/style-loader/dist/runtime/styleTagTransform.js";
      import content, * as namedExport from "!!../../../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[3].use[1]!../../../../node_modules/postcss-loader/dist/cjs.js??ruleSet[1].rules[3].use[2]!./mainHead.css?lazy";
      
      if (content && content.locals) {
              exported.locals = content.locals;
            }
            

var refs = 0;
var update;
var options = {};

options.styleTagTransform = styleTagTransformFn;
options.setAttributes = setAttributes;
options.insert = function insertAtTop(element) {
                // https://webpack.js.org/loaders/style-loader/#insert-styles-at-top
                /* eslint-disable no-var -- This code is not compiled, it needs to run everywhere */
                var parent = document.head;
                parent.insertBefore(element, window._pinElement);
                /* eslint-enable no-var -- Uncompiled code block ends here */
              };
options.domAPI = domAPI;
options.insertStyleElement = insertStyleElement;

exported.use = function(insertOptions) {
  options.options = insertOptions || {};

  if (!(refs++)) {
    update = API(content, options);
  }

  return exported;
};
exported.unuse = function() {
  if (refs > 0 && !--refs) {
    update();
    update = null;
  }
};



export * from "!!../../../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[3].use[1]!../../../../node_modules/postcss-loader/dist/cjs.js??ruleSet[1].rules[3].use[2]!./mainHead.css?lazy";
       export default exported;
