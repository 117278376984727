import { isNil } from 'lodash';
import Api from 'services/Api';
import {
  username as usernameRegex,
  email as emailRegex,
  password as passwordRegex
} from 'lib/valRegexes';
import { formatCurrency } from 'lib/formatters';
import { passwordErrorMessage } from 'lib/messages';

export const MIN_WITHDRAWAL = 10;
export const MAX_WITHDRAWAL = 500_000;
export const MIN_DEPOSIT = 10;
export const MAX_DEPOSIT_CARD = 10_000;
export const MAX_DEPOSIT_PAYPAL = 5_500;
export const MAX_DEPOSIT_MOBILE = 30;

export const username = (dispatch, data) => async (value) => {
  const trimmedValue = value.trim();
  const patternsValid = usernameRegex.test(trimmedValue);
  const partialUserRef = data?.partialUserRef;
  if (trimmedValue.length < 3 || trimmedValue.length > 30) {
    return '3-30 characters (letters, numbers, hyphens, dots and underscores)';
  }
  if (!patternsValid) {
    // eslint-disable-next-line max-len -- long error message
    return 'We love your creativity, but only letters, numbers, hyphens, dots and underscores are allowed';
  }
  try {
    await Api.actions.auth.checkUsernameIsAvailable(null, {
      username: trimmedValue,
      partialUserRef
    })(dispatch, true);
    return true;
  } catch (e) {
    return e.msg || 'This username has already been used';
  }
};

export const password =
  (dispatch, data) =>
  async (value, { passwordConfirm, oldPassword }) => {
    const partialUserRef = data?.partialUserRef;
    const hasConfirmation = !isNil(passwordConfirm);
    const hasOldPassword = !isNil(oldPassword);

    if (!passwordRegex.test(value)) {
      return passwordErrorMessage;
    }

    let validConfirmation;
    if (hasConfirmation) {
      validConfirmation = passwordRegex.test(passwordConfirm.value);
      if (validConfirmation && value !== passwordConfirm.value) {
        return "Passwords don't match";
      }
    }

    if (hasOldPassword) {
      if (value === oldPassword.value) {
        return 'New password must be different than the current password';
      }
    }

    try {
      await Api.actions.auth.checkPassword(null, { password: value, partialUserRef })(
        dispatch,
        true
      );
      if (validConfirmation) return ['passwordConfirm'];
      return true;
    } catch (e) {
      return e.msg || 'This password is too common or insecure';
    }
  };

export const withdrawAmount = (balance) => (amount) => {
  if (Number.isNaN(Number(amount))) {
    return false;
  } else if (amount < MIN_WITHDRAWAL) {
    return `Minimum amount ${formatCurrency(10)}`;
  } else if (balance < MIN_WITHDRAWAL) {
    return `Oops! Minimum amount ${formatCurrency(10)}. Your balance is too low.`;
  }

  const allowedAmount = Math.min(balance, MAX_WITHDRAWAL);

  if (amount > allowedAmount) {
    const formattedMax = formatCurrency(allowedAmount);
    return allowedAmount === MAX_WITHDRAWAL
      ? `Maximum amount ${formattedMax}`
      : `Oops! You only have ${formattedMax} in your wallet. Try a smaller amount.`;
  }

  return true;
};

export const depositAmount =
  (minAmount = MIN_DEPOSIT, maxAmount = MAX_DEPOSIT_CARD) =>
  (amount) => {
    if (Number.isNaN(Number(amount))) {
      return false;
    } else if (amount < minAmount) {
      return `Minimum amount ${formatCurrency(minAmount)}`;
    } else if (amount > maxAmount) {
      return `Maximum amount ${formatCurrency(maxAmount)}`;
    } else {
      return true;
    }
  };

export const depositAmountPaypal = (minAmount, maxAmount = MAX_DEPOSIT_PAYPAL) =>
  depositAmount(minAmount, maxAmount);

export const depositAmountMobile = (minAmount, maxAmount = MAX_DEPOSIT_MOBILE) =>
  depositAmount(minAmount, maxAmount);

// Verifies that email does not exist
export const emailIsAvailable = (dispatch) => async (value, partialUserRef) => {
  const email = value.trim();
  if (email === '') {
    return 'Please enter your email address';
  }
  const patternsValid = emailRegex.test(email);
  if (!patternsValid) {
    return "Hmmm, that's not a valid email...";
  }
  try {
    await Api.actions.auth.checkEmailIsAvailable(null, { email, partialUserRef })(dispatch, true);
    return true;
  } catch (e) {
    return e.msg || `Hmmm, that's not a valid email...`;
  }
};

// Verifies that email is valid (eg for password reset)
export const email = (value) => {
  const trimmedValue = value.trim();
  if (trimmedValue === '') {
    return 'Please enter your email address';
  }
  const patternsValid = emailRegex.test(trimmedValue);
  if (!patternsValid) {
    return "Hmmm, that's not a valid email...";
  }
  return true;
};

export const usernameEmail = () => async (value) => {
  const trimmedValue = value.trim();
  const patternsValid = trimmedValue.includes('@')
    ? emailRegex.test(trimmedValue)
    : usernameRegex.test(trimmedValue);

  if (!patternsValid) {
    return 'Incorrect username or email';
  }
  return true;
};

// Validates given regex only when field is required
export const validateRequired = (regex) => (value, fields, name) =>
  !fields[name].required || !!(value?.trim() && regex.test(value));
