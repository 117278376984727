import { createSelectorCreator, createSelector } from 'reselect';
import { flowRight, isNil, mapValues, omit } from 'lodash';
import { equalityCheckNParamsCreator } from 'reselect-equality-check-n-parameters';
import update from 'update-immutable';
import Bingo from 'modules/Bingo';
import User from 'modules/User';
import * as m from './model';
import { NAME } from './constants';

export const model = (state) => state[NAME] || m.initialState;
export const carousel = (state, name) => model(state)[name];
// Following selectors use (state, name) => {}
export const _getTopTabs = flowRight([m.topTabs, carousel]);
export const _getBottomTabs = flowRight([m.bottomTabs, carousel]);
export const getTopTabRefs = flowRight([m.topTabRefs, carousel]);

const _getBottomTabRefs = createSelector([carousel], m.bottomTabRefs);

export const getTopTabs = createSelector(
  [_getTopTabs, User.selectors.getDisabledTabs, User.selectors.getRewardsCount],
  (topTabs, disabledTabs, rewardCount) => {
    if (!rewardCount && !disabledTabs?.length) {
      return topTabs;
    }
    let topTabsWithRewardCount = topTabs;
    // topTabs?.rewards check because the topTabs completely changes when the player is in bingo room view.
    if (rewardCount && topTabs?.rewards) {
      topTabsWithRewardCount = update(topTabs, { rewards: { counter: { $set: rewardCount } } });
    }
    if (disabledTabs.length > 0) {
      return mapValues(topTabsWithRewardCount, (topTab) => ({
        ...topTab,
        bottomTabs: topTab.bottomTabs.filter((tab) => !disabledTabs.includes(tab))
      }));
    }
    return topTabsWithRewardCount;
  }
);

export const getBottomTabs = createSelector(
  [_getBottomTabs, User.selectors.getDisabledTabs],
  (bottomTabs, disabledTabs) => {
    if (disabledTabs.length > 0) {
      return omit(bottomTabs, disabledTabs);
    }
    return bottomTabs;
  }
);

export const getBottomTabRefs = createSelector(
  [_getBottomTabRefs, User.selectors.getDisabledTabs],
  (bottomTabRefs, disabledTabs) => {
    if (disabledTabs.length > 0) {
      return bottomTabRefs.filter((tab) => !disabledTabs.includes(tab));
    }
    return bottomTabRefs;
  }
);

export const getActiveTopRef = createSelector([getTopTabs], m.activeTopRef);

export const getActiveBottomRef = createSelector(
  getActiveTopRef,
  _getTopTabs,
  _getBottomTabs,
  (activeTab, topTabs, bottomTabs) =>
    topTabs[activeTab]?.bottomTabs.find((tab) => bottomTabs[tab].active)
);

const _next = (room) => room && room.next;
const createSelectorFirstParam = createSelectorCreator(equalityCheckNParamsCreator(1));

export const getLobbyCards = createSelectorFirstParam(
  Bingo.selectors.getRoomRefs,
  Bingo.selectors.getRooms,
  (bingoRefs, bingo) => {
    const hasBingo = bingoRefs && bingoRefs.length > 0;
    return {
      bingo: {
        'all-bingo': hasBingo && bingoRefs.filter((ref) => _next(bingo[ref])),
        ball75:
          hasBingo &&
          bingoRefs.filter((ref) => _next(bingo[ref]) && _next(bingo[ref]).type === '75'),
        ball90:
          hasBingo &&
          bingoRefs.filter((ref) => _next(bingo[ref]) && _next(bingo[ref]).type === '90'),
        freeBingo:
          hasBingo && bingoRefs.filter((ref) => _next(bingo[ref]) && _next(bingo[ref]).freeRoom),
        jackpots:
          hasBingo &&
          bingoRefs.filter(
            (ref) => _next(bingo[ref]) && !isNil(_next(bingo[ref]).progressiveJackpotAmount)
          )
      }
    };
  }
);

// export const _getFavorites = (refSelector, roomSelector) => createSelector(
//  refSelector,
//  roomSelector,
//  (slotRefs, slots) => {
//    const hasSlot = slotRefs && slotRefs.length > 0;
//    return hasSlot && slotRefs.filter((ref) => slots[ref].favourite);
//  }
// );
//
// export const getBingoFavorites = _getFavorites(Bingo.selectors.getRoomRefs, Bingo.selectors.getRooms);
// export const getSlotFavorites = _getFavorites(Slot.selectors.getRoomRefs, Slot.selectors.getRooms);
