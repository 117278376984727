import React from 'react';
import cc from 'classcat';
import { Typography, TypographyVariant } from 'components/Typography';
import Tab from 'components/Tab';
import { useAppSelector } from 'hooks/redux';
import Carousel from 'modules/Carousel';
import './topTabBar.css';

interface StateTab {
  refName: string;
  title?: string;
  icon?: string;
  counter?: number;
  active?: boolean;
}

interface TopTabBarProps {
  isLobby?: boolean;
}

const TopTabBar = ({ isLobby }: TopTabBarProps) => {
  const topTabs = useAppSelector(
    // @ts-expect-error -- correct number of arguments
    (state) => Carousel.selectors.getTopTabs(state, 'lobbyMiddle') as Record<string, StateTab>
  );
  const topTabRefs = useAppSelector(
    (state) => Carousel.selectors.getTopTabRefs(state, 'lobbyMiddle') as string[]
  );

  return (
    <div className="topTabBar">
      {topTabRefs.map((tabRef) => {
        const tab = topTabs[tabRef];
        return (
          <Typography variant={TypographyVariant.BodyXsStrong} key={tab.refName}>
            <Tab
              className={cc([
                'tab',
                'topTabBar__tab',
                tab.refName,
                isLobby && tab.active && 'selected'
              ])}
              icon={tab.icon}
              refName={tab.refName}
              counter={tab.counter}
              href={`/secure/lobby/${tab.refName}`}
            >
              {tab.title}
            </Tab>
          </Typography>
        );
      })}
    </div>
  );
};

export default TopTabBar;
