import React, { memo } from 'react';
import cc from 'classcat';
import { generatePath } from 'react-router-dom';
import { shallowEqual } from 'react-redux';
import Tab from 'components/Tab';
import { Typography, TypographyVariant } from 'components/Typography';

// USE ARRAY OF OBJECTS WITH REF KEY. OBJECT PROPERTY ORDER IS NOT GUARANTEED
const TopTabBar = ({ topTabs, topTabRefs, selectTop, name, override, path, matchParams }) => {
  if (topTabRefs.length <= 1) {
    return null;
  }
  return (
    <div className="topTabBar">
      {topTabRefs.map((ref) => {
        const tab = topTabs[ref];
        return (
          <Typography variant={TypographyVariant.BodyXsStrong} key={ref}>
            <Tab
              className={cc([
                'topTabBar__tab',
                ref,
                tab.active && 'selected',
                override?.[ref]?.tabClasses
              ])}
              key={ref}
              refName={ref}
              handleClick={selectTop}
              name={name}
              icon={tab.icon}
              counter={tab.counter}
              href={generatePath(path, {
                ...matchParams,
                urlBottomTab: undefined,
                urlTopTab: ref
              })}
            >
              {override?.[ref]?.component || tab.title}
            </Tab>
          </Typography>
        );
      })}
    </div>
  );
};

export default memo(
  TopTabBar,
  (prevProps, nextProps) =>
    prevProps.topTabs === nextProps.topTabs &&
    prevProps.topTabRefs === nextProps.topTabRefs &&
    prevProps.name === nextProps.name &&
    prevProps.path === nextProps.path &&
    prevProps.selectTop === nextProps.selectTop &&
    shallowEqual(prevProps.matchParams, nextProps.matchParams) &&
    prevProps.override === nextProps.override
);
