import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { useEffectOnce } from 'usehooks-ts';
import Api from 'services/Api';
import UserBox from 'components/UserBox';
import makeModal from 'components/Modals';
import { MagicMove } from 'components/Transitions';
import Button from 'components/Button';
import User from 'modules/User';
import { referAFriendIntro } from 'lib/referAFriendIntro';
import RouterLink from 'components/RouterLink';
import RefereeList from './RefereeList';
import CompletedScreen from './CompletedScreen';
import { screens } from './constants';
import './referAFriend.css';

const _ReferAFriend = ({ getFreeRounds, username, freeRounds, freeRoundsGames, close }) => {
  const intro = referAFriendIntro({ freeRounds, freeRoundsGames });
  const [screen, setScreen] = useState(screens.initial);
  const [invitedFriends, setInvitedFriends] = useState(0);

  useEffectOnce(() => {
    getFreeRounds();
  });

  const handleClick = useCallback((e) => {
    e.preventDefault();
    const value = e.currentTarget && e.currentTarget.value;
    setScreen(value);
  }, []);

  const handleInviteFocus = useCallback((e) => {
    e.target.select();
    document.execCommand('copy');
  }, []);

  const goToScreen = useCallback((nextScreen) => {
    setScreen(nextScreen);
    if (nextScreen === screens.initial) {
      setInvitedFriends(0);
    }
  }, []);

  const updateInvitedFriends = useCallback((friends) => {
    setInvitedFriends(friends);
  }, []);

  const goToFirstScreen = useCallback(() => {
    goToScreen(screens.initial);
  }, [goToScreen]);

  // TODO: @andycloke refactor without `referAFriend__content` div
  return (
    <UserBox
      id="referAFriendBox"
      className="referAFriend modal"
      title="Refer A Friend"
      closeCallback={close}
      onBack={screen === screens.email || screen === screens.tel ? goToFirstScreen : null}
    >
      <div className="referAFriend__content">
        {screen === screens.completed ? (
          <h3>
            Congrats, your invites have taken flight, and{' '}
            <span className="referAFriend__highlightedText--dark">
              {invitedFriends * freeRounds} free spins{' '}
            </span>
            could be coming your way very soon!
          </h3>
        ) : (
          <h3>{intro}</h3>
        )}
        <MagicMove>
          {screen === screens.initial ? (
            <div key="optionsDiv">
              <p className="referAFriend__instruction">
                Just copy the unique link below and send it to your friends to use when they sign
                up.
              </p>
              <input
                readOnly
                type="text"
                className="invite"
                value={`https://mrq.com/invited/${username}`}
                onFocus={handleInviteFocus}
              />
              <span className="horizBreak">
                <span>or</span>
              </span>
              <div className="referAFriend__centeredContainer">
                <Button
                  value="email"
                  name="referFriend"
                  onClick={handleClick}
                  buttonText="Invite by email"
                  className="button__grey button--fullWidth referAFriend__button"
                />
                <Button
                  className="button__grey button--fullWidth referAFriend__button"
                  value="tel"
                  name="referFriend"
                  onClick={handleClick}
                  buttonText="Invite by SMS"
                />
              </div>
            </div>
          ) : screen === screens.email || screen === screens.tel ? (
            <RefereeList
              key="refereeList"
              type={screen}
              invitedFriends={invitedFriends}
              freeRounds={freeRounds}
              updateInvitedFriends={updateInvitedFriends}
              goToScreen={goToScreen}
            />
          ) : (
            <CompletedScreen key="CompletedScreen" goToScreen={goToFirstScreen} close={close} />
          )}
        </MagicMove>
        <div className="referAFriend__centeredContainer referAFriend__footer">
          <RouterLink
            href="/promotions/refer-a-friend#terms-and-conditions"
            target="_blank"
            className="referAFriend__footerLink"
          >
            Terms & Conditions
          </RouterLink>
          <RouterLink
            href="https://help.mrq.com/"
            className="referAFriend__footerLink"
            target="_blank"
            rel="noopener noreferrer"
          >
            FAQ
          </RouterLink>
        </div>
      </div>
    </UserBox>
  );
};

const mapStateToProps = (state) => ({
  username: User.selectors.model(state).username,
  freeRounds: User.selectors.model(state).freeRounds,
  freeRoundsGames: User.selectors.model(state).freeRoundsGames
});

const mapDispatchToProps = (dispatch) => ({
  getFreeRounds: () => Api.actions.user.getFreeRounds()(dispatch)
});

_ReferAFriend.propTypes = {
  getFreeRounds: PropTypes.func,
  username: PropTypes.string,
  freeRounds: PropTypes.number,
  freeRoundsGames: PropTypes.array,
  close: PropTypes.func
};

_ReferAFriend.defaultProps = {
  getFreeRounds: null,
  username: null,
  freeRounds: null,
  freeRoundsGames: null,
  close: null
};

export default compose(
  makeModal(
    'referAFriend',
    { name: 'modal-fade', timeout: 200 },
    { className: 'referAFriend modal' }
  ),
  connect(mapStateToProps, mapDispatchToProps)
)(_ReferAFriend);
