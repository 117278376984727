import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { addDays } from 'date-fns';
import { isLoading } from 'lib/redux-utils';
import Api from 'services/Api';
import User from 'modules/User';
import UserBox from 'components/UserBox';
import makeModal from 'components/Modals';
import CoolOffForm from './CoolOffForm';

const fields = {
  days: {
    initial: null,
    required: true,
    onChange: () => true,
    error: 'Select days to cool off'
  }
};

class _CoolOff extends PureComponent {
  render() {
    const { submit, loading, close } = this.props;
    return (
      <UserBox
        id="coolOffBox"
        className="coolOff modal"
        title="Set time-out period"
        closeCallback={close}
      >
        <CoolOffForm fields={fields} submit={submit} loading={loading} />
      </UserBox>
    );
  }
}

const mapStateToProps = (state) => ({
  loading: isLoading(state, User.actionTypes.AT.COOL_OFF._)
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  submit: async ({ days }) => {
    try {
      const res = await Api.actions.user.coolOff(null, {
        endDate: addDays(new Date(), days)
      })(dispatch);
      ownProps.close();
      Api.actions.auth.logout(null, new FormData())(dispatch);
    } catch (e) {
      return e.msg;
    }
  }
});

export default compose(
  makeModal('coolOff', { name: 'modal-fade', timeout: 200 }, { className: 'coolOff modal' }),
  connect(mapStateToProps, mapDispatchToProps)
)(_CoolOff);
