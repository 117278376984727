import React, { useMemo, useRef, useState } from 'react';
import { type ReactMarkdownProps } from 'react-markdown';
import { kebabCase } from 'lodash';
import loadable from '@loadable/component';
import type { ExtraComponentProps } from '@loadable/component';
import RouterLink from 'components/RouterLink';
import LoadingElement from 'components/Loading/LoadingElement';
import Heading from 'components/Heading';
import MarkdownLinkFactory from './src/MarkdownLinkFactory';

export const ReactMarkdown = loadable(
  () => import(/* webpackChunkName: "react-markdown" */ 'react-markdown')
);

const Root = ({ children }: { children: JSX.Element[] }) => <>{children}</>;
const Address = ({ value }: { value: string }) => <address>{value}</address>;
const Title = ({ level, children }: { level: number; children: JSX.Element[] }) => (
  <Heading level={level} id={kebabCase(children[0].props.children)}>
    {children}
  </Heading>
);

export const defaultRenderers: ReactMarkdownProps['renderers'] = {
  link: RouterLink,
  root: Root,
  heading: Title,
  code: Address
};

interface MarkdownProps {
  content?: string;
  renderers?: ReactMarkdownProps['renderers'];
  onLinkClick?: () => void;
  fallback?: ExtraComponentProps['fallback'];
}

const Markdown = ({
  content,
  renderers = defaultRenderers,
  onLinkClick,
  fallback = <LoadingElement />
}: MarkdownProps) => {
  const ref = useRef<any>(); // ref object is persisted between renders
  ref.current = onLinkClick; // Share between this scope and MarkdownLinkFactory

  // Lazy useState will always return the same MarkdownLink instance
  const [MarkdownLink] = useState(() => MarkdownLinkFactory.bind(null, ref));

  const view = useMemo(
    () => (onLinkClick ? { ...renderers, link: MarkdownLink } : renderers),
    [MarkdownLink, onLinkClick, renderers]
  );

  if (!content) {
    return null;
  }

  return <ReactMarkdown source={content} renderers={view} escapeHtml={false} fallback={fallback} />;
};

export default Markdown;
