import React, { PureComponent, forwardRef } from 'react';
import type { ComponentType, ForwardedRef } from 'react';
import hoistStatics from 'hoist-non-react-statics';
import { nanoid } from 'nanoid';

interface IdState {
  id: string;
}

const withInstanceId = () =>
  function hoc<P>(BaseComponent: ComponentType<P>) {
    type Q = P & {
      forwardedRef: ForwardedRef<typeof BaseComponent>;
    };

    class InstanceId extends PureComponent<Q, IdState> {
      // eslint-disable-next-line react/static-property-placement
      static displayName = `withInstanceId(${BaseComponent.displayName || BaseComponent.name})`;

      constructor(props: Q) {
        super(props);
        this.state = {
          id: nanoid()
        };
      }

      render() {
        const { forwardedRef, ...rest } = this.props;
        const { id } = this.state;
        // @ts-ignore
        return <BaseComponent instanceId={id} ref={forwardedRef} {...rest} />;
      }
    }

    const Forwarded = forwardRef<typeof BaseComponent, P>((props, ref) => (
      <InstanceId {...props} forwardedRef={ref} />
    ));

    // @ts-ignore
    Forwarded.displayName = 'InstanceId';

    return hoistStatics(Forwarded, BaseComponent);
  };

export default withInstanceId;
