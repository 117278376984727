import Cookies from 'js-cookie';
import type { JsonObject } from 'type-fest';

interface BaseVariant {
  key: string;
  payload: JsonObject;
}

interface RegularVariant extends BaseVariant {
  experiment_key: string;
  expKey?: never;
}

interface RestartedVariant extends BaseVariant {
  experiment_key?: never;
  expKey: string;
}

type Variant = RegularVariant | RestartedVariant;

export const getExperiment = (id: string) => {
  const experimentDataCookie = Cookies.get('experiment_data');
  const experimentExclusionCookie = Cookies.get('experiment_exclude');
  let isExcluded = false;
  if (experimentDataCookie) {
    try {
      if (experimentExclusionCookie) {
        isExcluded = JSON.parse(experimentExclusionCookie);
      }
    } catch (e) {
      isExcluded = false;
    }
    if (isExcluded) return undefined;

    try {
      const {
        [id]: { key, payload, experiment_key, expKey }
      }: Record<string, Variant> = JSON.parse(experimentDataCookie);
      return {
        key,
        payload,
        experimentKey: expKey ?? experiment_key
      };
    } catch (e) {
      return undefined;
    }
  }
};

export default getExperiment;
