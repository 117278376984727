/* eslint-disable no-restricted-syntax */
function loadPolyfills(polyfills = []) {
  const polyfillImports = [];

  polyfills.forEach(async ({ test, imports, ordered = false }) => {
    if (!test) {
      if (Array.isArray(imports)) {
        if (ordered) {
          for (const singleImport of imports) {
            // eslint-disable-next-line no-await-in-loop
            const res = await singleImport();
            polyfillImports.push(res);
          }
        } else {
          polyfillImports.push(...imports.map((singleImport) => singleImport()));
        }
      } else {
        polyfillImports.push(imports());
      }
    }
  });
  return Promise.all(polyfillImports);
}

export default loadPolyfills;
