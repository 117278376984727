import React, { memo } from 'react';
import cc from 'classcat';
import { useAppSelector } from 'hooks/redux';
import type { constants } from 'modules/Wallet';
import Wallet from 'modules/Wallet';
import './paymentMethodStatus.css';

interface PaymentMethodStatusProps {
  provider: constants.Provider;
  isSemiTransparent?: boolean;
}

const PaymentMethodStatus = ({ provider, isSemiTransparent = false }: PaymentMethodStatusProps) => {
  const labelText = useAppSelector((state): any =>
    Wallet.selectors.getPaymentProviderUnavailableLabel(state, provider)
  );
  const classes = cc([
    'paymentMethodStateLabel',
    isSemiTransparent && 'paymentMethodStateLabel--semitransparent',
    'text-truncate'
  ]);
  return <div className={classes}>{labelText}</div>;
};

export default memo(PaymentMethodStatus);
