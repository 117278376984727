export const initialState = {
  messages: {
    entities: {},
    result: null
  },
  users: {
    entities: {},
    result: null
  },
  entities: {},
  result: [],
  rooms: [],
  stickyRoom: '',
  sticky: true,
  stateful: true,
  open: true,
  openLast: '',
  banned: false,
  banReason: null,
  messageLimit: 100
};

export const channels = (model) => (model && model.entities) || undefined;
export const channelRefs = (model) => (model && model.result) || undefined;
export const messages = (model) =>
  (model && model.messages && model.messages.entities) || undefined;
export const messageRefs = (model) =>
  (model && model.messages && model.messages.result) || undefined;
export const users = (model) => (model && model.users && model.users.entities) || undefined;
export const userRefs = (model) => (model && model.users && model.users.result) || undefined;
