import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { isEmpty } from 'lodash';
import { withRouter } from 'react-router';
import Api from 'services/Api';
import UserBox from 'components/UserBox';
import { isLoading } from 'lib/redux-utils';
import Modals from 'modules/Modals';
import Wallet from 'modules/Wallet';
import User from 'modules/User';
import makeModal from 'components/Modals';
import LoadingElement from 'components/Loading/LoadingElement';
import { MagicMove } from 'components/Transitions';
import { ListSolicitationsErrorMessage } from 'components/DepositLimitSolicitationsModal';
import { DISABLE_REVERSIBLE_TRANSACTIONS } from 'lib/featureFlags';
import { DepositTransferablePropsNames } from './types';
import DepositForm from './DepositForm';
import PendingWithdrawal from './components/PendingWithdrawal';

// for the moment it has been decided to not show the error but that may change later
const shouldShowGetDepositLimitSolicitationError = false;

const getTransferableProps = (instance) =>
  Object.values(DepositTransferablePropsNames).reduce(
    (acc, prop) => ({
      ...acc,
      ...(instance.props[prop] !== undefined ? { [prop]: instance.props[prop] } : {})
    }),
    {}
  );

export class _Deposit extends Component {
  static getDerivedStateFromProps({ cancellableTransactionsAmount }, { initialAmount }) {
    // Initial amount prevents delayed cancellable requests from suddenly changing the view, which could be intentionally deposit
    return !initialAmount && cancellableTransactionsAmount && !DISABLE_REVERSIBLE_TRANSACTIONS
      ? { screen: 'pendingWithdrawal', initialAmount: cancellableTransactionsAmount }
      : null;
  }

  constructor(props) {
    super(props);
    this.state = {
      screen:
        props.cancellableTransactionsAmount && !DISABLE_REVERSIBLE_TRANSACTIONS
          ? 'pendingWithdrawal'
          : 'depositForm',
      // eslint-disable-next-line react/no-unused-state -- Seems like it's unused, but will re-check in future.
      initialAmount: props.cancellableTransactionsAmount
    };
  }

  componentDidMount() {
    this.props.init();
  }

  componentDidUpdate(prevProps) {
    const { loading, paymentMethodRefs, failedKyc, depositLimitSolicitations } = this.props;
    if (prevProps.loading && !loading && isEmpty(paymentMethodRefs)) {
      this.props.close();
      this.props.openAddPayment();
    } else if (prevProps.loading && !loading && failedKyc) {
      this.props.close();
      this.props.openVerifyYourAccount();
    } else if (prevProps.loading && !loading && depositLimitSolicitations.solicitations) {
      const transferableProps = getTransferableProps(this);
      this.props.close();
      this.props.openDepositLimitSolicitationsModal({
        // We send our props to remote modal so that they can be passed back when remote modal re-opens this one.
        // The reason is that this one gets opened sometimes with custom props: { initialMethodRef: newMethodRef }
        // which we don't want to lose when opening another modal and then coming back.
        depositProps: transferableProps,
        title: 'Before you deposit'
      });
    }
  }

  handleDeposit = (_, noClose = false) => {
    const { close, getRooms } = this.props;
    getRooms();
    if (!noClose) close();
  };

  // eslint-disable-next-line class-methods-use-this, react/no-unused-class-component-methods -- Legacy code
  close = () => {};

  handleGoToScreen = (screen) => {
    this.setState({ screen });
  };

  renderChild() {
    const { screen } = this.state;
    const {
      cancellableTransactionsAmount,
      close,
      promoCode,
      location,
      initialMethodRef,
      depositLimitSolicitations
    } = this.props;
    const search = new URLSearchParams(location.search);
    const promoCodeParam = search.get('promoCode');
    const errorMessage =
      depositLimitSolicitations.requestsState[
        Wallet.constants.DEPOSIT_LIMIT_SOLICITATIONS_REQUESTS_STATE.IS_GET_SUCCESSFUL
      ] === false ? (
        <ListSolicitationsErrorMessage close={close} />
      ) : null;

    if (errorMessage && shouldShowGetDepositLimitSolicitationError) return errorMessage;

    switch (screen) {
      case 'pendingWithdrawal':
        return (
          <PendingWithdrawal
            amount={cancellableTransactionsAmount}
            goToScreen={this.handleGoToScreen}
          />
        );
      case 'depositForm':
        return (
          <DepositForm
            key="DepositForm"
            onDone={close}
            onDeposit={this.handleDeposit}
            promoCode={promoCode || promoCodeParam}
            initialMethodRef={initialMethodRef}
          />
        );
      default:
        return null;
    }
  }

  render() {
    const { close, loading } = this.props;

    return (
      <UserBox
        id="depositBox"
        className="deposit modal"
        title="Make a deposit"
        closeCallback={close}
      >
        <MagicMove loadingTimeout={0}>
          {loading ? <LoadingElement key="LoadingElement" /> : this.renderChild()}
        </MagicMove>
      </UserBox>
    );
  }
}

_Deposit.propTypes = {
  init: PropTypes.func.isRequired,
  close: PropTypes.func.isRequired,
  getRooms: PropTypes.func.isRequired,
  openAddPayment: PropTypes.func.isRequired,
  location: PropTypes.object.isRequired,
  paymentMethodRefs: PropTypes.arrayOf(PropTypes.string),
  loading: PropTypes.bool
};

_Deposit.defaultProps = {
  loading: true,
  paymentMethodRefs: null
};

const mapStateToProps = (state) => ({
  failedKyc: User.selectors.model(state).failedKyc,
  paymentMethodRefs: Wallet.selectors.getPaymentMethodRefs(state),
  cancellableTransactionsAmount: Wallet.selectors.getCancellableTransactionsAmountDeposit(state),
  depositLimitSolicitations: Wallet.selectors.getDepositLimitSolicitations(state),
  loading: isLoading(state, [
    Wallet.actionTypes.AT.SMS_VERIFICATION_STATE._,
    Wallet.actionTypes.AT.PAYMENT_AMOUNTS._,
    Wallet.actionTypes.AT.LIST_PAYMENT_METHODS._,
    Wallet.actionTypes.AT.BANK_TRANSACTIONS_DEPOSIT._,
    Wallet.actionTypes.AT.GET_DEPOSIT_LIMIT_SOLICITATIONS._
  ])
});

const mapDispatchToProps = (dispatch) => ({
  init: () => {
    Api.actions.wallet.listPaymentMethods()(dispatch);
    Api.actions.wallet.paymentAmounts()(dispatch, true); // Disabled until implemented.
    Api.actions.wallet.getDepositLimitSolicitations(undefined, undefined, {
      skipCache: true
    })(dispatch);
    return Api.actions.wallet.pendingWithdrawals()(dispatch);
  },
  getRooms: () => {
    Api.actions.bingo.list({ instance: 'next' })(dispatch);
  },
  openDepositLimitSolicitationsModal: (props) =>
    dispatch(Modals.actions.open('depositLimitSolicitations', props)),
  openAddPayment: () => dispatch(Modals.actions.open('addPayment')),
  openVerifyYourAccount: () => dispatch(Modals.actions.open('verifyYourAccount'))
});

export default compose(
  makeModal('deposit', { name: 'modal-fade', timeout: 200 }, { className: 'deposit modal' }),
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(_Deposit);
