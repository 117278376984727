/* eslint-disable @typescript-eslint/no-unsafe-return -- Redux modules missing full TS support */
import React, { memo, useCallback } from 'react';
import RewardCard from 'components/RewardCard';
import User, { constants } from 'modules/User';
import { useAppDispatch, useAppSelector } from 'hooks/redux';
import Modals from 'modules/Modals';

export const DailyFreeGamesList = () => {
  const dailyFreeGames = useAppSelector((state) => User.selectors.getDailyFreeGames(state));
  const dailyFreeGameRefs = useAppSelector((state) => User.selectors.getDailyFreeGameRefs(state));
  const dispatch = useAppDispatch();
  const openRewardTCModal = useCallback(
    (content) => dispatch(Modals.actions.open('rewardTermsConditions', { content })),
    [dispatch]
  );
  return (
    <>
      {dailyFreeGameRefs.map((ref: string) => {
        const { title, days, image, isLocked, toc, amount, currentDay, description, ribbonLabel } =
          dailyFreeGames[ref];
        return (
          <RewardCard
            key={ref}
            title={title}
            description={description}
            rewardAmount={amount}
            ribbonLabel={ribbonLabel}
            status={isLocked ? constants.RewardStatus.Locked : constants.RewardStatus.Seen}
            imageUrl={image}
            path={ref}
            currentDay={currentDay}
            openTCModal={openRewardTCModal}
            termsAndConditions={toc}
            isDfg
            dfgDays={days}
          />
        );
      })}
    </>
  );
};
export default memo(DailyFreeGamesList);
