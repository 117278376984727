import React, { memo } from 'react';
import ListTile from 'components/ListTile';

export type TileData = {
  ref: string;
  name: string;
  displayProvider: string;
  lobbyImageUrl: string;
  blur: boolean;
  useCFImage?: boolean;
};

export interface ListTilesProps {
  tiles: TileData[];
  isKycPassed: boolean;
}
const ListTiles = ({ tiles, isKycPassed }: ListTilesProps) => (
  <>
    {tiles.map((item) => {
      const { ref, name, blur, ...rest } = item;
      return <ListTile key={ref} refName={ref} name={name} blur={!isKycPassed && blur} {...rest} />;
    })}
  </>
);

export default memo(ListTiles);
