import { createMiddleware } from 'redux-beacon';
import GoogleTagManager from '@redux-beacon/google-tag-manager';
import gtmEvents from './src/googleTagManager';

const gtm = GoogleTagManager();

const gtmMiddleware = createMiddleware(gtmEvents, gtm);

const analyticsMiddleware = [gtmMiddleware];

export default analyticsMiddleware;
