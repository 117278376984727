import type { ReactNode } from 'react';
import React from 'react';
import * as ListItem from '../ListItem';

interface LinkBackProps {
  children: ReactNode;
  href?: string;
}

const ChevronRight = () => (
  <svg width="16" height="16">
    <use href="/assets/images1/chevron-left.svg#icon" />
  </svg>
);

const LinkBack = ({ children, href }: LinkBackProps) => (
  <ListItem.Root href={href} prefix={<ChevronRight />} prefixSpacing={ListItem.SpacingVariant.SM}>
    <ListItem.TextButton>{children}</ListItem.TextButton>
  </ListItem.Root>
);

export default LinkBack;
