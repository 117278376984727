export const screens = {
  initial: 'initial',
  email: 'email',
  tel: 'tel',
  completed: 'completed'
};

export enum ContactFieldType {
  EMAIL = 'email',
  TEL = 'tel'
}
