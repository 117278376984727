import React from 'react';
import PropTypes from 'prop-types';
import { pwaConnect } from 'lib/pwa';

export const _SidePWAButton = ({ PWAInfo }) => {
  const pwaInstallable = Boolean(PWAInfo?.installPrompt);
  const handleInstallPWA = () => {
    if (PWAInfo?.installPrompt) {
      PWAInfo?.installPrompt();
    }
  };

  return (
    (pwaInstallable && (
      <a>
        <button className="button pwaButton" type="button" onClick={handleInstallPWA}>
          <img src="/assets/images1/pwalogo.png" alt="logo" />
          <span>Add MrQ to Home Screen?</span>
        </button>
      </a>
    )) ||
    null
  );
};

_SidePWAButton.propTypes = {
  PWAInfo: PropTypes.object.isRequired
};

export default pwaConnect(_SidePWAButton);
