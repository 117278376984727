import React from 'react';
import Button from 'components/Button';
import './noMethodAllowed.css';

interface NoMethodAllowedProps {
  onClick: () => void;
  buttonText: string;
  description: string | JSX.Element;
}

const NoMethodAllowed = ({ onClick, buttonText, description }: NoMethodAllowedProps) => (
  <form className="widthdraw__noneFound">
    <img
      className="userBox__image"
      src="/assets/images1/add-payment-method.gif"
      alt=""
      width="140"
      height="120"
      data-chromatic="ignore"
    />
    <p>{description}</p>
    <div className="fieldset fieldset--fullflex fieldset--center ">
      <Button
        className="submit"
        buttonText={buttonText}
        onClick={onClick}
        name="openDeposit"
        id="noMethodAllowed-openDeposit"
      />
    </div>
  </form>
);

export default NoMethodAllowed;
