import type { types as WalletTypes } from 'modules/Wallet';
import { constants as WalletConstants } from 'modules/Wallet';
import { formatCurrency } from 'lib/formatters';
import { NO_LIMIT } from 'lib/depositLimit';
import type { DepositLimitIntervalsInURL } from './types';

const intervalToPeriod = {
  [WalletConstants.DepositLimitIntervalsInPayload.Daily]: 'day',
  [WalletConstants.DepositLimitIntervalsInPayload.Weekly]: 'week',
  [WalletConstants.DepositLimitIntervalsInPayload.Monthly]: 'month'
} as const;

export const getClosestDepositInterval = (
  depositLimitSolicitations: WalletTypes.DigestedSolicitations
) => {
  const closestIntervalName: WalletConstants.DepositLimitIntervalsInPayload = Object.values(
    WalletConstants.DepositLimitIntervalsInPayload
  ).find((entry) => depositLimitSolicitations[entry])!;
  return [
    closestIntervalName,
    depositLimitSolicitations[closestIntervalName] as WalletTypes.CurrentNextLimit
  ] as const;
};

interface GetFormatsArgs {
  interval: WalletConstants.DepositLimitIntervalsInPayload;
  current: number | null;
  next: number | null;
}

const isNil = (current: number | null): current is null | typeof NO_LIMIT.VALUE =>
  current === null || current === NO_LIMIT.VALUE;

export const getFormatted = ({ interval, current, next }: GetFormatsArgs) => {
  const _interval = interval.toLowerCase() as DepositLimitIntervalsInURL;
  const _period = intervalToPeriod[interval];

  const _current = isNil(current) ? '' : formatCurrency(current);
  const _next = isNil(next) ? '' : formatCurrency(next);

  return {
    interval: _interval,
    period: _period,
    current: _current,
    next: _next
  };
};
