import React from 'react';
import UserBox from 'components/UserBox';
import makeModal from 'components/Modals';
import AddMobile from 'components/AddPayment/AddMobile'; // TODO: Move to own dir

const _AddProfilePhoneModal = ({ close }) => (
  <UserBox
    id="addMobileBox"
    className="addMobile modal"
    title="Add profile phone number"
    closeCallback={close}
  >
    <AddMobile onDone={close} forceAdd user />
  </UserBox>
);

export default makeModal(
  'addProfilePhone',
  { name: 'modal-fade', timeout: 200 },
  { className: 'addMobile modal' }
)(_AddProfilePhoneModal);
