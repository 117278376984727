import React, { useCallback } from 'react';
import type { SyntheticEvent } from 'react';
import { useContainerQuery } from 'react-container-query';
import cc from 'classcat';
import type { RootState, AppDispatch } from 'src/store';
import type { ConnectedProps } from 'react-redux';
import { connect } from 'react-redux';
import Bingo from 'modules/Bingo';
import Analytics from 'modules/Analytics';
import { TIME, formatCurrency } from 'lib/formatters';
import Countdown from 'components/Countdown';
import Button from 'components/Button';
import { TypographyContext, TypographyVariant, getCurrentSize } from 'components/Typography';
import RouterLink from 'components/RouterLink';
import Modals from 'modules/Modals';
import Api from 'services/Api';
import * as BingoCard from './components';
import './bingoCard.css';

const { GAME_TYPE } = Bingo.constants;
type GameLaunch = Parameters<typeof Analytics.actions.gameLaunched>[0];

interface StateProps {
  /** Bingo game name */
  name?: string;
  /** Number of players in the room */
  players?: number;
  /** Bingo game type */
  gameType?: (typeof GAME_TYPE)[keyof typeof GAME_TYPE];
  /** DateTime when the next game starts */
  startDate: Date;
  /** Ticket price */
  price?: string;
  /** Jackpot amount */
  jackpot?: string;
  /** Card thumbnail image URL */
  thumbnailUrl: string;
  /** Flag to display the card as disabled */
  markedForDisable?: boolean;
  /** Flag that indicates if the card is locked */
  locked?: boolean;
  /** Message to display on the locked card */
  lockedMessage?: string;
  /** Flag that indicates if Deposit button is shown */
  promptDeposit?: boolean;
}

interface DispatchProps {
  trackLaunch: (payload: GameLaunch) => void;
  /** Deposit button onClick event handler */
  openDeposit: (e: SyntheticEvent) => void;
  /** Callback triggered when timer countdown finishes */
  updateStartDate: (refName: string) => void;
}

interface OwnProps {
  /** Reference name of the card */
  refName: string;
}

export type BingoCardProps = PropsFromRedux & OwnProps;

const cQuery = {
  'min-width-280': {
    minWidth: 280
  },
  default: {
    minWidth: 0
  }
};

const TypographyClasses: Record<string, TypographyContext> = {
  'min-width-280': { info: TypographyVariant.BodySmStrong },
  default: { info: TypographyVariant.BodyXsStrong }
};

const defaultMessage = 'We are very sorry, but this bingo room will soon be disabled';
const defaultLockedMessage = 'This bingo room is currently restricted';
const playersIcon = '/assets/images1/players.svg';
const lockIcon = '/assets/images1/lock.svg';
const priceIcon = '/assets/images1/price.svg';
const coinIcon = '/assets/images1/jackpot-coin.svg';

const handleLockedBingoCardClick = (e: React.MouseEvent<HTMLInputElement>) => {
  e.preventDefault();
  e.currentTarget.focus();
};

/** Info card for Bingo Lobby */
export const Root = ({
  refName,
  name = 'Bingo',
  players,
  gameType,
  startDate,
  price,
  jackpot,
  thumbnailUrl,
  trackLaunch,
  markedForDisable = false,
  locked = false,
  lockedMessage = defaultLockedMessage,
  promptDeposit = false,
  openDeposit,
  updateStartDate
}: BingoCardProps) => {
  const [params, containerRef] = useContainerQuery(cQuery, { width: 0 });
  const currentSize = getCurrentSize(cQuery, params);
  const restricted = markedForDisable || locked;
  const handleEnd = useCallback(() => updateStartDate(refName), [refName, updateStartDate]);
  const trackGameLaunch = useCallback(() => {
    trackLaunch({
      refName,
      name,
      section: 'Bingo'
    });
  }, [trackLaunch, name, refName]);

  return (
    <div
      ref={containerRef}
      className={cc(['bingoCard', refName, restricted && 'bingoCard--restricted', params])}
      onClickCapture={markedForDisable || locked ? handleLockedBingoCardClick : undefined}
      role="button"
      tabIndex={0}
    >
      {restricted ? (
        <BingoCard.Overlay>
          <p className="bingoCard__overlayText">{locked ? lockedMessage : defaultMessage}</p>
          {promptDeposit ? (
            <Button
              tag="div"
              buttonText="Deposit"
              name="openDeposit"
              id="bingoCard-openDeposit"
              className="bingoCard__prompt"
              onClick={openDeposit}
            />
          ) : null}
        </BingoCard.Overlay>
      ) : null}
      <TypographyContext.Provider value={TypographyClasses[currentSize]}>
        <RouterLink
          className="bingoCard__main"
          href={gameType ? `/secure/bingo/${gameType}/${refName}` : undefined}
          title={`Play ${name}`}
          onClick={trackGameLaunch}
        >
          <div className="bingoCard__top">
            <img className="bingoCard__background" src={thumbnailUrl} alt="" />
            <div className="bingoCard__labelWrapper">
              {players || players === 0 ? (
                <BingoCard.Info className="bingoCard__info" icon={playersIcon}>
                  {players}
                </BingoCard.Info>
              ) : null}
              {gameType && GAME_TYPE[gameType] ? (
                <div className="bingoCard__ring">
                  <img src={`/assets/images1/${gameType}-ball.svg`} alt={`Bingo ${gameType}`} />
                </div>
              ) : null}
            </div>
            {locked ? <img alt="" className="bingoCard__lock" src={lockIcon} /> : null}
          </div>
          <div className="bingoCard__bottom">
            <BingoCard.Info className="bingoCard__info" icon="/assets/images1/time.svg">
              <Countdown expirationDate={startDate} onEnd={handleEnd} updateInterval={TIME.SECOND}>
                {/* timeLeft is passed by Countdown where TimeDisplay is cloned */}
                <BingoCard.TimeDisplay timeLeft={0} />
              </Countdown>
            </BingoCard.Info>
            {price ? (
              <BingoCard.Info className="bingoCard__info" icon={priceIcon}>
                {price}
              </BingoCard.Info>
            ) : null}
            {jackpot ? (
              <BingoCard.Info className="bingoCard__info" icon={coinIcon}>
                {jackpot}
              </BingoCard.Info>
            ) : null}
          </div>
        </RouterLink>
      </TypographyContext.Provider>
    </div>
  );
};

Root.displayName = 'BingoCard.Root';

const mapStateToProps = (state: RootState, { refName }: OwnProps) => {
  const room = Bingo.selectors.getInstance(state, refName, 'next') || {};
  const startDate = Bingo.selectors.getNextStartDate(state, refName);
  const { name, lobbyImageUrl, players, type, markedForDisable } = room;

  return {
    name: name,
    thumbnailUrl: lobbyImageUrl,
    // @ts-expect-error
    jackpot: formatCurrency(Bingo.selectors.getBiggestJackpot(state, refName, 'next')),
    startDate: new Date(startDate),
    players: players || 0,
    // @ts-expect-error
    price: Bingo.selectors.getPriceLabel(state, refName, 'next'),
    markedForDisable: markedForDisable,
    locked: Bingo.selectors.getLocked(state, refName, 'next'),
    lockedMessage: Bingo.selectors.getLockedMessage(state, refName, 'next'),
    promptDeposit: Bingo.selectors.getPromptDeposit(state, refName, 'next'),
    gameType: type
  };
};

const mapDispatchToProps = (dispatch: AppDispatch) => ({
  trackLaunch: (payload: GameLaunch) => dispatch(Analytics.actions.gameLaunched(payload)),
  openDeposit: (e: SyntheticEvent) => {
    e.preventDefault();
    dispatch(Modals.actions.open('deposit'));
  },
  updateStartDate: (refName: string) =>
    Api.actions.bingo.startDate({ refName, instance: 'next' }, undefined, { skipSession: true })(
      dispatch
    )
});

export const connector = connect<StateProps, DispatchProps, OwnProps, RootState>(
  mapStateToProps,
  mapDispatchToProps
);
export type PropsFromRedux = ConnectedProps<typeof connector>;

export const ConnectedBingoCard = connector(Root);
