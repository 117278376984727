import Api from 'services/Api';
import { phone as validatePhone } from 'lib/valFuncs';
import { T } from './actionTypes';
import { kyc } from './constants';

const POLL_INTERVAL = 5_000;
const MAX_RETRIES = 30;

export const toggleShowBalance = {
  type: T.TOGGLE_SHOW_BALANCE
};

export const updateAvatarNotification = (avatarNotification) => ({
  type: T.UPDATE_AVATAR_NOTIFICATION,
  payload: avatarNotification
});

export const setMobile = (phoneNumber) => ({
  type: T.SET_MOBILE,
  payload: phoneNumber
});

export const unsetMobile = () => ({
  type: T.UNSET_MOBILE
});

// TODO: @GeKorm figure out a way to create shareable actions
export const updateMobile = (phoneNumber, onDone) => async (dispatch) => {
  const res = Api.actions.user.updateMobile(null, { phoneNumber })(dispatch);
  try {
    await res;
    dispatch(setMobile(phoneNumber));

    if (validatePhone(phoneNumber).warn) {
      onDone();
      return res;
    }

    await Api.actions.user.sendSmsVerification()(dispatch);
    Api.actions.user.smsVerificationState()(dispatch);

    return res;
  } catch (e) {
    return res;
  }
};

export const setRealityTimeoutId = (id) => ({
  type: T.SET_REALITY_TIMEOUT_ID,
  payload: id
});

export const setRealityIntervalId = (id) => ({
  type: T.SET_REALITY_INTERVAL_ID,
  payload: id
});

export const setRewardsCount = (count) => ({
  type: T.SET_REWARDS_COUNT,
  payload: count
});

const pollKycStatus = async (request, remainingRetries, delay) => {
  const result = await request();
  if (!result && remainingRetries > 0) {
    setTimeout(() => {
      pollKycStatus(request, remainingRetries - 1, remainingRetries > 15 ? 4_000 : 60_000);
    }, delay);
  }
};

export const getKYC = () => async (dispatch) => {
  const resData = await Api.actions.user.kyc()(dispatch);
  const kycStatus = resData.data.kycStatus;

  if (kycStatus === kyc.PENDING) {
    pollKycStatus(
      async () => {
        const newResData = await Api.actions.user.kyc()(dispatch);
        const newKycStatus = newResData.data.kycStatus;
        return newKycStatus !== kyc.PENDING;
      },
      MAX_RETRIES,
      POLL_INTERVAL
    );
  }
};

export const setNotAgreedToLatestTerms = {
  type: T.SET_NOT_AGREED_TO_LATEST_TERMS
};

export const setLiveChatUnreadCount = (count) => ({
  type: T.SET_LIVE_CHAT_UNREAD_COUNT,
  payload: count
});

export const setLiveChatLauncherVisible = (isVisible) => ({
  type: T.SET_LIVE_CHAT_LAUNCHER_VISIBLE,
  payload: isVisible
});
