import React, { cloneElement, PureComponent } from 'react';
import SwipeableViews from 'react-swipeable-views';

// eslint-disable-next-line react/prefer-stateless-function -- old component that should be refactored
class CarouselViews extends PureComponent {
  render() {
    const { activeTop, bottomRefs, activeBottomRef, getView, handleChange } = this.props;
    const activeIndex = bottomRefs.indexOf(activeBottomRef);
    const containersList = bottomRefs.map((ref) => (
      <div
        className={`viewcontainer flexContainer ${activeTop} ${ref}`}
        key={`viewcontainer-${activeTop}-${ref}`}
        id={`viewcontainer-${ref}`}
      >
        {cloneElement(getView, {
          top: activeTop,
          bottom: ref,
          isActive: activeBottomRef === ref,
          id: `viewcontainer-${ref}`
        })}
      </div>
    ));

    if (!activeBottomRef) return containersList;

    return (
      <SwipeableViews
        resistance
        index={activeIndex}
        onChangeIndex={handleChange}
        className="movable"
      >
        {containersList}
      </SwipeableViews>
    );
  }
}

export default CarouselViews;
