import React from 'react';
import makeModal from 'components/Modals';
import FailedKyc from 'components/FailedKyc';

const _VerifyYourAccount = ({ close, pathname }) => (
  <FailedKyc className="kyc modal" close={close} pathname={pathname} />
);

export default makeModal(
  'verifyYourAccount',
  { name: 'modal-fade', timeout: 200 },
  { className: 'verifyYourAccount modal' }
)(_VerifyYourAccount);
