import { createSelector } from 'reselect';
import { flowRight, pickBy } from 'lodash';
import { modals } from './model';
import { NAME } from './constants';

export const model = (state) => state[NAME];

export const getModals = flowRight([modals, model]);

export const getModal = (state, name) => model(state)[name];

export const isCurrentlyBlocking = createSelector([getModals], (_modals) =>
  _modals.some((modal) => modal.visible && modal.blocking)
);

const _getBlockingModals = createSelector(model, (_modals) =>
  Object.keys(_modals).filter((modal) => _modals[modal].visible && _modals[modal].blocking)
);

export const getBlockingModals = createSelector(
  [_getBlockingModals],
  (blockingModals) => blockingModals
);

export const dialogIsCurrentlyBlocking = createSelector([getModals], (_modals) =>
  _modals.some((modal) => modal.visible && modal.blocking && modal.dialog)
);

export const isVisible = (state, names) => names.some((name) => getModal(state, name)?.visible);

// Gets notifications in order of creation
export const getNotifications = createSelector(model, (_model) =>
  modals(pickBy(_model, (_modal) => _modal.notification)).sort(
    (prev, cur) => prev.creationDate - cur.creationDate
  )
);
