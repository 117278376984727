import { actionTypes as slotTypes } from 'modules/Slot';

/**
 * THE PAYLOAD DATA ARE THE JUST DEFAULT VALUES. The (pre-translated) action's payload is MERGED INTO the translation
 * ONLY add mappings for internal <-> external actions. Never external <-> external or internal <-> internal
 * keyMapping defines the **bidirectional** translation of the event/action keys.
 *
 * This is 2 way mapping!
 */
export default {
  keyMapping: {
    type: 'eventid',
    payload: 'data',
    flattenPayload: false
  },
  entities: {
    makedeposit: {
      type: 'Modals/OPEN',
      payload: {
        name: 'deposit'
      }
    },
    [slotTypes.T.FRAME_RESIZED]: {
      eventid: 'resizegame',
      data: {
        width: 0,
        height: 0
      }
    },
    [slotTypes.T.STOP_AUTOPLAY]: {
      eventid: 'pausegame'
    },
    [slotTypes.T.START_AUTOPLAY]: {
      eventid: 'resumegame'
    },
    roundstarted: {
      type: slotTypes.T.ROUND_STARTED
    },
    roundended: {
      type: slotTypes.T.ROUND_ENDED
    },
    dialogopened: (dispatch, action) => {
      console.log(action);
    },
    preloaderprogress: (dispatch, { payload, meta }) => {
      if (payload.percentage === 100) {
        dispatch({
          type: slotTypes.T.GAME_READY,
          payload: { refName: meta.refName }
        });
      }
    }
  }
};
