import { useCallback, useMemo } from 'react';
import { useAppDispatch, useAppSelector } from 'hooks/redux';
import CmsContent, { constants } from 'modules/CmsContent';
import type { types as CmsContentTypes } from 'modules/CmsContent';
import usePublicCmsData from 'hooks/usePublicCmsData';

const usePublicCmsDataAppFooterColumn = (column: constants.AppFooterColumn) => {
  const dispatch = useAppDispatch();
  const content = useAppSelector(CmsContent.selectors.getContentAppFooterColumn(column));
  const updateContent = useCallback(
    (cmsResponse) => {
      dispatch(CmsContent.actions.cacheContent(constants.columnStoreKey[column], cmsResponse));
    },
    [dispatch, column]
  );
  const contentPath: string = constants.columnContentPath[column];
  const { hasError, isLoading } = usePublicCmsData<CmsContentTypes.Navigation>({
    hasContent: Boolean(content),
    contentPath,
    updateContent
  });

  return useMemo(() => ({ content, hasError, isLoading }), [content, hasError, isLoading]);
};

export default usePublicCmsDataAppFooterColumn;
