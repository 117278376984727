import { useRef } from 'react';

const useDownloadHtml = (filename: string) => {
  const ref = useRef<HTMLDivElement>(null);
  const handleDownload = () => {
    if (!ref.current?.outerHTML) {
      return;
    }
    const element = document.createElement('a');
    const file = new Blob([ref.current.outerHTML], { type: 'text/html' });
    element.href = URL.createObjectURL(file);
    element.download = filename;
    element.click();
    URL.revokeObjectURL(element.href);
  };

  return { ref, handleDownload };
};

export default useDownloadHtml;
