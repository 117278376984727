import React from 'react';
import makeModal from 'components/Modals';
import UserBox from 'components/UserBox';
import Markdown from 'components/Markdown';

interface RewardTCModalProps {
  close: () => void;
  content: string;
}

const RewardTCModal = ({ close, content }: RewardTCModalProps) => (
  <UserBox id="rewardTermsConditions" title="Reward Terms and Conditions" closeCallback={close}>
    <Markdown content={content} />
  </UserBox>
);

export default makeModal(
  'rewardTermsConditions',
  { name: 'modal-fade', timeout: 200 },
  { className: 'modal' }
)(RewardTCModal);
