import { useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { useLocation } from 'react-router-dom';
import { actions as routerActions } from 'modules/Router';
import Notifications from 'modules/Notifications';

const { replace } = routerActions;

const PAYMENT_STATUS = {
  CANCELED: 'Canceled',
  COMPLETED: 'Completed',
  FAILED: 'Failed'
};

const SEVERITY = { INFO: 'INFO', SUCCESS: 'SUCCESS', WARN: 'WARN', ERROR: 'ERROR' };

const EcospendTransaction = ({ createNewNotification, redirectToLogin }) => {
  const { search } = useLocation();
  const { payment_id: paymentId, status: paymentStatus } = Object.fromEntries(
    new URLSearchParams(search).entries()
  );

  useEffect(() => {
    if (paymentId) {
      switch (paymentStatus) {
        case PAYMENT_STATUS.COMPLETED: {
          const message = `You have successfully deposited! WooHoo! (Transaction ID: ${paymentId}).`;
          createNewNotification(paymentId, message, SEVERITY.SUCCESS);
          break;
        }
        case PAYMENT_STATUS.CANCELED: {
          const message = `Your transaction has been cancelled. (Transaction ID: ${paymentId}).`;
          createNewNotification(paymentId, message, SEVERITY.ERROR);
          break;
        }
        case PAYMENT_STATUS.FAILED: {
          const message = `Your transaction has failed. Please try again. (Transaction ID: ${paymentId}).`;
          createNewNotification(paymentId, message, SEVERITY.ERROR);
          break;
        }
        default:
          break;
      }
    }
    redirectToLogin();
  }, [createNewNotification, paymentId, paymentStatus, redirectToLogin]);
  return null;
};

const mapDispatchToProps = (dispatch) => ({
  createNewNotification: (refName, message, severity) => {
    dispatch(
      Notifications.actions.create(refName, message, {
        severity: severity,
        paths: ['/secure/user/account', '/secure/lobby', '/login', '/signup']
      })
    );
  },
  redirectToLogin: () => dispatch(replace('/login'))
});

EcospendTransaction.propTypes = {
  createNewNotification: PropTypes.func.isRequired
};

export default connect(undefined, mapDispatchToProps)(EcospendTransaction);
