import React from 'react';
import { useContainerQuery } from 'react-container-query';
import cc from 'classcat';
import { constants } from 'modules/CmsContent';
import RouterLink from 'components/RouterLink';
import { Typography, TypographyVariant } from 'components/Typography';
import usePublicCmsDataAppFooterColumn from './usePublicCmsDataAppFooterColumn';
import './footerMenu.css';

const query = {
  'min-width-540': {
    minWidth: 540
  },
  'min-width-860': {
    minWidth: 860
  }
};

const FooterMenu = () => {
  const [params, containerRef] = useContainerQuery(query, { width: 0 });
  const { content: leftColMenuData } = usePublicCmsDataAppFooterColumn(
    constants.AppFooterColumn.LEFT
  );
  const { content: rightColMenuData } = usePublicCmsDataAppFooterColumn(
    constants.AppFooterColumn.RIGHT
  );
  return (
    <Typography variant={TypographyVariant.BodyXs}>
      <div ref={containerRef} className={cc(['footerMenu', params])}>
        <ul>
          {leftColMenuData
            ? leftColMenuData.map(({ id, title, path, externalPath }) => (
                <li key={id}>
                  <RouterLink href={path || externalPath}>{title}</RouterLink>
                </li>
              ))
            : null}
        </ul>
        <ul>
          {rightColMenuData
            ? rightColMenuData.map(({ id, title, path, externalPath }) => (
                <li key={id}>
                  <RouterLink href={path || externalPath}>{title}</RouterLink>
                </li>
              ))
            : null}
        </ul>
      </div>
    </Typography>
  );
};

export default FooterMenu;
