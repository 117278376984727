import React, { memo } from 'react';
import loadable from '@loadable/component';
import { LiveShape } from 'lib/LobbyEntities';
import type { LiveTile as LiveTileType } from 'lib/LobbyEntities';
import withLazyStyle from 'components/LazyStyle';
import useSlotTileAnalytics from '../../hooks/useSlotTileAnalytics';
import useLiveTile from '../../hooks/useLiveTile';
import { TileSkeleton } from '../Skeleton';
import style from './liveTile.css?lazy';

const NormalLiveTile = loadable(
  () =>
    import(
      /* webpackPrefetch: true, webpackChunkName: "LobbyNormalLiveTile" */
      './components/NormalLiveTile'
    ),
  {
    fallback: (
      <TileSkeleton
        className="dynamicLobby__skeleton-liveTile
      dynamicLobby__skeleton-liveTile--normal"
      />
    )
  }
);

const LargeLiveTile = loadable(
  () =>
    import(
      /* webpackPrefetch: true, webpackChunkName: "LobbyLargeLiveTile" */
      './components/LargeLiveTile'
    ),
  {
    fallback: (
      <TileSkeleton
        className="dynamicLobby__skeleton-liveTile
      dynamicLobby__skeleton-liveTile--large"
      />
    )
  }
);

const WideLiveTile = loadable(
  () =>
    import(
      /* webpackPrefetch: true, webpackChunkName: "LobbyWideLiveTile" */
      './components/WideLiveTile'
    ),
  {
    fallback: (
      <TileSkeleton
        className="dynamicLobby__skeleton-liveTile
      dynamicLobby__skeleton-liveTile--wide"
      />
    )
  }
);

interface LiveTileProps {
  entity: LiveTileType;
  position: number;
}

const LiveTile = ({ entity: liveTile, position }: LiveTileProps) => {
  const { shape, game } = liveTile;
  const { gameTile, tile } = useLiveTile({ game });

  const { trackGameLaunched } = useSlotTileAnalytics({
    tile: gameTile,
    source: 'Dynamic lobby',
    position,
    shape
  });

  if (!tile) {
    return null;
  }

  const path = `/secure/games/${tile.refName}`;

  return (
    <>
      {shape === LiveShape.liveNormal && (
        <NormalLiveTile tile={tile} path={path} onClick={trackGameLaunched} />
      )}
      {shape === LiveShape.liveLarge && (
        <LargeLiveTile tile={tile} path={path} onClick={trackGameLaunched} />
      )}
      {shape === LiveShape.liveWide && (
        <WideLiveTile tile={tile} path={path} onClick={trackGameLaunched} />
      )}
    </>
  );
};

export default memo(withLazyStyle(style)(LiveTile));
