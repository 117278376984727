import React, { Component, forwardRef } from 'react';
import type { ComponentType } from 'react';
import hoistStatics from 'hoist-non-react-statics';

// https://fettblog.eu/typescript-react-generic-forward-refs/
declare module 'react' {
  // eslint-disable-next-line @typescript-eslint/no-shadow,@typescript-eslint/ban-types
  function forwardRef<T, P = {}>(
    render: (props: P, ref: React.Ref<T>) => React.ReactElement | null
  ): (props: P & React.RefAttributes<T>) => React.ReactElement | null;
}

export interface Style {
  use: () => void;
  unuse: () => void;
}

export type Styles = Style | Style[];

const withLazyStyle = (styles: Styles) =>
  function hoc<P>(BaseComponent: ComponentType<P>) {
    type Q = P & {
      forwardedRef: React.ForwardedRef<typeof BaseComponent>;
    };
    const styleArray = Array.isArray(styles) ? styles : [styles];

    class LazyStyle extends Component<Q> {
      // eslint-disable-next-line react/static-property-placement
      static displayName = `withLazyStyle(${BaseComponent.displayName || BaseComponent.name})`;

      constructor(props: Q) {
        super(props);
        styleArray.forEach((style) => {
          style.use?.();
        });
      }

      componentWillUnmount() {
        styleArray.forEach((style) => {
          style.unuse?.();
        });
      }

      render() {
        const { forwardedRef, ...rest } = this.props;
        // @ts-ignore
        return <BaseComponent ref={forwardedRef} {...rest} />;
      }
    }

    const ForwardedRef = forwardRef<typeof BaseComponent, P>((props, ref) => (
      <LazyStyle {...props} forwardedRef={ref} />
    ));

    // @ts-ignore
    return hoistStatics(ForwardedRef, BaseComponent);
  };

export default withLazyStyle;
