import React, { useMemo } from 'react';
import cc from 'classcat';
import RouterLink from 'components/RouterLink';
import TextButton from '../TextButton';
import TextPrimary from '../TextPrimary';
import TextSecondary from '../TextSecondary';
import TextTertiary from '../TextTertiary';
import './listItem.css';
import type { ListItemContentProps } from './ListItemContent';
import ListItemContent from './ListItemContent';

export enum SpacingVariant {
  NONE = '0',
  XS = '4px',
  SM = '8px',
  MD = '16px',
  LG = '24px',
  XL = '32px'
}

interface ListItemProps extends ListItemContentProps {
  prefixSpacing?: SpacingVariant;
  suffixSpacing?: SpacingVariant;
  paddingX?: SpacingVariant;
  paddingY?: SpacingVariant;
  href?: string;
}

const ListItem = ({
  children,
  prefix,
  prefixSpacing = SpacingVariant.MD,
  suffix,
  suffixSpacing = SpacingVariant.MD,
  paddingX = SpacingVariant.MD,
  paddingY = SpacingVariant.MD,
  onClick,
  href
}: ListItemProps) => {
  const style = useMemo(
    () => ({
      '--prefixSpacing': prefixSpacing,
      '--suffixSpacing': suffixSpacing,
      '--paddingY': paddingY,
      '--paddingX': paddingX
    }),
    [prefixSpacing, suffixSpacing, paddingY, paddingX]
  );
  return (
    <li className={cc({ listItem: true, 'listItem--withAction': !!onClick })} style={style}>
      {href ? (
        <RouterLink href={href}>
          <ListItemContent prefix={prefix} suffix={suffix}>
            {children}
          </ListItemContent>
        </RouterLink>
      ) : (
        <ListItemContent prefix={prefix} suffix={suffix} onClick={onClick}>
          {children}
        </ListItemContent>
      )}
    </li>
  );
};

export { TextPrimary, TextSecondary, TextTertiary, TextButton, ListItem as Root };
