import type { ExperimentData } from '../../types';
import getExperiment from '../getExperiment';

const CONTROL_VARIANT = 'control';

export const getExperimentData = (flag: string): ExperimentData => {
  const experiment = getExperiment(flag);
  const variant = experiment?.key;
  return {
    flag,
    variant,
    experiment: experiment?.experimentKey,
    isControl: variant === CONTROL_VARIANT,
    isTreatment: variant ? variant !== CONTROL_VARIANT : false
  };
};

export default getExperimentData;
