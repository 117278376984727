import React, { useEffect } from 'react';
import Button from 'components/Button';
import useCountdown from 'hooks/useCountdown';

interface SMSAuthorisationProps {
  smsMobileNumber: string;
  onResendSms: (data: any) => void;
  onWrongNumber: () => void;
  nextSMSTime: number;
  smsLoading: boolean;
}

const SMSSent = ({
  onResendSms,
  onWrongNumber,
  smsMobileNumber,
  nextSMSTime,
  smsLoading
}: SMSAuthorisationProps) => {
  const [countdown, resetCountdown] = useCountdown(nextSMSTime - Date.now(), 1000);
  useEffect(() => {
    resetCountdown(nextSMSTime - Date.now());
  }, [nextSMSTime, resetCountdown]);
  const remainingSeconds = Math.ceil(countdown / 1000);
  return (
    <div className="ecospendSMSSent">
      <div className="ecospend__instruction">
        <b>An SMS with a link was sent to {smsMobileNumber}</b>
      </div>
      <div className="ecospend__tip">
        Please proceed with authorising your payment by clicking the link on the text message.
        Messages may take up to 1 minute to be delivered.
      </div>
      <div className="ecospendSMSSent__cta">
        <Button
          onClick={onResendSms}
          name="resendEcospendSms"
          loading={smsLoading}
          disabled={smsLoading || remainingSeconds > 0}
        >
          {remainingSeconds > 0
            ? `Resend in ${remainingSeconds} second${remainingSeconds === 1 ? '' : 's'}`
            : 'Resend'}
        </Button>
        <Button onClick={onWrongNumber} name="changeEcospendNumber" className="button__white">
          Wrong number?
        </Button>
      </div>
    </div>
  );
};

export default SMSSent;
