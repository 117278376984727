import React from 'react';
import DynamicModal from 'components/DynamicModal';
import makeModal from 'components/Modals';

interface ResponsiblePolicyAdapterProps {
  isPublic: boolean;
  close: () => void;
}

const ResponsiblePolicy = ({ isPublic, close }: ResponsiblePolicyAdapterProps) => (
  <DynamicModal
    id="responsiblePolicy"
    slug="responsible-policy"
    isPublic={isPublic}
    close={close}
  />
);

export default makeModal(
  'responsiblePolicy',
  { name: 'modal-fade', timeout: 200 },
  { className: 'responsiblePolicy modal', closeOnNavigation: true }
)(ResponsiblePolicy);
