import React, { memo } from 'react';
import usePublicCmsDataAppFooter from 'components/Footer/usePublicCmsDataAppFooter';
import Markdown from 'components/Markdown';

interface FooterLegalDetailsProps {
  copyrightContent?: string;
}

const empty = <>{null}</>;

const FooterLegalDetails = ({ copyrightContent }: FooterLegalDetailsProps) => {
  const { content: data } = usePublicCmsDataAppFooter(Boolean(copyrightContent));
  const content = copyrightContent ?? data?.copyright;

  return <Markdown content={content} fallback={empty} />;
};

export default memo(FooterLegalDetails);
