import React from 'react';
import PropTypes from 'prop-types';
import Button from 'components/Button';
import './pwa.css';

const DesktopNotification = ({ onTrigger }) => (
  <div onClick={(e) => e.stopPropagation()} className="pwaNotification pwaNotification--desktop ">
    <div className="pwaNotification__content pwaNotification__content--desktop">
      <img
        className="pwaNotification__content--logo"
        src="/assets/images1/pwalogo.png"
        alt="logo"
      />
      <div className="pwaNotification__content--text">Install MrQ?</div>
      <Button onClick={onTrigger} name="addPWA" id="desktop-addPWA" className="oval">
        Install
      </Button>
    </div>
  </div>
);

DesktopNotification.propTypes = {
  onTrigger: PropTypes.func.isRequired
};

export default DesktopNotification;
