import React from 'react';
import PropTypes from 'prop-types';
import Button from 'components/Button';
import FormError from 'components/FormError';

const ActionButton = ({ children, loading, error, onClick, title, name, id }) => (
  <>
    <Button
      className="submit oval"
      name={name}
      id={id}
      horizontal
      title={title || children}
      onClick={onClick}
      loading={loading}
    >
      {children}
    </Button>
    {error && <FormError errorMessage={error} />}
  </>
);

ActionButton.propTypes = {
  loading: PropTypes.bool,
  error: PropTypes.string,
  name: PropTypes.string.isRequired,
  id: PropTypes.string,
  onClick: PropTypes.func,
  title: PropTypes.string
};

ActionButton.defaultProps = {
  loading: false,
  error: null,
  id: undefined,
  onClick: null,
  title: null
};

export default ActionButton;
