import { useRef, useEffect } from 'react';
import { each } from 'lodash';
/*
 From Ciprian:
 the interval is to replay the gif. it seems that updating image’s src it will make the gif to replay from beginning.

 using setState will work only if i change it to null and then setTimeout(()=>setState(url),0) it will remove and add the logo from the dom. From user perspective this looks like a bug/glitch.
 using ref it doesn’t affect the logo in a visual way and it seems to do the job without setting it to null

 also there is the solution to append a random hash at the end of the gif src, but that would require to download a new image every minute and depending on the gif size the logo will go blank for some time, with a worst experience then using setState :slightly_smiling_face:
 */

const reset = (itself) => itself;

export const useImgSrcAutoRefresh = (refreshTime) => {
  const imageRef = useRef(null);
  useEffect(() => {
    const interval = setInterval(() => {
      const { current } = imageRef;
      if (current.children) {
        each(imageRef.current.children, (child) => {
          if (child.nodeName === 'IMG') {
            // eslint-disable-next-line no-param-reassign
            child.src = reset(child.src);
          }
        });
      } else {
        current.src = reset(current.src);
      }
    }, refreshTime);
    return () => {
      clearInterval(interval);
    };
  }, [refreshTime]);

  return imageRef;
};
