import React from 'react';
import cc from 'classcat';
import { Typography, TypographyVariant } from 'components/Typography';
import Button from 'components/Button';
import './limitsLabel.css';

interface LimitsLabelProps {
  onShow: () => void;
  showLimits: boolean;
  limitsSaved: boolean;
}
const LimitsLabel = ({ onShow, showLimits, limitsSaved }: LimitsLabelProps) => (
  <form className={cc(['limitsLabel', showLimits && 'limitsLabel__showLimits'])}>
    <Typography variant={TypographyVariant.HeaderXxs}>
      {limitsSaved ? <div>Deposit limits saved!</div> : <div>Set deposit limits?</div>}
    </Typography>
    <Typography variant={TypographyVariant.BodySm}>
      {limitsSaved ? (
        <span>You may change your limits later from your account.</span>
      ) : (
        <span>You may set deposit limits now or later from your account. </span>
      )}
    </Typography>
    {!showLimits && (
      <Button
        name="toggleSetLimits"
        className="limitsLabel__setLimitsAction"
        unstyled
        onClick={onShow}
      >
        Set deposit limits
      </Button>
    )}
  </form>
);

export default LimitsLabel;
