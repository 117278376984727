import { flowRight } from 'lodash';
import type { RootState } from 'hooks/redux';
import * as m from './model';
import { NAME } from './constants';

export const model = (state: RootState) => state[NAME] || m.initialState;
export const getLoadedSprites = flowRight([m.loadedSprites, model]) as (
  state: RootState,
  refName: string
) => string[];
