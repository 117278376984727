import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { actions as routerActions } from 'modules/Router';
import { formatCurrency } from 'lib/formatters';
import makeModal from 'components/Modals';
import UserBox from 'components/UserBox';
import Button from 'components/Button';
import './reversalSuccess.css';

const { push } = routerActions;

const _ReversalSuccess = ({ close, amount, pathname, gotoLobby }) => (
  <UserBox
    id="reversalSuccess"
    className="reversalSuccess modal"
    title="Reversal Success"
    closeCallback={close}
  >
    <p className="reversalSuccess__title">
      Your withdrawal reversal of{' '}
      <strong className="reversalSuccess__amount">{formatCurrency(amount)}</strong> was a success!
    </p>
    <p className="reversalSuccess__description">You’ll now find these funds in your account.</p>
    <Button
      buttonText="Play"
      name="close"
      id="reversalSuccess-play"
      onClick={() => {
        if (pathname.startsWith('/secure/user/account')) gotoLobby();
        close();
      }}
    />
  </UserBox>
);

const mapDispatchToProps = (dispatch) => ({
  gotoLobby: () => dispatch(push('/secure/lobby'))
});

export default compose(
  makeModal(
    'reversalSuccess',
    { name: 'modal-fade', timeout: 200 },
    { className: 'reversalSuccess modal' }
  ),
  connect(null, mapDispatchToProps)
)(_ReversalSuccess);
