import React from 'react';
import PropTypes from 'prop-types';
import Tooltip from 'components/Tooltip';

const RevealPasswordIcon = ({ passwordVisible, togglePasswordVisibility }) => {
  const labelName = passwordVisible ? 'Hide password' : 'Reveal password';
  return (
    <Tooltip className="fieldset__revealIcon" label={labelName} position="above">
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        role="img"
        aria-label={labelName}
        onClick={togglePasswordVisibility}
      >
        {passwordVisible ? (
          <use href="/assets/images1/lobby/hide-eye.svg#icon" />
        ) : (
          <use href="/assets/images1/lobby/show-eye.svg#icon" />
        )}
      </svg>
    </Tooltip>
  );
};

RevealPasswordIcon.propTypes = {
  passwordVisible: PropTypes.bool.isRequired,
  togglePasswordVisibility: PropTypes.func.isRequired
};

export default RevealPasswordIcon;
