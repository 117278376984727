import type { CSSProperties } from 'react';
import React, { memo } from 'react';
import cc from 'classcat';
import './animationContainer.css';

/*
The width/height of Animation is 100% by default, which means it should stay in a parent that has some width/height defined.
If some other variation is needed, please consider adding a new animation subcomponent.
Any new subcomponent created, it should be added to usePreloadAnimations Triggers hook;
*/

export interface AnimationProps {
  isPaused?: boolean;
  className?: string;
  style?: CSSProperties;
  children: JSX.Element | null;
}

const Animation = ({ style, className = '', isPaused = false, children }: AnimationProps) => {
  const containerClassName = cc([
    'animationContainer',
    className,
    { 'animation--paused': isPaused }
  ]);

  return (
    <div className={containerClassName} style={style}>
      {children}
    </div>
  );
};

export default memo(Animation);
