export interface ICfImage {
  width?: number;
  height?: number;
  ignoreGif?: boolean;
  format?: 'jpeg' | 'avif' | 'webp' | 'png' | 'auto';
  /** `number`: Multiply width/height (see [cloudflare docs](https://developers.cloudflare.com/images/image-resizing/url-format/#dpr))
   *
   * `"auto"`: Automatically add dpr from `window.devicePixelRatio` */
  dpr?: (typeof allowedDprs)[number] | 'auto';
  quality?: number;
  blur?: number; // 0 - 250
  brightness?: number; // 0 - 2 (1 means no change)
  // We can add support for other options as well, but for now these are enough
}
const DEFAULT_QUALITY = 92;
const DEFAULT_FORMAT = 'jpeg';
const CDN_CGI_ROOT = process.env.CDN_CGI_ROOT || '/cdn-cgi';

// MORE INFO HERE: https://developers.cloudflare.com/images/image-resizing/url-format/

// Default is Jpeg format and 92 quality. Use WebP when we migrate this implementation to Cloudflare Workers
// Do not use AVIF, encoding is very slow and not worth the headache if caching somehow fails

// Example prefix
// const squareUrlPrefix = `/cdn-cgi/image/width=265,height=265,format=jpeg,fit=cover,gravity=0.5x0.5,quality=92,dpr=1/`;

// Device pixel ratio
const allowedDprs = [1, 2] as const;
let dpr = 1;

// Dpr is often fractional, we don't want an infinite number of variations
// Works like the native srcset="xx.jpg 2x". Prefer srcSet and add the dpr option instead
// when used in img tags
export const getClosestDpr = (deviceDpr: number) => {
  const roundDpr = Math.round(deviceDpr);
  return allowedDprs.reduce((prev, curr) =>
    Math.abs(curr - roundDpr) < Math.abs(prev - roundDpr) ? curr : prev
  );
};

// Update dpr when changing screen resolution or moving the window to another screen.
// We don't want to trigger a Component update, instead when the Component is updated from other
// causes, it will get the latest url with the updated dpr.
const updatePixelRatio = () => {
  const deviceDpr = window.devicePixelRatio;
  dpr = getClosestDpr(deviceDpr);
  const resolutionQuery = matchMedia(`(resolution: ${dpr}dppx)`);
  if (resolutionQuery.addEventListener) {
    resolutionQuery.addEventListener('change', updatePixelRatio, {
      once: true
    });
  } else {
    resolutionQuery.removeListener(updatePixelRatio);
    resolutionQuery.addListener(updatePixelRatio);
  }
};

updatePixelRatio();

/**
 * #### Creates new URL with cloudflare's CDN prefix.
 * ---
 * ***Default options:***
 * ```
 * - quality: 92
 * - format: 'jpeg'
 * - ignoreGif: false
 * ```
 * @remarks The URL returned by this function will always reflect the current DPR of the device.
 * @param {string}  url - URL of the image. **Must end with a valid image format (.png, .jpeg, .gif, ...)**
 * @param {string} options - configure size, format, dimensions and quality
 * @returns {string} return new URL with the cloudflare prefix. If ignoreGif is enabled and the url ends with ".gif",
 * the original URL is returned.
 */
const cfImage = (url: string, options?: ICfImage) => {
  // Consider adding a warning/error if the url doesn't end with a valid image format (.jpg, .png, .gif, ...).
  if (!url || (options?.ignoreGif && url.endsWith('.gif'))) {
    // Cloudflare doesn't support resizing of gif images,
    // therefore we can ignore the resizing if we need to keep the gif image.
    // For context, sometime featured slot games get animated thumbnails.
    return url;
  } else {
    const format = `format=${options?.format || DEFAULT_FORMAT}`;
    const quality = `quality=${options?.quality || DEFAULT_QUALITY}`;
    // 0 (Zero) will return false for "width" and "height".
    // At the time of writing this it's not a concern
    const width = options?.width && `width=${options.width}`;
    const height = options?.height && `height=${options.height}`;
    const blur = options?.blur && `blur=${options?.blur}`;
    const brightness = options?.brightness && `brightness=${options?.brightness}`;
    // TODO: Add dpr option. 'auto' will be the current behavior using updatePixelRatio/getClosestDpr
    const others = `fit=crop,gravity=0.5x0.5,dpr=${dpr}`;
    // brightness=0.5,blur=15
    const joinedOptions = [width, height, format, quality, blur, brightness, others]
      .filter(Boolean)
      .join(',');

    return `${CDN_CGI_ROOT}/image/${joinedOptions}/${url}`;
  }
};

export default cfImage;
