import type { ChangeEvent } from 'react';
import React, { useCallback } from 'react';
import RadioGroup from 'components/RadioGroup';
import { formatCurrency } from 'lib/formatters';

interface AmountPresetPickerProps {
  values: number[];
  value?: number;
  uid?: string;
  variant?: 'blue' | 'purple';
  onChange: (value: number) => void;
}

const AmountPresetPicker = ({
  values,
  value = 0,
  uid = 'presetAmount',
  variant = 'blue',
  onChange
}: AmountPresetPickerProps) => {
  const handleChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      onChange(+event.target.value);
    },
    [onChange]
  );

  return (
    <RadioGroup
      values={values}
      uid={uid}
      variant={variant}
      onChange={handleChange}
      value={value}
      valueFormatter={formatCurrency}
    />
  );
};

export default AmountPresetPicker;
