import { actionTypeCreator, SYNC, ASYNC } from 'lib/redux-utils';
import { NAME } from './constants';

const namespace = actionTypeCreator(NAME);

export const AT = namespace({
  REFRESH: ASYNC,
  BUY_TICKETS: ASYNC,
  LIST: ASYNC,
  LIST_ASSETS: ASYNC,
  ROOM: ASYNC,
  JACKPOT: ASYNC,
  PROGRESS: ASYNC,
  GAME_IN_PROGRESS: ASYNC,
  PLAYER_COUNT: ASYNC,
  TIME_LEFT: ASYNC,
  START_GAME: ASYNC,
  START_DATE: ASYNC,
  PATTERN: ASYNC,
  PURCHASED_TICKETS: ASYNC,
  QUICK_BUY: ASYNC,
  BUY_SELECTED: ASYNC,
  ASSIGNED_TICKETS: ASYNC,
  PROGRESSIVE_INFO: ASYNC,
  WS_SUBSCRIBE: ASYNC,
  WS_UNSUBSCRIBE: ASYNC
});

export const T = namespace({
  ONE_LINE_WINNER: SYNC,
  TWO_LINES_WINNER: SYNC,
  BINGO_GAME_JACKPOT_WINNER: SYNC,
  BINGO_PROGRESSIVE_JACKPOT_WINNER: SYNC,
  BINGO_GAME_JACKPOT_ROLLOVER: SYNC,
  SELECT_TICKETS: SYNC,
  DESELECT_TICKETS: SYNC,
  AUTOSELECT_TICKETS: SYNC,
  PRESERVE: SYNC,
  DRAW_BALL: SYNC,
  MUTE: SYNC,
  SET_ACTIVE: SYNC
});
