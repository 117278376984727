export const contentUKGC = `If you feel like you need to take a break from our site, you can choose to self-exclude for either six months, nine months, one year or 5 years. If you decide to self-exclude, you won't be able to access your account until after your exclusion period has finished.

You can only re-open your account by contacting us in writing to say you’re ready to come back again; we’ll then arrange a phone call to discuss. If you’re not ready to come back, you can extend your self-exclusion period.

Find more ways to stay in control of your gaming in our [Safer Gambling Policy](?modals=responsiblePolicy)

[![GAMSTOP logo](/assets/images1/gamstop-logo.svg)](https://www.gamstop.co.uk/)

MrQ is registered with GamStop – the UK National online Self-Exclusion Scheme which lets players exclude from all participating operators gambling sites through one self-exclusion request for a period of six months, one year or five years.

For more information visit: www.gamstop.co.uk or call their free helpline on 0800 138 6518 (opening hours 08:00 am - midnight) for more information.`;

export const contentMGA = `If you feel like you need to take a break from our site, you can choose to self-exclude for a period of 6 months up to 5 years or indefinitely. If you decide to self-exclude, you won't be able to access your account during the period of your self-exclusion and you will be unsubscribed from receiving marketing emails.

You can only reopen your account by contacting us in writing once your self-exclusion has expired to say you’re ready to come back again with your reason for initially self-excluding.

A 24-hour cooling off period will apply following return from a definite period of exclusion. If you’re not ready to come back, you can extend your self-exclusion period.

Find more ways to stay in control of your gaming in our [Safer Gambling Policy](?modals=responsiblePolicy)`;
