import { T } from './actionTypes';
import type { SoundSprite } from './types';

export const loadSprite = (id: string, sounds: SoundSprite) => ({
  type: T.LOAD_SPRITE,
  payload: id,
  meta: {
    sound: {
      add: {
        [id]: sounds
      }
    }
  }
});

export const canLoadSprite = () => ({
  type: T.CAN_LOAD_SPRITE
});

export const playSound = (sprite: string, sound: string) => ({
  type: T.PLAY_SOUND,
  meta: { sound: { play: `${sprite}.${sound}` } }
});
