import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import type { SwipeableViewsProps } from 'react-swipeable-views';
import SwipeableViews from 'react-swipeable-views';
import type { SIDE_LOCK } from './constants';
import './swipeable.css';

const springConfig = {
  duration: '0.4s',
  // easeFunction is used in both directions (in and out cannot be set separately)
  easeFunction: 'ease-in-out',
  delay: '0s'
};

interface SwipeableProps {
  children: SwipeableViewsProps['children'];
  className?: SwipeableViewsProps['className'];
  index?: SwipeableViewsProps['index'];
  onSwipe?: SwipeableViewsProps['onChangeIndex'];
  ignoreNativeScroll?: SwipeableViewsProps['ignoreNativeScroll'];
  axis?: SwipeableViewsProps['axis'];
  hysteresis?: SwipeableViewsProps['hysteresis'];
  sideLock?: SIDE_LOCK;
}

const Swipeable = ({
  onSwipe,
  children,
  // when shifting the content and the surrounding container has display: block,
  // the drag behaviour will be broken due to false positives (as documented https://react-swipeable-views.com/api/api/).
  // We can set ignoreNativeScroll to true to fix this issue.
  ignoreNativeScroll,
  hysteresis,
  axis,
  sideLock,
  index = 1,
  className = 'swipeable'
}: SwipeableProps) => {
  const [internalIndex, setInternalIndex] = React.useState(index);

  // fix EdgePanel first close when blocking
  useEffect(() => {
    setTimeout(() => {
      setInternalIndex(index);
    }, 0);
  }, [index]);

  const content = sideLock
    ? [<div key="before" className="swipeable__before" />, children]
    : [<div key="before" className="swipeable__before" />, children, <div key="after" />];

  return (
    <SwipeableViews
      axis={axis}
      ignoreNativeScroll={ignoreNativeScroll}
      index={internalIndex} // fix for having animation for the first closing when modal is blocking and already in URL at first landing
      onChangeIndex={onSwipe}
      className={className}
      springConfig={springConfig}
      hysteresis={hysteresis}
    >
      {content}
    </SwipeableViews>
  );
};

Swipeable.propTypes = {
  onSwipe: PropTypes.func.isRequired
};

export default Swipeable;
