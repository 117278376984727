import type { ModernNumberFormatOptions } from './types';

let _canUseTrailingZeroDisplay: boolean;

try {
  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    trailingZeroDisplay: 'stripIfInteger',
    maximumFractionDigits: 2,
    minimumFractionDigits: 2
  } as ModernNumberFormatOptions);
  const formatted = formatter.format(1);
  _canUseTrailingZeroDisplay = formatted.endsWith('1');
} catch (e) {
  /* Istanbul ignore next */
  _canUseTrailingZeroDisplay = false;
}

/**
 * Browser supports the `Intl.Numberformat`
 * [`trailingZeroDisplay` option](https://caniuse.com/mdn-javascript_builtins_intl_numberformat_numberformat_options_trailingzerodisplay_parameter)
 */
const canUseTrailingZeroDisplay = _canUseTrailingZeroDisplay;

export default canUseTrailingZeroDisplay;
