import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import localStore, { GLOBAL } from 'lib/localStore';
import Notifications from 'modules/Notifications';
import Notification from '../Notifications/Notification';

const severityStates = ['INFO', 'SUCCESS', 'WARN', 'ERROR'];
const globalStore = localStore.instances.get(GLOBAL);

export class _Notification extends Component {
  render() {
    const { closeNotification, message, severity, dismissible } = this.props;

    return (
      <Notification
        message={message}
        severity={severityStates.indexOf(severity) + 1}
        contactSupport
        dismissible={dismissible}
        close={closeNotification}
        handleMouseEnter={() => null}
        handleMouseLeave={() => null}
      />
    );
  }
}

_Notification.propTypes = {
  message: PropTypes.string,
  severity: PropTypes.string,
  closeNotification: PropTypes.func
};

_Notification.defaultProps = {
  message: null,
  severity: null,
  closeNotification: null
};

const mapDispatchToProps = (dispatch, { refName }) => ({
  closeNotification: () => {
    globalStore.getItem('dismissedNotifications').then((localValue) => {
      const dismissedNotifications = localValue || [];
      if (dismissedNotifications.indexOf(refName) < 0) {
        dismissedNotifications.push(refName);
        globalStore.setItem('dismissedNotifications', dismissedNotifications);
      }
    });
    dispatch(Notifications.actions.close(refName));
  }
});

export default connect(null, mapDispatchToProps)(_Notification);
