import Fieldset from 'components/Fieldset';
import Button from 'components/Button';
import React from 'react';
import PropTypes from 'prop-types';

const RefereeItem = ({
  fieldId,
  nameField,
  contactField,
  contactFieldType,
  showDeleteButton,
  handleFormChange,
  handleFocus,
  handleDeleteRow
}) => (
  <div className="referAFriend__row">
    <div className="referAFriend__inputsList">
      <div className="referAFriend__inputWrapper">
        <Fieldset
          field={nameField}
          inputType="text"
          autoComplete="off"
          name={`name_${fieldId}`}
          labelText="Friend's Name"
          onChange={handleFormChange}
          onFocus={handleFocus}
        />
      </div>
      <div className="referAFriend__inputWrapper">
        <Fieldset
          field={contactField}
          inputType={contactFieldType}
          autoComplete="falsy"
          name={`contactDetails_${fieldId}`}
          labelText={contactFieldType === 'email' ? 'Email address' : 'Mobile no.'}
          onChange={handleFormChange}
          onFocus={handleFocus}
        />
      </div>
    </div>
    {showDeleteButton && (
      <Button
        className="button--iconButton button__grey referAFriend__deleteButton"
        title="Delete"
        name={fieldId}
        icon="/assets/images1/trash-primary.svg"
        onClick={handleDeleteRow}
        data-test-id="friend-button-delete"
      />
    )}
  </div>
);

RefereeItem.propTypes = {
  fieldId: PropTypes.string,
  nameField: PropTypes.object,
  contactField: PropTypes.object,
  contactFieldType: PropTypes.string,
  showDeleteButton: PropTypes.bool,
  handleFormChange: PropTypes.func,
  handleFocus: PropTypes.func,
  handleDeleteRow: PropTypes.func
};

RefereeItem.defaultProps = {
  fieldId: null,
  nameField: null,
  contactField: null,
  contactFieldType: 'text',
  showDeleteButton: false,
  handleFormChange: null,
  handleFocus: null,
  handleDeleteRow: null
};

export default RefereeItem;
