import React from 'react';

const RewardCardLoading = () => (
  <div className="rewardCard">
    <div className="rewardCard__content">
      <div className="rewardCard__loadingBlock rewardCard__thumbLoading rewardCard__thumbnail">
        <div className="shine" />
      </div>
      <div className="rewardCard__info text-truncate">
        <div className="rewardCard__loadingBlock rewardCard__title rewardCard__textLoading">
          <div className="shine" />
        </div>
        <div className="rewardCard__loadingBlock rewardCard__title rewardCard__textLoading">
          <div className="shine" />
        </div>
        <div
          className="rewardCard__loadingBlock rewardCard__title
        rewardCard__textLoading rewardCard__textLoading--tc"
        >
          <div className="shine" />
        </div>
      </div>
      <div className="rewardCard__count rewardCard__loadingBlock rewardCard__rewardAmountLoading">
        <div className="shine" />
      </div>
    </div>
  </div>
);

export default RewardCardLoading;
