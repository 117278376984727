import React, { type RefObject, useCallback } from 'react';
import buildActionRefUrl from 'lib/buildActionRefUrl';
import RouterLink from 'components/RouterLink';

interface MarkdownLinkFactoryProps {
  href: string;
  title: string;
  children: any;
}

const MarkdownLinkFactory = (
  ref: RefObject<any>,
  { href, title, children }: MarkdownLinkFactoryProps
) => {
  const { cleanHref, actionRef } = buildActionRefUrl(href);

  const handleClick = useCallback(() => {
    // Queue up the action to happen after navigation
    setTimeout(() => {
      ref.current(actionRef);
    }, 0);
  }, [actionRef, ref]);

  return (
    <RouterLink href={cleanHref} onClick={handleClick} title={title} actionRefs={[actionRef]}>
      {children}
    </RouterLink>
  );
};

export default MarkdownLinkFactory;
