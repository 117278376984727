import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import PropTypes from 'prop-types';
import { isLoading } from 'lib/redux-utils';
import Api from 'services/Api';
import User from 'modules/User';
import Modals from 'modules/Modals';
import UserBox from 'components/UserBox';
import makeModal from 'components/Modals';
import Button from 'components/Button';
import LoadingElement from 'components/Loading/LoadingElement';
import Markdown from 'components/Markdown';
import { MagicMove } from 'components/Transitions';
import './latestTerms.css';

const title = 'Terms & Conditions';
const FALLBACK_MESSAGE = `Hey there, we’ve updated our [Terms & Conditions](openTermsConditions) of use. Please make sure you read and accept them before you continue playing on our site.`;

const _LatestTerms = ({
  agreeLatestTerms,
  agreedToLatestTerms,
  closeLatestTerms,
  loadingSubmit
}) => {
  const [content, setContent] = useState(null);
  const [loadingData, setLoadingData] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoadingData(true);
        const result = await Api.request.user.termsAndConditionsSummary();
        setLoadingData(false);
        setContent(result.data || FALLBACK_MESSAGE);
      } catch (error) {
        setLoadingData(false);
        setContent(FALLBACK_MESSAGE);
      }
    };
    fetchData();
  }, []);

  useEffect(() => {
    if (agreedToLatestTerms) {
      closeLatestTerms();
    }
  }, [closeLatestTerms, agreedToLatestTerms]);

  return (
    <UserBox title={`Updated ${title}`} className="modal">
      <MagicMove>
        {loadingData || !content ? (
          <LoadingElement key="LoadingElement" display="medium" />
        ) : (
          <div>
            <article>
              <Markdown content={content} />
            </article>
            <div className="fieldset fieldset--fullflex">
              <Button
                className="submit"
                title={`Accept ${title}`}
                buttonText="Accept"
                name="agreeLatestTerms"
                loading={loadingSubmit}
                onClick={agreeLatestTerms}
              />
            </div>
          </div>
        )}
      </MagicMove>
    </UserBox>
  );
};

const mapStateToProps = (state) => ({
  agreedToLatestTerms: User.selectors.model(state).agreedToLatestTerms,
  loadingSubmit: isLoading(state, User.actionTypes.AT.AGREE_LATEST_TERMS._)
});

const mapDispatchToProps = (dispatch, { close }) => ({
  closeLatestTerms: () => {
    dispatch(Modals.actions.close('latestTerms'));
  },
  agreeLatestTerms: () => Api.actions.user.agreeLatestTerms()(dispatch)
});

_LatestTerms.propTypes = {
  agreeLatestTerms: PropTypes.func,
  closeLatestTerms: PropTypes.func,
  loadingSubmit: PropTypes.bool,
  agreedToLatestTerms: PropTypes.bool
};
_LatestTerms.defaultProps = {};

export default compose(
  makeModal(
    'latestTerms',
    { name: 'modal-fade', timeout: 200 },
    { className: 'latestTerms modal' }
  ),
  connect(mapStateToProps, mapDispatchToProps)
)(_LatestTerms);
