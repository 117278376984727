import * as actions from './src/actions';
import * as actionTypes from './src/actionTypes';
import * as constants from './src/constants';
import * as selectors from './src/selectors';
import { initialState } from './src/model';
import reducer from './src/reducer';

export default {
  actions,
  reducer,
  selectors,
  actionTypes,
  NAME: constants.NAME,
  initialState,
  constants
};
export { constants };
export * as actionTypes from './src/actionTypes';
export * from './src/types';
