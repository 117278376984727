/* eslint-disable no-nested-ternary -- it's nice here */
import React, { useCallback, useEffect, useState, useMemo, memo } from 'react';
import makeModal from 'components/Modals';
import UserBox from 'components/UserBox';
import { MagicMove } from 'components/Transitions';
import LoadingElement from 'components/Loading/LoadingElement';
import type { DepositTransferableProps } from 'components/Deposit/types';
import ListSolicitationsErrorMessage from './ListSolicitationsErrorMessage';
import DepositLimitSolicitations from './DepositLimitSolicitations';
import { View } from './types';
import type { SubmitDepositLimitSolicitations } from './hooks';
import { useStateAndDispatch } from './hooks';
import './depositLimitSolicitations.css';

type AcceptOrCancel = (action: SubmitDepositLimitSolicitations) => SubmitDepositLimitSolicitations;

export { default as ListSolicitationsErrorMessage } from './ListSolicitationsErrorMessage';

const submitSolicitationsErrorMessage =
  'We cannot process your request right now. Please try again later.';

interface DepositLimitSolicitationsModalProps {
  depositProps?: DepositTransferableProps;
  close: () => void;
  title: string;
  shouldAutoCloseOnNoSolicitation: boolean;
}

/* TODO: Prop drilling, imperative flow.
    Leverage redux and also investigate how this would fit in a state machine */
export const _DepositLimitSolicitationsModal = ({
  depositProps,
  close,
  title,
  shouldAutoCloseOnNoSolicitation = true
}: DepositLimitSolicitationsModalProps) => {
  const {
    getDepositLimitSolicitations,
    getDepositLimit,
    acceptDepositLimitSolicitations,
    cancelDepositLimitSolicitations,
    openDeposit,
    depositLimitSolicitations: { solicitations }
  } = useStateAndDispatch();

  const [isBusy, setIsBusy] = useState(true);

  const [initializationFailed, setInitializationFailed] = useState(false);
  const [actionRequestErrorMessage, setActionRequestErrorMessage] = useState<string | null>(null);

  const [currentView, setCurrentView] = useState(View.Accept);

  const goToAcceptView = useCallback(() => {
    setActionRequestErrorMessage(null);
    setCurrentView(View.Accept);
  }, []);

  const goToRejectView = useCallback(() => {
    setActionRequestErrorMessage(null);
    setCurrentView(View.Reject);
  }, []);

  const doClose = useCallback(() => {
    close();
    if (depositProps) {
      openDeposit(depositProps);
    }
  }, [close, depositProps, openDeposit]);

  const getSolicitations = useCallback(async () => {
    try {
      setIsBusy(true);
      await getDepositLimitSolicitations();
      setIsBusy(false);
    } catch (err) {
      setIsBusy(false);
      setInitializationFailed(true);
    }
  }, [getDepositLimitSolicitations]);

  const acceptOrCancel = useCallback<AcceptOrCancel>(
    (action) =>
      async ({ interval }) => {
        try {
          setIsBusy(true);
          await action({ interval });
          goToAcceptView();
          await getSolicitations();
          await getDepositLimit(); // updates "Play Safely" section in account dashboard
          setIsBusy(false);
        } catch (err) {
          setActionRequestErrorMessage(submitSolicitationsErrorMessage);
          setIsBusy(false);
        }
      },
    [goToAcceptView, getSolicitations, getDepositLimit]
  );

  const accept = useMemo(
    () => acceptOrCancel(acceptDepositLimitSolicitations),
    [acceptOrCancel, acceptDepositLimitSolicitations]
  );
  const cancel = useMemo(
    () => acceptOrCancel(cancelDepositLimitSolicitations),
    [acceptOrCancel, cancelDepositLimitSolicitations]
  );

  useEffect(() => {
    void (async () => {
      await getSolicitations();
    })();
  }, [getSolicitations]);

  useEffect(() => {
    if (!solicitations && !isBusy && !initializationFailed && shouldAutoCloseOnNoSolicitation) {
      doClose();
    }
  }, [solicitations, shouldAutoCloseOnNoSolicitation, initializationFailed, doClose, isBusy]);

  return (
    <UserBox title={title} closeCallback={close}>
      <MagicMove loadingTimeout={0}>
        {isBusy ? (
          <LoadingElement key="LoadingElement" />
        ) : initializationFailed ? (
          <ListSolicitationsErrorMessage key="error-message" close={close} />
        ) : solicitations && !isBusy ? (
          <DepositLimitSolicitations
            key="deposit-limit-solicitations"
            depositLimitSolicitations={solicitations}
            acceptSolicitations={accept}
            cancelSolicitations={cancel}
            currentView={currentView}
            goToAcceptView={goToAcceptView}
            goToRejectView={goToRejectView}
            actionRequestErrorMessage={actionRequestErrorMessage}
          />
        ) : !solicitations && !isBusy ? (
          <p key="none">No current solicitations for deposit limit raise.</p>
        ) : null}
      </MagicMove>
    </UserBox>
  );
};

export default makeModal(
  'depositLimitSolicitations',
  { name: 'modal-fade', timeout: 200 },
  { className: 'deposit-limit-solicitations modal', id: 'deposit-limit-solicitations' }
)(memo(_DepositLimitSolicitationsModal));
