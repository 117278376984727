import React, { memo } from 'react';

const CvvIcon = () => (
  <svg
    className="cvvIcon"
    xmlns="http://www.w3.org/2000/svg"
    width="45"
    height="32"
    viewBox="0 0 45 32"
  >
    <g fill="none" fillRule="evenodd">
      <path
        fill="#A560DF"
        fillRule="nonzero"
        d="M40.645 32H4.355C1.95 32 0 29.851 0 27.2V4.8C0 2.149 1.95 0 4.355 0h36.29C43.05 0 45 2.149 45 4.8v22.4c0 2.651-1.95 4.8-4.355 4.8"
      />
      <path fill="#3D454D" fillRule="nonzero" d="M0 5h45v6H0z" />
      <g transform="translate(17.432 12.784)">
        <text fill="#FFF" fontFamily="Muli-SemiBold, Muli" fontSize="12" fontWeight="500">
          <tspan x="1" y="12">
            123
          </tspan>
        </text>
        <rect width="24" height="14" y="1" stroke="#FFC944" rx="1" />
      </g>
    </g>
  </svg>
);

export default memo(CvvIcon, () => false);
