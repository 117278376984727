import update from 'update-immutable';
import { handleActions } from 'redux-actions';
import { uniq, partialRight, isEmpty, isNil, intersection } from 'lodash';
import module from 'lib/module';
import { initialState } from './model';
import { AT, T } from './actionTypes';
import { NAME, CATEGORIES } from './constants';

const _ = partialRight.placeholder;
const isNotFound = (status) => status >= '5500';

const getCategory = (action, key) => key || action.meta?.args?.categ;
const setLoadingCategory = (state, action, key) => {
  const category = getCategory(action, key);
  if (!category) return state;
  return update(state, {
    categoryLoading: {
      [category]: { $set: true }
    }
  });
};

function updateSlotList(state, action, key) {
  const category = getCategory(action, key);
  if (!category) return state;
  const contents = action.payload?.contents ?? action.payload;
  if (isEmpty(contents)) {
    return update(state, {
      categoryLoading: {
        [category]: { $set: false }
      }
    });
  }
  const { entities } = contents;
  if (entities) {
    // If the slots from action's payload already exists in store reset the categoryOffset and re-fetch slots from beginning
    const hasDuplicates = intersection(state.categoryRefs[category], contents.result).length > 0;
    // Do not reset offset if the category offset is less than how many slots exists in store
    const canResetOffset =
      state.categoryRefs[category] &&
      state.categoryRefs[category].length <= state.categoryOffset[category];

    const updatedRooms = Object.assign(
      {},
      ...Object.keys(entities).map(
        (ref) =>
          ref !== state.active && {
            [ref]: update(entities[ref], {
              $set: {
                logoImageUrl: state.entities[ref] && state.entities[ref].logoImageUrl,
                backgroundImageUrl: state.entities[ref] && state.entities[ref].backgroundImageUrl,
                ...state.entities[ref],
                ...entities[ref],
                ...(state.entities[ref]?.categoryPositions && {
                  categoryPositions: {
                    ...state.entities[ref]?.categoryPositions,
                    ...entities[ref].categoryPositions
                  }
                })
              }
            })
          }
      )
    );
    const updatedPayload = update(contents, {
      entities: {
        $set: updatedRooms
      }
    });
    return update(state, {
      // A shallow merge like this resets the state for all rooms. _.merge preserves it
      entities: {
        $merge: updatedPayload.entities
      },
      result: {
        $apply: (prevResult) =>
          prevResult ? uniq([...prevResult, ...contents.result]) : contents.result
      },
      categoryRefs: {
        [category]: {
          $apply: (prevResult) =>
            prevResult ? uniq([...prevResult, ...contents.result]) : contents.result
        }
      },
      totalElements: {
        [category]: {
          $set: isNil(action.payload.totalElements)
            ? contents.result.length
            : action.payload.totalElements
        }
      },
      categoryOffset: {
        [category]: {
          $apply: (prevResult = 0) =>
            hasDuplicates && prevResult !== 0 && canResetOffset
              ? 0
              : prevResult + contents.result.length
        }
      },
      categoryLoading: {
        [category]: { $set: false }
      }
    });
  } else {
    return update(state, {
      categoryRefs: {
        [category]: {
          $apply: (prevResult) =>
            prevResult ? uniq([...prevResult, ...contents.result]) : contents.result
        }
      },
      totalElements: {
        [category]: {
          $set: isNil(action.payload.totalElements)
            ? contents.result.length
            : action.payload.totalElements
        }
      },
      categoryLoading: {
        [category]: { $set: false }
      }
    });
  }
}

const slotNotFound = (refName, state, meta) =>
  update(state, {
    entities: {
      [refName]: {
        notFound: { $set: isNotFound(meta.code) },
        notFoundMsg: { $set: meta.msg ?? '' }
      }
    }
  });

function updateRoom(state, action) {
  const refName = action.meta && action.meta.args && action.meta.args.refName;
  const result = state.result;
  const isError = action.error;

  if (isError) {
    return slotNotFound(refName, state, action.meta);
  } else if (refName && !isEmpty(action.payload)) {
    const updater = {
      entities: {
        [refName]: {
          id: { $set: action.payload.id },
          ref: { $set: action.payload.ref },
          name: { $set: action.payload.name },
          lobbyImageUrl: { $apply: (previous) => action.payload.lobbyImageUrl || previous },
          visibleInLobby: { $set: action.payload.visibleInLobby },
          orientation: { $set: action.payload.orientation },
          hideSlotDeposit: { $set: action.payload.hideSlotDeposit },
          categories: { $set: action.payload.categories },
          position: { $set: action.payload.position },
          jackpotAmount: { $set: action.payload.jackpotAmount },
          provider: { $set: action.payload.provider },
          platform: { $set: action.payload.platform },
          desktopUrl: { $set: action.payload.desktopUrl },
          mobileUrl: { $set: action.payload.mobileUrl },
          backgroundImageUrl: {
            $apply: (previous) => action.payload.backgroundImageUrl || previous
          },
          logoImageUrl: { $apply: (previous) => action.payload.logoImageUrl || previous },
          balanceUpdateDelay: { $set: action.payload.balanceUpdateDelay },
          gameReady: { $set: false }
        }
      },
      result: (() => {
        if (!result) {
          return { $set: [refName] };
        } else if (!result.includes(refName)) {
          return { $push: [refName] };
        } else {
          return { remove: true };
        }
      })()
    };

    if (updater.result.remove) {
      delete updater.result;
    }

    return update(state, updater);
  } else {
    return state;
  }
}

function updateRoomAssets(state, action) {
  const refName = action.meta && action.meta.args && action.meta.args.refName;
  const result = state.result;
  if (refName) {
    const updater = {
      entities: {
        [refName]: {
          metadata: { $merge: action.payload.metadata },
          lobbyImageUrl: { $apply: (previous) => action.payload.lobbyImageUrl || previous },
          backgroundImageUrl: {
            $apply: (previous) => action.payload.backgroundImageUrl || previous
          },
          altBackgroundImageUrl: {
            $apply: (previous) => action.payload.altBackgroundImageUrl || previous
          },
          logoImageUrl: { $apply: (previous) => action.payload.logoImageUrl || previous }
        }
      },
      result: (() => {
        if (!result) {
          return { $set: [refName] };
        } else if (!result.includes(refName)) {
          return { $push: [refName] };
        } else {
          return { remove: true };
        }
      })()
    };

    if (updater.result.remove) {
      delete updater.result;
    }

    return update(state, updater);
  } else {
    return state;
  }
}

const updateGameReady = (state, action) => {
  const { refName } = action.payload;
  return update(state, {
    entities: {
      [refName]: {
        gameReady: { $set: true }
      }
    }
  });
};

const reducer = module(
  handleActions(
    {
      [T.GAME_READY]: updateGameReady,
      [AT.LIST.PENDING]: partialRight(setLoadingCategory, _, _),
      [AT.LIST.FULFILLED]: partialRight(updateSlotList, _, _),
      [AT.LIST_ASSETS.FULFILLED]: partialRight(updateSlotList, _, _, CATEGORIES.ASSETS),
      [AT.ASSETS.FULFILLED]: updateRoomAssets,
      [AT.ROOM.FULFILLED]: updateRoom,
      [AT.ROOM.REJECTED]: updateRoom
    },
    initialState
  ),
  NAME
);

export default reducer;
