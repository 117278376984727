import React, { cloneElement, Children, memo, createContext, useContext } from 'react';
import type { Params } from 'react-container-query/lib/interfaces';
import cc from 'classcat';
import type { TypographyVariant } from 'components/Typography/constants';
import { classMap } from 'components/Typography/constants';
import './typography.css';

export type ContainerQueryMinWidth = {
  [key: string]: { minWidth: number };
};

export interface TypographyCQProps {
  name: string;
  children: JSX.Element;
}

export type TypographyContext = Record<string, TypographyVariant> | undefined;
export const TypographyContext = createContext<TypographyContext>(undefined);

/* eslint-disable react/prop-types */
const TypographyCQ = ({ name, children }: TypographyCQProps) => {
  const context = useContext(TypographyContext) || {};
  const variant = context[name] || '';
  const finalClassName = cc({
    typography: true,
    [classMap[variant]]: !!classMap[variant],
    [children.props.className]: !!children.props.className
  });
  return cloneElement(Children.only(children), {
    className: finalClassName
  });
};
export default memo(TypographyCQ);
/* eslint-enable react/prop-types */

export const getCurrentSize = (cQuery: ContainerQueryMinWidth, params: Params) => {
  const sortedKeysDescByMinWidth = Object.keys(cQuery).sort(
    (a, b) => cQuery[b].minWidth - cQuery[a].minWidth
  );
  return sortedKeysDescByMinWidth.find((item) => params[item])!;
};
