import React from 'react';
import { connect } from 'react-redux';
import type { MapStateToProps } from 'react-redux';
import type { AnyAction } from 'redux';
import type { ThunkDispatch } from 'redux-thunk';
import loadable from '@loadable/component';
import cc from 'classcat';
import Modals from 'modules/Modals';
import DialogModule from 'modules/Dialog';
import makeModal from 'components/Modals';
import 'components/Modals/modal.css';
import type { DialogAdapterProps } from '../DialogAdapter';
import './globalDialogs.css';

const DialogAdapter = loadable(() => import('../DialogAdapter'));

interface GlobalDialogsProps {
  isBlocking?: boolean;
  dialogs: Record<string, DialogAdapterProps>;
  dialogsRefs: string[];
}

export const _GlobalDialogs = ({ dialogsRefs, dialogs, isBlocking }: GlobalDialogsProps) => {
  const dialogModals =
    dialogsRefs?.length > 0 &&
    dialogsRefs.map((ref, i) => {
      const dialog = dialogs[ref];
      const Modal = makeModal(
        dialog.refName,
        dialog.animation || { name: 'modal-fade', timeout: 200 },
        {
          className: `modal modal--dialog ${dialog.refName}`,
          ...dialog.meta,
          visible: i === 0,
          destroyOnClose: true,
          dialog: true,
          pinned: !dialog.meta.onDismissActionRef
        }
      )(DialogAdapter);

      return <Modal key={dialog.refName} {...dialog} />;
    });

  return (
    <div
      className={cc([
        {
          modalContainer: true,
          globalDialogs: true,
          'globalDialogs--blocking': isBlocking
        }
      ])}
    >
      {dialogModals && dialogModals[0]}
    </div>
  );
};

const mapStateToProps: MapStateToProps<any, any> = (state) => ({
  dialogsRefs: DialogModule.selectors.getDialogsRefs(state),
  isBlocking: Modals.selectors.dialogIsCurrentlyBlocking(state),
  dialogs: DialogModule.selectors.getDialogs(state)
});

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, AnyAction>) => ({
  open: (name: string) => dispatch(Modals.actions.open(name))
});

export default connect(mapStateToProps, mapDispatchToProps)(_GlobalDialogs);
