import { useCallback, useContext, useMemo } from 'react';
import { useAppDispatch, useAppSelector } from 'hooks/redux';
import Lobby from 'modules/Lobby';
import Analytics from 'modules/Analytics';
import type { EffectTypes as Types } from 'services/Effects';
import type {
  LiveGame,
  LiveTile,
  Normalized,
  Platform,
  Provider,
  Slot,
  SlotTile
} from 'lib/LobbyEntities';
import DynamicLobbyContext from '../../src/DynamicLobbyContext';
import getDynamicLobbyEventData from '../../src/getDynamicLobbyEventData';

type useSlotTileAnalyticsProps = {
  tile: Normalized<Slot> | Normalized<LiveGame> | null;
  source: Types.GameLaunched['payload']['source'];
  position: number;
  shape: SlotTile['shape'] | LiveTile['shape'];
};

const useSlotTileAnalytics = ({ tile, source, position, shape }: useSlotTileAnalyticsProps) => {
  const dispatch = useAppDispatch();
  const { section, category } = useContext(DynamicLobbyContext);
  const provider = useAppSelector((state) =>
    Lobby.selectors.getEntityByKey(state, tile?.provider?.__access)
  ) as Normalized<Provider> | null;
  const platform = useAppSelector((state) =>
    Lobby.selectors.getEntityByKey(state, provider?.platform?.__access)
  ) as Normalized<Platform> | null;

  const trackGameLaunched = useCallback(
    (e) => {
      if (!tile) {
        return null;
      }
      const { refName, title } = tile;
      const { listRef, listPosition } = getDynamicLobbyEventData(e);
      dispatch(
        Analytics.actions.gameLaunched({
          refName,
          name: title,
          section,
          source,
          listRef,
          category,
          position,
          shape,
          listPosition,
          provider: provider?.label,
          platform: platform?.label
        })
      );
    },
    [dispatch, tile, provider, platform, section, category, source, position, shape]
  );

  return useMemo(() => ({ trackGameLaunched }), [trackGameLaunched]);
};

export default useSlotTileAnalytics;
