import { combineReducers } from 'redux';
import Lobby from 'modules/Lobby';
import Analytics from 'modules/Analytics';
import User from 'modules/User';
import Auth from 'modules/Auth';
import Banner from 'modules/Banner';
import Chat from 'modules/Chat';
import Wallet from 'modules/Wallet';
import Modals from 'modules/Modals';
import Bingo from 'modules/Bingo';
import Slot from 'modules/Slot';
import Live from 'modules/Live';
import Carousel from 'modules/Carousel';
import Notifications from 'modules/Notifications';
import Dialog from 'modules/Dialog';
import CmsContent from 'modules/CmsContent';
import UI from 'modules/UI';
import Loading from 'modules/Loading';
import Sounds from 'modules/Sounds';
import Offers from 'modules/Offers';

// TODO: `SideMenu` has a NAME of `sideMenu`
// TODO: For simplicity here, let's use `SideMenu  : SideMenu.reducer`

const appReducer = combineReducers({
  Analytics: Loading.reducer(Analytics.reducer, Analytics.NAME),
  User: Loading.reducer(User.reducer, User.NAME),
  Auth: Loading.reducer(Auth.reducer, Auth.NAME),
  Banner: Loading.reducer(Banner.reducer, Banner.NAME),
  Offers: Loading.reducer(Offers.reducer, Offers.NAME),
  Chat: Loading.reducer(Chat.reducer, Chat.NAME),
  Wallet: Loading.reducer(Wallet.reducer, Wallet.NAME),
  Live: Loading.reducer(Live.reducer, Live.NAME),
  Bingo: Loading.reducer(Bingo.reducer, Bingo.NAME),
  Slot: Loading.reducer(Slot.reducer, Slot.NAME),
  Notifications: Notifications.reducer,
  Dialog: Loading.reducer(Dialog.reducer, Dialog.NAME),
  Modals: Modals.reducer,
  Carousel: Loading.reducer(Carousel.reducer, Carousel.NAME),
  Lobby: Loading.reducer(Lobby.reducer, Lobby.NAME),
  CmsContent: Loading.reducer(CmsContent.reducer, CmsContent.NAME),
  UI: UI.reducer,
  Sounds: Sounds.reducer
});

const rootReducer = (state, action) =>
  action.type === 'Root/RESET'
    ? appReducer(
        {
          ...state,
          Analytics: undefined,
          User: undefined,
          Wallet: undefined,
          Chat: undefined,
          Modals: undefined,
          Sounds: undefined,
          Slot: undefined,
          Dialog: undefined,
          Lobby: undefined
        },
        action
      )
    : appReducer(state, action);

export default rootReducer;
