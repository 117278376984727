import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Api from 'services/Api';
import './loading.css';

export class _FullscreenLoading extends Component {
  componentDidMount() {
    const { currentUser, authenticated } = this.props;
    if (authenticated === null) {
      currentUser();
    }
  }

  render() {
    return <div className="loading-fullscreen-blocking" data-chromatic="ignore" />;
  }
}

_FullscreenLoading.propTypes = {
  currentUser: PropTypes.func,
  authenticated: PropTypes.bool
};

_FullscreenLoading.defaultProps = {
  authenticated: null,
  currentUser: null
};

const mapStateToProps = (state) => ({
  authenticated: state.Auth.authenticated
});

const mapDispatchToProps = (dispatch) => ({
  currentUser: () => Api.actions.user.getCurrent()(dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(_FullscreenLoading);
