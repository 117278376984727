import React, { type ChangeEventHandler } from 'react';
import type { Authority } from 'lib/constants';
import SimpleSelect from 'components/SimpleSelect';
import type { ReformField } from 'components/Reform/types';
import data from './data';

const AUTHORITY = __ENV__.MRQ_GAMING_AUTHORITY;

interface TimeoutSelectProps {
  days: ReformField;
  handleChange: ChangeEventHandler<HTMLSelectElement>;
  authority?: Authority;
}

const TimeoutSelect = ({ days, handleChange, authority = AUTHORITY }: TimeoutSelectProps) => {
  const options = data?.[authority]?.options ?? {};

  return (
    <SimpleSelect
      name="days"
      placeholder="Select time-out period"
      value={days.value}
      options={Object.keys(options)}
      numberFormatter={(val) => options[val]}
      onChange={handleChange}
    />
  );
};

export default TimeoutSelect;
