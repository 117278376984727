import type { ReactNode } from 'react';
import React from 'react';
import classNames from 'classcat';
import { Typography, TypographyVariant } from 'components/Typography';
import { BadgeSize, BadgeVariant } from './constants';
import './badge.css';

/*
Figma:
https://www.figma.com/file/k3a2l4uTwqB5Urdfp2LSq2/Casino-DS%3A-Design-system%3A-Molecules-(Product-Design)?type=design&mode=design&t=79TxHVHZQuKbAzGT-0
* */

const typographyMap = {
  [BadgeSize.XL]: TypographyVariant.BodyMdStrong,
  [BadgeSize.LG]: TypographyVariant.BodySmStrong,
  [BadgeSize.MD]: TypographyVariant.BodyXsStrong,
  [BadgeSize.SM]: TypographyVariant.BodyXxsStrong
};
const sizeClassNameMap = {
  [BadgeSize.XL]: 'badge--xl',
  [BadgeSize.LG]: 'badge--lg',
  [BadgeSize.MD]: 'badge--md',
  [BadgeSize.SM]: 'badge--sm'
};
const variantClassNameMap = {
  [BadgeVariant.PRIMARY]: 'badge--primary',
  [BadgeVariant.ACCENT]: 'badge--accent',
  [BadgeVariant.SUCCESS]: 'badge--success',
  [BadgeVariant.ATTENTION]: 'badge--attention',
  [BadgeVariant.WARNING]: 'badge--warning',
  [BadgeVariant.NEUTRAL]: 'badge--neutral'
};
interface BadgeProps {
  children: ReactNode;
  size?: BadgeSize;
  variant?: BadgeVariant;
  disabled?: boolean;
  prefix?: ReactNode;
  suffix?: ReactNode;
  onClick?: () => void;
}
const Badge = ({
  children,
  size = BadgeSize.MD,
  variant = BadgeVariant.PRIMARY,
  disabled = false,
  prefix,
  suffix,
  onClick
}: BadgeProps) => (
  <button
    className={classNames(['badge', sizeClassNameMap[size], variantClassNameMap[variant]])}
    onClick={onClick}
    disabled={disabled}
    type="button"
    name="badge"
  >
    {prefix && <div className="badge__prefix">{prefix}</div>}
    <Typography variant={typographyMap[size]}>
      <span>{children}</span>
    </Typography>
    {suffix && <div className="badge__suffix">{suffix}</div>}
  </button>
);
export default Badge;
