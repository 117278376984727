import React from 'react';
import PropTypes from 'prop-types';
import { isObject } from 'lodash';
import { TILE_TYPE, NUMBER_LIST_TYPE } from '../constants';
import NumberList from './NumberList';
import './embeddedTileInfo.css';

const EmbeddedTileInfo = ({ tileInfo }) => {
  let embeddedInfo = null;
  if (!isObject(tileInfo) || !tileInfo.tileType) return null;
  const tileType = tileInfo.tileType;
  switch (tileType) {
    case TILE_TYPE.ROULETTE: {
      const { results } = tileInfo;
      embeddedInfo = (
        <NumberList numbers={results} type={NUMBER_LIST_TYPE.ROULETTE} maxLength={7} />
      );
      break;
    }
    case TILE_TYPE.ROULETTE_MULTIPLIER: {
      const { results } = tileInfo;
      embeddedInfo = (
        <NumberList numbers={results} type={NUMBER_LIST_TYPE.ROULETTE} maxLength={7} />
      );
      break;
    }
    case TILE_TYPE.BLACKJACK: {
      const { takenSeats } = tileInfo;
      embeddedInfo = (
        <NumberList numbers={takenSeats} type={NUMBER_LIST_TYPE.BLACKJACK} maxLength={7} />
      );
      break;
    }
    case TILE_TYPE.MONEYWHEEL:
      {
        const { results } = tileInfo;
        embeddedInfo = (
          <NumberList numbers={results} type={NUMBER_LIST_TYPE.UNIFORM_SQUARES} maxLength={7} />
        );
      }
      break;
    default:
      break;
  }
  return <div className="embeddedTileInfo">{embeddedInfo}</div>;
};

EmbeddedTileInfo.defaultProps = {
  tileInfo: undefined
};

EmbeddedTileInfo.propTypes = {
  tileInfo: PropTypes.object
};

export default EmbeddedTileInfo;
