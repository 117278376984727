import update from 'update-immutable';

const entityName = (state) => state && state.entities && Object.keys(state.entities)[0];

export const add = (state, item, key) =>
  key && (!state.result || !state.result.includes(key))
    ? update(state, {
        entities: {
          [key]: {
            $set: item
          }
        },
        result: {
          $push: key
        }
      })
    : state;

export const remove = (state, key) => {
  const index = key && state.result && state.result.indexOf(key);
  return index >= 0
    ? update(state, {
        entities: {
          $unset: key
        },
        result: {
          $splice: [index, 1]
        }
      })
    : state;
};

export const merge = (state, item, key) =>
  !state.result || !state.result.includes(key)
    ? update(state, {
        entities: {
          [key]: {
            $merge: item
          }
        },
        result: {
          $push: key
        }
      })
    : update(state, {
        entities: {
          [key]: {
            $set: item
          }
        }
      });

export const addNamed = (state, item, key) =>
  key && (!state.result || !state.result.includes(key))
    ? update(state, {
        entities: {
          [entityName(state)]: {
            [key]: {
              $set: item
            }
          }
        },
        result: {
          $push: key
        }
      })
    : state;

export const removeNamed = (state, key) => {
  const index = key && state.result && state.result.indexOf(key);
  return index >= 0
    ? update(state, {
        entities: {
          [entityName(state)]: {
            $unset: key
          }
        },
        result: {
          $splice: [index, 1]
        }
      })
    : state;
};

export const mergeNamed = (state, item, key) =>
  key && item && (!state.result || !state.result.includes(key))
    ? update(state, {
        entities: {
          [entityName(state)]: {
            [key]: {
              $merge: item
            }
          }
        },
        result: {
          $push: key
        }
      })
    : update(state, {
        entities: {
          [key]: {
            $set: item
          }
        }
      });
