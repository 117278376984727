import { flowRight } from 'lodash';
import { createSelector } from 'reselect';
import { types, providerStatus } from 'lib/constants';
import { labelFormatter as formatDepositLimitLabel } from 'lib/depositLimit';
import * as m from './model';
import { NAME, DepositLimitIntervalsInPayload } from './constants';

export const model = (state) => state[NAME];
export const getTemporaryMobileNumber = (state) =>
  model(state).phoneNumber || state.User.depositPhoneNumber;
export const phoneVerified = (state) =>
  model(state).phoneVerified || state.User.depositPhoneNumberValidated;
export const getHasDeposited = flowRight([m.hasDeposited, model]);
export const getGameTransactions = flowRight([m.gameTransactions, model]);
export const getGameTransactionRefs = flowRight([m.gameTransactionRefs, model]);
export const getGameTransactionsHasNext = flowRight([m.gameTransactionsHasNext, model]);
export const getGameTransactionsCursor = flowRight([m.gameTransactionsCursor, model]);
export const getBankTransactions = flowRight([m.bankTransactions, model]);
export const getBankTransactionRefs = flowRight([m.bankTransactionRefs, model]);
export const getBankTransactionsHasNext = flowRight([m.bankTransactionsHasNext, model]);
export const getBankTransactionsCursor = flowRight([m.bankTransactionsCursor, model]);
export const getSelectedTransactionsRefs = flowRight([m.selectedTransactionsRefs, model]);
export const getBankTransactionsDeposit = flowRight([m.bankTransactionsDeposit, model]);
export const getBankTransactionRefsDeposit = flowRight([m.bankTransactionRefsDeposit, model]);
export const getBankTransactionsCountDeposit = flowRight([m.bankTransactionsCountDeposit, model]);
export const getSelectedTransactionsRefsDeposit = flowRight([
  m.selectedTransactionsRefsDeposit,
  model
]);
/**
 *
 * @typedef {import('../../../../store').RootState} RootState
 * @type {(state: RootState) => any}
 */
export const getPaymentMethods = flowRight([m.paymentMethods, model]);
export const getPaymentProviders = flowRight([m.paymentProviders, model]);
export const getPaymentProvidersRefs = flowRight([m.paymentProvidersRefs, model]);
export const getPaymentProvidersAvailable = flowRight([m.paymentProvidersAvailable, model]);
export const getPaymentProvidersAvailableRefs = flowRight([m.paymentProvidersAvailableRefs, model]);
export const _getPaymentMethodRefs = flowRight([m.paymentMethodRefs, model]);
export const getPaymentAmounts = flowRight([m.paymentAmounts, model]);
export const getPaymentPresetRefs = flowRight([m.paymentPresetRefs, model]);
export const getFormattedDepositLimitDaily = flowRight([
  formatDepositLimitLabel,
  m.depositLimitDaily,
  model
]);
export const getFormattedDepositLimitWeekly = flowRight([
  formatDepositLimitLabel,
  m.depositLimitWeekly,
  model
]);
export const getFormattedDepositLimitMonthly = flowRight([
  formatDepositLimitLabel,
  m.depositLimitMonthly,
  model
]);
export const getPaymentPresetPresets = (state, provider) =>
  getPaymentAmounts(state)?.[provider]?.presets;
export const getPaymentPresetMinAmount = (state, provider) =>
  getPaymentAmounts(state)?.[provider]?.minAmount;
export const getPaymentPresetMaxAmount = (state, provider) =>
  getPaymentAmounts(state)?.[provider]?.maxAmount;
export const getPaymentProviderLabels = (state, provider) => getPaymentProviders(state)?.[provider];
export const getPaymentProviderUnavailableLabel = (state, provider) =>
  getPaymentProviderLabels(state, provider)?.unavailableMethodText || 'Temporarily unavailable';
export const getPaymentProviderUnavailableDescription = (state, provider) =>
  getPaymentProviderLabels(state, provider)?.unavailableMethodExpandedText ||
  'is temporarily unavailable';
export const getBalance = (state) => +(model(state).balanceOverride || model(state).balance) || 0;
export const getBalanceReal = (state) => model(state).balance;
export const getDepositLimitAmount = flowRight([m.depositLimitAmount, model]);
export const getEcospendBanks = flowRight([m.ecospendBanks, model]);
export const getEcospendBanksIds = flowRight([m.ecospendBanksIds, model]);
export const getEcospendDeposit = flowRight([m.ecospendDeposit, model]);
export const getExpandedBankTransactionId = flowRight([m.expandedBankTransactionId, model]);
export const getExpandedGameTransactionId = flowRight([m.expandedGameTransactionId, model]);
export const getTotalAmountPending = flowRight([m.totalAmountPending, model]);
export const getWithdrawProcessingTime = flowRight([m.withdrawProcessingTime, model]);
export const getGlobalJackpotAmount = flowRight([m.globalJackpotAmount, model]);
export const getDropinKey = flowRight([m.dropinKey, model]);
export const getRawDepositLimitSolicitations = (state) => model(state).depositLimitSolicitations;
export const getPciProxyError = (state) => model(state).pciProxyError;
export const hasAgreedPaymentTerms = flowRight([m.agreedPaymentTerms, model]);

export const getPaymentMethodRefs = createSelector(
  [getPaymentMethods, _getPaymentMethodRefs],
  (methods, refs) => {
    if (!refs || refs.length <= 1) return refs;
    let primary;
    let i = 0;
    while (i < refs.length && !primary) {
      if (methods[refs[i]]?.primary) {
        primary = refs[i];
      }
      i++;
    }
    return [refs[i - 1], ...refs.slice(0, i - 1), ...refs.slice(i)];
  }
);

export const getPossibleNewPaymentsRefs = createSelector(
  [
    getPaymentProviders,
    getPaymentProvidersRefs,
    getPaymentProvidersAvailable,
    getPaymentProvidersAvailableRefs
  ],
  (providers, providersRefs, available, availableRefs) => {
    const possibleAvailableRefs = availableRefs?.filter(
      (ref) =>
        available[ref].status === providerStatus.AVAILABLE ||
        available[ref].status === providerStatus.UNAVAILABLE
    );

    return (
      providersRefs?.filter((ref) => possibleAvailableRefs?.includes(providers[ref].provider)) || []
    );
  }
);

export const hasCard = createSelector([getPaymentMethods, getPaymentMethodRefs], (methods, refs) =>
  refs?.some(
    // TODO: Backend inconsistency MRQ-1030
    (ref) => methods[ref].type === types.CARD || methods[ref].paymentMethodType === types.CARD
  )
);

export const hasMobile = createSelector(
  [getPaymentMethods, getPaymentMethodRefs],
  (methods, refs) => refs?.some((ref) => methods[ref].type === types.MOBILE)
);

export const hasPaypal = createSelector(
  [getPaymentMethods, getPaymentMethodRefs],
  (methods, refs) =>
    refs?.some(
      // TODO: Backend inconsistency MRQ-1030
      (ref) => methods[ref].type === types.PAYPAL || methods[ref].paymentMethodType === types.PAYPAL
    )
);

export const getPaypalPaymentMethodRef = createSelector(
  [getPaymentMethods, getPaymentMethodRefs],
  (methods, refs) =>
    refs?.find(
      // TODO: Backend inconsistency MRQ-1030
      (ref) => methods[ref].type === types.PAYPAL || methods[ref].paymentMethodType === types.PAYPAL
    )
);

export const hasBankAccount = createSelector(
  [getPaymentMethods, getPaymentMethodRefs],
  (methods, refs) => refs?.some((ref) => methods[ref].type === types.BANK_ACCOUNT)
);

export const canAddPaypal = createSelector(
  [getPaymentProvidersAvailable, getPaymentProvidersAvailableRefs],
  (providers, refs) =>
    refs?.some(
      (ref) =>
        providers[ref].type === types.PAYPAL && providers[ref].status === providerStatus.AVAILABLE
    )
);

export const hideAddPaypal = createSelector(
  [getPaymentProvidersAvailable, getPaymentProvidersAvailableRefs],
  (providers, refs) =>
    !refs?.some(
      (ref) =>
        providers[ref].type === types.PAYPAL && providers[ref].status !== providerStatus.HIDDEN
    )
);

export const canAddPhone = createSelector(
  [getPaymentProvidersAvailable, getPaymentProvidersAvailableRefs],
  (providers, refs) =>
    refs?.some(
      (ref) =>
        providers[ref].type === types.MOBILE && providers[ref].status === providerStatus.AVAILABLE
    )
);

export const hideAddPhone = createSelector(
  [getPaymentProvidersAvailable, getPaymentProvidersAvailableRefs],
  (providers, refs) =>
    !refs?.some(
      (ref) =>
        providers[ref].type === types.MOBILE && providers[ref].status !== providerStatus.HIDDEN
    )
);

export const canAddCard = createSelector(
  [getPaymentProvidersAvailable, getPaymentProvidersAvailableRefs],
  (providers, refs) =>
    refs?.some(
      (ref) =>
        providers[ref].type === types.CARD && providers[ref].status === providerStatus.AVAILABLE
    )
);

export const canAddBraintree = createSelector(
  [getPaymentProvidersAvailable],
  (providers) => providers?.BRAINTREE?.status === providerStatus.AVAILABLE
);

export const canAddPciProxy = createSelector(
  [getPaymentProvidersAvailable],
  (providers) => providers?.PCI_PROXY?.status === providerStatus.AVAILABLE
);

export const hideAddCard = createSelector(
  [getPaymentProvidersAvailable, getPaymentProvidersAvailableRefs],
  (providers, refs) =>
    !refs?.some(
      (ref) => providers[ref].type === types.CARD && providers[ref].status !== providerStatus.HIDDEN
    )
);

export const hasPayment = createSelector(
  [getPaymentMethods, getPaymentMethodRefs],
  (methods, refs) => refs?.some((ref) => methods[ref].type === types.PAYPAL)
);

export const canAddBankAccount = createSelector(
  [getPaymentProvidersAvailable, getPaymentProvidersAvailableRefs],
  (providers, refs) =>
    refs?.some(
      (ref) =>
        providers[ref].type === types.BANK_ACCOUNT &&
        providers[ref].status === providerStatus.AVAILABLE
    )
);

export const hideAddBankAccount = createSelector(
  [getPaymentProvidersAvailable, getPaymentProvidersAvailableRefs],
  (providers, refs) =>
    !refs?.some(
      (ref) =>
        providers[ref].type === types.BANK_ACCOUNT &&
        providers[ref].status !== providerStatus.HIDDEN
    )
);

export const getBankAccountRefs = createSelector(
  [getPaymentMethods, getPaymentMethodRefs],
  (methods, refs) => refs?.filter((ref) => methods[ref].type === types.BANK_ACCOUNT)
);

export const getCardRefs = createSelector(
  [getPaymentMethods, getPaymentMethodRefs],
  (methods, refs) =>
    refs?.filter(
      // TODO: Backend inconsistency MRQ-1030
      (ref) => methods[ref].type === types.CARD || methods[ref].paymentMethodType === types.CARD
    )
);

export const getMobileNumber = createSelector(
  [getPaymentMethods, getPaymentMethodRefs],
  (methods, refs) => {
    if (!refs || !methods) return undefined;

    const mobileRef = refs?.find((ref) => methods[ref].type === types.MOBILE);
    return methods[mobileRef]?.identifier;
  }
);

export const getMobilePaymentRef = createSelector(
  [getPaymentMethods, getPaymentMethodRefs],
  (methods, refs) => {
    if (!refs || !methods) return undefined;

    const mobileRef = refs?.find((ref) => methods[ref].type === types.MOBILE);
    return methods[mobileRef]?.ref;
  }
);

export const getPrimaryMethodRef = createSelector(
  [getPaymentMethods, getPaymentMethodRefs],
  /**
   * @returns {(string|undefined)}
   */
  (methods, refs) => refs?.find((ref) => methods[ref].primary)
);

export const getFirstPossibleMethodRef = createSelector(
  [getPrimaryMethodRef, getPaymentMethods, getPaymentMethodRefs],
  (primaryRef, methods, refs) => {
    if (!primaryRef) {
      return refs?.find((ref) => methods[ref].status === providerStatus.AVAILABLE);
    }
    return primaryRef;
  }
);

export const getPrimaryMethod = createSelector(
  [getPaymentMethods, getPrimaryMethodRef],
  (methods, primaryMethodRef) => methods && primaryMethodRef && methods[primaryMethodRef]
);

export const getOpenLoopAlternativeMethod = createSelector(
  [getPaymentMethods, getBankAccountRefs],
  (methods, bankRefs) =>
    bankRefs?.length &&
    methods[bankRefs?.find((ref) => methods[ref].allowed && bankRefs?.includes(ref))]
);

export const getNonMobileMethodRefs = createSelector(
  [getPaymentMethods, getPaymentMethodRefs],
  (methods, refs) => refs?.filter((ref) => methods[ref].type !== types.MOBILE)
);

export const getPaypalEmail = createSelector(
  [getPaymentMethods, getPaymentMethodRefs],
  (methods, refs) => {
    if (!refs || !methods) return undefined;

    const paypalRef = refs?.find(
      // TODO: Backend inconsistency MRQ-1030
      (ref) => methods[ref].type === types.PAYPAL || methods[ref].paymentMethodType === types.PAYPAL
    );
    // TODO: Backend inconsistency MRQ-1030
    return methods[paypalRef]?.identifier ?? methods[paypalRef]?.details?.email;
  }
);

export const getCancellableBankTransactionRefs = createSelector(
  [getBankTransactions, getBankTransactionRefs],
  (transactions, refs) =>
    refs?.filter(
      (ref) =>
        transactions[ref].type === 'WITHDRAW' &&
        (transactions[ref].status === 'AUTO' || transactions[ref].status === 'APPROVED')
    )
);
export const getCancellableBankTransactionRefsDeposit = createSelector(
  [getBankTransactionsDeposit, getBankTransactionRefsDeposit],
  (transactions, refs) =>
    refs &&
    refs.filter(
      (ref) =>
        transactions[ref].type === 'WITHDRAW' &&
        (transactions[ref].status === 'AUTO' || transactions[ref].status === 'APPROVED')
    )
);

export const canAlterPrimaryMethod = createSelector(
  [getPrimaryMethodRef, getBalanceReal, getTotalAmountPending],
  (primaryRef, balance, totalAmountPending) =>
    !primaryRef || (balance < 1 && totalAmountPending < 1)
);

export const getAvailablePaymentMethodProviders = createSelector(
  [canAddBankAccount, canAddBraintree, canAddPaypal, canAddPciProxy, canAddPhone],
  (bankAccount, braintree, paypal, pciProxy, phone) => {
    const options = [];
    if (bankAccount) options.push(types.BANK_ACCOUNT);
    if (braintree) options.push(types.BRAINTREE);
    if (paypal) options.push(types.PAYPAL);
    if (pciProxy) options.push(types.PCI_PROXY);
    if (phone) options.push(types.MOBILE);
    return options;
  }
);

export const getDepositableMethodRefs = createSelector(
  [getPaymentMethods, getPaymentMethodRefs, canAlterPrimaryMethod],
  (methods, refs, canAlter) =>
    canAlter
      ? refs
      : refs?.filter((ref) => methods[ref].primary || methods[ref].type === types.MOBILE)
);

export const getCanceledTransactionsAmount = createSelector(
  [getBankTransactions, getSelectedTransactionsRefs],
  (transactions, selectedRefs) =>
    transactions &&
    selectedRefs &&
    selectedRefs.reduce(
      (acc, ref) => acc + ((transactions[ref] && transactions[ref].amount) || 0),
      0
    )
);

export const getCanceledTransactionsAmountDeposit = createSelector(
  [getBankTransactionsDeposit, getSelectedTransactionsRefsDeposit],
  (transactions, selectedRefs) =>
    transactions &&
    selectedRefs &&
    selectedRefs.reduce(
      (acc, ref) => acc + ((transactions[ref] && transactions[ref].amount) || 0),
      0
    )
);

export const getCancellableTransactionsAmount = createSelector(
  [getBankTransactions, getCancellableBankTransactionRefs],
  (transactions, refs) =>
    transactions &&
    refs &&
    refs.reduce((acc, ref) => acc + ((transactions[ref] && transactions[ref].amount) || 0), 0)
);

export const getCancellableTransactionsAmountDeposit = createSelector(
  [getBankTransactionsDeposit, getCancellableBankTransactionRefsDeposit],
  (transactions, refs) =>
    transactions &&
    refs &&
    refs.reduce((acc, ref) => acc + ((transactions[ref] && transactions[ref].amount) || 0), 0)
);
export const parseDepositLimitSolicitations = (rawSolicitations) => {
  const solicitations = Object.values(DepositLimitIntervalsInPayload).reduce((acc, interval) => {
    const willIncreaseKey = `depositLimit${interval}WillIncrease`;
    if (!rawSolicitations[willIncreaseKey]) return acc;
    return {
      ...acc,
      [interval]: {
        current: rawSolicitations[`currentDepositLimit${interval}`],
        next: rawSolicitations[`newDepositLimit${interval}`]
      }
    };
  }, {});
  return solicitations;
};
export const getDepositLimitSolicitations = createSelector(
  [getRawDepositLimitSolicitations],
  (rawDepositLimitSolicitations) => {
    if (!rawDepositLimitSolicitations.solicitationsPayload) {
      return {
        requestsState: rawDepositLimitSolicitations.requestsState,
        solicitations: rawDepositLimitSolicitations.solicitationsPayload
      };
    }
    const solicitations = parseDepositLimitSolicitations(
      rawDepositLimitSolicitations.solicitationsPayload
    );
    return {
      requestsState: rawDepositLimitSolicitations.requestsState,
      solicitations: Object.keys(solicitations).length ? solicitations : null
    };
  }
);
