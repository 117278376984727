import Slot, { actionTypes as slotTypes } from 'modules/Slot';

/**
 * THE PAYLOAD DATA ARE THE JUST DEFAULT VALUES. The (pre-translated) action's payload is MERGED INTO the translation
 * ONLY add mappings for internal <-> external actions. Never external <-> external or internal <-> internal
 * keyMapping defines the **bidirectional** translation of the event/action keys.
 *
 * This is 2 way mapping!
 */
export default {
  keyMapping: {
    type: 'name',
    payload: '',
    flattenPayload: false
  },
  entities: {
    [slotTypes.T.STOP_AUTOPLAY]: {
      name: 'stopAutoplay'
    },
    [slotTypes.T.FRAME_ORIENTATION_CHANGED]: {
      name: 'changeOrientation'
    },
    busyStatus: (dispatch, { payload: { busy } }) => {
      dispatch({
        type: busy ? slotTypes.T.ROUND_STARTED : slotTypes.T.ROUND_ENDED
      });
    },
    gameClose: (dispatch, action) => dispatch(Slot.actions.closeGame(action)),
    gameUiReady: (dispatch, { meta }) =>
      dispatch({
        type: slotTypes.T.GAME_READY,
        payload: { refName: meta.refName }
      })
  }
};
