import Wallet from 'modules/Wallet';

const ignored = [
  Wallet.actionTypes.AT.DEPOSIT_MOBILE.FULFILLED,
  Wallet.actionTypes.AT.ADD_NEW_CARD.FULFILLED,
  Wallet.actionTypes.AT.CREATE_PAYMENT_METHOD.FULFILLED,
  Wallet.actionTypes.AT.DEPOSIT_PAYPAL.FULFILLED,
  Wallet.actionTypes.AT.CREATE_PAYPAL.FULFILLED,
  Wallet.actionTypes.AT.DEPOSIT_CARD_PCI_PROXY.FULFILLED,
  Wallet.actionTypes.AT.DEPOSIT_CARD_BRAINTREE.FULFILLED
];

export default ignored;
