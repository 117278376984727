import React from 'react';
import * as Animation from 'components/Animation';
import './loadingListTile.css';

const LoadingListTile = () => (
  <div className="loadingListTile">
    <Animation.Root className="loadingListTile__image">
      <Animation.ShimmerInputPrimary />
    </Animation.Root>
    <div className="loadingListTile__info">
      <Animation.Root className="loadingListTile__title">
        <Animation.ShimmerInputPrimary />
      </Animation.Root>
      <Animation.Root className="loadingListTile__extra">
        <Animation.ShimmerInputPrimary />
      </Animation.Root>
    </div>
  </div>
);

export default LoadingListTile;
