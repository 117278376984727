const gameTitle = (freeRoundsGames) => {
  const gameTitles = freeRoundsGames.map((game) => game.name);
  const length = freeRoundsGames.length;

  if (length === 1) {
    return gameTitles[0];
  } else {
    return `${gameTitles.slice(0, length - 1).join(', ')} or ${gameTitles[length - 1]}`;
  }
};

export const referAFriendIntro = ({ freeRounds, freeRoundsGames }) =>
  freeRounds && freeRoundsGames
    ? `Invite your friends to play at MrQ and you'll both get ${freeRounds} free spins on ${gameTitle(
        freeRoundsGames
      )} when they deposit!`
    : null;
