import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { actions as routerActions } from 'modules/Router';
import Modals from 'modules/Modals';
import User from 'modules/User';
import Button from 'components/Button';
import UserBox from 'components/UserBox';
import YotiButton from 'components/YotiButton';
import HooyuButton from 'components/HooyuButton';
import Markdown from 'components/Markdown';
import './failedKyc.css';

const { push } = routerActions;

export const _FailedKyc = ({
  className,
  kycStatus,
  kycProviders,
  openVerifyDocuments,
  openAddPayment,
  gotoLobby,
  close
}) => (
  <UserBox className={className} title={User.constants.kycTitles[kycStatus]} closeCallback={close}>
    <article className="failedKyc">
      {User.constants.kycImages[kycStatus] && (
        <img
          className="failedKyc__image"
          alt=""
          src={User.constants.kycImages[kycStatus]}
          data-chromatic="ignore"
        />
      )}
      <Markdown content={User.constants.kycMessages[kycStatus]} />
      <div className="failedKyc__buttonsGroup fieldset fieldset--fullflex">
        {kycStatus === User.constants.kyc.NONE && (
          <Button
            horizontal
            name="addPayment"
            id="failedKyc-addPayment"
            title="Add payments"
            buttonText="Add payment method"
            onClick={openAddPayment}
          />
        )}
        {kycStatus === User.constants.kyc.PASS && (
          <Button
            className="submit"
            name="close"
            id="kyc-play"
            horizontal
            title="Play"
            buttonText="Play"
            onClick={gotoLobby}
          />
        )}
        {kycStatus === User.constants.kyc.FAIL && (
          <Button
            horizontal
            name="openVerifyDocuments"
            title="Send documents"
            buttonText="Send documents"
            onClick={openVerifyDocuments}
          />
        )}
        {kycStatus !== User.constants.kyc.PASS &&
          kycStatus !== User.constants.kyc.PENDING &&
          kycStatus !== User.constants.kyc.NONE &&
          kycStatus !== User.constants.kyc.FAIL && (
            <div className="failedKyc__buttonsGroup">
              {kycProviders?.includes(User.constants.kycProviders.HOOYU) ? (
                <HooyuButton close={close} journey={User.constants.VerificationType.KYC}>
                  Upload your documents
                </HooyuButton>
              ) : kycProviders?.includes(User.constants.kycProviders.YOTI) ? (
                <YotiButton close={close} />
              ) : null}
              <a className="failedKyc__link" onClick={openVerifyDocuments}>
                Email the Verification Team
              </a>
            </div>
          )}
      </div>
    </article>
  </UserBox>
);

_FailedKyc.propTypes = {
  className: PropTypes.string,
  close: PropTypes.func,
  pathname: PropTypes.string,
  kycStatus: PropTypes.oneOf(['PENDING', 'PASS', 'REFER', 'FAIL', 'NONE']).isRequired,
  kycProviders: PropTypes.arrayOf(PropTypes.oneOf(['YOTI', 'HOOYU'])),
  openVerifyDocuments: PropTypes.func.isRequired,
  openAddPayment: PropTypes.func.isRequired,
  gotoLobby: PropTypes.func.isRequired
};

_FailedKyc.defaultProps = {
  className: null,
  close: null,
  pathname: null,
  kycProviders: []
};

const mapStateToProps = (state) => ({
  kycStatus: User.selectors.getKycStatus(state),
  kycProviders: User.selectors.model(state).kycProviders
});

const mapDispatchToProps = (dispatch, { close, pathname }) => ({
  openVerifyDocuments: () => {
    dispatch(Modals.actions.open('verifyDocuments'));
  },
  openAddPayment: () => {
    if (close) {
      close();
    }
    dispatch(Modals.actions.open('addPayment'));
  },
  gotoLobby: () => {
    if (pathname.startsWith('/secure/bingo') && close) {
      close();
    } else {
      dispatch(push('/secure/lobby'));
    }
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(_FailedKyc);
