import React from 'react';
import { Provider } from 'react-redux';
import { HelmetProvider } from 'react-helmet-async';
import { Router } from 'react-router-dom';
import { PWAProvider } from 'lib/pwa';
import ScrollRestoration from 'components/ScrollRestoration';
import BaseRoutes from './router';
import store, { history } from './store';

const Starter = () => (
  <HelmetProvider>
    <PWAProvider>
      <Provider store={store}>
        <Router history={history}>
          <ScrollRestoration />
          <BaseRoutes />
        </Router>
      </Provider>
    </PWAProvider>
  </HelmetProvider>
);

export default Starter;
