import React, { memo, useCallback, useMemo } from 'react';
import cc from 'classcat';
import { isNil } from 'lodash';
import buildActionRefUrl from 'lib/buildActionRefUrl';
import Button from 'components/Button';
import useDynamicAction from './src/useDynamicAction';

export enum Variant {
  PRIMARY = 'PRIMARY',
  SECONDARY = 'SECONDARY',
  TERTIARY = 'TERTIARY'
}

type Truthy = <T>(x: T | false | '' | 0 | null | undefined) => x is T;

interface DialogButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  /** The markdown text */
  href?: string;
  variant?: Variant;
  actionRefs?: string[];
  icon?: string;
}

interface DialogButtonAdapterProps extends DialogButtonProps {
  actionRef?: string;
  refName: string;
  formId?: string;
}

const DialogButtonBase = ({ children, variant, href, ...rest }: DialogButtonProps) => (
  <Button
    {...rest}
    tag={href ? 'Link' : undefined}
    href={href}
    buttonText={children}
    className={cc([
      {
        'button--fakeLink': variant === Variant.TERTIARY,
        button__grey: variant === Variant.SECONDARY
      }
    ])}
  />
);

export const DialogButton = memo(DialogButtonBase);

const DialogButtonAdapter = ({
  actionRef,
  refName,
  href,
  formId,
  ...rest
}: DialogButtonAdapterProps) => {
  const action = useDynamicAction();
  const { cleanHref, actionRef: urlActionRef } = buildActionRefUrl(href || '');

  const handleClick = useCallback(() => {
    // If it's submitting a form, do nothing as the action will be dispatched by the form's submit handler
    if (formId) {
      return;
    }

    if (!isNil(href)) {
      /* TODO: Phase 3 or 2 - this is behavior that will always be either unexpected, unclear, or inconsistent, as
           it reverses the default behavior of anchor clicks.
           Refine the current modal attributes, and/or sync up with the backend on `closeOnNavigation` */
      // If this is a link, queue up the action to be dispatched after the (internal) navigation action
      window.setTimeout(() => {
        if (urlActionRef) action(urlActionRef);
        if (actionRef) action(actionRef);
      }, 0);
    } else if (actionRef) {
      action(actionRef);
    }
  }, [action, actionRef, formId, href, urlActionRef]);

  const actionRefs = useMemo(
    () => [actionRef, urlActionRef].filter(Boolean as unknown as Truthy),
    [actionRef, urlActionRef]
  );

  return (
    <DialogButton
      form={formId}
      name={refName}
      onClick={actionRef || urlActionRef ? handleClick : undefined}
      href={!isNil(href) ? cleanHref : undefined}
      actionRefs={actionRefs.length > 0 ? actionRefs : undefined}
      type={formId ? 'submit' : 'button'}
      {...rest}
    />
  );
};

export default memo(DialogButtonAdapter);
