import { useCallback, useMemo, useState } from 'react';
import { nanoid } from 'nanoid';
import type {
  BasicSecureFieldElement,
  SecureFieldElement,
  SecureFieldsChangeEvent
} from '../types';

const useFields = (field: string) => {
  const fields = useMemo(
    () =>
      field === 'cardNumber'
        ? {
            cardNumber: {
              placeholderElementId: `cardNumber__${nanoid()}`,
              label: 'Card number',
              inputType: 'tel',
              length: 16
            }
          }
        : {
            cvv: {
              placeholderElementId: `cvv__${nanoid()}`,
              label: 'CVV',
              inputType: 'tel',
              length: 3
            }
          },
    [field]
  );
  const [cardNumber, setCardNumber] = useState<SecureFieldElement | BasicSecureFieldElement>({
    ...fields.cardNumber,
    valid: null,
    error: 'The card number is not valid',
    empty: true,
    hasBeenFocused: false,
    focus: false
  });
  const [cvv, setCvv] = useState<SecureFieldElement | BasicSecureFieldElement>({
    ...fields.cvv,
    valid: null,
    error: 'Three-digit number on the back of the card',
    empty: true,
    hasBeenFocused: false,
    focus: false
  });

  const onFieldChange = useCallback((e: SecureFieldsChangeEvent) => {
    if (e.cardNumber) {
      setCardNumber((prevState) => ({
        ...prevState,
        ...e.cardNumber,
        hasBeenFocused: true
      }));
    }
    if (e.cvv) {
      setCvv((prevState) => ({
        ...prevState,
        ...e.cvv,
        hasBeenFocused: true
      }));
    }
  }, []);

  const onResetFields = useCallback(() => {
    setCardNumber((prevState) => ({
      ...prevState,
      valid: null,
      empty: true,
      hasBeenFocused: false,
      focus: false
    }));
    setCvv((prevState) => ({
      ...prevState,
      valid: null,
      empty: true,
      hasBeenFocused: false,
      focus: false
    }));
  }, []);

  return useMemo(
    () => ({
      fields,
      cardNumber,
      cvv,
      onFieldChange,
      onResetFields
    }),
    [cardNumber, cvv, fields, onFieldChange, onResetFields]
  );
};

export default useFields;
