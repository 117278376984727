import React from 'react';
import ListButton from 'components/ListButton';
import Chip from 'components/Chip';
import LoadingElement from 'components/Loading/LoadingElement';
import type { EcospendStep } from '../types';

interface AuthOptionProps {
  option: EcospendStep;
  title: string;
  label: string;
  tags?: string[];
  onClick: (option: EcospendStep) => void;
  loading?: boolean;
  disabled?: boolean;
}

const AuthOption = ({
  title,
  option,
  label,
  tags,
  onClick,
  disabled = false,
  loading = false
}: AuthOptionProps) => (
  <ListButton
    disabled={disabled || loading}
    onClick={() => onClick(option)}
    icon={null}
    buttonText={null}
    name="authOption"
  >
    <div className="authOption">
      <div className="authOption__content">
        {title}
        {tags?.map((tag) => (
          <Chip key={tag} label={tag} />
        ))}
        {loading && <LoadingElement display="inline-block" />}
      </div>
      <div className="ecospend__tip ecospend__tip--gray">{label}</div>
    </div>
  </ListButton>
);

export default AuthOption;
