import { useMemo, useState, useCallback } from 'react';
import usePublicCmsData from 'hooks/usePublicCmsData';
import type { types as CmsContentTypes } from 'modules/CmsContent';

interface useDynamicModalResponseType {
  data: CmsContentTypes.CmsPage[];
}

const usePublicCmsDataDynamicModal = (slug: string) => {
  const [content, setContent] = useState<CmsContentTypes.CmsPage>();

  const updateContent = useCallback((cmsResponse: useDynamicModalResponseType) => {
    setContent(cmsResponse?.data?.at(0));
  }, []);

  const { hasError, isLoading } = usePublicCmsData<useDynamicModalResponseType>({
    contentPath: `/api/modals/slug/${slug}`,
    updateContent
  });

  return useMemo(() => ({ content, hasError, isLoading }), [content, hasError, isLoading]);
};

export default usePublicCmsDataDynamicModal;
