import { memo, useEffect } from 'react';
import { useUserInteraction } from 'hooks/useUserInteraction';
import { useAppDispatch } from 'hooks/redux';
import Sounds from 'modules/Sounds';

const DetectUserInteraction = () => {
  const dispatch = useAppDispatch();
  const hasUserInteraction = useUserInteraction();
  useEffect(() => {
    if (hasUserInteraction) {
      dispatch(Sounds.actions.canLoadSprite());
    }
  }, [dispatch, hasUserInteraction]);
  return null;
};

export default memo(DetectUserInteraction);
