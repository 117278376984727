import localStore from 'lib/localStore';
import User from 'modules/User';
import Auth from 'modules/Auth';
import Modals from 'modules/Modals';

const MINUTE = 60000;
const defaultUsername = User.initialState.username;

const { setRealityTimeoutId, setRealityIntervalId } = User.actions;
const openRealityCheck = Modals.actions.open('realityCheck');

const clearRealityIntervals = async (model, newInterval) => {
  const { username, realityTimeoutId, realityIntervalId } = model;
  if (username && username !== defaultUsername) {
    const store = localStore.instances.get(username) || localStore.createInstance(username);
    clearTimeout(realityTimeoutId);
    clearInterval(realityIntervalId);
    if (store) {
      const result = newInterval
        ? store
            .getItem('realityCheckInterval')
            .then((oldInterval) => oldInterval !== newInterval * MINUTE)
            .then(
              (intervalChanged) =>
                intervalChanged &&
                Promise.all([
                  store.removeItem('realityCheckExpiration'),
                  store.removeItem('realityCheckInterval')
                ])
            )
        : [store.removeItem('realityCheckExpiration'), store.removeItem('realityCheckInterval')];
      return Array.isArray(result) ? Promise.all(result) : result;
    } else {
      return false;
    }
  } else {
    return false;
  }
};

let lastTimeoutId;
let lastIntervalId;

const initializeRealityIntervals = (
  realityCheckInterval,
  username,
  dispatch,
  realityTimeoutId,
  realityIntervalId
) => {
  if (username && username !== defaultUsername && realityCheckInterval) {
    const store = localStore.instances.get(username) || localStore.createInstance(username);
    if (store) {
      const setExpiration = (trigger, interval) => {
        store.setItem('realityCheckExpiration', Date.now() + trigger);
        store.setItem('realityCheckInterval', interval || trigger);
      };

      const checkValid = async (interval) => {
        const localStoreInterval = await store.getItem('realityCheckInterval');
        return interval === localStoreInterval;
      };

      const setupTimeouts = (trigger, interval) => {
        clearTimeout(realityTimeoutId);
        clearInterval(realityIntervalId);
        clearTimeout(lastTimeoutId);
        clearInterval(lastIntervalId);

        const timeoutId = setTimeout(() => {
          checkValid(interval || trigger).then((isValid) => {
            if (isValid) {
              dispatch(openRealityCheck);
              setExpiration(trigger);
            } else {
              initializeRealityIntervals(
                realityCheckInterval,
                username,
                dispatch,
                realityTimeoutId,
                realityIntervalId
              );
            }
          });
        }, trigger);
        const intervalId = setInterval(() => {
          checkValid(interval || trigger).then((isValid) => {
            if (isValid) {
              dispatch(openRealityCheck);
              setExpiration((interval || trigger) + trigger);
            } else {
              initializeRealityIntervals(
                realityCheckInterval,
                username,
                dispatch,
                realityTimeoutId,
                realityIntervalId
              );
            }
          });
        }, (interval || trigger) + trigger);

        dispatch(setRealityTimeoutId(timeoutId));
        dispatch(setRealityIntervalId(intervalId));
        lastTimeoutId = timeoutId;
        lastIntervalId = intervalId;
      };

      store.getItem('realityCheckExpiration').then((realityCheckExpiration) => {
        store.getItem('realityCheckInterval').then((localStoreInterval) => {
          if (!realityCheckExpiration || localStoreInterval !== realityCheckInterval * MINUTE) {
            const trigger = localStoreInterval || realityCheckInterval * MINUTE;
            setExpiration(trigger);
            setupTimeouts(trigger);
          } else {
            const difference = realityCheckExpiration - Date.now();
            if (difference < 0) {
              // Outdated, set new intervals
              const trigger = (realityCheckInterval * MINUTE) % difference;
              setExpiration(trigger, realityCheckInterval * MINUTE);
              setupTimeouts(trigger, realityCheckInterval * MINUTE);
            } else {
              setupTimeouts(difference, realityCheckInterval * MINUTE);
            }
          }
        });
      });
    }
  }
};

const localSessionMiddleware = (store) => (next) => (action) => {
  const state = store.getState();
  const user = User.selectors.model(state);
  const username = (action.payload && action.payload.username) || user.username;
  const { realityTimeoutId, realityIntervalId } = user;

  if (!username || username === defaultUsername) return next(action);

  const init = (interval) =>
    initializeRealityIntervals(
      interval,
      username,
      store.dispatch,
      realityTimeoutId,
      realityIntervalId
    );
  switch (action.type) {
    case User.actionTypes.AT.SET_REALITY_CHECK_INTERVAL.FULFILLED: {
      const realityCheckInterval =
        action.meta && action.meta.data && action.meta.data.realityCheckInterval;
      clearRealityIntervals(user, realityCheckInterval).then(() => {
        init(realityCheckInterval);
      });
      break;
    }
    case User.actionTypes.AT.GET_CURRENT.FULFILLED: {
      const realityCheckInterval = user.realityCheckInterval;
      clearRealityIntervals(user, realityCheckInterval).then(() => {
        init(realityCheckInterval);
      });
      break;
    }
    case User.actionTypes.AT.GET_SETTINGS.FULFILLED: {
      const realityCheckInterval = action.payload && action.payload.realityCheckInterval;
      clearRealityIntervals(user, realityCheckInterval).then(() => {
        init(realityCheckInterval);
      });
      break;
    }
    case Auth.AT.LOGOUT.PENDING: {
      clearRealityIntervals(user);
      break;
    }
    default:
      break;
  }
  return next(action);
};

export default localSessionMiddleware;
