import React from 'react';
import PropTypes from 'prop-types';
import AnimatedLogo from './AnimatedLogo';

const Logo = ({ src, srcList, alt, autoRefresh }) =>
  autoRefresh ? (
    <AnimatedLogo src={src} srcList={srcList} alt={alt} />
  ) : (
    <img src={src} alt={alt} />
  );

Logo.propTypes = {
  src: PropTypes.string.isRequired,
  srcList: PropTypes.array,
  alt: PropTypes.string,
  autoRefresh: PropTypes.bool
};

Logo.defaultProps = {
  srcList: [],
  alt: 'MrQ Logo',
  autoRefresh: false
};

export default Logo;
