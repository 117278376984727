import { combineReducers } from 'redux';
import { handleActions } from 'redux-actions';
import update from 'update-immutable';
import { externalSlicer, slicer } from 'lib/redux-utils';
import { actionTypes as UserTypes, constants as UserConstants } from 'modules/User';
import * as m from './model';
import * as t from './actionTypes';
import * as constants from './constants';
import { ACCOUNT_TAB } from './constants';
import { initialState } from './model';

const { TAB } = constants;

const updateTabs = (refs, entities, tabRef) =>
  refs.reduce((acc, curr) => {
    acc[curr] =
      curr === tabRef
        ? update(entities[curr], {
            active: { $set: true },
            counter: { $set: curr === 'chat' ? 0 : entities[curr].counter }
          })
        : update(entities[curr], { active: { $set: false } });
    return acc;
  }, {});

const updateChatCounter = (refs, entities) =>
  refs.reduce((acc, curr) => {
    acc[curr] =
      curr === 'chat' && !entities[curr].active
        ? update(entities[curr], {
            counter: { $set: entities[curr].counter + 1 }
          })
        : entities[curr];
    return acc;
  }, {});

const updateCategories = (category) => (state, action) => {
  const {
    payload: { data }
  } = action;
  if (!data.result.length) return state;

  return update(state, {
    entities: {
      bottomTabs: {
        $merge: data.entities // merge live and slot together, remember, their refs need to be unique
      },
      topTabs: {
        [category]: {
          bottomTabs: {
            // Merge (deduplicate) refs
            $apply: (previousTabRefs) => [...new Set([...previousTabRefs, ...data.result])]
          }
        }
      }
    },
    result: {
      bottomTabs: {
        // Merge (deduplicate) refs
        $apply: (previousTabRefs) => [...new Set([...previousTabRefs, ...data.result])]
      }
    }
  });
};

const _reducer = (state, action) => {
  switch (action.type) {
    case t.SELECT_TOP: {
      return state.result.topTabs
        ? update(state, {
            entities: {
              topTabs: {
                $set: updateTabs(
                  state.result.topTabs,
                  state.entities.topTabs,
                  action.payload.tabRef
                )
              }
            }
          })
        : state;
    }
    case t.SELECT_BOTTOM: {
      const top = m.topTabs(state);
      const bottom = m.bottomTabs(state);
      return top[m.activeTopRef(top)]?.bottomTabs
        ? update(state, {
            entities: {
              bottomTabs: {
                $merge: updateTabs(
                  top[m.activeTopRef(top)]?.bottomTabs,
                  bottom,
                  action.payload.tabRef
                )
              }
            }
          })
        : state;
    }
    case t.RESET: {
      return update(state, { $set: m.initialState[action.payload.name] });
    }
    case t.UPDATE_CHAT_COUNTER: {
      const top = m.topTabs(state);
      const bottom = m.bottomTabs(state);
      return update(state, {
        entities: {
          bottomTabs: {
            $merge: updateChatCounter(top[m.activeTopRef(top)].bottomTabs, bottom)
          }
        }
      });
    }
    case t.AT.LIST_SLOT_CATEGORIES.FULFILLED: {
      return updateCategories(TAB.SLOTS)(state, action);
    }
    case t.AT.LIST_LIVE_CATEGORIES.FULFILLED: {
      return updateCategories(TAB.LIVE)(state, action);
    }
    default:
      return state;
  }
};

const externalAccountReducer = handleActions(
  {
    [UserTypes.AT.KYC_VERIFICATION.FULFILLED]: (state, action) => {
      const isEddRequested =
        action.payload &&
        action.payload.status !== UserConstants.VerificationStatus.APPROVED &&
        action.payload.journey === UserConstants.VerificationType.EDD &&
        action.payload.provider === UserConstants.VerificationProvider.DOCUMENT_CENTRE;
      if (isEddRequested) {
        return updateCategories(TAB.ACCOUNT)(state, {
          payload: {
            data: {
              result: [ACCOUNT_TAB.VERIFICATION],
              entities: {
                [ACCOUNT_TAB.VERIFICATION]: {
                  title: 'Verification',
                  refName: ACCOUNT_TAB.VERIFICATION,
                  active: false
                }
              }
            }
          }
        });
      }
      return state;
    }
  },
  initialState.accountMiddle
);

const _loadingReducer = (state = initialState) => state;

// Multireducer
const moduleReducer = combineReducers({
  // All use a common reducer
  lobbyMiddle: slicer(_reducer, m.initialState, 'lobbyMiddle'),
  bingoMiddle: slicer(_reducer, m.initialState, 'bingoMiddle'),
  bingoMiddleMobile: slicer(_reducer, m.initialState, 'bingoMiddleMobile'),
  accountMiddle: externalSlicer(_reducer, externalAccountReducer, m.initialState, 'accountMiddle'),
  loading: _loadingReducer // allow Loading reducer to work
});

export default moduleReducer;
