import React, { createContext, useState, useContext, useEffect, useRef } from 'react';

const POP_UP_SHOW_THRESHOLD = 1209600000; // 2 weeks, 1000*60*60*24*14
const PWAContext = createContext({});

export const PWAProvider = (props) => {
  const lastPopUptimestamp = localStorage.getItem('pwa-popup-timestamp');
  const showModal =
    !Number.isFinite(parseInt(lastPopUptimestamp, 10)) ||
    parseInt(lastPopUptimestamp, 10) + POP_UP_SHOW_THRESHOLD < Date.now();
  const updateLastPopUpTimestamp = () => {
    const newTimestmap = Date.now();
    localStorage.setItem('pwa-popup-timestamp', newTimestmap);
  };

  const [PWAInfo, setPWAInfo] = useState({
    installPrompt: null,
    appInstalled: localStorage.getItem('pwa-installed') === 'true',
    appInstalledNow: false,
    showModal: showModal,
    lastPopUptimestamp: localStorage.getItem('pwa-popup-timestamp'),
    updateLastPopUpTimestamp: updateLastPopUpTimestamp
  });

  const contextValue = { PWAInfo };
  const deferredPrompt = useRef(null);

  const handleInstallPrompt = () => {
    if (deferredPrompt.current) {
      deferredPrompt.current
        .prompt()
        .then((userChoice) => {
          switch (userChoice.outcome) {
            case 'accepted':
              // analytics_track(key, userChoice);
              break;
            case 'dismissed':
              // analytics_track(key, userChoice);
              break;
            default:
              break;
          }
        })
        .catch((reason) => {
          console.error('Could not prompt', reason);
        });
    }
  };

  const handleBeforeInstallPromptEvent = (event) => {
    event.preventDefault();
    deferredPrompt.current = event;
    setPWAInfo((s) => ({ ...s, installPrompt: handleInstallPrompt }));
  };

  const handleAfterInstallPromptEvent = () => {
    console.log('appInstalled new');
    localStorage.setItem('pwa-installed', 'true');
    setPWAInfo((s) => ({ ...s, appInstalled: true, appInstalledNow: true }));
  };

  useEffect(() => {
    window.addEventListener('beforeinstallprompt', handleBeforeInstallPromptEvent);
    window.addEventListener('appinstalled', handleAfterInstallPromptEvent);

    return () => {
      window.removeEventListener('beforeinstallprompt', handleBeforeInstallPromptEvent);
      window.removeEventListener('appinstalled', handleAfterInstallPromptEvent);
    };
  }, []);

  return <PWAContext.Provider value={contextValue}>{props.children}</PWAContext.Provider>;
};

export const pwaConnect = (WrappedComponent) => (props) => {
  const pwaContext = useContext(PWAContext);
  return <WrappedComponent {...props} PWAInfo={pwaContext.PWAInfo} />;
};
