import Slot, { actionTypes as slotTypes } from 'modules/Slot';
import Modals from 'modules/Modals';
import Analytics from 'modules/Analytics';

/**
 * THE PAYLOAD DATA ARE THE JUST DEFAULT VALUES. The (pre-translated) action's payload is MERGED INTO the translation
 * ONLY add mappings for internal <-> external actions. Never external <-> external or internal <-> internal
 * keyMapping defines the **bidirectional** translation of the event/action keys.
 *
 * This is 2 way mapping!
 */
export default {
  keyMapping: {
    type: 'type',
    payload: 'data',
    flattenPayload: false
  },
  entities: {
    gameLoad: (dispatch, { meta }) =>
      dispatch({
        type: slotTypes.T.GAME_READY,
        payload: { refName: meta.refName }
      }),
    roundStart: { type: slotTypes.T.ROUND_STARTED },
    roundEnded: { type: slotTypes.T.ROUND_ENDED },
    error: { type: Analytics.T.REPORT_ERROR },
    quit: (dispatch, action) => dispatch(Slot.actions.closeGame(action)),
    loadingPercentageUpdated: (dispatch, data) => {
      console.log('loadingPercentageUpdated', data.payload.data);
    },
    cashier: {
      type: Modals.actionTypes.T.OPEN,
      payload: {
        name: 'deposit'
      }
    }
  }
};
