import { actionTypeCreator, ASYNC, SYNC } from 'lib/redux-utils';
import { NAME } from './constants';

const namespace = actionTypeCreator(NAME);

export const AT = namespace({
  SEARCH: ASYNC,
  SEARCH_POPULAR: ASYNC,
  GET_CATEGORY_CONTENT: ASYNC,
  GET_COMPONENT_CONTENT: ASYNC
});

export const T = namespace({
  CLEAR_SEARCH_RESULT: SYNC,
  UPDATE_QUERY: SYNC
});
