import { actionTypeCreator, ASYNC, SYNC } from 'lib/redux-utils';
import { NAME } from './constants';

const namespace = actionTypeCreator(NAME);

export const AT = namespace({
  REFRESH_EXPERIMENT: ASYNC
});

export const T = namespace({
  REGISTRATION: SYNC,
  REPORT_ERROR: SYNC,
  PROFILE: SYNC,
  LOGIN: SYNC,
  LOGGED_OUT: SYNC,
  EXPOSE_EXPERIMENT: SYNC,
  GAME_LAUNCHED: SYNC,
  SECTION_SCROLLED: SYNC,
  OFFER_CLICKED: SYNC,
  MODAL_OPENED: SYNC,
  MODAL_CLOSED: SYNC,
  ADDRESS_INPUT_MODE_CHANGED: SYNC,
  ADDRESS_EXPANDED: SYNC,
  ADDRESS_OPTION_SELECTED: SYNC,
  TRACK_EVENT: SYNC
});
