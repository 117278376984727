/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/**
 * Get the type of the argument. Compared to lodash _.isX this will return a stricter result.
 * For example, `_.isObject([])` returns true, while `getType([])` returns Array
 *
 * @example
 * getType(5); // => Returns 'Number'
 * @param {*} any
 * @returns {string | undefined} The type of the argument
 */
export default function getType(any: any): string | undefined {
  if (Array.isArray(any)) return 'Array'; // Fastest array check
  // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
  if (any?.then) return 'Promise'; // Bluebird would return 'Object' otherwise /bluebird/issues/1277 & /bluebird/pull/1421
  return /\[object (\w+)\]/.exec(Object.prototype.toString.call(any))?.[1];
}
