import { isNil } from 'lodash';
import { handleActions } from 'redux-actions';
import update from 'update-immutable';
import module from 'lib/module';
import { actionTypes as routerTypes } from 'modules/Router';
import { actionTypes as userTypes } from 'modules/User';
import { actionTypes as slotTypes } from 'modules/Slot';
import { NAME } from './constants';
import { initialState } from './model';
import { T, AT } from './actionTypes';

const moduleReducer = module(
  handleActions(
    {
      [AT.LOGIN.FULFILLED]: (state) =>
        update(state, {
          authenticated: { $set: true },
          loggedThisWindow: { $set: true }
        }),
      [AT.LOGIN.REJECTED]: (state, action) =>
        update(state, {
          authenticated: { $set: false },
          loggedThisWindow: { $set: false },
          loginError: { $set: action?.payload }
        }),
      [AT.REGISTER.FULFILLED]: (state) =>
        update(state, {
          registered: { $set: true },
          authenticated: { $set: true },
          loggedThisWindow: { $set: true }
        }),
      [AT.REGISTER.REJECTED]: (state, action) =>
        update(state, {
          authenticated: { $set: false },
          loggedThisWindow: { $set: true },
          registerError: { $set: action?.payload }
        }),
      [AT.PARTIAL_REGISTER.REJECTED]: (state, action) =>
        update(state, {
          authenticated: { $set: false },
          loggedThisWindow: { $set: true },
          registerError: { $set: action?.payload }
        }),
      [AT.PARTIAL_USER_REF.FULFILLED]: (state, action) =>
        update(state, {
          partialUserRef: { $set: action.payload }
        }),
      [AT.LOGOUT.FULFILLED]: (state) =>
        update(state, {
          authenticated: { $set: false },
          loggedThisWindow: { $set: false }
        }),
      [AT.LOGOUT.REJECTED]: (state) =>
        update(state, {
          authenticated: { $set: false },
          loggedThisWindow: { $set: false }
        }),
      [AT.CHECK_AUTH.FULFILLED]: (state) =>
        update(state, {
          authenticated: { $set: true }
        }),
      [AT.CHECK_AUTH.REJECTED]: (state) =>
        update(state, {
          authenticated: { $set: false }
        }),
      [T.LOGOUT]: (state) =>
        update(state, {
          authenticated: { $set: false }
        })
    },
    initialState
  ),
  NAME
);

const externalReducer = handleActions(
  {
    [routerTypes.T.LOCATION_CHANGE]: (state) =>
      update(state, {
        loginError: { $set: null },
        registerError: { $set: null }
      }),
    'Root/RESET': (state) =>
      update(state, {
        authenticated: { $set: false }
      }),
    [userTypes.AT.GET_CURRENT.FULFILLED]: (state) =>
      update(state, {
        authenticated: { $set: true }
      }),
    [userTypes.AT.GET_CURRENT.REJECTED]: (state) =>
      update(state, {
        authenticated: { $set: false }
      }),
    [slotTypes.AT.ROOM.FULFILLED]: (state) =>
      update(state, {
        isVerified: { $set: false }
      })
  },
  initialState
);

const reducer = (state = initialState, action) =>
  // FIXME: Correct check is `getNamespace(action.type) !== NAME` or `!action.type.startsWith(NAME)`
  isNil(action.type) || !action.type.includes(NAME)
    ? externalReducer(state, action)
    : moduleReducer(state, action);

export default reducer;
