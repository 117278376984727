export interface SoundsModel {
  loadedSprites: string[];
  canLoadSprite: boolean;
}
export const initialState: SoundsModel = {
  loadedSprites: [],
  canLoadSprite: false
} as SoundsModel;

export const loadedSprites = (model: SoundsModel) => model && model.loadedSprites;
export const canLoadSprite = (model: SoundsModel) => model && model.canLoadSprite;
