import React from 'react';
import { phoneNumberErrorMessage } from 'lib/messages';
import Button from 'components/Button';
import reform from 'components/Reform';
// @ts-ignore
import { mobilePhone } from 'lib/valFuncs';
// eslint-disable-next-line import/no-named-as-default,import/no-named-as-default-member -- We use default imports
import Fieldset from 'components/Fieldset';
import useCountdown from 'hooks/useCountdown';

const Form = reform()(
  ({
    smsPhoneNumber,
    handleChange,
    handleBlur,
    handleFocus,
    onSubmit,
    formValid,
    loading,
    nextSMSTime
  }: {
    smsPhoneNumber: string;
    handleChange: () => void;
    handleBlur: () => void;
    handleFocus: () => void;
    formValid: () => void;
    onSubmit: () => void;
    loading: () => void;
    nextSMSTime: number;
  }) => {
    const [countdown] = useCountdown(nextSMSTime - Date.now(), 1000);
    const remainingSeconds = Math.ceil(countdown / 1000);

    return (
      <form onSubmit={onSubmit}>
        <Fieldset
          // @ts-ignore -- fieldset is not typescript component. "field" is required prop, has no default.
          field={smsPhoneNumber}
          inputType="text"
          name="smsPhoneNumber"
          labelText="Enter your mobile number"
          autoComplete="one-time-code"
          onChange={handleChange}
          onFocus={handleFocus}
          onBlur={handleBlur}
          disabled={loading}
        />
        <div className="ecospend__tip ecospend__tip--gray">
          We will send an SMS with the link to this number.
        </div>
        <div className="fieldset fieldset--centerflex">
          <Button
            className="submit "
            disabled={!formValid || loading || remainingSeconds > 0}
            loading={loading}
            title="Text me the link"
            buttonText={
              remainingSeconds > 0
                ? `Resend in ${remainingSeconds} second${remainingSeconds === 1 ? '' : 's'}`
                : 'Text me the link'
            }
            type="submit"
          />
        </div>
      </form>
    );
  }
);

interface SMSNumberProps {
  initialPhoneNumber: string;
  onSubmit: (phoneNumber: string) => void;
  loading: boolean;
  nextSMSTime: number;
}

const SMSNumber = ({ initialPhoneNumber, onSubmit, loading, nextSMSTime }: SMSNumberProps) => {
  const fields = {
    smsPhoneNumber: {
      required: true,
      initial: initialPhoneNumber,
      onChange: mobilePhone,
      error: phoneNumberErrorMessage
    }
  };

  return <Form fields={fields} submit={onSubmit} loading={loading} nextSMSTime={nextSMSTime} />;
};

export default SMSNumber;
