import React, { memo } from 'react';
import SlotCard from 'components/SlotCard';

const SlotList = ({ refs, path, type, category, source }) =>
  refs.map((card) => (
    <SlotCard
      refName={card}
      key={`slot-${card}`}
      path={path}
      type={type}
      category={category}
      source={source}
    />
  ));

export default memo(SlotList);
