import React, { memo } from 'react';
import type { ReactNode } from 'react';
import type { LoadableComponent } from '@loadable/component';

// using Partial prevented using Component as a constructor
// changed by making "mapping" optional
type LoadableWithMapping = LoadableComponent<any> & {
  mapping?: Mapping;
};

export type Mapping = Record<string, LoadableWithMapping>;
type Child = CmsData[] | ReactNode;

export interface CmsData {
  component: string;
  refName: string;
  children: Child;
}

export interface BuilderProps {
  children: Child;
  mapping?: Mapping;
}

function isCmsData(item: any): item is CmsData {
  return String(item.component) === item.component && String(item.refName) === item.refName;
}

function isCmsDataArray(children: Child): children is CmsData[] {
  return Array.isArray(children) && !!children[0] && isCmsData(children[0]);
}

// TODO: Phase 2 - Introduce error boundaries per row
const Builder = ({ children, mapping }: BuilderProps) =>
  isCmsDataArray(children) ? (
    <>
      {children.map(({ component, children: nestedChildren, refName, ...props }) => {
        const Component = mapping?.[component];
        return Component ? (
          <Component key={refName} {...props}>
            {isCmsDataArray(nestedChildren) ? (
              /* TODO: Phase 2 - Consider using the component's mapping only, `Component.mapping`.
                   That may introduce a more complicated client<->CMS relationship than we want though.
                   If we end up with Component.mapping, be sure to introduce proper ErrorBoundaries on
                   the component level as well */
              <Builder mapping={mapping}>{nestedChildren}</Builder>
            ) : (
              nestedChildren
            )}
          </Component>
        ) : null;
      })}
    </>
  ) : (
    // fragment is needed so that Builder always returns a JSX.Element else TS complains it can't be used as JSX.Element
    <>{children}</>
  );

export default memo(Builder);
