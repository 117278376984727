import { schema, normalize } from 'normalizr';
import { ACCOUNT_TAB, TAB } from './constants';

// Tab refs must be unique
const initial = {
  lobbyMiddle: {
    topTabs: [
      {
        title: 'Casino',
        refName: TAB.SLOTS,
        active: true,
        icon: 'SlotsIcon',
        bottomTabs: []
      },
      {
        title: 'Live',
        refName: TAB.LIVE,
        active: false,
        icon: 'LiveIcon',
        bottomTabs: []
      },
      ...(__ENV__.MRQ_BINGO_ENABLED === 'true'
        ? [
            {
              title: 'Bingo',
              refName: TAB.BINGO,
              active: false,
              icon: 'BingoIcon',
              hideBottomTabBar: true,
              bottomTabs: [
                {
                  title: 'All',
                  refName: 'all-bingo',
                  active: true
                }
              ]
            }
          ]
        : []),
      {
        title: 'Rewards',
        refName: TAB.REWARDS,
        active: false,
        icon: 'RewardsTabIcon',
        hideBottomTabBar: true,
        counter: 0,
        bottomTabs: [
          {
            title: 'All',
            refName: 'all-offers',
            active: true
          }
        ]
      }
    ]
  },
  // TODO: Remove Bingo related code to optimize bundle size
  bingoMiddle: {
    topTabs: [
      {
        title: 'Waiting',
        refName: 'waiting',
        active: true,
        bottomTabs: [
          {
            title: 'Quick Buy',
            refName: 'quick-buy',
            active: true
          },
          {
            title: 'Select Tickets',
            refName: 'select-tickets',
            active: false
          },
          {
            title: 'My Tickets',
            refName: 'my-tickets',
            active: false
          }
        ]
      },
      {
        title: 'In Play',
        refName: 'playing',
        active: false,
        bottomTabs: [
          {
            title: 'Buy Next',
            refName: 'buy-next',
            active: false
          },
          {
            title: 'Current Game',
            refName: 'current-game',
            active: true
          }
        ]
      },
      {
        title: 'In Progress',
        refName: 'in-progress',
        active: false,
        bottomTabs: [
          {
            title: 'Buy Next',
            refName: 'buy-in-progress',
            active: true
          }
        ]
      }
    ]
  },
  // TODO: Remove Bingo related code to optimize bundle size
  bingoMiddleMobile: {
    topTabs: [
      {
        title: 'Waiting',
        refName: 'waiting',
        active: true,
        bottomTabs: [
          {
            title: 'Quick Buy',
            refName: 'buy-tickets',
            active: false,
            counter: 0
          },
          {
            title: 'Select',
            refName: 'select-tickets',
            active: false,
            counter: 0
          },
          {
            title: 'My Tickets',
            refName: 'default',
            active: true,
            counter: 0
          },
          {
            title: 'Chat',
            refName: 'chat',
            active: false,
            counter: 0
          }
        ]
      },
      {
        title: 'In Play',
        refName: 'playing',
        active: false,
        bottomTabs: [
          {
            title: 'Buy Next',
            refName: 'buy-next',
            active: false
          },
          {
            title: 'Current Game',
            refName: 'my-tickets',
            active: true
          },
          {
            title: 'Chat',
            refName: 'chat',
            active: false
          }
        ]
      },
      {
        title: 'In Progress',
        refName: 'in-progress',
        active: false,
        bottomTabs: [
          {
            title: 'Buy Next',
            refName: 'buy-in-progress',
            active: false
          },
          {
            title: 'Chat',
            refName: 'chat',
            active: false
          },
          {
            title: 'My Tickets',
            refName: 'default',
            active: true
          }
        ]
      }
    ]
  },
  accountMiddle: {
    topTabs: [
      {
        title: 'Account',
        refName: TAB.ACCOUNT,
        active: true,
        bottomTabs: [
          {
            title: 'Wallet',
            refName: ACCOUNT_TAB.WALLET,
            active: true
          },
          {
            title: 'Profile',
            refName: ACCOUNT_TAB.PROFILE,
            active: false
          },
          {
            title: 'Limits',
            refName: ACCOUNT_TAB.SAFER_GAMBLING,
            active: false
          },
          {
            title: 'Help',
            refName: ACCOUNT_TAB.HELP,
            active: false
          }
        ]
      }
    ]
  }
};

const bottomTab = new schema.Entity('bottomTabs', {}, { idAttribute: 'refName' });
const topTab = new schema.Entity(
  'topTabs',
  { bottomTabs: [bottomTab] },
  { idAttribute: 'refName' }
);
const carouselSchema = { topTabs: [topTab], bottomTabs: [bottomTab] };
Object.keys(initial).forEach((key) => {
  initial[key] = normalize(initial[key], carouselSchema);
  // The result is always dependent on the entry schema passed to normalize
  // so we add bottomTabs manually
  initial[key].result.bottomTabs = Object.keys(initial[key]?.entities?.bottomTabs);
});

export const initialState = Object.freeze(initial);

export const topTabs = (carousel) => carousel?.entities?.topTabs;
export const bottomTabs = (carousel) => carousel.entities.bottomTabs;
export const topTabRefs = (carousel) => carousel.result.topTabs;
export const bottomTabRefs = (carousel) => carousel.result.bottomTabs;
export const activeTopRef = (tabs) => Object.keys(tabs).find((ref) => tabs[ref].active);
