import React from 'react';
import PropTypes from 'prop-types';
import Button from 'components/Button';
import './pwa.css';

const AndroidNotification = ({ onTrigger }) => (
  <div onClick={onTrigger} className="pwaNotification">
    <div className="pwaNotification__content">
      <img
        className="pwaNotification__content--logo"
        src="/assets/images1/pwalogo.png"
        alt="logo"
      />
      <div className="pwaNotification__content--text">Add MrQ to Home Screen?</div>
      <Button
        onClick={onTrigger}
        name="addPWA"
        id="android-addPWA"
        className="oval"
        buttonText="Add"
        type="button"
      />
    </div>
  </div>
);

AndroidNotification.propTypes = {
  onTrigger: PropTypes.func.isRequired
};

export default AndroidNotification;
