import React from 'react';
import cc from 'classcat';
import type { RewardOfferCardProps } from 'components/RewardOfferCard';
import RewardOfferCard from 'components/RewardOfferCard';
import RewardsCalendarDay from 'components/RewardsCalendar/RewardsCalendarDay';
import type { SubOffer } from 'components/RewardOffersGridList/types';
import './rewardsCalendar.css';

interface RewardsCalendarProps extends RewardOfferCardProps {
  subOffers: SubOffer[];
}

const RewardsCalendar = ({
  refName,
  title,
  imageUrl,
  shortDescription,
  cta,
  heading,
  subOffers = []
}: RewardsCalendarProps) => (
  <RewardOfferCard
    refName={refName}
    title={title}
    imageUrl={imageUrl}
    shortDescription={shortDescription}
    cta={cta}
    heading={heading}
    className="offerCard--calendar"
  >
    <div
      className={cc([
        'rewardsCalendar',
        {
          'rewardsCalendar--cols3': subOffers.length % 3 === 0,
          'rewardsCalendar--cols4': subOffers.length % 4 === 0,
          'rewardsCalendar--cols5': subOffers.length % 5 === 0
        }
      ])}
    >
      {subOffers.map((offer) => (
        <RewardsCalendarDay key={offer.ref} offer={offer} />
      ))}
    </div>
  </RewardOfferCard>
);

export default RewardsCalendar;
