import React from 'react';
import makeModal from 'components/Modals';
import LoadingElement from 'components/Loading/LoadingElement';
import Dialog from 'components/Dialog';
import { DialogHeader } from 'components/Dialog/Header';
import Challenge from './Challenge';
import './challengeModal.css';

interface ChallengeModalProps {
  url: string;
  close: () => void;
}

const ChallengeModal = ({ url, close }: ChallengeModalProps) => (
  <Dialog name="pci-3d-challenge-dialog" onClose={close}>
    <DialogHeader icon="/assets/images1/dialogIcon.svg" onClose={close}>
      3D Secure
    </DialogHeader>
    {!url ? <LoadingElement /> : <Challenge url={url} close={close} />}
  </Dialog>
);

export default makeModal(
  'pci3dChallengeModal',
  { name: 'modal-fade-dark', timeout: 400 },
  { className: 'pci-3d-challenge modal modal--dialog' }
)(ChallengeModal);
