import React, { useEffect, memo, useMemo } from 'react';
import loadable from '@loadable/component';
import FocusTrap from 'focus-trap-react';
import type { Options as FocusTrapOptions } from 'focus-trap';
import Builder from './Builder';
import NameContext from './src/NameContext';
import type { BuilderProps } from './Builder';
import './dialog.css';

const FOCUS_TRAP_OPTIONS: FocusTrapOptions = { clickOutsideDeactivates: true };

const Header = loadable(() => import('./Header'));
const Close = loadable(() => import('./Close'));
const Article = loadable(() => import('./Article'));
const Image = loadable(() => import('./Image'));
const Gallery = loadable(() => import('./Gallery'));
const Footer = loadable(() => import('./Footer'));
const Button = loadable(() => import('./Button'));
const ChangePassword = loadable(() => import('./ChangePassword'));

const mapping = {
  Header,
  Close,
  Article,
  Image,
  Footer,
  Button,
  ChangePassword,
  Gallery
};

export interface Meta {
  trapFocus?: boolean;
  visible?: boolean;
  onDismissActionRef?: string | boolean;
  blocking?: boolean;
  overflowInside?: boolean;
  expiration?: number;
  focusTrapOptions?: FocusTrapOptions;
}

export interface DialogProps extends Meta {
  name: string;
  children: BuilderProps['children'];
  onOpen?: (name: string) => void;
  onClose?: () => void;
}

const Dialog = ({
  children,
  trapFocus = false,
  blocking = true,
  visible = true,
  overflowInside = true,
  expiration = 0,
  onDismissActionRef = true,
  focusTrapOptions = {},
  name,
  onOpen,
  onClose
}: DialogProps) => {
  useEffect(() => onOpen?.(name), [onOpen, name]);
  useEffect(() => onClose, [onClose]);
  const combinedFocusTrapOptions = useMemo(
    () => ({ ...FOCUS_TRAP_OPTIONS, ...focusTrapOptions }),
    [focusTrapOptions]
  );

  // TODO: Phase 3 - Inject focustrap activator (or use context) so components like Button or Close can enable it when loaded
  return (
    <FocusTrap focusTrapOptions={combinedFocusTrapOptions} active={trapFocus}>
      <div className="dialog ignore-react-onclickoutside">
        <NameContext.Provider value={name}>
          <Builder mapping={mapping}>{children}</Builder>
        </NameContext.Provider>
      </div>
    </FocusTrap>
  );
};

export default memo(Dialog);
