import React from 'react';
import DynamicModal from 'components/DynamicModal';
import makeModal from 'components/Modals';

interface PrivacyPolicyAdapterProps {
  isPublic: boolean;
  close: () => void;
}

const PrivacyPolicy = ({ isPublic, close }: PrivacyPolicyAdapterProps) => (
  <DynamicModal id="privacyPolicy" slug="privacy-policy" isPublic={isPublic} close={close} />
);

export default makeModal(
  'privacyPolicy',
  { name: 'modal-fade', timeout: 200 },
  { className: 'privacyPolicy modal' }
)(PrivacyPolicy);
