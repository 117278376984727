import Api from 'services/Api';
import * as t from './actionTypes';

export const getBottomTabs = (categories) =>
  categories
    .filter((category) => !category.hidden)
    .sort((a, b) => a.position - b.position)
    .map((category) => ({
      title: category.label,
      isDynamicLobby: category.multipleComponents,
      refName: category.code
    }));

export const selectTop = (name, tabRef) => ({
  type: t.SELECT_TOP,
  payload: { name, tabRef }
});

export const selectBottom = (name, tabRef) => ({
  type: t.SELECT_BOTTOM,
  payload: { name, tabRef }
});

export const removeBottom = (name, tabRef) => ({
  type: t.SELECT_BOTTOM,
  payload: { name, tabRef }
});

export const reset = (name) => ({
  type: t.RESET,
  payload: { name }
});

export const updateChatCounter = (name) => ({
  type: t.UPDATE_CHAT_COUNTER,
  payload: { name }
});

const _listSlotCategories = async () => {
  const { data } = await Api.request.carousel.listSlotCategories();
  return {
    name: 'lobbyMiddle',
    data: getBottomTabs(data)
  };
};

export const listSlotCategories = () => ({
  type: t.AT.LIST_SLOT_CATEGORIES._,
  payload: _listSlotCategories()
});

const _listLiveCategories = async () => {
  const { data } = await Api.request.carousel.listLiveCategories();
  return {
    name: 'lobbyMiddle',
    data: getBottomTabs(data)
  };
};

export const listLiveCategories = () => ({
  type: t.AT.LIST_LIVE_CATEGORIES._,
  payload: _listLiveCategories()
});
