// TODO: Move to module/Wallet and consolidate with lib/constants
export type PositiveId = keyof typeof POSITIVE;
export const POSITIVE = Object.freeze({
  DEPOSIT: 'Deposit',
  FONIX_DEPOSIT: 'Deposit',
  WITHDRAW_CANCELLATION: 'Cancelled withdrawal',
  WITHDRAW_DENIED: 'Withdrawal denied',
  CREDIT: 'Adjustment',
  SLOT_ROLLBACK_BET: 'Bet refund',
  SLOT_WIN: 'Win',
  BINGO_ROLLBACK_BET: 'Bet refund',
  BINGO_WIN: 'Win',
  SLOT_TOURNAMENT_WIN: 'Win',
  FREE_ROUND: 'Free round'
});

export type NegativeId = keyof typeof NEGATIVE;
export const NEGATIVE = Object.freeze({
  WITHDRAW: 'Withdrawal',
  SLOT_BET: 'Bet',
  BINGO_BET: 'Bet',
  SLOT_ROLLBACK_WIN: 'Cancelled win',
  BINGO_ROLLBACK_WIN: 'Cancelled win',
  REFUND: 'Refund',
  REFUND_DENIED: 'Refund denied',
  NEGATIVE_ADJUSTMENT: 'Adjustment',
  DEPOSIT_VOID: 'Deposit Void',
  SLOT_TOURNAMENT_ENTRY_FEE: 'Entry fee'
});

export type InstrumentId = keyof typeof INSTRUMENT;
export const INSTRUMENT = Object.freeze({
  BINGO: 'BINGO',
  SLOT: 'SLOT',
  CARD: 'CARD',
  MOBILE: 'MOBILE',
  PAYPAL: 'PAYPAL',
  BANK_ACCOUNT: 'BANK_ACCOUNT',
  CREDIT: 'CREDIT'
});

export const TRANSACTION_STATUS = Object.freeze({
  Requested: 'Requested',
  Approved: 'Approved',
  Paused: 'Paused',
  Failed: 'Failed',
  ProcessingFailed: 'Processing Failed',
  Voided: 'Voided'
});
