import { isArray } from 'lodash';
import update from 'update-immutable';
import { handleActions } from 'redux-actions';
import { T } from './actionTypes';
import { initialState } from './model';

// Use lib/module if adding external reducers or sub-slices
const reducer = handleActions(
  {
    [T.CAN_LOAD_SPRITE]: (state) => update(state, { canLoadSprite: { $set: true } }),
    [T.LOAD_SPRITE]: (state, action) =>
      update(
        state,
        isArray(state.loadedSprites)
          ? {
              loadedSprites: { $push: [action.payload] }
            }
          : { loadedSprites: { $set: [action.payload] } }
      )
  },
  initialState
);

export default reducer;
