import React from 'react';
import Button from 'components/Button';
import './limitsToggler.css';

interface LimitsTogglerProps {
  onLater: () => void;
  onShow: () => void;
}
const LimitsToggler = ({ onLater, onShow }: LimitsTogglerProps) => (
  <form className="limitsToggler">
    <p className="userBox__intro">
      Setting deposit limits helps you stay in control of your spending
    </p>
    <div className="fieldset fieldset--centerflex">
      <Button className="submit" buttonText="Set limits" name="setDepositLimits" onClick={onShow} />
      <Button
        className="submit"
        buttonText="Later&nbsp;&nbsp;&nbsp;▶"
        name="skipDepositLimits"
        onClick={onLater}
      />
    </div>
  </form>
);

export default LimitsToggler;
