/* eslint-disable no-unused-expressions */
import React, { Component, cloneElement } from 'react';
import PropTypes from 'prop-types';
import ReactCSSTransitionReplace from 'react-css-transition-replace';

const XFADE_MILLIS = 250;
const XFADE_MILLIS_LONG = 400;
export const LOADING_TIMEOUT = 300;

class MagicMove extends Component {
  componentDidMount() {
    this.mounted = true;
  }

  shouldComponentUpdate(nextProps) {
    if (
      nextProps.loadingTimeout &&
      this.props.children &&
      nextProps.children &&
      ((nextProps.children.key !== this.props.children.key &&
        nextProps.children.key === 'LoadingElement') ||
        (nextProps.childKey !== this.props.childKey && nextProps.childKey === 'LoadingElement'))
    ) {
      setTimeout(() => {
        if (
          this.mounted &&
          ((this.props.children && this.props.children.key === 'LoadingElement') ||
            this.props.childKey === 'LoadingElement')
        ) {
          this.forceUpdate();
        }
      }, nextProps.loadingTimeout);
      return false;
    } else {
      return true;
    }
  }

  componentWillUnmount() {
    this.mounted = false;
  }

  renderChildren = () => {
    const { children, childKey } = this.props;

    if (childKey) {
      return children ? cloneElement(children, { key: childKey }) : null;
    } else {
      return children;
    }
  };

  render() {
    const { children, childKey, long, loadingTimeout, childComponent, ...rest } = this.props;
    return (
      <ReactCSSTransitionReplace
        component="div"
        childComponent={childComponent}
        transitionName={long ? 'cross-fade-long' : 'cross-fade'}
        transitionEnterTimeout={long ? XFADE_MILLIS_LONG : XFADE_MILLIS}
        transitionLeaveTimeout={long ? XFADE_MILLIS_LONG : XFADE_MILLIS}
        {...rest}
      >
        {this.renderChildren()}
      </ReactCSSTransitionReplace>
    );
  }
}

MagicMove.propTypes = {
  long: PropTypes.bool,
  loadingTimeout: PropTypes.number,
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.arrayOf(PropTypes.element)]),
  childKey: PropTypes.string,
  childComponent: PropTypes.string
};

MagicMove.defaultProps = {
  long: false,
  loadingTimeout: LOADING_TIMEOUT,
  children: null,
  childKey: null,
  childComponent: 'span' // used by default by ReactCSSTransitionReplace
};

export default MagicMove;
