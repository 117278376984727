import React, { Component } from 'react';
import PropTypes from 'prop-types';
import hoistStatics from 'hoist-non-react-statics';
import { connect } from 'react-redux';
import { isLoading } from 'lib/redux-utils';
import registry from 'services/Api/registry';

const isFresh = (maxAge, _type) => (type = _type) => registry.get(type) >= Date.now() - maxAge;

/**
 * This will make MagicMove *always* render the loading element at least once, overriding the 250ms
 * threshold
 *
 * @param {(string|string[])} types - (Overriden if prop is provided) The action types to be
 *   fulfilled
 * @param  {Number} [maxAge=0] - (Overriden if prop is provided) hasLoaded is true if the response
 *   is fresh
 * @returns {function(*=)} - Enhanced component that starts off with the prop: hasLoaded === false
 */
const requiresData = (types, maxAge) => (BaseComponent) => {
  /**
   * Makes sure loading === true unti the relevant actions (types) have been fulfilled
   */
  class RequiresData extends Component {
    static propTypes = {
      /** Overrides the enhancer argument if passed at any point */
      types: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.string), PropTypes.string]),
      /** Overrides the enhancer argument if passed at any point */
      maxAge: PropTypes.number,
      loading: PropTypes.bool.isRequired
    };

    static defaultProps = {
      maxAge: null,
      types: null
    };

    constructor(props) {
      super(props);
      const _maxAge = props.maxAge || maxAge;
      const _types = props.types || types;
      let hasLoaded = false;

      if (maxAge > 0) {
        if (Array.isArray(_types)) {
          hasLoaded = _types.every(isFresh(_maxAge));
        } else {
          hasLoaded = isFresh(_maxAge, _types)();
        }
      }

      this.state = { hasLoaded: hasLoaded, wasLoading: false };
    }

    static getDerivedStateFromProps(nextProps, prevState) {
      const { loading } = nextProps;
      const { hasLoaded, wasLoading } = prevState;
      if (wasLoading !== loading && !loading && !hasLoaded) {
        return { hasLoaded: true, wasLoading: loading };
      }
      return { wasLoading: loading };
    }

    componentWillUnmount() {
      this.mounted = false;
    }

    render() {
      const { hasLoaded } = this.state;
      return <BaseComponent hasLoaded={hasLoaded} {...this.props} />;
    }
  }

  const mapStateToProps = (state, ownProps) => ({
    loading: isLoading(state, ownProps.types || types)
  });

  return hoistStatics(connect(mapStateToProps)(RequiresData), BaseComponent);
};

export default requiresData;
