import type { ReactNode } from 'react';
import React from 'react';
import { Typography, TypographyVariant } from 'components/Typography';

const TextPrimary = ({ children, color }: { children: ReactNode; color?: string }) => (
  <Typography variant={TypographyVariant.BodyMdStrong}>
    <span className="listItem__text--primary" style={{ color }}>
      {children}
    </span>
  </Typography>
);
export default TextPrimary;
