import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { addMonths } from 'date-fns';
import parseUTC from 'lib/parseUTC';
import { isLoading } from 'lib/redux-utils';
import Api from 'services/Api';
import User from 'modules/User';
import UserBox from 'components/UserBox';
import makeModal from 'components/Modals';
import SelfExclusionForm from './src/SelfExclusionForm';
import './selfExclusion.css';

const INDEFINITE_OPTION = 'INDEFINITE';

const fields = {
  months: {
    initial: null,
    required: true,
    onChange: () => true,
    error: 'Select how long you want to self-exclude'
  },
  agreeCheckbox: {
    initial: null,
    required: true,
    onChange: (value) => value,
    error: 'Must check the checkbox to continue'
  }
};

class _SelfExclusion extends PureComponent {
  render() {
    const { submit, loading, close } = this.props;
    return (
      <UserBox
        id="selfExclusionBox"
        className="selfExclusion modal"
        title="Want to self-exclude?"
        closeCallback={close}
      >
        <SelfExclusionForm fields={fields} submit={submit} close={close} loading={loading} />
      </UserBox>
    );
  }
}

const mapStateToProps = (state) => ({
  loading: isLoading(state, User.actionTypes.AT.SELF_EXCLUDE._)
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  submit: async ({ months }) => {
    try {
      const monthsCount = Number(months);
      const endDate =
        monthsCount < 0 ? INDEFINITE_OPTION : parseUTC(addMonths(new Date(), monthsCount));
      await Api.actions.user.selfExclude(null, {
        endDate
      })(dispatch);
      ownProps.close();
      Api.actions.auth.logout(null, new FormData())(dispatch);
    } catch (e) {
      return e.msg;
    }
  }
});

export default compose(
  makeModal(
    'selfExclusion',
    { name: 'modal-fade', timeout: 200 },
    { className: 'selfExclusion modal' }
  ),
  connect(mapStateToProps, mapDispatchToProps)
)(_SelfExclusion);
