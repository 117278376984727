import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';

const Img = forwardRef(({ src, srcList, alt }, ref) => (
  <picture ref={ref}>
    {srcList &&
      srcList.map((imageSrc) => (
        <source key={imageSrc.srcSet} srcSet={imageSrc.srcSet} type={imageSrc.type} />
      ))}
    <img src={src} alt={alt} />
  </picture>
));

Img.propTypes = {
  srcList: PropTypes.arrayOf(
    PropTypes.shape({
      srcSet: PropTypes.string,
      type: PropTypes.oneOf(['image/jpeg', 'image/webp'])
    })
  ),
  src: PropTypes.string,
  alt: PropTypes.string
};

Img.defaultProps = {
  srcList: [],
  src: null,
  alt: null
};

export default Img;
