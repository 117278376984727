import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import FocusTrap from 'focus-trap-react';
import cc from 'classcat';
import { applyContainerQuery } from 'lib/react-container-query';
// import { push, goBack } from 'react-router-redux'; // TODO: Do it
import Button from 'components/Button';
import UI from 'modules/UI';
import { CLASS_NAME_IGNORE_CLICK_OUTSIDE } from 'components/ClickOutside';
import withLazyStyle from 'components/LazyStyle';
import style from './userBox.css?lazy';

const SHAKE_TIME = 400;
const query = {
  'max-width-470': {
    maxWidth: 470
  }
};
const focusTrapOptions = { clickOutsideDeactivates: true, fallbackFocus: '.userBox__header' };

class UserBox extends Component {
  state = {
    shaking: false
  };

  componentWillUnmount() {
    clearTimeout(this.shakeTimeout);
  }

  shake = () => {
    this.setState({ shaking: true }, () => {
      this.shakeTimeout = setTimeout(() => this.setState({ shaking: false }), SHAKE_TIME);
    });
  };

  render() {
    const {
      id,
      className,
      title,
      children,
      overScroll,
      onBack,
      footer,
      focusTrap,
      containerQuery,
      closeCallback
    } = this.props;
    // We don't remove the button with a ternary
    // Because by the time the event tries to determine if this Button is inside the userbox, it will be removed by the DOM (Chrome)
    // This breaks ClickOutside (where the check happens)
    const backClass = cc([
      'button--iconButton',
      'button__grey',
      'userBox__back',
      onBack ? false : 'userBox__back--invisible'
    ]);
    const userBoxClass = cc([
      'userBox',
      className,
      containerQuery,
      {
        'userBox--dismissible': closeCallback,
        'animate--shake': this.state.shaking,
        [CLASS_NAME_IGNORE_CLICK_OUTSIDE]: className.includes('modal')
      }
    ]);
    const box = (
      <FocusTrap focusTrapOptions={focusTrapOptions} active={focusTrap && !!title}>
        <div id={id} className={userBoxClass}>
          {title && (
            <header className="userBox__header" tabIndex={-1}>
              <Button
                onClick={onBack || null}
                className={backClass}
                name="goBack"
                id="userBox-goBack"
                value="back"
                icon="/assets/images1/lobby/arrow-left-primary.svg"
              />
              <h6>{title}</h6>
              {closeCallback ? (
                <Button
                  className="button--iconButton button__grey userBox__close"
                  title="Close"
                  icon="/assets/images1/close-primary.svg"
                  name="close"
                  id="userbox-close"
                  onClick={closeCallback}
                />
              ) : null}
            </header>
          )}
          <div className="userBox__content">{children}</div>
          {footer ? <footer className="userBox__footer">{footer}</footer> : null}
        </div>
      </FocusTrap>
    );
    return overScroll ? <div className="overflow-wrap">{box}</div> : box;
  }
}

UserBox.propTypes = {
  id: PropTypes.string,
  className: PropTypes.string,
  title: PropTypes.string,
  children: PropTypes.node,
  footer: PropTypes.node,
  overScroll: PropTypes.bool,
  /** Set to false if your UserBox doesn't contain focusable elements */
  focusTrap: PropTypes.bool,
  onBack: PropTypes.func,
  containerQuery: PropTypes.object,
  closeCallback: PropTypes.func
};

UserBox.defaultProps = {
  id: null,
  containerQuery: null,
  className: '',
  title: '',
  children: null,
  footer: null,
  overScroll: false,
  focusTrap: true,
  onBack: null,
  closeCallback: null
};

const mapDispatchToProps = (dispatch) => ({
  prepareShake: () => dispatch(UI.actions.shakeModal)
});

export default compose(
  connect(null, mapDispatchToProps),
  withLazyStyle(style),
  applyContainerQuery
)(UserBox, query);
