// Lobby banner autoselect tickets waits for Bingo/WS_SUBSCRIBE_FULFILLED. Why is that?
// It's because `autoSelectedTicketCount: { $set: 0 }` is set on `Bingo/REFRESH_PENDING`, and the action
// right after is Bingo/WS_SUBSCRIBE_FULFILLED. Since deferUntilMiddleware dispatches the deferred
// action right BEFORE the condition action, we have to use Bingo/WS_SUBSCRIBE_FULFILLED which is called
// right after Bingo.refresh()

// If we ever want to make this fire AFTER the condition action, use setTimeout(dispatch, 0) instead of
// just dispatch()
import update from 'update-immutable';

export default function deferUntilMiddleware(shouldDefer, until) {
  // Will dispatch and flush when until(prev, cur) returns true
  let queue = [];
  // eslint-disable-next-line consistent-return
  return ({ dispatch }) => (next) => (action) => {
    let actionWasCaught = false;
    const flush = [];

    if (shouldDefer(action)) {
      queue.push(action);
      actionWasCaught = true;
    }
    queue.forEach((caught, index) => {
      // May still contain items that should be dispatched
      if (until(caught, action)) {
        const deferredAction = update(caught, {
          meta: { $unset: 'defer' }
        });
        dispatch(deferredAction);
        flush.push(index);
      }
    });

    queue = queue.filter((_, index) => !flush.includes(index));
    flush.length = 0;

    if (!actionWasCaught) {
      return next(action);
    }
  };
}
