import { isNil } from 'lodash';
import update from 'update-immutable';
import { NAME } from './constants';
import * as t from './actionTypes';
import { initialState } from './model';

const reducer = (state = initialState, action) => {
  const actionType = action.type;
  if (isNil(actionType)) {
    return state;
  } else if (!actionType.includes(NAME)) {
    // TODO: @GeKorm Is this actually important for performance?
    return state;
  } else {
    switch (actionType) {
      case t.COPY:
        return update(state, {
          clipboard: { $set: [action.payload] }
        });
      case t.ADD_MODIFIER:
        return update(state, {
          [action.payload.name]: { $push: [action.payload.modifier] }
        });
      case t.REMOVE_MODIFIER: {
        const name = action.payload.name;
        const arr = state[name];
        const modifier = action.payload.modifier;
        const index = arr.indexOf(modifier);
        if (arr.length > 0 && index >= 0) {
          return update(state, {
            [name]: { $splice: [[index, 1]] }
          });
        } else {
          return state;
        }
      }
      case t.RESET:
        return update(state, {
          $merge: {
            initialState
          }
        });
      case t.SET_MODIFIER:
        return update(state, {
          [action.payload.name]: { $set: action.payload.modifier }
        });
      case t.UNSET_MODIFIER:
        return update(state, {
          [action.payload.name]: { $unset: action.payload.modifier }
        });
      default:
        return state;
    }
  }
};

export default reducer;
