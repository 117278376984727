import type { Middleware } from 'redux';
import type { FluxStandardAction } from 'redux-promise-middleware';
import type { History } from 'src/store';
import type { types } from 'modules/Router';
import { T, actions } from 'modules/Router';

const isNavigationAction = (
  action: FluxStandardAction | types.NavigationAction
): action is types.NavigationAction => action.type === T.CALL_HISTORY_METHOD;

const routerMiddleware =
  (history: History): Middleware =>
  ({ dispatch }) => {
    setTimeout(() => {
      // Initial location POP, dispatch after middleware is contstructed (setTimeout 0)
      dispatch(actions.locationChanged(history.location, history.action));
    }, 0);
    history.listen((location, action) => {
      dispatch(actions.locationChanged(location, action));
    });
    return (next) => (action: FluxStandardAction) => {
      if (isNavigationAction(action)) {
        const { payload } = action;
        // Swallow CALL_HISTORY_METHOD
        // @ts-expect-error -- Can't be be bothered to create one case per method to narrow payload.args
        history[payload.method](...payload.args);
      } else {
        return next(action);
      }
    };
  };

export default routerMiddleware;
