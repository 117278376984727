/* eslint-disable @typescript-eslint/no-unsafe-return -- Redux modules missing full TS support */
import { useEffect, useRef } from 'react';
import { useAppDispatch, useAppSelector } from 'hooks/redux';
import Api from 'services/Api';
import Modals from 'modules/Modals';
import Wallet from 'modules/Wallet';
import Auth from 'modules/Auth';

export const usePostLoginLimitConfirmation = () => {
  const dispatch = useAppDispatch();
  const hasOpened = useRef(false);

  const isAnyDepositModalOpened: boolean = useAppSelector((state) =>
    Modals.selectors.isVisible(state, ['deposit', 'depositLimitSolicitations'])
  );
  const hasAnySolicitation = useAppSelector(
    (state) => !!Wallet.selectors.getDepositLimitSolicitations(state).solicitations
  );
  const hasLoggedIn: boolean = useAppSelector(Auth.selectors.getLoggedThisWindow);

  useEffect(() => {
    // @ts-ignore
    if (hasLoggedIn) Api.actions.wallet.getDepositLimitSolicitations()(dispatch);
  }, [dispatch, hasLoggedIn]);

  if (isAnyDepositModalOpened) hasOpened.current = true;

  useEffect(() => {
    if (!hasOpened.current && hasLoggedIn && hasAnySolicitation && !isAnyDepositModalOpened) {
      dispatch(Modals.actions.open('depositLimitSolicitations'));
      hasOpened.current = true;
    }
  }, [hasLoggedIn, dispatch, hasAnySolicitation, isAnyDepositModalOpened]);
};
