import { isNil, isEmpty } from 'lodash';
import update from 'update-immutable';
import { handleActions } from 'redux-actions';
import { createModuleReducer } from 'lib/module';
import { add, merge } from 'lib/update-normalized';
import { getNamespace } from 'lib/redux-utils';
import { actionTypes as ModalTypes } from 'modules/Modals';
import Socket from 'services/Socket';
import { T, AT } from './actionTypes';
import { NAME } from './constants';
import { initialState } from './model';

const addDialogs = (state, { payload }) => {
  if (!isEmpty(payload?.result)) {
    const { entities, result } = payload;
    const actions = result.reduce((acc, cur) => {
      acc[cur] = {
        $merge: entities[cur]?.actions
      };
      return acc;
    }, {});

    return update(state, {
      actions,
      entities: { $merge: entities },
      result: {
        // Merge (deduplicate) refs
        $apply: (previousDialogRefs) => [...new Set([...previousDialogRefs, ...result])]
      }
    });
  } else {
    return state;
  }
};

const addSingleDialog = (state, { payload }) => {
  if (!isEmpty(payload)) {
    const { actions, refName } = payload;

    const updater = {
      actions: {
        [refName]: { $merge: actions }
      },
      entities: {
        [refName]: { $merge: payload }
      },
      result: { $push: [refName] }
    };

    if (state.result.includes(refName)) delete updater.result; // dedupe

    return update(state, updater);
  } else {
    return state;
  }
};

const createDialog = (state, { payload }) => {
  if (!isEmpty(payload)) {
    const refName = payload.refName;
    return update(state, {
      entities: {
        [refName]: {
          $set: {
            ref: payload.refName,
            message: payload.message,
            severity: payload.severity,
            paths: payload.paths
          }
        }
      },
      result: {
        // Merge (deduplicate) refs
        $apply: (previousDialogRefs) => [...new Set([...previousDialogRefs, refName])]
      }
    });
  } else {
    return state;
  }
};

const manageActions = (state, refName) => {
  const { max, ttl, items } = state.actionsEvictingQueue;
  /** @type {Set<string>} */
  const evict = new Set();
  const now = Date.now();
  const size = items.length;
  /** @type {[string, number]} */
  const item = [refName, now + ttl];
  items.forEach(([key, expiry]) => {
    if (now >= expiry) {
      // Dialog TTL, evict expired dialog's actions
      evict.add(key);
    }
  });

  if (size - evict.size >= max) {
    // Maximum size reached, evict one dialog's actions.
    // First in line to be evicted is 0. If any entries expired, size is strictly below max
    evict.add(items[0][0]);
  }

  if (!evict.size) {
    return update(state, {
      actionsEvictingQueue: {
        /** @type {{$push: [string, number][]}} */
        items: { $push: [item] }
      }
    });
  } else {
    return update(state, {
      actions: { $unset: [...evict] },
      actionsEvictingQueue: {
        /** @type {{ $apply: (prevItems: [string, number][] | []) => [string, number][] }} */
        items: {
          $apply: (prevItems) => [...prevItems.filter(([key]) => !evict.has(key)), item]
        }
      }
    });
  }
};

const closeDialog = (state, refName) => {
  if (refName) {
    const index = state.result.indexOf(refName);
    if (index >= 0) {
      const removedDialogState = update(state, {
        entities: {
          $unset: refName
        },
        result: {
          $splice: [[index, 1]]
        }
      });

      return manageActions(removedDialogState, refName);
    }
  }
  return state;
};

const moduleReducer = createModuleReducer(
  {
    [T.OPEN]: (state, action) =>
      add(state, action.payload, action.payload && action.payload.refName),
    [T.CLOSE]: (state, action) => closeDialog(state, action.payload && action.payload.refName),
    [T.UPDATE]: (state, action) =>
      merge(state, action.payload, action.payload && action.payload.refName),
    [T.CREATE]: createDialog,
    [AT.LIST_SINGLE.FULFILLED]: addSingleDialog,
    [AT.LIST.FULFILLED]: addDialogs
  },
  initialState,
  NAME
);

/* TODO: Phase 2 or 3 - On the topic of the relationship between Dialogs, Modals and Notifications, we probably need
     Modals to be a higher-order reducer. This could dependence on GlobalDialogs, and make dialogs usable in more
     contexts  */
const externalReducer = handleActions(
  {
    [ModalTypes.T.CLOSE]: (state, action) => closeDialog(state, action.payload?.name)
  },
  initialState
);

const reducer = (state = initialState, action) =>
  isNil(action.type) || getNamespace(action.type) !== NAME
    ? externalReducer(state, action)
    : moduleReducer(state, action);

export default Socket.reducer(reducer, NAME);
