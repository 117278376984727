import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import makeModal from 'components/Modals';
import UserBox from 'components/UserBox';
import Button from 'components/Button';
import { formatCurrency } from 'lib/formatters';
import { isLoading } from 'lib/redux-utils';
import Auth from 'modules/Auth';
import Wallet from 'modules/Wallet';
import Api from 'services/Api';

const VerifyMobileSuccess = ({
  loading,
  close,
  depositAmount,
  sendDeposit,
  depositMobilePaymentRef
}) => {
  const handleClickDeposit = async () => {
    await sendDeposit(depositMobilePaymentRef);
    close();
  };

  return (
    <UserBox title="Success" closeCallback={close} className="verifyMobileSuccess modal">
      <p>
        You successfully verified your phone number! We may need to verify it again in 6 months
        time.
      </p>
      <p>Would you like to complete your deposit?</p>
      <div className="fieldset fieldset--centerflex">
        <Button
          loading={loading}
          name="completeDeposit"
          buttonText={`Complete ${formatCurrency(depositAmount)} deposit`}
          onClick={handleClickDeposit}
        />
      </div>
    </UserBox>
  );
};

const mapStateToProps = (state) => ({
  loading: isLoading(state, Wallet.actionTypes.AT.DEPOSIT_MOBILE._),
  depositMobilePaymentRef: Wallet.selectors.getMobilePaymentRef(state)
});

const mapDispatchToProps = (dispatch, { depositAmount, promotionCode }) => ({
  sendDeposit: async (paymentMethodRef) => {
    const amount = depositAmount;
    const { value: deviceInfo } = await dispatch(Auth.actions.getDeviceFingerprint());
    const result = await Api.actions.wallet.depositMobile(null, {
      paymentMethodRef,
      amount,
      promotionCode,
      deviceInfo
    })(dispatch);
    return result;
  }
});

VerifyMobileSuccess.propTypes = {
  loading: PropTypes.bool.isRequired,
  close: PropTypes.func.isRequired,
  // eslint-disable-next-line react/no-unused-prop-types
  paymentMethodRef: PropTypes.string.isRequired,
  depositAmount: PropTypes.string.isRequired,
  // eslint-disable-next-line react/no-unused-prop-types
  promotionCode: PropTypes.string.isRequired,
  sendDeposit: PropTypes.func.isRequired,
  depositMobilePaymentRef: PropTypes.string.isRequired
};

export default compose(
  makeModal(
    'verifyMobileSuccess',
    { name: 'modal-fade', timeout: 200 },
    { className: 'verifyMobileSuccess modal' }
  ),
  connect(mapStateToProps, mapDispatchToProps)
)(VerifyMobileSuccess);
