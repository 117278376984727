import React, { useRef, useEffect, memo, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import Wallet from 'modules/Wallet';
import Api from 'services/Api';
import Modals from 'modules/Modals';
import Iframe from 'components/Iframe';

interface IChallenge {
  url: string;
  close: () => void;
}

const Challenge = ({ url, close }: IChallenge) => {
  const success = useRef<boolean>();
  const history = useHistory();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(Wallet.actions.pciProxy3dChallengePending);

    return () => {
      // if the challenge is canceled outside of the iframe
      if (success.current === undefined) {
        dispatch(Wallet.actions.handlePciProxyError(true, '3D Secure cancelled.', ['deposit']));
        dispatch(Wallet.actions.depositCardPciProxyRejected);
      }

      if (success.current) {
        dispatch(Wallet.actions.pciProxy3dChallengeFulfilled);
      } else {
        dispatch(Wallet.actions.pciProxy3dChallengeRejected);
      }
    };
  }, [dispatch]);

  const handleMessage = useCallback(
    (message) => {
      if (message.data === 'redirectingToACS') {
        // in order for popstate to work we need to push the current location
        // on top of the iframe history stack
        history.push(window.location.pathname + window.location.search);
        return;
      }

      const { state, datatransTrxId, type } = message.data;
      if (type === 'PCI_PROXY_3D_CHALLENGE') {
        success.current = state === 'completed';
        void Api.actions.wallet.depositCardPciProxy3ds(null, {
          state,
          transactionId: datatransTrxId
        })(dispatch);
        close();
      }
    },
    [history, dispatch, close]
  );

  const handlePopState = useCallback(() => {
    // in case the player tries to go back to the previous page
    // we catch the history popstate and close the modal
    dispatch(Modals.actions.close('pci3dChallengeModal'));
  }, [dispatch]);

  useEffect(() => {
    window.addEventListener('popstate', handlePopState);

    return () => window.removeEventListener('popstate', handlePopState);
  }, [dispatch, handlePopState]);

  return (
    <Iframe
      src={url}
      width="100%"
      height="400"
      frameBorder="0"
      title="3D Secure Challenge"
      onReceiveMessage={handleMessage}
      onReady={null}
    />
  );
};

export default memo(Challenge);
