import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { actions as routerActions } from 'modules/Router';
import makeModal from 'components/Modals';
import UserBox from 'components/UserBox';
import Button from 'components/Button';
import './keepAliveSlotError.css';

const { push } = routerActions;

const _KeepAliveSlotError = ({ close, gotoLobby }) => (
  <UserBox id="keepAliveSlotError" className="keepAliveSlotError modal" title="Server error">
    <img
      className="keepAliveSlotError__img"
      alt="Server error"
      src="/assets/images1/server-error-character.svg"
    />
    <p className="keepAliveSlotError__text">
      Whoops, something went wrong... <br />
      Please click refresh to reload the game
    </p>
    <div className="keepAliveSlotError__buttonsWrapper">
      <Button
        className="keepAliveSlotError__button"
        buttonText="Refresh"
        name="reload"
        id="keepAliveSlotError-reload"
        onClick={() => {
          window.location.reload();
          close();
        }}
      />
      <a
        className="underline"
        href=""
        onClick={() => {
          gotoLobby();
        }}
      >
        {'< Back to lobby'}
      </a>
    </div>
  </UserBox>
);

const mapDispatchToProps = (dispatch) => ({
  gotoLobby: () => dispatch(push('/secure/lobby'))
});

export default compose(
  makeModal(
    'keepAliveSlotError',
    { name: 'modal-fade', timeout: 200 },
    { className: 'keepAliveSlotError modal' }
  ),
  connect(null, mapDispatchToProps)
)(_KeepAliveSlotError);
