import React, { memo } from 'react';
import cc from 'classcat';
import Animate from 'react-move/Animate';
import { easeLinear } from 'd3-ease';
import { formatCurrency } from 'lib/formatters';
import Modals from 'modules/Modals';
import Wallet from 'modules/Wallet';
import Button from 'components/Button';
import { MagicMove } from 'components/Transitions';
import { useAppDispatch, useAppSelector } from 'hooks/redux';
import './balance.css';

interface BalanceProps {
  onOpenDeposit: () => void;
  spinning: boolean;
  balance: number;
  hasDeposited?: boolean;
}

export const BalanceBase = ({ balance, onOpenDeposit, spinning, hasDeposited }: BalanceProps) => {
  // While we are waiting for the backend response, we don't show anything. We consider that intermediate state.
  // That is because we want to prevent unnecessary flashing of the balance component when the value of "hasDeposited"
  // changes from an intermediate (undefined) value to real bool value (true/false).
  let element = <div key="empty" />;
  // There is an edge case where a player could have balance > 0 and hasn't deposited yet. Such scenario is extremely rare and
  // can only happen when a player has been manually credited from the backoffice. In that case, there might a slight
  // flicker between the states of the CTA because of the order of the backend API calls, but this will always have the proper
  // state in the end.
  if (hasDeposited || balance > 0)
    element = (
      <div key="deposited" className="balance">
        <div className="balance__amount">
          <Animate
            start={{
              balance
            }}
            update={{
              balance: [balance],
              timing: { duration: 1000, ease: easeLinear }
            }}
          >
            {({ balance: dataBalance }) => (
              <span className={cc([spinning && 'animate--loading-faded'])}>
                {formatCurrency(dataBalance || 0, { precise: true })}
              </span>
            )}
          </Animate>
        </div>
        <Button
          className="balance__depositButton button__grey balance__depositButton--hasDeposited"
          onClick={onOpenDeposit}
          name="openDeposit"
          id="balance-openDeposit"
        />
      </div>
    );
  else if (hasDeposited !== undefined && !hasDeposited && balance <= 0) {
    element = (
      <div key="firstDeposit" className="balance">
        <Button
          className="balance__depositButton balance__depositButton--noDeposits"
          onClick={onOpenDeposit}
          name="openDeposit"
          id="balance-openFirstDeposit"
        >
          Deposit&nbsp;
        </Button>
      </div>
    );
  }
  return <MagicMove>{element}</MagicMove>;
};

const Balance = () => {
  const balance = useAppSelector((state) => Wallet.selectors.getBalance(state));
  const balanceOverride = useAppSelector(
    (state) => Wallet.selectors.model(state).balanceOverride as number | null
  );
  const hasDeposited = useAppSelector(
    (state) => Wallet.selectors.getHasDeposited(state) as boolean | undefined
  );
  const dispatch = useAppDispatch();
  const onOpenDeposit = () => {
    dispatch(Modals.actions.open('deposit'));
  };

  return (
    <BalanceBase
      balance={balance}
      onOpenDeposit={onOpenDeposit}
      spinning={!!balanceOverride}
      hasDeposited={hasDeposited}
    />
  );
};

export default memo(Balance);
