import React, { Component } from 'react';
import PropTypes from 'prop-types';

export const CLASS_NAME_IGNORE_CLICK_OUTSIDE = 'ignore-react-onclickoutside';

export default class ClickOutside extends Component {
  static propTypes = {
    onClickOutside: PropTypes.func.isRequired,
    noAction: PropTypes.bool
  };

  static defaultProps = {
    noAction: false
  };

  componentDidMount() {
    document.addEventListener('click', this.handle, { capture: true });
  }

  componentWillUnmount() {
    document.removeEventListener('click', this.handle, { capture: true });
  }

  getContainer = (ref) => {
    this.container = ref;
  };

  handle = (e) => {
    const { onClickOutside, noAction } = this.props;
    const { target } = e;
    const ignored = [...document.getElementsByClassName(CLASS_NAME_IGNORE_CLICK_OUTSIDE)];
    if (this.container.contains(target) || !document.contains(target)) {
      //      e.stopPropagation();
    } else if (!ignored.some((elem) => elem.contains(target))) {
      if (noAction) e.preventDefault();
      onClickOutside(e, this.container);
    }
  };

  render() {
    const { children, onClickOutside, noAction, ...props } = this.props;
    return (
      <div {...props} ref={this.getContainer}>
        {children}
      </div>
    );
  }
}
