/* eslint-disable prefer-template */
import webstomp from 'webstomp-client';
import SockJS from '@gekorm/sockjs-client';
import proxyTarget from 'lib/proxyTarget';
import { detectDevice } from 'lib/device';
import Auth from 'modules/Auth';

let proxyParams = '';
if (proxyTarget) {
  const params = new URLSearchParams();
  params.set('xProxyTarget', proxyTarget);
  proxyParams = `?${params.toString()}`;
}

const WS_BINGO = __ENV__.MRQ_URL_BINGO + '/bingo-ws' + proxyParams;
const WS_SLOT = __ENV__.MRQ_URL_SLOT + '/slot-ws' + proxyParams;
const WS_CHAT = __ENV__.MRQ_URL_CHAT + '/chat-ws' + proxyParams;
const WS_WALLET = __ENV__.MRQ_URL_WALLET + '/wallet-ws' + proxyParams;
const WS_POPUP = __ENV__.MRQ_URL_POPUPS + '/popups-ws' + proxyParams;

const debug = __ENV__.MRQ_DEBUG === 'true';

export const isAuthenticated = (dispatch) => dispatch(Auth.actions.checkAuth());

export const Bingo = () =>
  __ENV__.MRQ_BINGO_ENABLED === 'true'
    ? webstomp.over(new SockJS(WS_BINGO), { debug: debug })
    : undefined;
export const Slot = () => webstomp.over(new SockJS(WS_SLOT), { debug: debug });
export const Chat = () => webstomp.over(new SockJS(WS_CHAT), { debug: debug });
export const Wallet = () => webstomp.over(new SockJS(WS_WALLET), { debug: debug });
export const Dialog = () => webstomp.over(new SockJS(WS_POPUP), { debug: debug });

export const destination = {
  ...(__ENV__.MRQ_BINGO_ENABLED === 'true'
    ? {
        Bingo: (refName) => ({
          in: `/topic/${refName}/bingo.message`,
          out: `/app/${refName}/bingo.message`
        })
      }
    : {}),
  Wallet: () => ({ in: '/user/queue/wallet.message' }),
  Dialog: () => ({ in: '/user/queue/popups.messages' }),
  Chat: (refName) =>
    refName === '__UNIQUE_OR_PRIVATE__' || !refName
      ? {
          in: '/user/queue/private-chat.message',
          out: `/app/private-chat.message`
        }
      : {
          in: `/topic/${refName}/chat.message`,
          out: `/app/${refName}/chat.message`
        }
};
// Subscribes and returns a subscriber client to call client.unsubscribe()
export const subscriber = {
  ...(__ENV__.MRQ_BINGO_ENABLED === 'true'
    ? {
        Bingo: (onMessage, refName, dispatch) => (client) =>
          client.subscribe(
            destination.Bingo(refName).in,
            (message) => onMessage(client, dispatch, message, { refName }),
            detectDevice()
          )
      }
    : {}),
  Wallet: (onMessage, payload, dispatch) => (client) =>
    client.subscribe(
      destination.Wallet().in,
      (message) => onMessage(client, dispatch, message),
      detectDevice()
    ),
  Dialog: (onMessage, refName, dispatch) => (client) =>
    client.subscribe(
      destination.Dialog().in,
      (message) => onMessage(client, dispatch, message),
      detectDevice()
    ),
  Chat: (onMessage, refName, dispatch) => (client) =>
    client.subscribe(
      destination.Chat(refName).in,
      (message) => onMessage(client, dispatch, message, { refName }),
      detectDevice()
    )
};
