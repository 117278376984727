import { useMediaQuery } from 'usehooks-ts';

interface MediaProps {
  query: string;
  children: JSX.Element | ((matches: boolean) => JSX.Element | null) | null;
}
const Media = (props: MediaProps) => {
  const { query, children } = props;
  const matches = useMediaQuery(query);
  if (typeof children === 'function') {
    return children(matches);
  }
  return matches ? children : null;
};

export default Media;
