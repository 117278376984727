import React from 'react';
import PropTypes from 'prop-types';
import { useImgSrcAutoRefresh } from 'hooks/useImgSrcAutoRefresh';
import Img from 'components/Img';

const REFRESH_TIME = 60 * 1000;

const AnimatedLogo = ({ src, srcList, alt }) => {
  const imageRef = useImgSrcAutoRefresh(REFRESH_TIME);

  return <Img key={src} ref={imageRef} src={src} srcList={srcList} alt={alt} />;
};

AnimatedLogo.propTypes = {
  src: PropTypes.string.isRequired,
  srcList: PropTypes.array.isRequired,
  alt: PropTypes.string.isRequired
};

export default AnimatedLogo;
