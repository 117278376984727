import { handleActions } from 'redux-actions';

const _module =
  (reducer, name) =>
  (state = reducer(undefined, {}), action) =>
    action.type && action.type.startsWith(name) ? reducer(state, action) : state;

export const createModuleReducer = (actionMap, initialState, name) =>
  _module(handleActions(actionMap, initialState), name);

export default _module;
