import type { Locale, ModernNumberFormatOptions } from './types';
import canUseTrailingZeroDisplay from './canUseTrailingZeroDisplay';
import canUseNarrowSymbol from './canUseNarrowSymbol';

const commonFormatOptions = {
  style: 'currency',
  currencyDisplay: canUseNarrowSymbol ? 'narrowSymbol' : 'symbol'
};

export class CurrencyFormatter {
  loose!: Intl.NumberFormat;
  precise!: Intl.NumberFormat;
  preciseStripZeroTrail?: Intl.NumberFormat;
  #decimalFraction!: string;
  #symbol!: string;
  // #locale: Locale;
  // #currency: string;

  constructor(
    locale: Locale = __ENV__.MRQ_LOCALE || undefined, // undefined defaults to user preferences
    currency: string = __ENV__.MRQ_CURRENCY
  ) {
    // this.#locale = locale;
    // this.#currency = currency;
    this.update(locale, currency);
  }

  #getDecimalFraction = () =>
    this.precise
      .formatToParts(1)
      .filter(({ type }) => type === 'decimal' || type === 'fraction')
      .map(({ value }) => value)
      .join('');

  get decimalFraction() {
    return this.#decimalFraction;
  }

  #getSymbol = () => this.precise.formatToParts(1).find((x) => x.type === 'currency')?.value || '¤';

  get symbol() {
    return this.#symbol;
  }

  // get locale() {
  //   return this.#locale;
  // }
  //
  // set locale(value: Locale) {
  //   this.#locale = value;
  //   this.update(value, this.#currency);
  // }
  //
  // get currency() {
  //   return this.#currency;
  // }
  //
  // set currency(value: string) {
  //   this.#currency = value;
  //   this.update(this.#locale, value);
  // }

  // Can use setters for #locale and #currency if it turns out that we set one of each at a time
  // in the UI
  update = (
    locale: Locale,
    currency: string = this.precise?.resolvedOptions()?.currency || __ENV__.MRQ_CURRENCY
  ) => {
    // this.#locale = locale;
    // this.#currency = currency;
    this.loose = new Intl.NumberFormat(locale, {
      ...commonFormatOptions,
      currency,
      maximumFractionDigits: 0,
      minimumFractionDigits: 0
    });
    this.precise = new Intl.NumberFormat(locale, {
      ...commonFormatOptions,
      currency,
      maximumFractionDigits: 2,
      minimumFractionDigits: 2
    });
    this.preciseStripZeroTrail = canUseTrailingZeroDisplay
      ? new Intl.NumberFormat(locale, {
          ...commonFormatOptions,
          currency,
          maximumFractionDigits: 2,
          minimumFractionDigits: 2,
          trailingZeroDisplay: 'stripIfInteger'
        } as ModernNumberFormatOptions)
      : undefined;
    this.#decimalFraction = this.#getDecimalFraction();
    this.#symbol = this.#getSymbol();
  };
}

/**
 * To be used with a locale provider in React
 */
const formatter = new CurrencyFormatter();

export default formatter;
