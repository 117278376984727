import React, { memo, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';

const Iframe = ({ onReceiveMessage, onReady, ...attributes }) => {
  const handleLoadIframe = () => {
    if (onReady) {
      onReady();
    }
  };

  const handleReceiveMessage = useCallback(
    (event) => {
      if (onReceiveMessage) {
        onReceiveMessage(event);
      }
    },
    [onReceiveMessage]
  );

  useEffect(() => {
    window.addEventListener('message', handleReceiveMessage, false);
    return () => window.removeEventListener('message', handleReceiveMessage, false);
  }, [handleReceiveMessage]);

  return <iframe title={attributes.title} onLoad={handleLoadIframe} {...attributes} />;
};

Iframe.defaultProps = {
  allowFullScreen: false,
  frameBorder: 0,
  height: null,
  name: null,
  scrolling: null,
  sandbox: null,
  srcDoc: null,
  width: null,
  onReady: null,
  onReceiveMessage: null
};

Iframe.propTypes = {
  allowFullScreen: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  frameBorder: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  name: PropTypes.string,
  scrolling: PropTypes.string,
  sandbox: PropTypes.string,
  srcDoc: PropTypes.string,
  src: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onReady: PropTypes.func,
  onReceiveMessage: PropTypes.func
};

export default memo(Iframe);
