/* eslint-disable no-unused-expressions */
import React from 'react';
import PropTypes from 'prop-types';
import { TransitionGroup, CSSTransition } from 'react-transition-group';

const Base = ({ children, childKey, classNames, timeout, className }) => (
  <TransitionGroup component="div" className={className}>
    {children && (
      <CSSTransition in key={childKey} classNames={classNames} timeout={timeout} appear enter exit>
        {children}
      </CSSTransition>
    )}
  </TransitionGroup>
);

Base.propTypes = {
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.arrayOf(PropTypes.element)]),
  childKey: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  className: PropTypes.string,
  classNames: PropTypes.string.isRequired,
  timeout: PropTypes.number.isRequired
};

Base.defaultProps = {
  children: null,
  className: null,
  childKey: null
};

export default Base;
