import React, { Component } from 'react';
import PropTypes from 'prop-types';
import update from 'update-immutable';
import isEmpty from 'lodash/isEmpty'; // Babel screws up here for some reason if { isEmpty }...'lodash'
import cc from 'classcat';
import AutoSizer from 'react-virtualized/dist/commonjs/AutoSizer';
import List from 'react-virtualized/dist/commonjs/List';
import InfiniteLoader from 'react-virtualized/dist/commonjs/InfiniteLoader';
import CellMeasurer, { CellMeasurerCache } from 'react-virtualized/dist/commonjs/CellMeasurer';
import './virtualList.css';

const STATUS_LOADING = 1;
const STATUS_LOADED = 2;

// PureComponent: SIMPLE props only, ALL children must be PURE too!
class VirtualList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loadedRowsMap: new Array(props.increment).fill(STATUS_LOADED),
      listHeight: isEmpty(props.refs)
        ? props.emptyListHeight
        : props.refs.length * props.defaultRowHeight
    };
    this.cache = new CellMeasurerCache({
      fixedWidth: true,
      minHeight: 0,
      defaultHeight: props.defaultRowHeight,
      keyMapper: () => 1
    });
  }

  componentDidMount() {
    this.mounted = true;
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { refs, totalElements } = nextProps;
    if (
      (refs && refs.length) !== (this.props.refs && this.props.refs.length) ||
      (!refs && this.props.refs) ||
      (refs && !this.props.refs)
    ) {
      this.handleResize(refs);
    }
    if (totalElements !== this.props.totalElements) {
      this.clearData();
    }
  }

  componentWillUnmount() {
    this.mounted = false;
  }

  setFirstRowRef = (_) => {
    this.firstRowRef = _;
  };

  setListRef = (registerChild) => (_) => {
    this.list = _;
    if (registerChild) registerChild.call(this, _);
  };

  setInfiniteLoaderRef = (_) => {
    this.infiniteLoader = _;
  };

  handleResize = (_refs) => {
    const calledByAutoSizer = !Array.isArray(_refs);
    const { defaultRowHeight, emptyListHeight, loadMore, totalElements } = this.props;
    const refs = !calledByAutoSizer ? _refs : this.props.refs;
    const rowHeight = (this.firstRowRef && this.firstRowRef.offsetHeight) || defaultRowHeight;
    let listHeight = ((refs && refs.length) || 0) * rowHeight;

    if (loadMore) {
      listHeight = (totalElements || 0) * rowHeight;
    }

    if (listHeight !== this.state.listHeight || calledByAutoSizer) {
      this.cache.clearAll();
      this.setState({ listHeight: listHeight || emptyListHeight }, () => {
        if (this.list && !isEmpty(refs)) {
          this.list.recomputeRowHeights();
          this.list.measureAllRows();
        }
      });
    }
  };

  rowRenderer = ({ style, index, key, parent }) => {
    const { refs, entities, propsMapper, children: Child, defaultEntity, loadMore } = this.props;
    const { loadedRowsMap } = this.state;
    if (isEmpty(refs)) return null;
    const refName = refs[index];
    const isLoading = loadMore && (loadedRowsMap[index] !== STATUS_LOADED || !entities[refName]);
    const entity = isLoading ? defaultEntity : entities[refName];
    const pickedProps = propsMapper ? propsMapper(entity) : entity;

    return (
      <CellMeasurer cache={this.cache} columnIndex={0} key={key} parent={parent} rowIndex={index}>
        <Child
          key={key}
          refName={refName}
          style={style}
          containerRef={index === 0 ? this.setTransactionRef : undefined}
          showPlaceholder={isLoading}
          {...pickedProps}
        />
      </CellMeasurer>
    );
  };

  noRowsRenderer = () => this.props.placeholder;

  isRowLoaded = ({ index }) => !!this.props.refs[index];

  loadMore = async ({ startIndex, stopIndex }) => {
    const { loadMore } = this.props;
    const map = {};
    for (let i = startIndex; i <= stopIndex; i++) {
      map[i] = { $set: STATUS_LOADING };
    }
    if (this.mounted) {
      this.setState((prevState) =>
        update(prevState, {
          loadedRowsMap: map
        })
      );
    }

    await loadMore({ startIndex, stopIndex });
    for (let i = startIndex; i <= stopIndex; i++) {
      map[i] = { $set: STATUS_LOADED };
    }
    if (this.mounted) {
      this.setState((prevState) =>
        update(prevState, {
          loadedRowsMap: map
        })
      );
    }
  };

  clearData = () => {
    this.setState(
      {
        loadedRowsMap: new Array(this.props.increment).fill(STATUS_LOADED)
      },
      this.infiniteLoader && this.infiniteLoader.resetLoadMoreRowsCache
    );
  };

  render() {
    const {
      refs,
      maxListHeight,
      defaultRowHeight,
      className,
      totalElements,
      loadMoreThreshold,
      minimumBatchSize,
      loadMore
    } = this.props;
    const { listHeight } = this.state;
    // Not really needed since rendering is split by loadMore below
    const elementCount = loadMore ? totalElements : refs && refs.length;

    return (
      <div className={cc(['virtual-list', className])}>
        {loadMore ? (
          <InfiniteLoader
            isRowLoaded={this.isRowLoaded}
            loadMoreRows={this.loadMore}
            rowCount={totalElements}
            threshold={loadMoreThreshold}
            ref={this.setInfiniteLoaderRef}
            minimumBatchSize={minimumBatchSize}
          >
            {({ onRowsRendered, registerChild }) => (
              <AutoSizer disableHeight onResize={this.handleResize}>
                {({ width }) => (
                  <List
                    tabIndex={null}
                    height={Math.min(listHeight, maxListHeight)}
                    rowHeight={this.cache.rowHeight}
                    estimatedRowSize={!isEmpty(refs) ? listHeight / elementCount : defaultRowHeight}
                    rowRenderer={this.rowRenderer}
                    rowCount={elementCount || 0}
                    onRowsRendered={onRowsRendered}
                    noRowsRenderer={this.noRowsRenderer}
                    width={width}
                    ref={this.setListRef(registerChild)}
                  />
                )}
              </AutoSizer>
            )}
          </InfiniteLoader>
        ) : (
          <AutoSizer disableHeight onResize={this.handleResize}>
            {({ width }) => (
              <List
                tabIndex={null}
                height={Math.min(listHeight, maxListHeight)}
                rowHeight={this.cache.rowHeight}
                estimatedRowSize={!isEmpty(refs) ? listHeight / refs.length : defaultRowHeight}
                rowRenderer={this.rowRenderer}
                rowCount={(refs && refs.length) || 0}
                noRowsRenderer={this.noRowsRenderer}
                width={width}
                ref={this.setListRef()}
              />
            )}
          </AutoSizer>
        )}
      </div>
    );
  }
}

VirtualList.propTypes = {
  defaultRowHeight: PropTypes.number.isRequired,
  emptyListHeight: PropTypes.number,
  className: PropTypes.string,
  loadMore: PropTypes.func,
  increment: PropTypes.number,
  maxListHeight: PropTypes.number,
  totalElements: PropTypes.number,
  loadMoreThreshold: PropTypes.number,
  minimumBatchSize: PropTypes.number,
  placeholder: PropTypes.element,
  /** The list item. Must pass back its container React ref using the containerRef prop */
  children: PropTypes.func.isRequired,
  /** Transform props passed to the list child, picked from its entity. Passes all by default
   * propsMapper: (ownerProps: Object) => Object,
   * */
  propsMapper: PropTypes.func,
  refs: PropTypes.arrayOf(PropTypes.string),
  defaultEntity: PropTypes.object,
  entities: PropTypes.object
};

VirtualList.defaultProps = {
  emptyListHeight: 0,
  maxListHeight: 0,
  totalElements: 0,
  increment: 20,
  minimumBatchSize: 20,
  loadMoreThreshold: 5,
  defaultEntity: {},
  className: null,
  loadMore: null,
  propsMapper: null,
  placeholder: null,
  refs: null,
  entities: null
};

export default VirtualList;
