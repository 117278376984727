import { useCallback } from 'react';
import { useAppDispatch, useAppSelector } from 'hooks/redux';
import Api from 'services/Api';
import type { DepositTransferableProps } from 'components/Deposit/types';
import Modals from 'modules/Modals';
import type { types as WalletTypes } from 'modules/Wallet';
import Wallet from 'modules/Wallet';
import type { DepositLimitIntervalsInURL } from './types';

export type SubmitDepositLimitSolicitations = (args: {
  interval: DepositLimitIntervalsInURL;
}) => Promise<any>;

export const useStateAndDispatch = () => {
  const dispatch = useAppDispatch();

  /* eslint-disable @typescript-eslint/no-unsafe-return -- because Api is not TS aware */
  const getDepositLimitSolicitations = useCallback<() => Promise<any>>(
    () =>
      // @ts-ignore
      Api.actions.wallet.getDepositLimitSolicitations(undefined, undefined, {
        skipCache: true
      })(dispatch),
    [dispatch]
  );

  const getDepositLimit = useCallback<() => Promise<any>>(
    () =>
      // @ts-ignore
      Api.actions.wallet.getDepositLimit(undefined, undefined, {
        skipCache: true
      })(dispatch),
    [dispatch]
  );

  const acceptDepositLimitSolicitations = useCallback<SubmitDepositLimitSolicitations>(
    ({ interval }) =>
      // @ts-ignore
      Api.actions.wallet.acceptDepositLimitSolicitations({ interval }, undefined, {
        skipCache: true
      })(dispatch),
    [dispatch]
  );

  const cancelDepositLimitSolicitations = useCallback<SubmitDepositLimitSolicitations>(
    ({ interval }) =>
      // @ts-ignore
      Api.actions.wallet.cancelDepositLimitSolicitations({ interval }, undefined, {
        skipCache: true
      })(dispatch),
    [dispatch]
  );
  /* eslint-enable @typescript-eslint/no-unsafe-return -- see description before */

  const openDeposit = useCallback(
    (depositProps: DepositTransferableProps) =>
      dispatch(Modals.actions.open('deposit', depositProps)),
    [dispatch]
  );

  const depositLimitSolicitations: WalletTypes.DepositLimitSolicitationsSelectorReturn =
    useAppSelector(Wallet.selectors.getDepositLimitSolicitations);

  return {
    getDepositLimitSolicitations,
    getDepositLimit,
    acceptDepositLimitSolicitations,
    cancelDepositLimitSolicitations,
    openDeposit,
    depositLimitSolicitations
  };
};
