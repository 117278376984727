import { merge, isNil, omit } from 'lodash';
import update from 'update-immutable';
import Bourne from '@hapi/bourne';
import { noPrefix } from 'lib/redux-utils';
import getType from 'lib/getType';
import mrqSockets from './src/mrqSockets';
import BALLY_WULFF from './src/bally_wulff';
import BLUEPRINT from './src/blueprint';
import EYECON from './src/eyecon';
import FOR_THE_PLAYER from './src/for_the_player';
import HACKSAW from './src/hacksaw';
import INSPIRED from './src/inspired';
import GREEN_JADE_GAMES from './src/green_jade_games';
import NET_ENT from './src/net_ent';
import NO_LIMIT_CITY_GAMES from './src/no_limit_city_games';
import QUICKFIRE from './src/quickfire';
import PARIPLAY from './src/pariplay';
import PRAGMATIC from './src/pragmatic_play';
import RELAX_GAMING from './src/relax';
import RUBY_PLAY from './src/ruby_play';
import SKYWIND from './src/skywind';
import THUNDERKICK from './src/thunderkick';
import WAZDAN from './src/wazdan';
import GOOSICORN from './src/goosicorn';
import PUSH_GAMING from './src/push_gaming';
import EVOLUTION from './src/evolution';
import MICROGAMING from './src/microgaming';

export const defaultMappings = {
  mrqSockets,
  BALLY_WULFF,
  BLUEPRINT,
  EYECON,
  FOR_THE_PLAYER,
  HACKSAW,
  INSPIRED,
  GREEN_JADE_GAMES,
  NET_ENT,
  NO_LIMIT_CITY_GAMES,
  QUICKFIRE,
  PARIPLAY,
  PRAGMATIC,
  RUBY_PLAY,
  SKYWIND,
  THUNDERKICK,
  RELAX_GAMING,
  GOOSICORN,
  PUSH_GAMING,
  WAZDAN,
  GAMES_GLOBAL: MICROGAMING,
  EVOLUTION, // for live games
  RED_TIGER: EVOLUTION,
  NETENT: EVOLUTION,
  EVO_NO_LIMIT_CITY_GAMES: EVOLUTION
};

function translateIncoming(mapping, action, _, initialAction) {
  if (mapping) {
    const event =
      getType(action.payload) !== 'Object'
        ? Bourne.safeParse(action.payload) || action.payload
        : action.payload;
    if (getType(event) === 'Array') {
      const initial = initialAction || action;
      for (const e of event) {
        // TODO: Replace with loop
        // TODO: Maybe this is confusing, I could instead dispatch multiple if array in onMessage (SocketMiddleware)
        return translateIncoming(
          mapping,
          {
            ...initial,
            payload: e,
            meta: {
              ...e.meta,
              ...initial.meta
            }
          },
          false,
          initial
        );
      }
    }
    if (event) {
      const typeKey = mapping.keyMapping.type;
      const eventType = event[typeKey] || event;
      const payloadKey = mapping.keyMapping.payload;
      let eventPayload;
      if (payloadKey) {
        eventPayload = event[payloadKey];
      } else {
        eventPayload = omit(event, [typeKey]);
      }
      const metaKey = mapping.keyMapping.meta;
      const eventMeta = event[metaKey];
      const errorKey = mapping.keyMapping.error;
      const eventError = event[errorKey];

      const translation = mapping.entities[eventType]; // eg: entities.makedeposit

      if (translation && getType(translation) === 'Object') {
        return merge(
          {},
          translation,
          { payload: eventPayload },
          { meta: { ...action.meta, ...eventMeta, translated: true, globally: true } },
          { error: eventError }
        );
      } else if (translation && getType(translation) === 'Function') {
        const updater = {
          payload: { $set: eventPayload },
          meta: { $merge: eventMeta },
          error: { $set: eventError }
        };

        if (isNil(eventMeta)) delete updater.meta;
        if (isNil(eventError)) delete updater.error;

        return {
          __withFunc: true,
          defaultTranslation:
            mapping.whitelist === false &&
            merge(
              {},
              { type: `Notification/${eventType}` },
              { payload: eventPayload },
              { meta: { ...action.meta, ...eventMeta, translated: true, globally: true } },
              { error: eventError }
            ),
          func: (...args) => translation(...args, update(action, updater))
        };
      }
    }
  }
  return false;
}

function translateOutgoing(mapping, action, provider) {
  if (mapping) {
    const payloadKey = mapping.keyMapping.payload;
    const flattenPayload = mapping.keyMapping.flattenPayload;
    const translation = mapping.entities[action.type]; // eg: entities.makedeposit

    if (translation) {
      if (payloadKey) {
        return merge({}, translation, {
          [payloadKey]: action.payload,
          meta: { provider }
        });
      } else if (flattenPayload) {
        return merge({}, translation, action.payload, {
          meta: { provider }
        });
      } else {
        return merge({}, translation, {
          meta: { provider }
        });
      }
    }
  }
  return false;
}

export function translatorMiddleware(mappings = defaultMappings) {
  return (store) => (next) => (action) => {
    if (action && action.type) {
      const provider = action.meta && action.meta.provider && action.meta.provider;
      const alreadyTranslated = action.meta && action.meta.translated;
      if (provider && !alreadyTranslated) {
        // An action that needs translation
        const type = noPrefix(action.type);
        const isIncoming = type === 'INCOMING';
        const mapping = mappings[provider];
        const translation = isIncoming
          ? translateIncoming(mapping, action)
          : translateOutgoing(mapping, action, provider);
        if (mapping && translation) {
          // Is translateable
          if (isIncoming) {
            if (translation.__withFunc) {
              const result = translation.func((_action) => {
                if (getType(_action) === 'Object') {
                  store.dispatch(
                    update(_action, {
                      meta: {
                        translated: { $set: true }
                      }
                    })
                  );
                } else {
                  store.dispatch(_action);
                }
              });
              if (getType(result) === 'Object') {
                const syntheticMapping = update(mapping, {
                  entities: {
                    [Object.keys(result)[0]]: { $set: Object.values(result)[0] }
                  }
                });
                return next(translateIncoming(syntheticMapping, action));
              } else {
                const defaultTranslation = translation.defaultTranslation;
                // Return default
                if (
                  defaultTranslation &&
                  !(defaultTranslation.meta && defaultTranslation.meta.visible === false)
                ) {
                  return next(defaultTranslation);
                } else {
                  // eslint-disable-next-line consistent-return
                  return;
                }
              }
            }
            // Translate action and forward it
            return next(translation);
          } else {
            // Translate and send window event, then forward the action
            window.dispatchEvent(new CustomEvent('outgoingFrameMessage', { detail: translation }));
            return next(action);
          }
        } else {
          // Not translateable, discard the action
          // eslint-disable-next-line consistent-return
          return;
        }
      }
    }
    return next(action);
  };
}
