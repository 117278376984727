import { actionTypeCreator, SYNC, ASYNC } from 'lib/redux-utils';
import { NAME } from './constants';

const namespace = actionTypeCreator(NAME);

export const T = namespace({
  OPEN: SYNC,
  UPDATE: SYNC,
  PAUSE: SYNC,
  CLOSE: SYNC,
  CREATE: SYNC
});

export const AT = namespace({
  MAINTENANCE: ASYNC
});
