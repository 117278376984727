import React from 'react';
import RowListSkeleton from './src/RowListSkeleton';
import TileSkeleton from './src/TileSkeleton';
import './skeleton.css';

interface SkeletonProps {
  count?: number;
}
const Skeleton = ({ count = 5 }: SkeletonProps) => (
  <div className="dynamicLobby__skeleton">
    <RowListSkeleton count={1}>
      <TileSkeleton className="dynamicLobby__skeleton-offerTile" />
    </RowListSkeleton>
    {Array.from({ length: count }).map((_, i: number) => (
      // eslint-disable-next-line react/no-array-index-key -- list is static
      <RowListSkeleton key={`list_${i}`}>
        <TileSkeleton className="dynamicLobby__skeleton-slotTile" />
      </RowListSkeleton>
    ))}
  </div>
);

export default Skeleton;
