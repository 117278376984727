import React, { memo } from 'react';
import { isValid } from 'date-fns';
import parseUTC from 'lib/parseUTC';
import { TIME, formatInterval } from 'lib/formatters';

interface TimeDisplayProps {
  timeLeft: number;
  className?: string;
}

const TimeDisplay = ({ timeLeft, className }: TimeDisplayProps) => {
  const utcTimeLeft = parseUTC(new Date(timeLeft));

  return isValid(utcTimeLeft) && timeLeft >= TIME.SECOND ? (
    <span className={className}>{formatInterval(timeLeft)}</span>
  ) : (
    <span className="loader" />
  );
};

TimeDisplay.displayName = 'BingoCard.TimeDisplay';

export default memo(TimeDisplay);
