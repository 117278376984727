import React, { memo } from 'react';
import cc from 'classcat';
import PropTypes from 'prop-types';
import { NUMBER_LIST_TYPE, ROULETTE_NUMBERS } from '../constants';

const rouletteClasses = (n, options = {}) =>
  cc([
    'number',
    ROULETTE_NUMBERS.RED.includes(n) && 'number--red',
    ROULETTE_NUMBERS.BLACK.includes(n) && 'number--black',
    n === 0 && 'number--green',
    options.border && 'number--whiteOutline',
    options.bonus && 'number--whiteOutline number--bonus'
  ]);

const blackjackClasses = (options = {}) =>
  cc([
    'number',
    'number--blackjack',
    options.opaque && 'number--opaque',
    options.border && 'number--whiteOutline'
  ]);
const uniformSquareClasses = (options = {}) =>
  cc([
    'number',
    'number--uniformSquare',
    options.border && 'number--whiteOutline',
    options.opaque && 'number--opaque'
  ]);
const NumberItem = ({ number, type, border, opaque, bonus }) => {
  if (!type) return null;
  const classes = cc([
    type === NUMBER_LIST_TYPE.ROULETTE && rouletteClasses(number, { border, bonus }),
    type === NUMBER_LIST_TYPE.BLACKJACK && blackjackClasses({ opaque, border }),
    type === NUMBER_LIST_TYPE.UNIFORM_SQUARES && uniformSquareClasses({ opaque, border })
  ]);

  return (
    <div>
      <span className={classes}>{number}</span>
      {bonus && type === NUMBER_LIST_TYPE.ROULETTE && (
        <span className="number__bonusBadge">x{bonus}</span>
      )}
    </div>
  );
};

NumberItem.propTypes = {
  type: PropTypes.oneOf(Array.from(Object.values(NUMBER_LIST_TYPE))),
  number: PropTypes.number,
  bonus: PropTypes.string,
  border: PropTypes.bool,
  opaque: PropTypes.bool
};

NumberItem.defaultProps = {
  number: null,
  type: null,
  bonus: '',
  border: false,
  opaque: false
};

export default memo(NumberItem);
