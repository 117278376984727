import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { isNil } from 'lodash';
import User from 'modules/User';
import Api from 'services/Api';
import UserBox from 'components/UserBox';
import Button from 'components/Button';
import makeModal from 'components/Modals';
import LoadingElement from 'components/Loading/LoadingElement';
import FormError from 'components/FormError';
import Markdown, { defaultRenderers } from 'components/Markdown';
import './termsConditions.css';

let ul;
const ulRef = (_) => {
  ul = _;
};

const ERROR_MESSAGE = `Oops, computers failed us. Please check <a href="/terms-conditions">here for our latest General Terms & Conditions</a> or <a onClick="location.reload(true);">refresh</a> the page to try again`;
const handleDownload = () => {
  const element = document.createElement('a');
  const file = new Blob([ul.outerHTML], { type: 'text/html' });
  element.href = URL.createObjectURL(file);
  element.download = 'MrQ Terms & Conditions.html';
  element.click();
};

const publicRenderers = {
  ...defaultRenderers,
  link: ({ children }) => children
};

const TermsConditions = ({ close, agreeButton, handleAgreeToLatestTerms, isPublic }) => {
  const [isError, setIsError] = useState(false);
  const [content, setContent] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const result = await Api.request.user.termsAndConditionsContent();
        setContent(result.data);
      } catch (error) {
        setIsError(true);
      }
    };
    fetchData();
  }, []);

  return (
    <UserBox
      id="termsConditions"
      className="termsConditions modal terms"
      title="General Terms and Conditions"
      closeCallback={close}
    >
      <>
        <article ref={ulRef}>
          <Markdown content={content} renderers={isPublic ? publicRenderers : defaultRenderers} />
        </article>
        {!isNil(content) ? (
          <div className="fieldset fieldset--centerflex termsConditions__buttons">
            {agreeButton ? (
              <Button
                onClick={handleAgreeToLatestTerms}
                buttonText="Agree"
                name="agreeLatestTerms"
                id="termsConditions-agreeLatestTerms"
                title="Agree MrQ Terms & Conditions"
                className="submit"
              />
            ) : (
              <Button
                onClick={close}
                buttonText="Close"
                name="close"
                id="termsConditions-close"
                title="Close"
                className="submit"
              />
            )}
            <Button
              onClick={handleDownload}
              name="download"
              id="termsConditions-download"
              buttonText="Download&nbsp;&nbsp;&nbsp;⇩"
              title="Download the entire document"
              className="submit"
            />
          </div>
        ) : (
          <>
            {isError ? (
              <FormError errorMessage={ERROR_MESSAGE} />
            ) : (
              <LoadingElement key="LoadingElement" />
            )}
          </>
        )}
      </>
    </UserBox>
  );
};
const mapStateToProps = (state) => ({
  agreeButton: !User.selectors.model(state).agreedToLatestTerms
});

const mapDispatchToProps = (dispatch, { close }) => ({
  handleAgreeToLatestTerms: () => {
    close();
    Api.actions.user.agreeLatestTerms()(dispatch);
  }
});

TermsConditions.propTypes = {
  isPublic: PropTypes.bool,
  agreeButton: PropTypes.bool.isRequired,
  close: PropTypes.func.isRequired,
  handleAgreeToLatestTerms: PropTypes.func.isRequired
};

TermsConditions.defaultProps = {
  isPublic: false
};

export default compose(
  makeModal(
    'termsConditions',
    { name: 'modal-fade', timeout: 200 },
    { className: 'termsConditions modal' }
  ),
  connect(mapStateToProps, mapDispatchToProps)
)(TermsConditions);
