import { handleActions } from 'redux-actions';
import update from 'update-immutable';
import { isNil } from 'lodash';
import module from 'lib/module';
import { NAME } from './constants';
import { AT } from './actionTypes';
import { initialState } from './model';

const getRegistrationBanner = (state, { payload }) =>
  update(state, {
    registration: { $set: payload }
  });

const moduleReducer = module(
  handleActions(
    {
      [AT.REGISTRATION.FULFILLED]: getRegistrationBanner
    },
    initialState
  ),
  NAME
);

const reducer = (state = initialState, action) =>
  isNil(action.type) || !action.type.includes(NAME) ? state : moduleReducer(state, action);

export default reducer;
