import React, { memo, useCallback, useEffect } from 'react';
import { compose } from 'redux';
import loadable from '@loadable/component';
import UserBox from 'components/UserBox';
import makeModal from 'components/Modals';
import usePublicCmsData from 'hooks/usePublicCmsData';
import { useAppDispatch, useAppSelector } from 'hooks/redux';
import Bingo from 'modules/Bingo';
import Modals from 'modules/Modals';
import CmsContent from 'modules/CmsContent';
import type { ModalData } from './src/types';
import './howToPlay.css';

interface HowToPlayProps {
  close: () => void;
}

const HowToPlayContent = loadable(
  () => import(/* webpackChunkName: "HowToPlayContent" */ './src/HowToPlayContent')
);

export const HowToPlay = ({ close }: HowToPlayProps) => {
  const dispatch = useAppDispatch();
  const activeRoomType = useAppSelector(Bingo.selectors.getGameType);
  const modal = useAppSelector(
    (state) => Modals.selectors.getModal(state, 'howToPlay') as ModalData
  );
  const updateContent = useCallback(
    (cmsResponse) => {
      if (cmsResponse.data && cmsResponse.data[0]) {
        dispatch(CmsContent.actions.cacheContent('bingoRules', cmsResponse.data[0]));
      }
    },
    [dispatch]
  );
  const content = useAppSelector(CmsContent.selectors.getBingoRules);
  const { isLoading } = usePublicCmsData({
    contentPath: '/api/modals/slug/bingo-rules',
    hasContent: Boolean(content),
    updateContent
  });

  useEffect(() => {
    if (!modal.bingoType && !modal.initialised && activeRoomType) {
      dispatch(
        Modals.actions.update('howToPlay', { bingoType: activeRoomType, initialised: true })
      );
    }
  }, [modal.bingoType, modal.initialised, activeRoomType, dispatch]);

  const handleClickBack = useCallback(() => {
    dispatch(Modals.actions.update('howToPlay', { title: null, bingoType: null }));
  }, [dispatch]);

  const handleClose = useCallback(() => {
    dispatch(Modals.actions.update('howToPlay', { title: null, bingoType: null }));
    close();
  }, [close, dispatch]);

  return (
    <UserBox
      id="howToPlay"
      title={modal.title || content?.title}
      close={handleClose}
      onBack={modal.bingoType ? handleClickBack : null}
    >
      <HowToPlayContent isLoading={isLoading} content={content} />
    </UserBox>
  );
};

export default compose(
  makeModal('howToPlay', { name: 'modal-fade', timeout: 200 }, { className: 'howToPlay modal' }),
  memo
)(HowToPlay);
