import { createSelector } from 'reselect';
import { flowRight as compose } from 'lodash';
import { NAME } from './constants';
import * as m from './model';

export const model = (state) => state[NAME] || m.initialState;
export const getChannels = compose([m.channels, model]);
export const getChannelRefs = compose([m.channelRefs, model]);
export const getChannelMessageRefs = (state, channel) =>
  getChannels(state)[channel] && getChannels(state)[channel].messages;
export const getChannelUserRefs = (state, channel) =>
  getChannels(state)[channel] && getChannels(state)[channel].users;
export const getChannelLeftAt = (state, channel) =>
  getChannels(state)[channel] && getChannels(state)[channel].leftAt;
export const getMessages = compose([m.messages, model]);
export const getMessageRefs = compose([m.messageRefs, model]);
export const getUsers = compose([m.users, model]);
export const getUserRefs = compose([m.userRefs, model]);

export const getDefaultChannelRef = createSelector(
  [getChannels, getChannelRefs],
  (_channels, _refs) => {
    if (!_refs) return;
    let result;
    let i = 0;
    while (i < _refs.length && !result) {
      if (_channels[_refs[i]].default) {
        result = _refs[i];
      }
      i++;
    }
    return result;
  }
);
