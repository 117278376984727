import React from 'react';
import cc from 'classcat';
import type { DfgDayStatus, Day } from '../types';
import { DayStatus } from '../types';
import { DaysOrder } from '../constants';

export interface DfgDaysProps {
  dayStatusMap: DfgDayStatus;
  currentDay?: Day;
}

const DfgDays = ({ dayStatusMap, currentDay }: DfgDaysProps) => (
  <div className="rewardCard__dfgDayList">
    {DaysOrder.map((day) => (
      <span
        key={day}
        className={cc([
          'rewardCard__dfgDay',
          dayStatusMap[day] === DayStatus.NOT_PLAYED && 'rewardCard__dfgDay--lineThrough',
          dayStatusMap[day] === DayStatus.PLAYED && 'rewardCard__dfgDay--gray',
          currentDay === day && 'rewardCard__dfgDayMarker',
          currentDay === day &&
            dayStatusMap[day] === DayStatus.PLAYED &&
            'rewardCard__dfgDayMarker--played'
        ])}
      >
        {day[0]}
      </span>
    ))}
  </div>
);

export default DfgDays;
