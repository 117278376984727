import React, { memo } from 'react';
import LiveGame from './components/LiveGame';

interface LiveGamesListProps {
  refs: string[];
  category: string;
}

const LiveGamesList = ({ refs, category }: LiveGamesListProps) => (
  <>
    {refs.map((ref) => (
      <LiveGame key={`live-${ref}`} refName={ref} category={category} />
    ))}
  </>
);

export default memo(LiveGamesList);
