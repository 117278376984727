import React from 'react';
import * as Animation from 'components/Animation';
import './tileSkeleton.css';

interface SlotTileSkeletonProps {
  className: string;
}

const TileSkeleton = ({ className }: SlotTileSkeletonProps) => (
  <Animation.Root className={className}>
    <Animation.ShimmerInputPrimary />
  </Animation.Root>
);

export default TileSkeleton;
