import React from 'react';
import loadable from '@loadable/component';
import { compose } from 'redux';
import { connect } from 'react-redux';
import cc from 'classcat';
import UserBox from 'components/UserBox';
import makeModal from 'components/Modals';
import LoadingElement from 'components/Loading/LoadingElement';
import Wallet from 'modules/Wallet';

const Braintree = loadable(() => import('components/Braintree'), {
  fallback: <LoadingElement />
});

const PciProxyForm = loadable(() => import('components/PciProxyForm'), {
  fallback: <LoadingElement />
});

export const _ChangeCard = ({ close, canAddBraintree, canAddPciProxy }) => (
  <UserBox
    id="changeCardBox"
    className={cc(['changeCard', 'modal', canAddBraintree && 'braintree'])}
    title="Payment card"
    closeCallback={close}
  >
    {canAddPciProxy ? (
      <PciProxyForm onDone={close} />
    ) : canAddBraintree ? (
      <Braintree show ownSubmit onDone={close} />
    ) : null}
  </UserBox>
);

const mapStateToProps = (state) => ({
  canAddBraintree: Wallet.selectors.canAddBraintree(state),
  canAddPciProxy: Wallet.selectors.canAddPciProxy(state)
});

export default compose(
  makeModal('changeCard', { name: 'modal-fade', timeout: 200 }, { className: 'changeCard modal' }),
  connect(mapStateToProps)
)(_ChangeCard);
