export const initialState = {
  totalElements: {},
  categoryRefs: {},
  categoryOffset: {},
  categoryLoading: {},
  playerSessionToken: '',
  entities: {},
  notFound: false,
  notFoundMsg: '',
  result: null
};

export const rooms = (model) => model && model.entities;
export const refs = (model) => model && model.result;
export const notFound = (room) => (room && room.notFound) || false;
export const notFoundMsg = (room) => (room && room.notFoundMsg) || '';
export const metadata = (room) => room && room.metadata;
export const gameReady = (room) => (room && room.gameReady) || false;
