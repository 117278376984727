export const NAME = 'Carousel';

export enum TAB {
  SLOTS = 'casino',
  LIVE = 'live',
  BINGO = 'bingo',
  REWARDS = 'rewards',
  ACCOUNT = 'account'
}

export enum ACCOUNT_TAB {
  WALLET = 'wallet',
  PROFILE = 'profile',
  SAFER_GAMBLING = 'limits',
  HELP = 'help',
  VERIFICATION = 'verification'
}
