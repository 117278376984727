import type { LobbyState } from 'modules/Lobby/src/types';
import { constants as CarouselConstants } from 'modules/Carousel';

export const initialState: LobbyState = {
  query: '',
  searchResult: null,
  searchPopularResult: {
    [CarouselConstants.TAB.SLOTS]: null,
    [CarouselConstants.TAB.LIVE]: null
  },
  entities: null
};

export const searchResult = (model: LobbyState) => model.searchResult;
export const searchPopularResult = (model: LobbyState) => model.searchPopularResult;
export const query = (model: LobbyState) => model.query;
export const entities = (model: LobbyState) => model.entities;
