import { compose, assocPath, drop, split, map, keys, equals, cond, T, concat, __ } from 'lodash/fp';
import { merge } from 'lodash';
import { SYNC, ASYNC, ALL_IN_ASYNC, ASYNC_TYPES_DEFAULT_SUFFIX } from './constants';

const destructPath = compose([drop(1), split('/')]);
const mergeObjects = (objects) => merge({}, ...objects);

export { SYNC, ASYNC };

export default (namespace = '', options = {}) => (definition, syncType) => {
  let _definition = definition;
  if (syncType) {
    if (!Array.isArray(_definition)) {
      throw new TypeError('definition must be Array to define syncType');
    }
    _definition = _definition.reduce((obj, type) => {
      // eslint-disable-next-line no-param-reassign
      obj[type] = syncType;
      return obj;
    }, {});
  }
  const APP_NAMESPACE = `${namespace}`;
  const ASYNC_TYPES_SUFFIX = options.asyncSuffix || ASYNC_TYPES_DEFAULT_SUFFIX;

  const createActionType = (typeDefine, path = '') => {
    const constructSyncType = assocPath(__, `${APP_NAMESPACE}${path}`, {});

    const constructAsyncType = (objectPath) =>
      compose([
        concat(
          __,
          assocPath(
            [...objectPath, ALL_IN_ASYNC],
            map((suffix) => `${APP_NAMESPACE}${path}_${suffix}`)(ASYNC_TYPES_SUFFIX.slice(0, 3)),
            {}
          )
        ),
        map((action) =>
          assocPath(
            [...objectPath, action],
            action !== '_' ? `${APP_NAMESPACE}${path}_${action}` : `${APP_NAMESPACE}${path}`,
            {}
          )
        )
      ])(ASYNC_TYPES_SUFFIX);

    const generateTypeForKeys = map((key) => createActionType(typeDefine[key], `${path}/${key}`));

    const generateSyncType = compose([constructSyncType, destructPath]);

    const generateAsyncType = compose([mergeObjects, constructAsyncType, destructPath]);

    const generateTypeForTypeDefine = compose([mergeObjects, generateTypeForKeys, keys]);

    return cond([
      [equals(SYNC), () => generateSyncType(path)],
      [equals(ASYNC), () => generateAsyncType(path)],
      [T, generateTypeForTypeDefine]
    ])(typeDefine);
  };

  return createActionType(_definition);
};
