import { useCallback, useEffect, useMemo, useState } from 'react';
import SecureFieldsClientApi from '../api';
import type { SecureFieldsConfig, SecureFieldOptions } from '../types';

const useSecureFields = (fields: SecureFieldsConfig) => {
  const [secureFields, setSecureFields] = useState<SecureFieldOptions | null>();
  const [isScriptAvailable, setIsScriptAvailable] = useState(true);

  useEffect(() => {
    if (!secureFields) {
      if (!SecureFieldsClientApi.isScriptLoaded()) {
        void SecureFieldsClientApi.load()
          .then(() => {
            setSecureFields(SecureFieldsClientApi.initSecureFields(fields));
          })
          .catch(() => setIsScriptAvailable(false));
      } else {
        setSecureFields(SecureFieldsClientApi.initSecureFields(fields));
      }
    }

    return () => {
      if (secureFields) {
        secureFields.destroy();
        setSecureFields(null);
      }
    };
  }, [secureFields, fields]);

  const onResetSecureFields = useCallback(() => {
    // this will trigger reinitialization
    setSecureFields(null);
  }, []);

  return useMemo(
    () => ({
      secureFields,
      isScriptAvailable,
      onResetSecureFields
    }),
    [isScriptAvailable, secureFields, onResetSecureFields]
  );
};

export default useSecureFields;
