const bracketGroup = /{{([^}]*)}}/;

export function parse(err) {
  const message = err && err.headers && err.headers.message;
  if (!message) {
    return {};
  }

  const matches = message.match(bracketGroup);
  if (!matches) {
    return {
      reason: message
    };
  }

  let reason = matches[1];
  reason = reason && reason.trim();
  const rawErr = message.replace(matches[0], '');
  let command;
  let subscription = '';

  if (rawErr.includes('KICK')) {
    command = 'KICK';
    subscription = rawErr
      .split('KICK')[1]
      .replace('KICK', '')
      .replace(':', '')
      .replace('\\c', '')
      .trim();
  } else if (rawErr.includes('BAN')) {
    command = 'BAN';
  } else {
    return {};
  }

  return { command, subscription, reason };
}
