import { useCallback, useContext } from 'react';
import { useAppDispatch } from 'hooks/redux';
import Dialog from 'modules/Dialog';
import NameContext from './NameContext';

const useDynamicAction = (refName?: string) => {
  const dispatch = useAppDispatch();
  const dialogName = useContext(NameContext);
  return useCallback(
    (actionRef?: string) => {
      dispatch(Dialog.actions.dynamicAction(actionRef, dialogName || refName));
    },
    [dialogName, dispatch, refName]
  );
};

export default useDynamicAction;
