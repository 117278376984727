import { flowRight, difference, pick, sortBy, isEmpty, isNil, partialRight } from 'lodash';
import createCachedSelector, { LruObjectCache } from 're-reselect';
import getType from 'lib/getType';
import cfImage from 'lib/cfImage';
import { formatCurrency } from 'lib/formatters';
import { NAME, STAGE, STATUS } from './constants';
import * as m from './model';

const _ = partialRight.placeholder;

export const model = (state) => state[NAME] || m.initialState;
export const getRooms = flowRight([m.rooms, model]);
export const getRoomRefs = flowRight([m.refs, model]);
export const getActiveRef = flowRight([m.active, model]);
export const getRoom = (state, refName) => {
  const safeRefName = refName || getActiveRef(state);
  const rooms = getRooms(state);
  return rooms && rooms[safeRefName];
};
export const getActiveRoom = (state) =>
  getActiveRef(state) && getRoom(state, [getActiveRef(state)]);
export const getInstance = (state, refName, instance = 'next') =>
  getRoom(state, refName) && getRoom(state, refName)[instance];
// The following selectors use (state, instance) => {}
// TODO: https://mrq.atlassian.net/browse/MRQ-1077
export const _getCurrentInstance = partialRight(getInstance, _, _, 'current');
export const _getNextInstance = partialRight(getInstance, _, _, 'next');
export const getInPlay = flowRight([m.inPlay, _getCurrentInstance]);
export const getLocked = flowRight([m.locked, getInstance]);
export const getLockedMessage = flowRight([m.lockedMsg, getInstance]);
export const getPromptDeposit = flowRight([m.promptDeposit, getInstance]);
export const getInProgress = flowRight([m.inProgress, _getCurrentInstance]);
export const getInProgressStatus = flowRight([m.inProgressStatus, _getCurrentInstance]);
export const getNotFound = flowRight([m.notFound, getRoom]);
export const getGameType = flowRight([m.gameType, getInstance]);
export const getNextStartDate = flowRight([m.nextStartDate, getInstance]);
export const getDropped = flowRight([m.dropped, _getCurrentInstance]); // TODO: Use regular getInstance in case we want the order of dropped balls of past instances
export const getRolloverEnd = flowRight([m.rolloverEnd, getInstance]);
export const getOneLineWinners = flowRight([m.oneLineWinners, getInstance]);
export const getTwoLinesWinners = flowRight([m.twoLinesWinners, getInstance]);
export const getGameWinners = flowRight([m.gameWinners, getInstance]);
export const getProgressiveGameWinners = flowRight([m.progressiveGameWinners, getInstance]);
export const getPattern = flowRight([m.pattern, getInstance]);
export const getProgressiveJackpotAmount = flowRight([m.progressiveJackpotAmount, getInstance]);
export const getProgressiveJackpotMaxCalls = flowRight([m.progressiveJackpotMaxCalls, getInstance]);
export const getOneLineJackpotAmount = flowRight([m.oneLineJackpotAmount, getInstance]);
export const getTwoLinesJackpotAmount = flowRight([m.twoLinesJackpotAmount, getInstance]);
export const getGameJackpotAmount = flowRight([m.gameJackpotAmount, getInstance]);
export const getStartDate = flowRight([m.startDate, getInstance]);
export const getAssignedTickets = flowRight([m.assignedTickets, getInstance]);
export const getAssignedTicketRefs = flowRight([m.assignedTicketRefs, getInstance]);
export const getAssignedTicketCount = flowRight([m.assignedTicketCount, getInstance]);
export const _getPurchasedTicketRefs = flowRight([m.purchasedTicketRefs, getInstance]);
export const getPurchasedTickets = flowRight([m.purchasedTickets, getInstance]);
export const getSelectedTicketRefs = flowRight([m.selectedTicketRefs, getInstance]);
export const getAutoSelectedTicketCount = flowRight([m.autoSelectedTicketCount, getInstance]);
export const getPlayers = flowRight([m.players, getInstance]);
export const getWagerContribution = flowRight([m.wagerContribution, getInstance]);
export const getSeedAmount = flowRight([m.seedAmount, getInstance]);
export const getWinningCondition = flowRight([m.winningCondition, getInstance]);
export const getProgressiveTitle = flowRight([m.progressiveTitle, getInstance]);
export const getRTPLabel = flowRight([m.rtpLabel, getInstance]);
export const getRTPLabelForActiveRoom = createCachedSelector(
  getRTPLabel,
  getGameType,
  (state, refName, instance, gameType) => gameType,
  (rtpLabel, activeGameType, gameType) => {
    if (activeGameType === gameType) {
      return rtpLabel;
    }
  }
)(
  (state, refName, instance = 'next', gameType) => refName + instance + gameType, // Use instance as cache key
  {
    cacheObject: new LruObjectCache({ cacheSize: 4 }) // next, current and 2 date keys will be cached
  }
);

export const getAudioSprite = flowRight([m.audio, getInstance]);
export const getPictureNumbersImageUrl = flowRight([m.pictureNumbersImageUrl, getInstance]);
export const getPictureNumbersImageCfUrl = createCachedSelector(getPictureNumbersImageUrl, (src) =>
  cfImage(src, { width: 1000, format: 'png' })
)(
  (state, refName, instance = 'next') => instance, // Use instance as cache key
  {
    cacheObject: new LruObjectCache({ cacheSize: 4 }) // next, current and 2 date keys will be cached
  }
);
export const getAnimatedNumbersImageUrls = flowRight([m.animatedNumbersImageUrls, getInstance]);
// TODO: https://mrq.atlassian.net/browse/MRQ-1076
export const getProgressiveJackpotCalls = createCachedSelector(
  getProgressiveJackpotMaxCalls,
  getDropped,
  (maxCount, dropped) => {
    const remaining = !isEmpty(dropped) && !isNil(maxCount) ? maxCount - dropped.length : maxCount;
    return remaining > 0 ? remaining : 0;
  }
)(
  (state, refName, instance = 'next') => refName + instance, // Use instance as cache key
  {
    cacheObject: new LruObjectCache({ cacheSize: 4 }) // next, current and 2 date keys will be cached
  }
);

export const getBiggestJackpot = createCachedSelector(
  getProgressiveJackpotAmount,
  getOneLineJackpotAmount,
  getTwoLinesJackpotAmount,
  getGameJackpotAmount,
  (
    progressiveJackpotAmount = null,
    oneLineJackpotAmount = null,
    twoLinesJackpotAmount = null,
    gameJackpotAmount = null
  ) =>
    Math.max(
      oneLineJackpotAmount + twoLinesJackpotAmount + gameJackpotAmount,
      oneLineJackpotAmount,
      twoLinesJackpotAmount,
      progressiveJackpotAmount,
      gameJackpotAmount
    )
)(
  (state, refName, instance = 'next') => refName + instance, // Use instance as cache key
  {
    cacheObject: new LruObjectCache({ cacheSize: 40 })
  }
);

/**
 * Get whether the current instance is a rollover bingo instance.
 *
 * @returns {bool} Room properties current || next
 */
export const getRollover = createCachedSelector(
  getProgressiveJackpotAmount,
  getGameJackpotAmount,
  (progressiveJackpotAmount, gameJackpotAmount) =>
    !!(!gameJackpotAmount && progressiveJackpotAmount)
)(
  (state, refName, instance = 'next') => refName + instance, // Use instance as cache key
  {
    cacheObject: new LruObjectCache({ cacheSize: 4 })
  }
);

/**
 * Returns every current or, if it doesn't exist, next room property .
 *
 * @param {Object} state
 * @param {String} refName
 * @returns {Object} Room properties current || next
 */
export const getDisplayProps = createCachedSelector(
  _getNextInstance,
  _getCurrentInstance,
  getInProgress,
  (nextInstance, currentInstance, inProgress) => {
    if (!inProgress || isEmpty(currentInstance)) {
      return nextInstance;
    } else {
      return { ...currentInstance, ...nextInstance };
    }
  }
)(
  (state, refName, instance = 'next') => refName + instance, // Use instance as cache key
  {
    cacheObject: new LruObjectCache({ cacheSize: 4 }) // next, current and 2 date keys will be cached
  }
);

export const getPriceLabel = createCachedSelector(
  getDisplayProps,
  (displayProps) =>
    displayProps &&
    (displayProps.ticketPrice > 0
      ? formatCurrency(displayProps.ticketPrice)
      : displayProps.ticketPriceLabel || 'FREE')
)(
  (state, refName, instance = 'next') => refName + instance, // Use instance as cache key
  {
    cacheObject: new LruObjectCache({ cacheSize: 4 }) // next, current and 2 date keys will be cached
  }
);

export const getWaitingStage = createCachedSelector(
  getOneLineJackpotAmount,
  getTwoLinesJackpotAmount,
  getOneLineWinners,
  getTwoLinesWinners,
  getInProgressStatus,
  (
    oneLineJackpotAmount,
    twoLinesJackpotAmount,
    oneLineWinners,
    twoLinesWinners,
    inProgressStatus
  ) => {
    let result = STAGE.WAITING_FIRST_LINE;
    const oneLineWon =
      !isEmpty(oneLineWinners && oneLineWinners.result) ||
      inProgressStatus === STATUS.ONE_LINE_JACKPOT_WON ||
      (!isNil(oneLineJackpotAmount) && inProgressStatus === STATUS.TWO_LINES_JACKPOT_WON);
    const twoLinesWon =
      !isEmpty(twoLinesWinners && twoLinesWinners.result) ||
      inProgressStatus === STATUS.TWO_LINES_JACKPOT_WON;
    if (isNil(oneLineJackpotAmount)) {
      result = STAGE.WAITING_FIRST_TWO_LINES;
    }
    if (oneLineWon) {
      result = STAGE.WAITING_SECOND_LINE;
    }
    if (isNil(oneLineJackpotAmount) && isNil(twoLinesJackpotAmount)) {
      result = STAGE.WAITING_THREE_LINES;
    }
    if (oneLineWon && isNil(twoLinesJackpotAmount)) {
      result = STAGE.WAITING_LAST_TWO_LINES;
    }
    if (oneLineWon && twoLinesWon) {
      result = STAGE.WAITING_THIRD_LINE;
    }
    return result;
  }
)(
  (state, refName, instance = 'next') => instance, // Use instance as cache key
  {
    cacheObject: new LruObjectCache({ cacheSize: 4 }) // next, current and 2 date keys will be cached
  }
);

// TODO: getDabCount isn't a selector!
export const getDabCount = (dropped, rows, stage) => {
  const isValid = getType(dropped) === 'Array' && rows;
  if (isValid && stage) {
    // Return the best rows for the current stage
    const sortedRows = rows
      .map((row) =>
        row.positions.reduce((prevPos, position) => {
          if (position.partOfPattern && dropped.includes(position.value)) {
            return prevPos + 1;
          } else {
            return prevPos;
          }
        }, 0)
      )
      .sort((prev, cur) => cur - prev);
    if (stage === STAGE.WAITING_FIRST_LINE) {
      return sortedRows[0];
    }
    if (stage === STAGE.WAITING_SECOND_LINE || stage === STAGE.WAITING_FIRST_TWO_LINES) {
      return sortedRows[0] + sortedRows[1];
    }
    return sortedRows.reduce((acc, cur) => acc + cur, 0);
  } else if (isValid) {
    return rows.reduce(
      (prevRow, row) =>
        row.positions.reduce((prevPos, position) => {
          if (position.partOfPattern && dropped.includes(position.value)) {
            return prevPos + 1;
          } else {
            return prevPos;
          }
        }, prevRow),
      0
    );
  } else {
    return null;
  }
};

export const _sortedTicketRefs = (refs, entities, dropped, stage) =>
  sortBy(
    refs,
    (ref) => entities && entities[ref] && -getDabCount(dropped, m.rows(entities[ref]), stage)
  );

let lastRefs = [];
let lastSortedRefs = [];

export const _sortedCurrentTicketRefs = (refs, entities, dropped, stage) => {
  if (!isEmpty(refs) && refs !== lastRefs) {
    lastSortedRefs = refs;
    lastRefs = refs;
  }
  lastSortedRefs = sortBy(
    !isEmpty(dropped) && !isEmpty(lastSortedRefs) ? lastSortedRefs : refs,
    (ref) => entities && entities[ref] && -getDabCount(dropped, m.rows(entities[ref]), stage)
  );
  return lastSortedRefs;
};

// export const getPatternFromAssigned = createSelector(
//  getAssignedTicketRefs,
//  getAssignedTickets,
//  (assignedTicketRefs, assignedTickets) => {
//    const firstTicket = assignedTickets[assignedTicketRefs[0]];
//    const rows = m.rows(firstTicket);
//    return rows.map((row) => ({
//      positions: row.positions.map((pos) => ({
//        partOfPattern: pos.partOfPattern
//      }))
//    }));
//  }
// );

const emptyArrayReference = [];

export const getAvailableTicketRefs = createCachedSelector(
  getAssignedTicketRefs,
  _getPurchasedTicketRefs,
  (assignedTicketRefs, purchasedTicketRefs) =>
    (assignedTicketRefs &&
      purchasedTicketRefs &&
      difference(assignedTicketRefs, purchasedTicketRefs)) ||
    emptyArrayReference
)(
  (state, refName, instance = 'next') => instance, // Use instance as cache key
  {
    cacheObject: new LruObjectCache({ cacheSize: 4 }) // next, current and 2 date keys will be cached
  }
);

export const getAvailableTicketCount = createCachedSelector(
  getAssignedTicketCount,
  _getPurchasedTicketRefs,
  (assignedTicketCount, purchasedTicketRefs) =>
    (assignedTicketCount &&
      purchasedTicketRefs &&
      assignedTicketCount - purchasedTicketRefs.length) ||
    0
)(
  (state, refName, instance = 'next') => instance, // Use instance as cache key
  {
    cacheObject: new LruObjectCache({ cacheSize: 4 }) // next, current and 2 date keys will be cached
  }
);

export const getPatternCount = createCachedSelector(
  getPattern,
  getWaitingStage,
  (positions, stage) => {
    if (positions) {
      let count = 0;
      positions.forEach((pos) => {
        if (pos.selected) {
          count++;
        }
      });
      return count;
    } else if (stage) {
      // Bingo 90
      if (stage === STAGE.WAITING_FIRST_LINE) {
        return 5;
      }
      if (stage === STAGE.WAITING_SECOND_LINE || stage === STAGE.WAITING_FIRST_TWO_LINES) {
        return 10;
      }
      return 15;
    } else {
      return undefined;
    }
  }
)(
  (state, refName, instance = 'next') => instance, // Use instance as cache key
  {
    cacheObject: new LruObjectCache({ cacheSize: 4 }) // next, current and 2 date keys will be cached
  }
);

export const getAvailableTickets = createCachedSelector(
  getAvailableTicketRefs,
  getAssignedTickets,
  (availableTicketRefs, assignedTickets) => pick(assignedTickets, availableTicketRefs)
)(
  (state, refName, instance = 'next') => instance, // Use instance as cache key
  {
    cacheObject: new LruObjectCache({ cacheSize: 4 }) // next, current and 2 date keys will be cached
  }
);

// Returned in order
export const getPurchasedTicketRefs = createCachedSelector(
  _getPurchasedTicketRefs,
  getPurchasedTickets,
  getDropped,
  getWaitingStage,
  (purchasedTicketRefs, purchasedTickets, dropped, stage) =>
    _sortedTicketRefs(purchasedTicketRefs, purchasedTickets, dropped, stage)
)(
  (state, refName, instance = 'next') => refName + instance, // Use ref+instance as cache key
  {
    cacheObject: new LruObjectCache({ cacheSize: 4 }) // next, current and 2 date keys will be cached
  }
);

// Returned in order
export const getCurrentPurchasedTicketRefs = createCachedSelector(
  _getPurchasedTicketRefs,
  getPurchasedTickets,
  getDropped,
  getWaitingStage,
  (purchasedTicketRefs, purchasedTickets, dropped, stage) =>
    _sortedCurrentTicketRefs(purchasedTicketRefs, purchasedTickets, dropped, stage)
)(
  (state, refName, instance = 'current') => refName + instance, // Use as cache key
  {
    cacheObject: new LruObjectCache({ cacheSize: 1 })
  }
);
