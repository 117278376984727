import React, { useCallback, useMemo, memo } from 'react';
import type { types as WalletTypes } from 'modules/Wallet';
import { MagicMove } from 'components/Transitions';
import AcceptView from './AcceptView';
import RejectView from './RejectView';
import { getClosestDepositInterval, getFormatted } from './helpers';
import { View } from './types';
import type { SubmitDepositLimitSolicitations } from './hooks';

interface DepositLimitSolicitationsProps {
  depositLimitSolicitations: WalletTypes.DigestedSolicitations;
  acceptSolicitations: SubmitDepositLimitSolicitations;
  cancelSolicitations: SubmitDepositLimitSolicitations;
  currentView: View;
  goToAcceptView: () => void;
  goToRejectView: () => void;
  actionRequestErrorMessage: string | null;
}

const DepositLimitSolicitations = ({
  depositLimitSolicitations,
  acceptSolicitations,
  cancelSolicitations,
  currentView,
  goToAcceptView,
  goToRejectView,
  actionRequestErrorMessage
}: DepositLimitSolicitationsProps) => {
  const [interval, currentNext] = getClosestDepositInterval(depositLimitSolicitations);
  const { current, next } = currentNext;
  const formattedValues = useMemo(
    () => getFormatted({ interval, current, next }),
    [interval, current, next]
  );

  const handleAcceptLimitRaise = useCallback(() => {
    void acceptSolicitations({
      interval: formattedValues.interval
    });
  }, [acceptSolicitations, formattedValues.interval]);

  const handleAcceptViewCancel = useCallback(() => {
    goToRejectView();
  }, [goToRejectView]);

  const handleRejectViewTakeMeBack = useCallback(() => {
    goToAcceptView();
  }, [goToAcceptView]);

  const handleCancelLimitRaise = useCallback(() => {
    void cancelSolicitations({
      interval: formattedValues.interval
    });
  }, [cancelSolicitations, formattedValues.interval]);

  return (
    <MagicMove>
      {currentView === View.Accept ? (
        <AcceptView
          key="AcceptView"
          values={currentNext}
          formattedValues={formattedValues}
          actionRequestErrorMessage={actionRequestErrorMessage}
          onAcceptLimitRaise={handleAcceptLimitRaise}
          onCancel={handleAcceptViewCancel}
        />
      ) : (
        <RejectView
          key="RejectView"
          formattedValues={formattedValues}
          actionRequestErrorMessage={actionRequestErrorMessage}
          onTakeMeBack={handleRejectViewTakeMeBack}
          onCancelLimitRaise={handleCancelLimitRaise}
        />
      )}
    </MagicMove>
  );
};

export default memo(DepositLimitSolicitations);
