// TODO: Use Modernizr https://github.com/itgalaxy/webpack-modernizr-loader

export function supportsIntersectionObserver() {
  return (
    'IntersectionObserver' in global &&
    'IntersectionObserverEntry' in global &&
    'intersectionRatio' in IntersectionObserverEntry.prototype
  );
}

export function supportsFormData() {
  const fdTest = new FormData();
  return fdTest && fdTest.entries; // Not supported in Edge 17 and under
}
