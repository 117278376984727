import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classcat';
import { connect } from 'react-redux';
import Media from 'components/Media';
import UI from 'modules/UI';
import './mainLayout.css';

const { selectors: ui } = UI;

export const _MainLayout = ({
  left,
  middle,
  right,
  backgroundImageUrl,
  leftModifiers,
  midModifiers,
  rightModifiers,
  className,
  children
}) => {
  const leftClass = classNames([
    {
      mainLayout__col: true,
      leftCol: true,
      [leftModifiers]: leftModifiers
    }
  ]);
  const midClass = classNames([
    {
      mainLayout__col: true,
      midCol: true,
      [midModifiers]: midModifiers
    }
  ]);
  const rightClass = classNames([
    {
      mainLayout__col: true,
      rightCol: true,
      [rightModifiers]: rightModifiers
    }
  ]);

  return (
    <div
      className={`layout-wrap layout-wrap--${className}`}
      // through background-size 1e+10px we make sure that we are only using roughly one color pixel as the background color. When the b-e will return the background color we will replace the image with the color.
      style={{ background: `url('${backgroundImageUrl}') 0px/1e+10px` }}
    >
      {children}
      <div className={`mainLayout ${className}`}>
        {left && (
          <Media query="(max-width: 1024px)">
            {(matches) => !matches && <div className={leftClass}>{left}</div>}
          </Media>
        )}
        <div className={midClass}>{middle}</div>
        {right && (
          <Media query="(max-width: 768px)">
            {(matches) => !matches && <div className={rightClass}>{right}</div>}
          </Media>
        )}
      </div>
    </div>
  );
};

_MainLayout.propTypes = {
  left: PropTypes.node,
  middle: PropTypes.node,
  right: PropTypes.node,
  backgroundImageUrl: PropTypes.string,
  leftModifiers: PropTypes.string,
  midModifiers: PropTypes.string,
  rightModifiers: PropTypes.string,
  className: PropTypes.string
};

_MainLayout.defaultProps = {
  left: null,
  middle: null,
  right: null,
  backgroundImageUrl: '',
  leftModifiers: '',
  midModifiers: '',
  rightModifiers: '',
  className: ''
};

const mapStateToProps = (state) => ({
  leftModifiers: ui.getClass(state, 'leftCol'),
  midModifiers: ui.getClass(state, 'midCol'),
  rightModifiers: ui.getClass(state, 'rightCol')
});

export default connect(mapStateToProps)(_MainLayout);
