import React, { memo } from 'react';
import PropTypes from 'prop-types';
import './progressCircle.css';

const ProgressCircle = ({ percentage }) => (
  <svg viewBox="0 0 36 36" className="progressCircle" data-chromatic="ignore">
    <path
      className="progressCircle__bg"
      d="M18 2.0845
          a 15.9155 15.9155 0 0 1 0 31.831
          a 15.9155 15.9155 0 0 1 0 -31.831"
    />
    <path
      className="progressCircle__fill"
      strokeDasharray={`${percentage}, 100`}
      d="M18 2.0845
          a 15.9155 15.9155 0 0 1 0 31.831
          a 15.9155 15.9155 0 0 1 0 -31.831"
    />
  </svg>
);

ProgressCircle.propTypes = {
  percentage: PropTypes.number.isRequired
};

export default memo(ProgressCircle);
