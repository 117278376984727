import React, { type MouseEvent } from 'react';
import cc from 'classcat';
import Button from 'components/Button';
import { logos, types } from 'lib/constants';
import EcospendButton from '../../EcospendButton';

interface PaymentMethodsChooserProps {
  previewMode: boolean;
  onClickHandler: (e: MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  availableTypes: string[];
}

const PaymentMethodsChooser = ({
  onClickHandler,
  previewMode,
  availableTypes
}: PaymentMethodsChooserProps) => (
  <div>
    {availableTypes.includes(types.BANK_ACCOUNT) && (
      <EcospendButton
        ecospendButtonClass={cc([previewMode && 'preview'])}
        onClick={onClickHandler}
      />
    )}
    <div
      className={cc([
        'chooser',
        'collapsible',
        {
          preview: previewMode
        }
      ])}
    >
      {availableTypes.includes(types.PCI_PROXY) ? (
        <Button
          type="button"
          buttonText="Add card"
          // TODO: Remove clean up the classnames in the 3rd part of the Rock.
          //  Too much effort to move them around now, since they will be removed anyway.
          className="combo--horizontal leftIcon chooser__button"
          icon={logos.CARD}
          title="Add card"
          name="addPaymentMethod"
          value="pci-proxy"
          onClick={onClickHandler}
        />
      ) : availableTypes.includes(types.BRAINTREE) ? (
        <Button
          type="button"
          buttonText="Add card"
          className="combo--horizontal leftIcon chooser__button"
          icon={logos.CARD}
          title="Add card"
          name="addPaymentMethod"
          value="braintree"
          onClick={onClickHandler}
        />
      ) : null}
      {availableTypes.includes(types.MOBILE) && (
        <Button
          type="button"
          buttonText="Add mobile"
          className="combo--horizontal leftIcon chooser__button"
          icon={logos.MOBILE}
          title="Add phone"
          name="addPaymentMethod"
          value="mobile"
          onClick={onClickHandler}
        />
      )}
      {availableTypes.includes(types.PAYPAL) && (
        <Button
          type="button"
          buttonText={
            <>
              Add&nbsp;
              <img alt="PayPal" className="chooser__paypalText" src={logos.PAYPAL_TEXT_WHITE} />
            </>
          }
          className="combo--horizontal leftIcon chooser__button"
          icon={logos.PAYPAL_WHITE}
          title="Add your PayPal account"
          name="addPaymentMethod"
          value="paypal"
          onClick={onClickHandler}
        />
      )}
      {availableTypes.length === 0 && (
        <span>We are currently experiencing some issues, please try again later.</span>
      )}
    </div>
  </div>
);

export default PaymentMethodsChooser;
