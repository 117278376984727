import type { CmsContentState } from './types';

export const NAME = 'CmsContent';

export enum AppFooterColumn {
  LEFT = 'LEFT',
  RIGHT = 'RIGHT'
}

interface columnStoreKeyType {
  [AppFooterColumn.LEFT]: keyof CmsContentState;
  [AppFooterColumn.RIGHT]: keyof CmsContentState;
}

export const columnStoreKey: columnStoreKeyType = {
  [AppFooterColumn.LEFT]: 'appFooterLeftColumn',
  [AppFooterColumn.RIGHT]: 'appFooterRightColumn'
};

export const columnContentPath = {
  [AppFooterColumn.LEFT]: '/api/navigation/render/app-footer-left-column',
  [AppFooterColumn.RIGHT]: '/api/navigation/render/app-footer-right-column'
};
