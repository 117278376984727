import classNames from 'classcat';

const evaluateModalTitle = (firstDeposit: boolean, selected: string) =>
  classNames([
    firstDeposit
      ? 'First deposit'
      : {
          'Please choose': !selected,
          'Add payment card': ['braintree', 'pci-proxy'].includes(selected),
          'Add deposit phone number': selected === 'mobile',
          'Make a deposit': selected === 'ecospend',
          'Add PayPal account': selected === 'paypal'
        }
  ]);

export default evaluateModalTitle;
