import React, { memo } from 'react';
import cc from 'classcat';
import type { types as CmsContentTypes } from 'modules/CmsContent';
import usePublicCmsDataAppFooter from 'components/Footer/usePublicCmsDataAppFooter';
import FooterLogos from 'components/FooterLogos';

interface FooterTrustFactorsProps {
  className?: string;
  trustFactors?: CmsContentTypes.FooterLogo[];
}

const FooterTrustFactors = ({ className, trustFactors }: FooterTrustFactorsProps) => {
  const { content: data } = usePublicCmsDataAppFooter(Boolean(trustFactors));
  const trustFactorLogos = trustFactors ?? data?.trustFactors;

  return (
    <FooterLogos logos={trustFactorLogos} className={cc(['footer-trustFactors', className])} />
  );
};

export default memo(FooterTrustFactors);
