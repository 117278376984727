import update from 'update-immutable';
import { handleActions } from 'redux-actions';
import { uniq, isEmpty, difference } from 'lodash';
import module from 'lib/module';
import Socket from 'services/Socket';
import { getRefName } from '../lib/action-utils';
import { initialState } from './model';
import { AT, T } from './actionTypes';
import { NAME } from './constants';

const maxSize = 60;

function listRooms(state, action) {
  const { entities, result } = action.payload;
  if (entities) {
    if (!state.result) {
      // No rooms exist
      return update(state, {
        $merge: action.payload
      });
    } else {
      return update(state, {
        entities: {
          $apply: (curEntities) =>
            Object.assign(
              {},
              ...result.map((channelRef) => ({
                [channelRef]: state.result.includes(channelRef)
                  ? {
                      ...entities[channelRef],
                      leftAt: curEntities[channelRef].leftAt,
                      messages: entities[channelRef].messages
                        ? [
                            ...curEntities[channelRef].messages,
                            ...action.entities[channelRef].messages
                          ]
                        : curEntities[channelRef].messages,
                      users: entities[channelRef].users
                        ? [...curEntities[channelRef].users, ...action.entities[channelRef].users]
                        : curEntities[channelRef].users
                    }
                  : entities[channelRef]
              }))
            )
        },
        result: { $set: result }
      });
    }
  } else {
    return state;
  }
}

function closePrivateRoom(state) {
  const index = state.result && state.result.indexOf('__UNIQUE_OR_PRIVATE__');
  if (state.result && index >= 0) {
    return update(state, {
      entities: { $unset: '__UNIQUE_OR_PRIVATE__' },
      result: { $splice: [[index, state.result.length]] }
    });
  } else {
    return state;
  }
}

function getMessage(state, action) {
  const roomRef = action.meta && action.meta.args && action.meta.args.refName;
  if (roomRef) {
    const messageId = action.payload.sentBy + action.payload.ref;
    const messageCount = state.messages?.result && state.messages.result.length;
    const roomMessages =
      state.entities && state.entities[roomRef] && state.entities[roomRef].messages;
    const discardRefs =
      messageCount &&
      messageCount >= maxSize &&
      state.messages.result.slice(0, messageCount - maxSize + 1);
    const keepInRoom = discardRefs && roomMessages && difference(roomMessages, discardRefs);

    const updater = {
      entities: {
        [roomRef]: {
          messages: keepInRoom ? { $set: [...keepInRoom, messageId] } : { $push: [messageId] },
          kicked: { $set: false },
          kickReason: { $set: null }
        }
      },
      result: !state.result ? { $set: [roomRef] } : { $push: [roomRef] },
      messages: {
        entities: {
          [messageId]: { $set: action.payload }
        },
        result: discardRefs
          ? {
              $splice: [
                [0, messageCount - maxSize + 1],
                [messageCount, 0, messageId]
              ]
            }
          : { $push: [messageId] }
      }
    };
    if (state.result?.includes(roomRef)) delete updater.result;
    if (discardRefs) updater.messages.entities.$unset = discardRefs;

    return update(state, updater);
  } else {
    return state;
  }
}

function handleBan(state, { reason }) {
  return update(state, {
    banned: { $set: true },
    banReason: { $set: reason }
  });
}

const leaveChannel = (state, action) =>
  update(state, {
    entities: {
      [action.payload]: {
        leftAt: { $set: Date.now() }
      }
    }
  });

const handleKick = (state, { subscription, reason }) =>
  update(state, {
    entities: {
      [subscription]: {
        kicked: { $set: true },
        kickReason: { $set: reason }
      }
    },
    result: !state.result
      ? { $set: [subscription] }
      : state.result.includes(subscription)
      ? { $set: state.result }
      : { $push: [subscription] }
  });

function handleRejecttion(state, action) {
  if (action.payload.command === 'BAN') {
    return handleBan(state, action.payload);
  } else if (action.payload.command === 'KICK') {
    return handleKick(state, action.payload);
  } else {
    return state;
  }
}

function addUser(state, action) {
  const refName = getRefName(action);
  const { chatUser } = action.payload;
  if (refName && !isEmpty(chatUser)) {
    const username = chatUser.username;
    return update(state, {
      entities: {
        [refName]: {
          users: {
            $apply: (curUsers) => (!isEmpty(curUsers) ? uniq([...curUsers, username]) : [username])
          },
          kicked: { $set: false },
          kickReason: { $set: null }
        }
      },
      result: !state.result
        ? { $set: [refName] }
        : state.result.includes(refName)
        ? { $set: state.result }
        : { $push: [refName] },
      users: {
        entities: {
          [username]: { $set: chatUser }
        },
        result: {
          $apply: (curUsers) => (!isEmpty(curUsers) ? uniq([...curUsers, username]) : [username])
        }
      }
    });
  } else {
    return state;
  }
}

function removeUser(state, action) {
  const refName = getRefName(action);
  const { chatUser } = action.payload;
  const currentUsers =
    refName && !isEmpty(chatUser) && state.entities[refName] && state.entities[refName].users;
  if (!isEmpty(currentUsers)) {
    const username = chatUser.username;
    const entityIndex = currentUsers.indexOf(username);
    const userIndex = (state.users.result && state.users.result.indexOf(username)) || 0;
    const userRefs = state.users.result;
    const userRefIndex = userRefs.indexOf(username);

    return update(state, {
      entities: {
        [refName]: {
          users: entityIndex > -1 ? { $splice: [[entityIndex, 1]] } : {},
          kicked: { $set: false },
          kickReason: { $set: null }
        }
      },
      // Hydrate room
      result: !state.result
        ? { $set: [refName] }
        : state.result.includes(refName)
        ? { $set: state.result }
        : { $push: [refName] },
      users: {
        entities: { $unset: username },
        result: userRefIndex > -1 ? { $splice: [[userRefIndex, 1]] } : { $set: userRefs }
      }
    });
  } else {
    return state;
  }
}

function updateUsers(state, action) {
  const refName = getRefName(action);
  const { subscribers } = action.payload;
  if (refName && subscribers && !isEmpty(subscribers.result)) {
    return update(state, {
      entities: {
        [refName]: {
          users: { $set: subscribers.result },
          kicked: { $set: false },
          kickReason: { $set: null }
        }
      },
      result: !state.result
        ? { $set: [refName] }
        : state.result.includes(refName)
        ? { $set: state.result }
        : { $push: [refName] },
      users: {
        entities: { $merge: subscribers.entities },
        result: {
          $apply: (curUsers) =>
            !isEmpty(curUsers) ? uniq([...curUsers, ...subscribers.result]) : subscribers.result
        }
      }
    });
  } else {
    return state;
  }
}

function getUsers(state, action) {
  const refName = getRefName(action);
  const subscribers = action.payload;
  if (refName && subscribers && !isEmpty(subscribers.result)) {
    return update(state, {
      entities: {
        [refName]: {
          users: { $set: subscribers.result },
          kicked: { $set: false },
          kickReason: { $set: null }
        }
      },
      result: !state.result
        ? { $set: [refName] }
        : state.result.includes(refName)
        ? { $set: state.result }
        : { $push: [refName] },
      users: {
        entities: { $merge: subscribers.entities },
        result: {
          $apply: (curUsers) =>
            !isEmpty(curUsers) ? uniq([...curUsers, ...subscribers.result]) : subscribers.result
        }
      }
    });
  } else {
    return state;
  }
}

const reducer = module(
  handleActions(
    {
      [AT.LIST_ROOMS.FULFILLED]: listRooms,
      [AT.CLOSE_PRIVATE_ROOM.PENDING]: closePrivateRoom,
      [T.GET_MESSAGE]: getMessage,
      [AT.SUBSCRIBERS.FULFILLED]: getUsers,
      [T.SUBSCRIBE_USER]: addUser,
      [T.UNSUBSCRIBE_USER]: removeUser,
      [T.UPDATE_USERS]: updateUsers,
      [AT.WS_UNSUBSCRIBE.FULFILLED]: leaveChannel,
      [AT.WS_CONNECT.REJECTED]: handleRejecttion
    },
    initialState
  ),
  NAME
);

export default Socket.reducer(reducer, NAME);
