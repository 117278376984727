import React, { memo } from 'react';
import RewardOfferCardLoading from 'components/RewardOfferCard/RewardOfferCardLoading';

interface RewardOffersGridListProps {
  count: number;
}

export const RewardOffersGridListLoading = ({ count }: RewardOffersGridListProps) => (
  <>
    {[...Array(count)].map((_, index) => (
      // eslint-disable-next-line react/no-array-index-key -- list is static
      <RewardOfferCardLoading key={index} />
    ))}
  </>
);

export default memo(RewardOffersGridListLoading);
