import { memo } from 'react';
import { usePostLoginLimitConfirmation } from './hooks/usePostLoginLimitConfirmation';
import useSlotCategories from './hooks/useSlotCategories';
import useLiveCategories from './hooks/useLiveCategories';
import useKycVerification from './hooks/useKycVerification';

/**
 * This component should be used to extract out existing side effects that are executed in App
 * like: openLatestTerms(), getKyc(), promptKycStatus(), trackToGTM(), trackLogin(), trackProfile(), etc.
 * so that we can liberate the App from a few Redux connection which will result in fewer App re-renders.
 */
const Triggers = () => {
  usePostLoginLimitConfirmation();
  useSlotCategories();
  useLiveCategories();
  useKycVerification();
  return null;
};

export default memo(Triggers);
