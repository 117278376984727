const getDynamicLobbyEventData = (e: Event) => {
  // We generally don't want analytics to cause rerendering or dictate our component tree,
  // so picking from the DOM is OK when reliable enough.
  const element = e.target as HTMLElement;
  const parentGameList = element?.closest('[data-amp-id="gamelist"]');
  const listRef = parentGameList?.getAttribute('data-amp-list-ref') ?? undefined;
  const listPosition = Number(parentGameList?.getAttribute('data-amp-list-position')) ?? undefined;

  return { listRef, listPosition: Number.isNaN(listPosition) ? undefined : listPosition };
};

export default getDynamicLobbyEventData;
