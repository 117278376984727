import { actionTypeCreator, SYNC, ASYNC } from 'lib/redux-utils';
import { NAME } from './constants';

const namespace = actionTypeCreator(NAME);

export const AT = namespace({
  REGISTER: ASYNC,
  PARTIAL_REGISTER: ASYNC,
  LOGOUT: ASYNC,
  LOGIN: ASYNC,
  RESET_PASSWORD_REQUEST: ASYNC,
  RESET_PASSWORD: ASYNC,
  CHECK_AUTH: ASYNC,
  FIND_ADDRESS: ASYNC,
  FINGERPRINT: ASYNC,
  RETRIEVE_ADDRESS: ASYNC,
  PARTIAL_SEND_SMS_VERIFICATION: ASYNC,
  PARTIAL_VERIFY_SMS: ASYNC,
  PARTIAL_USER_REF: ASYNC
});

export const T = namespace({
  LOGOUT: SYNC
});
