export enum ListVariant {
  Card = 'Card',
  FullWidth = 'FullWidth'
}

export const classMap = {
  [ListVariant.Card]: 'list--card',
  [ListVariant.FullWidth]: ''
};

export const defaultVariant = ListVariant.Card;
export const defaultClassName = classMap[defaultVariant];
