import React, { Component } from 'react';
import { connect } from 'react-redux';
import { isLoading } from 'lib/redux-utils';
import Wallet from 'modules/Wallet';
import Api from 'services/Api';
import AddPaypalForm from './src/AddPaypalForm';

const fields = {
  nullField: {
    initial: true,
    onChange: () => true,
    required: false
  }
};

export class _AddPaypal extends Component {
  state = {
    clientTokenError: null,
    nonce: null,
    deviceData: null
  };

  componentDidMount() {
    this.mounted = true;
    const { clientToken, requestAuthorization } = this.props;
    // Get a client token for authorization from your server
    if (!clientToken) {
      requestAuthorization()
        .then(() => this.mounted && this.setState({ clientTokenError: null }))
        .catch(
          () =>
            this.mounted &&
            this.setState({
              clientTokenError:
                'There was a problem contacting PayPal. Please try another payment option.'
            })
        );
    }
  }

  componentDidUpdate({ paypalPaymentMethodRef: prevPaypalPaymentMethodRef }) {
    const { paypalPaymentMethodRef, onDone } = this.props;
    if (!prevPaypalPaymentMethodRef && paypalPaymentMethodRef) {
      onDone();
    }
  }

  componentWillUnmount() {
    this.mounted = false;
  }

  handlePaymentMethod = ({ nonce, deviceData }) => {
    if (this.mounted) this.setState({ nonce, deviceData });
  };

  submit = (data) => this.props.createPaypal(data, this.state);

  render() {
    const { loading, clientToken } = this.props;
    const { nonce, deviceData, clientTokenError } = this.state;

    return (
      <AddPaypalForm
        fields={fields}
        loading={loading}
        nonce={nonce}
        deviceData={deviceData}
        clientToken={clientToken}
        clientTokenError={clientTokenError}
        submit={this.submit}
        handlePaymentMethod={this.handlePaymentMethod}
      />
    );
  }
}

const mapStateToProps = (state) => ({
  clientToken: state.Wallet.paypalToken,
  paypalPaymentMethodRef: Wallet.selectors.getPaypalPaymentMethodRef(state),
  loading: isLoading(state, [
    Wallet.actionTypes.AT.DEPOSIT_PAYPAL._,
    Wallet.actionTypes.AT.CREATE_PAYPAL._,
    Wallet.actionTypes.AT.PAYPAL_TOKEN._
  ])
});

const mapDispatchToProps = (dispatch) => ({
  requestAuthorization: () => Api.actions.wallet.paypalToken()(dispatch),
  createPaypal: async (_, { nonce }) => {
    try {
      const result = await Api.actions.wallet.createPaypal(null, {
        paymentMethodType: 'PAYPAL',
        details: { nonce }
      })(dispatch, true);
      return result;
    } catch (e) {
      throw e;
    }
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(_AddPaypal);
