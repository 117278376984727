import canUseTrailingZeroDisplay from './canUseTrailingZeroDisplay';
import canUseNarrowSymbol from './canUseNarrowSymbol';
import type { CurrencyFormatterOptions } from './types';
import formatter from './formatter';

const PRECISION_DEFAULT = 9999;

const defaultOpts = {
  precise: false,
  pennies: true,
  precisionCutoff: PRECISION_DEFAULT
} as const satisfies CurrencyFormatterOptions;

const format = (input: number, opts: CurrencyFormatterOptions = {}) => {
  const { precise, pennies, precisionCutoff } = { ...defaultOpts, ...opts };
  const money =
    !precise && input > precisionCutoff
      ? formatter.loose
      : !precise && canUseTrailingZeroDisplay
      ? (formatter.preciseStripZeroTrail as Intl.NumberFormat)
      : formatter.precise;
  let result = money.format(input);
  const { currency, locale } = money.resolvedOptions();
  if (currency === 'GBP' && locale === 'en-GB' && pennies) {
    const tenPenniesIndex = result.indexOf('£0.0');
    const penniesIndex = result.indexOf('£0.');
    if (tenPenniesIndex >= 0) {
      result = `${result.substring(tenPenniesIndex + 4, result.length)}p`;
    } else if (penniesIndex >= 0) {
      result = `${result.substring(penniesIndex + 3, result.length)}p`;
    }
  }
  // Obviates precisionCutoff
  // FIXME: Remove when `trailingZeroDisplay` browser support picks up
  if (!canUseTrailingZeroDisplay && !precise && Number.isInteger(input)) {
    result = result.replace(formatter.decimalFraction, '');
  }

  // FIXME: Remove when iOS < 14.5 falls outside our coverage
  if (!canUseNarrowSymbol) {
    // Eg. replace 'HK' in 'HK$' with '' -> 'HK' ->
    // -> replace 'HK' in 'HK$1,000.00' with '' -> '$1,000.00'
    result = result.replace(formatter.symbol.replace(/\W+/g, ''), '');
  }

  return result;
};

export default format;
