import React, { type ChangeEventHandler } from 'react';
import type { Authority } from 'lib/constants';
import type { ReformField } from 'components/Reform/types';
import reform from 'components/Reform';
import Button from 'components/Button';
import FormError from 'components/FormError';
import TimeoutSelect from './src/TimeoutSelect';
import data from './data';

const AUTHORITY = __ENV__.MRQ_GAMING_AUTHORITY;

interface CoolOffFormProps {
  days: ReformField;
  loading: boolean;
  formValid: boolean;
  errorMessage: string;
  handleChange: ChangeEventHandler<HTMLSelectElement>;
  onSubmit: ChangeEventHandler<HTMLFormElement>;
  authority?: Authority;
}

const CoolOffForm = reform()(
  ({
    days,
    formValid,
    errorMessage,
    loading,
    handleChange,
    onSubmit,
    authority = AUTHORITY
  }: CoolOffFormProps) => {
    const content = data?.[authority]?.content;

    return (
      <form onSubmit={onSubmit} className="form">
        <p className="userBox__intro">{content}</p>
        <TimeoutSelect days={days} handleChange={handleChange} authority={authority} />
        <div className="fieldset fieldset--centerflex">
          <Button
            disabled={!formValid}
            loading={loading}
            title="You will be logged out immediately"
            buttonText="Set time-out"
            type="submit"
          />
        </div>
        <FormError errorMessage={errorMessage} />
      </form>
    );
  }
);

export default CoolOffForm;
