import { memo, useEffect } from 'react';
import { useAppSelector } from 'hooks/redux';
import amplitude from 'lib/analytics';
import User from 'modules/User';

/**
 * AppWrapperDataBridge Component
 *
 * This component is responsible for exposing certain variables (such as `userRef` and `amplitude`)
 * to the global `window` object so that they can be accessed by the mobile React Native app.
 *
 * The mobile app (referred to as "app wrapper") uses a WebView to load the web app, and since
 * the WebView acts as a bridge between the native and web layers, exposing these variables
 * to the `window` object allows the native layer to easily access important data.
 */

window.ampli = amplitude;

const AppWrapperDataBridge = () => {
  const userRef = useAppSelector(User.selectors.getRef);

  useEffect(() => {
    window.userRef = userRef;
  }, [userRef]);
  return null;
};

export default memo(AppWrapperDataBridge);
