import React, { Component } from 'react';
import { connect } from 'react-redux';
import { phoneNumberErrorMessage } from 'lib/messages';
import reform from 'components/Reform';
import Fieldset from 'components/Fieldset';
import Button from 'components/Button';
import Wallet from 'modules/Wallet';
import User from 'modules/User';
import { isLoading } from 'lib/redux-utils';
import { phone as validatePhone, mobilePhone as validateMobile } from 'lib/valFuncs';

const Form = reform()(
  ({ phoneNumber, onSubmit, handleFocus, loading, formValid, handleChange, user }) => (
    <form className="add-mobile" onSubmit={onSubmit}>
      <Fieldset
        field={phoneNumber}
        inputType="tel"
        name="phoneNumber"
        labelText={`${user ? 'Profile' : 'Deposit'} phone number`}
        onChange={handleChange}
        onFocus={handleFocus}
      />
      <div className="fieldset fieldset--centerflex">
        <Button
          disabled={!formValid}
          loading={loading}
          title="Add number"
          buttonText="Add number"
          type="submit"
        />
      </div>
    </form>
  )
);

export class _AddMobileForm extends Component {
  fields = {
    phoneNumber: {
      required: true,
      error: phoneNumberErrorMessage,
      hint: 'You can change this from your account settings',
      onChange: this.props.user ? validatePhone : validateMobile
    }
  };

  render() {
    return <Form fields={this.fields} {...this.props} />;
  }
}

const mapStateToProps = (state, ownProps) => ({
  loading: ownProps.user
    ? isLoading(state, User.actionTypes.AT.SMS_VERIFICATION_STATE._) ||
      isLoading(state, User.actionTypes.AT.UPDATE_MOBILE._)
    : isLoading(state, Wallet.actionTypes.AT.SMS_VERIFICATION_STATE._) ||
      isLoading(state, Wallet.actionTypes.AT.UPDATE_MOBILE._)
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  submit: (data) =>
    ownProps.user
      ? dispatch(User.actions.updateMobile(data.phoneNumber, ownProps.onDone))
      : dispatch(Wallet.actions.updateMobile(data.phoneNumber))
});

export default connect(mapStateToProps, mapDispatchToProps)(_AddMobileForm);
