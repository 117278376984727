import React, { useCallback } from 'react';
import cc from 'classcat';
import { useHistory } from 'react-router-dom';
import RouterLink from 'components/RouterLink';
import { constants } from 'modules/User';
import { useAppDispatch } from 'hooks/redux';
import Analytics from 'modules/Analytics';
import type { DfgDayStatus, Day } from './types';
import DfgDays from './src/DfgDays';
import StatusMarker from './src/StatusMarker';
import RibbonLabel from './src/RibbonLabel';
import './rewardCard.css';

interface RewardCardProps {
  path: string;
  title?: string;
  description?: string;
  termsAndConditions?: string;
  imageUrl?: string;
  isDfg?: boolean;
  ribbonLabel?: string;
  currentDay?: Day;
  dfgDays?: DfgDayStatus | null;
  status: constants.RewardStatus;
  openTCModal: (content: string) => void;
  rewardAmount: string;
}

const RewardCard = ({
  title = '',
  description = '',
  imageUrl,
  termsAndConditions = '',
  status = constants.RewardStatus.Seen,
  isDfg = false,
  dfgDays = null,
  ribbonLabel = '',
  currentDay,
  rewardAmount,
  path,
  openTCModal
}: RewardCardProps) => {
  const history = useHistory();
  const dispatch = useAppDispatch();

  const gameLaunched = useCallback(() => {
    dispatch(
      Analytics.actions.gameLaunched({
        refName: path,
        source: 'Rewards'
      })
    );
  }, [path, dispatch]);

  const handleCardClick = useCallback(() => {
    gameLaunched();
    history.push(`/secure/games/${path}`);
  }, [history, path, gameLaunched]);

  const handleClickTC = useCallback(
    (e) => {
      e.preventDefault();
      e.stopPropagation();
      openTCModal(termsAndConditions);
    },
    [openTCModal, termsAndConditions]
  );

  const isLocked = status === constants.RewardStatus.Locked;

  // Why does the root div have a onClick Handler?
  // The stakeholders of this feature want a click anywhere on the card to trigger the default action,
  // in this case, redirecting the user to the respective game.
  // Making the div have an onClick will give that behaviour, while buttons inside may have another
  // onClick functionality which will stop the onClick propagation (bubbling).
  // With the title being a RouterLink to the same location as the root div onClick handler
  // we get the desired behavior and still get to keep the accessibility working properly

  return (
    // eslint-disable-next-line jsx-a11y/no-static-element-interactions,jsx-a11y/click-events-have-key-events -- described above
    <div onClick={!isLocked ? handleCardClick : undefined} className="rewardCard">
      <StatusMarker rewardStatus={status} />
      <div className={cc(['rewardCard__content', isDfg && 'rewardCard__content--lightYellow'])}>
        <div className="rewardCard__thumbnail">
          <img alt="game thumbnail" src={imageUrl} />
        </div>
        <div className="rewardCard__info text-truncate">
          {ribbonLabel && <RibbonLabel label={ribbonLabel} />}
          <RouterLink
            href={isLocked ? '' : `/secure/games/${path}`}
            title={`Play ${title}`}
            className="rewardCard__title text-truncate"
          >
            {title}
          </RouterLink>
          {description && <div className="rewardCard__subtitle text-truncate">{description}</div>}
          {termsAndConditions && (
            // eslint-disable-next-line jsx-a11y/anchor-is-valid -- Might need to replace with button
            <a onClick={handleClickTC} href="" className="rewardCard__tc text-truncate">
              T&Cs apply
            </a>
          )}
          {dfgDays && <DfgDays dayStatusMap={dfgDays} currentDay={currentDay} />}
        </div>
        <div className="rewardCard__count">{rewardAmount}</div>
      </div>
    </div>
  );
};

export default RewardCard;
