import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import Slot from 'modules/Slot';
import Live from 'modules/Live';
import { Typography, TypographyVariant } from 'components/Typography';
import { formatCurrency } from 'lib/formatters';
import cfImage from 'lib/cfImage';
import User from 'modules/User';
import Analytics from 'modules/Analytics';
import './roomCard.css';
import './slotRoomCard.css';

export const _SlotCard = ({
  refName,
  name,
  lobbyImageUrl,
  handleClick,
  jackpotAmount,
  provider,
  platform,
  type,
  category,
  source,
  trackLaunch,
  path,
  blur
}) => {
  const imageUrl = cfImage(lobbyImageUrl, {
    width: 265,
    height: 265,
    ignoreGif: !blur,
    blur: blur && 25,
    brightness: blur && 0.8
  });

  const trackGameLaunch = useCallback(() => {
    trackLaunch({
      refName,
      name,
      provider,
      section: type,
      platform,
      source,
      category
    });
  }, [trackLaunch, name, platform, provider, refName, type, category, source]);

  return (
    <div className={`roomCard slot ${refName}`}>
      {jackpotAmount ? (
        <span className="roomCard__jackpot">{formatCurrency(jackpotAmount)}</span>
      ) : null}
      {!blur ? null : (
        <Typography variant={TypographyVariant.BodyMdStrong}>
          <span className="roomCard__title">{name}</span>
        </Typography>
      )}
      {!handleClick ? (
        <Link
          to={`${path || '/secure/games'}/${refName}`}
          className="main"
          style={{
            background: `url('${imageUrl}') center center / cover no-repeat`
          }}
          onClick={trackGameLaunch}
          title={name}
        />
      ) : (
        <div
          className="main"
          style={{
            background: `url('${imageUrl}') center center / cover no-repeat`
          }}
          onClick={handleClick}
          data-ref={refName}
          title={name}
        />
      )}
    </div>
  );
};

_SlotCard.propTypes = {
  refName: PropTypes.string.isRequired,
  // eslint-disable-next-line react/no-unused-prop-types -- Used in mapStateToProps
  type: PropTypes.oneOf(['casino', 'live']),
  category: PropTypes.string,
  source: PropTypes.string,
  name: PropTypes.string,
  path: PropTypes.string,
  lobbyImageUrl: PropTypes.string,
  provider: PropTypes.string,
  platform: PropTypes.string,
  jackpotAmount: PropTypes.number,
  blur: PropTypes.bool,
  handleClick: PropTypes.func
};

_SlotCard.defaultProps = {
  name: '',
  type: 'casino',
  category: '',
  source: 'Lobby',
  lobbyImageUrl: '',
  path: null,
  jackpotAmount: null,
  provider: null,
  platform: null,
  blur: false,
  handleClick: null
};

const mapStateToProps = (state, { refName, type }) => {
  const getRoom = type === 'live' ? Live.selectors.getRoom : Slot.selectors.getRoom;
  const kycStatus = User.selectors.getKycStatus(state);
  const { name, lobbySquareImageUrl, jackpotAmount, blur, provider, platform } =
    getRoom?.(state, refName) || {};

  const lobbyImageUrl = lobbySquareImageUrl;
  const shouldBlur = blur && kycStatus !== User.constants.kyc.PASS;

  return {
    name,
    jackpotAmount,
    lobbyImageUrl,
    provider,
    platform,
    blur: shouldBlur
  };
};

const mapDispatchToProps = (dispatch) => ({
  trackLaunch: (payload) => dispatch(Analytics.actions.gameLaunched(payload))
});

export default connect(mapStateToProps, mapDispatchToProps)(_SlotCard);
