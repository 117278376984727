import React, { useCallback, useEffect } from 'react';
import loadable from '@loadable/component';
import { formatMinutes } from 'lib/formatters';
import Api from 'services/Api';
import User from 'modules/User';
import makeModal from 'components/Modals';
import Dialog from 'components/Dialog';
import DialogArticle, { Variant as DialogArticleVariant } from 'components/Dialog/Article';
import DialogFooter from 'components/Dialog/Footer';
import { DialogHeader } from 'components/Dialog/Header';
import { DialogButton, Variant as DialogButtonVariant } from 'components/Dialog/Button';
import { useAppDispatch, useAppSelector } from 'hooks/redux';

const SessionStats = loadable(() => import('components/SessionStats'));

const initialFocusElementSelector = '.dialog .button__grey';

interface RealityCheckProps {
  close: () => void;
}

interface RealityCheckBaseProps extends RealityCheckProps {
  interval: number;
  logout: () => void;
}

export const RealityCheckBase = ({ interval, logout, close }: RealityCheckBaseProps) => (
  <Dialog
    name="realityCheck"
    trapFocus
    focusTrapOptions={{ initialFocus: initialFocusElementSelector }}
  >
    <DialogHeader icon="/assets/images1/dialogIcon.svg">Reality Check</DialogHeader>
    <DialogArticle>
      {`**This session has reached your ${formatMinutes(interval)} Reality Check limit**
____`}
    </DialogArticle>
    <SessionStats />
    <DialogFooter>
      <DialogButton onClick={logout}>Log out</DialogButton>
      <DialogButton variant={DialogButtonVariant.SECONDARY} onClick={close}>
        Confirm & keep playing
      </DialogButton>
      <DialogArticle variant={DialogArticleVariant.XXS} onLinkClick={close}>
        {`<br/>Play responsibly, to set limits & learn more please visit the [Safer Gambling
            Settings](/secure/user/account/limits)`}
      </DialogArticle>
    </DialogFooter>
  </Dialog>
);

const RealityCheckAdapter = ({ close }: RealityCheckProps) => {
  const dispatch = useAppDispatch();
  const interval = useAppSelector(
    (state) => User.selectors.model(state).realityCheckInterval as number
  );

  const logout = useCallback(() => {
    void Api.actions.auth.logout(null, new FormData())(dispatch);
  }, [dispatch]);

  useEffect(() => {
    void Api.actions.user.getSettings()(dispatch);
  }, [dispatch]);

  return <RealityCheckBase interval={interval} close={close} logout={logout} />;
};

export default makeModal(
  'realityCheck',
  { name: 'modal-fade', timeout: 200 },
  { className: 'realityCheck modal modal--dialog' }
)(RealityCheckAdapter);
