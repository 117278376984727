// eslint-disable-next-line import/no-mutable-exports -- intentionally mutable config variable
let proxyTarget;

if (process.env.IS_CF_PAGES) {
  try {
    proxyTarget = window.localStorage.getItem('xProxyTarget');
    const params = new URLSearchParams(window.location.search);
    const proxyTargetFromUrl = params.get('xProxyTarget');
    if (proxyTargetFromUrl || proxyTargetFromUrl === '') {
      proxyTarget = proxyTargetFromUrl;
      window.localStorage.setItem('xProxyTarget', proxyTarget);
    }
  } catch (e) {
    console.warn(e);
  }
}

export default proxyTarget;
