import React, { memo } from 'react';
import type { PropsWithChildren, ReactNode } from 'react';
import './dialogFooter.css';

interface FooterProps {
  children?: ReactNode;
}

// Default children to null because the backend sometimes messes it up
const Footer = ({ children = null }: PropsWithChildren<FooterProps>) => (
  <footer className="dialogFooter">{children}</footer>
);

export default memo(Footer);
