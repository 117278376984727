import React, { memo } from 'react';
import loadable from '@loadable/component';
import User from 'modules/User';
import { isLoading } from 'lib/redux-utils';
import { useAppSelector } from 'hooks/redux';
import RewardOfferCard from 'components/RewardOfferCard';
import RewardsCalendar from 'components/RewardsCalendar';
import LoadingOffers from './RewardOffersGridListLoading';
import { OfferType } from './types';
import type { OfferData } from './types';
import './rewardOffersGridList.css';

const Footer = loadable(() => import('components/Footer'));

interface OffersGridListProps {
  offers?: Array<OfferData>;
  openOfferModal: (content: OfferData) => void;
}

const RewardOffersGridList = ({ offers, openOfferModal }: OffersGridListProps) => {
  const loadingOffers = useAppSelector((state) =>
    isLoading(state, User.actionTypes.AT.GET_REWARD_OFFERS._)
  );

  return (
    <div className="rewardOffersGridList hide-scrollbar">
      <div className="rewardOffersGridList__container">
        {!offers && loadingOffers ? (
          <LoadingOffers count={3} />
        ) : (
          <>
            {(offers?.length || 0) > 0 &&
              offers?.map((e) =>
                e.type === OfferType.CALENDAR ? (
                  <RewardsCalendar
                    key={e.ref}
                    refName={e.ref}
                    type={e.type}
                    title={e.title}
                    heading={e.heading}
                    shortDescription={e.shortDescription}
                    cta={e.cta}
                    imageUrl={e.imageUrl}
                    subOffers={e.subOffers}
                  />
                ) : (
                  <RewardOfferCard
                    key={e.ref}
                    refName={e.ref}
                    type={e.type}
                    title={e.title}
                    heading={e.heading}
                    shortDescription={e.shortDescription}
                    cta={e.cta}
                    ctaText={e.ctaText}
                    imageUrl={e.imageUrl}
                    openModal={() => openOfferModal(e)}
                  />
                )
              )}
          </>
        )}
      </div>
      <Footer />
    </div>
  );
};

export default memo(RewardOffersGridList);
