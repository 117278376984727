export const NAME = 'Bingo';
export const STAGE = Object.freeze({
  WAITING_FIRST_LINE: 'WAITING_FIRST_LINE',
  WAITING_SECOND_LINE: 'WAITING_SECOND_LINE',
  WAITING_FIRST_TWO_LINES: 'WAITING_FIRST_TWO_LINES',
  WAITING_THIRD_LINE: 'WAITING_THIRD_LINE',
  WAITING_LAST_TWO_LINES: 'WAITING_LAST_TWO_LINES',
  WAITING_THREE_LINES: 'WAITING_THREE_LINES'
});
export const JACKPOT = Object.freeze({
  ONE_LINE: 'ONE_LINE',
  TWO_LINES: 'TWO_LINES',
  GAME: 'GAME',
  PROGRESSIVE: 'PROGRESSIVE'
});
export const STATUS = Object.freeze({
  ONE_LINE_JACKPOT_WON: 'ONE_LINE_JACKPOT_WON',
  TWO_LINES_JACKPOT_WON: 'TWO_LINES_JACKPOT_WON',
  GAME_FINISHED: 'GAME_FINISHED'
});
export const GAME_TYPE = Object.freeze({
  30: '30',
  75: '75',
  90: '90'
});
export const JACKPOT_TYPE = Object.freeze({
  progressive: 'progressive',
  rollover: 'rollover'
});
export const ephemeral = [
  'assignedTickets',
  'purchasedTickets',
  'inPlay',
  'drawnNumbers',
  'inProgress',
  'initialDelay',
  'maxTicketsPerPlayer',
  'inProgressStatus'
];
