import React from 'react';
import UserBox from 'components/UserBox';
import makeModal from 'components/Modals';
import AddMobile from 'components/AddPayment/AddMobile';

const _VerifyMobileModal = ({ close }) => (
  <UserBox
    id="verifyMobile"
    className="verifyMobile modal"
    title="Verify phone number"
    closeCallback={close}
  >
    <AddMobile onDone={close} user verifyOnly />
  </UserBox>
);

export default makeModal(
  'verifyMobile',
  { name: 'modal-fade', timeout: 200 },
  { className: 'verifyMobile modal' }
)(_VerifyMobileModal);
