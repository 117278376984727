/* eslint-disable react/prop-types */
import React from 'react';
import Base from './src/Base';

const ACCORDION_MILLIS = 250;

const AccordionVertical = ({ className, childKey, children }) => (
  <Base
    classNames="accordion-vertical"
    timeout={ACCORDION_MILLIS}
    className={className}
    childKey={childKey}
  >
    {children}
  </Base>
);

export default AccordionVertical;
