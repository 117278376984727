import React, { Component } from 'react';
import { compose } from 'redux';
import cc from 'classcat';
import reform from 'components/Reform';
import Paypal from 'components/Paypal';
import LoadingElement from 'components/Loading/LoadingElement';
import FormError from 'components/FormError';
import 'components/Deposit/depositForm.css';
import 'components/Deposit/src/depositCardForm.css';

const enhance = compose(reform());

class BaseForm extends Component {
  state = {
    dropinLoaded: false
  };

  componentDidUpdate({ nonce: prevNonce }) {
    if (!prevNonce && this.props.nonce) {
      // This is how you trigger the synthetic submit event in React /react/issues/6796
      this.form.dispatchEvent(new Event('submit'));
    }
  }

  handleInstance = () => {
    this.setState({ dropinLoaded: true });
  };

  formRef = (element) => {
    this.form = element;
  };

  render() {
    const {
      disabled,
      formValid,
      handlePaymentMethod,
      clientToken,
      clientTokenError,
      errorMessage,
      loading,
      onSubmit
    } = this.props;
    const { dropinLoaded } = this.state;
    const cannotDeposit = disabled || !formValid;

    return (
      <form
        id="depositCardForm"
        name="addPaypal"
        onSubmit={onSubmit}
        className={disabled ? 'form form--disabled' : 'form'}
        ref={this.formRef}
      >
        {clientToken ? (
          <p>Add your PayPal account for easy and secure deposits and withdrawals.</p>
        ) : null}
        {clientToken ? (
          <div
            id="dropin-container"
            className={cc([
              'field',
              'dropin-container',
              (!dropinLoaded || loading) && 'dropin-container--hidden',
              cannotDeposit && 'dropin-container--forbid'
            ])}
          >
            <Paypal
              onPaymentMethod={handlePaymentMethod}
              onInstance={this.handleInstance}
              clientToken={clientToken}
            />
          </div>
        ) : null}
        {loading || !dropinLoaded || (!clientToken && !clientTokenError) ? (
          <LoadingElement display="medium" />
        ) : null}
        <FormError errorMessage={errorMessage || (!clientToken && clientTokenError) || undefined} />
      </form>
    );
  }
}

export default enhance(BaseForm);
