// TODO: Move to modules
import update from 'update-immutable';
import { getNamespace } from 'lib/redux-utils';
import * as t from './actionTypes';

export default function socket(reducer, name) {
  const initialState = update(reducer(undefined, {}), {
    socket: {
      $set: {
        connected: null,
        subscriptions: {}
      }
    }
  });

  return function(state = initialState, action) {
    const type = action.type;
    const operation = type && getNamespace(type);
    const reduced = reducer(state, action);
    if (name === operation) {
      switch (type) {
        case `${name}/${t.WS_CONNECT}_FULFILLED`: {
          return update(reduced, {
            socket: {
              connected: { $set: true }
            }
          });
        }
        case `${name}/${t.WS_DISCONNECT}_FULFILLED`:
          return {
            ...reduced,
            socket: {
              ...reduced.socket,
              connected: false
            }
          };
        case `${name}/${t.WS_CONNECT}_REJECTED`:
          return {
            ...reduced,
            socket: {
              ...reduced.socket,
              connected: false
            }
          };
        case `${name}/${t.WS_SUBSCRIBE_FULFILLED}`: {
          const refName = action.payload;
          return refName
            ? update(reduced, {
                socket: {
                  subscriptions: {
                    $merge: {
                      [refName]: refName
                    }
                  }
                }
              })
            : state;
        }
        case `${name}/${t.WS_UNSUBSCRIBE_FULFILLED}`: {
          const refName = action.payload;
          return refName
            ? update(reduced, {
                socket: {
                  subscriptions: { $unset: refName }
                }
              })
            : state;
        }
        default:
          break;
      }
    }
    return reduced;
  };
}
