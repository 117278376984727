import { actionTypes as slotTypes } from 'modules/Slot';

/**
 * THE PAYLOAD DATA ARE THE JUST DEFAULT VALUES. The (pre-translated) action's payload is MERGED INTO the translation
 * ONLY add mappings for internal <-> external actions. Never external <-> external or internal <-> internal
 * keyMapping defines the **bidirectional** translation of the event/action keys.
 *
 * This is 2 way mapping!
 */
export default {
  keyMapping: {
    type: 'type',
    payload: '',
    flattenPayload: true
  },
  entities: {
    [slotTypes.T.FRAME_RESIZED]: {
      type: 'resize'
    },
    gameReady: (dispatch, { meta }) =>
      dispatch({
        type: slotTypes.T.GAME_READY,
        payload: { refName: meta.refName }
      }),
    // >> from prev provider - keeping around in case some games still send these messages
    roundStart: (dispatch) =>
      dispatch({
        type: slotTypes.T.ROUND_STARTED
      }),
    roundEnded: (dispatch) =>
      dispatch({
        type: slotTypes.T.ROUND_ENDED
      }),
    // << from old provider
    gameBusy: (dispatch) =>
      dispatch({
        type: slotTypes.T.ROUND_STARTED
      }),
    gameNotBusy: (dispatch) =>
      dispatch({
        type: slotTypes.T.ROUND_ENDED
      })
  }
};
