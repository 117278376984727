// TODO: It's copied from mrq-front-unsecured, extract to shared components
import React, { memo } from 'react';
import type { PropsWithChildren, CSSProperties } from 'react';

interface HeadingProps {
  /** The heading level, for example 2 corresponds to <h2 /> */
  level: number;
  className?: string;
  id?: string;
  style?: CSSProperties;
}

const Heading = ({ level, className, id, style, children }: PropsWithChildren<HeadingProps>) =>
  level > 0 && level < 7 ? (
    React.createElement(`h${level.toString()}`, { className, style, id }, children)
  ) : (
    <div className={className} style={style} id={id}>
      {children}
    </div>
  );

export default memo(Heading);
