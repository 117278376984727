import React from 'react';
import * as Animation from 'components/Animation';
import './rowListSkeleton.css';

interface RowListSkeletonProps {
  count?: number;
  showTitle?: boolean;
  children: any;
}

const RowListSkeleton = ({ count = 8, showTitle = true, children }: RowListSkeletonProps) => (
  <div className="dynamicLobby__skeleton-listWrap">
    {showTitle && (
      <Animation.Root className="dynamicLobby__skeleton-title">
        <Animation.ShimmerInputPrimary />
      </Animation.Root>
    )}
    <div className="dynamicLobby__skeleton-rowList">
      {Array.from({ length: count }).map((_, i: number) => (
        // eslint-disable-next-line react/no-array-index-key -- list is static
        <React.Fragment key={`image_${i}`}>{children}</React.Fragment>
      ))}
    </div>
  </div>
);

export default RowListSkeleton;
