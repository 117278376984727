import { flowRight, find, sortBy } from 'lodash';
import createCachedSelector, { LruObjectCache } from 're-reselect';
import { NAME } from './constants';
import * as m from './model';

export const model = (state) => state[NAME] || m.initialState;
export const getRooms = flowRight([m.rooms, model]);
export const getRoomRefs = flowRight([m.refs, model]);
export const getRoom = (state, refName) => getRooms(state)[refName];
export const getNotFound = flowRight([m.notFound, getRoom]);
export const getNotFoundMsg = flowRight([m.notFoundMsg, getRoom]);
export const getIsolatedRefCount = (state, category) => model(state).categoryRefs[category]?.length;
export const getCategoryTotalElements = (state, category) => model(state).totalElements[category];
export const getRoomRefsByCategory = (state, category) => model(state).categoryRefs[category];
export const getCategoryOffset = (state, category) => model(state).categoryOffset[category];
export const getMetadata = flowRight([m.metadata, getRoom]);

export const getCategoryRefs = createCachedSelector(
  getRooms,
  getRoomRefsByCategory,
  (state, category) => category,
  (slots, slotRefs, category) =>
    slots && slotRefs && slotRefs.length > 0
      ? sortBy(slotRefs, [
          (ref) => {
            if (slots[ref]?.categoryPositions) {
              return slots[ref]?.categoryPositions[category];
            } else {
              return null;
            }
          }
        ])
      : []
)((state, category) => category + getIsolatedRefCount(state, category), {
  cacheObject: new LruObjectCache({ cacheSize: 4 })
});

// Working with inequalities allows us to gracefully handle out-of-range errors
export const getCategoryFullyLoaded = (state, category) =>
  getIsolatedRefCount(state, category) >= getCategoryTotalElements(state, category);

export const getRoomById = (state, id) =>
  find(getRooms(state), { mobileGameId: id }) || find(getRooms(state), { desktopGameId: id });

export const getLoadingByCategory = (state, category) => !!model(state).categoryLoading[category];
