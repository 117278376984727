import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import Button from 'components/Button';

const CompletedScreen = ({ close, goToScreen }) => (
  <div>
    <p className="referAFriend__instruction">
      We’re just waiting for your friends to catch them. In the meantime, you can check the status
      of your referrals in your{' '}
      <Link to="/secure/user/account/refer-a-friend" className="referAFriend_instructionLink">
        account page!
      </Link>
    </p>
    <div className="referAFriend__centeredContainer--marginTop">
      <Button
        className="referAFriend__button"
        onClick={close}
        name="close"
        id="referAFriend-close"
        buttonText="Close"
      />
      <Button
        className="referAFriend__button"
        onClick={goToScreen}
        name="referMore"
        buttonText="Refer more"
      />
    </div>
  </div>
);

CompletedScreen.propTypes = {
  goToScreen: PropTypes.func.isRequired,
  close: PropTypes.func.isRequired
};

export default CompletedScreen;
