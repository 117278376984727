import React, { memo } from 'react';
import classNames from 'classcat';
import AccordionVertical from 'components/Transitions/AccordionVertical';
import type { SecureFieldElement } from '../types';

type SecureFieldProps = {
  field: SecureFieldElement;
  icon?: JSX.Element;
  isOutsideIcon?: boolean;
  iconWidth?: number;
  className?: string;
  focused?: boolean;
  notEmpty?: boolean;
  handleFocus: () => void;
};

const SecureField = ({
  field,
  icon,
  className,
  isOutsideIcon,
  iconWidth,
  notEmpty,
  focused,
  handleFocus
}: SecureFieldProps) => {
  const inputClass = classNames([
    'number',
    'secure-field-input',
    'userText',
    {
      'secure-field--beforeInitialFocus': !field.hasBeenFocused,
      'secure-field--focused': focused,
      'secure-field--valid': field.valid,
      'secure-field--invalid': field.valid === false
    }
  ]);

  const fieldClass = classNames([
    'fieldset',
    'fieldset--dynamic',
    field.placeholderElementId,
    className,
    {
      'fieldset--outsideIcon': isOutsideIcon,
      'fieldset--insideIcon': !isOutsideIcon,
      'fieldset--hasIcon': icon,
      notEmpty: notEmpty
    }
  ]);

  return (
    <div
      className={fieldClass}
      style={iconWidth && { '--iconWidth': `${iconWidth}px` }}
      id={`${field.placeholderElementId}-container`}
      onClick={handleFocus}
    >
      <div className="fieldset__iconAndInput">
        {icon && (
          <div className="fieldset__icon" onClick={(e) => e.stopPropagation()}>
            {icon}
          </div>
        )}
        <div className={inputClass} id={field.placeholderElementId} />
        <label className="fieldset__label fieldset__label--pciProxy">{field.label}</label>
      </div>
      <AccordionVertical
        childKey={`${field.placeholderElementId}-errorMsg`}
        className="errorMsg-container"
      >
        {field.valid === false ? (
          <label
            htmlFor={field.placeholderElementId}
            className="errorMsg"
            key={`${field.placeholderElementId}-errorMsg`}
            role="alert"
          >
            <span>{field.error}</span>
          </label>
        ) : null}
      </AccordionVertical>
    </div>
  );
};

export default memo(SecureField);
