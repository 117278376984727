import React, { useCallback, useEffect, useLayoutEffect, useRef, useState } from 'react';
import { createPortal } from 'react-dom';
import PropTypes from 'prop-types';
import { Transition } from 'react-transition-group';
import { debounce } from 'lodash';
import cc from 'classcat';
import withLazyStyle from 'components/LazyStyle';
import style from './tooltip.css?lazy';

const TOOLTIP_OFFSET = 12;
const DEBOUNCE_TIME = 100;

const getCoordsForPosition = (position, rect, tooltipRect, scrollXOffset, scrollYOffset) => {
  let top;
  let left;
  let arrowLeft = 0;

  // above
  if (position === 'above') {
    top = rect.top + scrollYOffset - tooltipRect.height - TOOLTIP_OFFSET;
    left = rect.left + scrollXOffset + rect.width / 2 - tooltipRect.width / 2;
  }
  // below
  if (position === 'below') {
    top = rect.top + scrollYOffset + rect.height + TOOLTIP_OFFSET;
    left = rect.left + scrollXOffset + rect.width / 2 - tooltipRect.width / 2;
  }
  // left
  if (position === 'left') {
    top = rect.top + scrollYOffset + rect.height / 2 - tooltipRect.height / 2;
    left = rect.left + scrollXOffset - tooltipRect.width - TOOLTIP_OFFSET;
  }
  // right
  if (position === 'right') {
    top = rect.top + scrollYOffset + rect.height / 2 - tooltipRect.height / 2;
    left = rect.left + scrollXOffset + rect.width + TOOLTIP_OFFSET;
  }

  // if the tooltip overflows on the left side
  if (left < 0) {
    arrowLeft = left;
    left = 0;
  }
  // if the tooltip overflows on the right side
  if (left + (tooltipRect?.width || 0) > window.innerWidth) {
    const newLeft = window.innerWidth - (tooltipRect?.width || 0);
    arrowLeft = left - newLeft;
    left = newLeft;
  }
  return { top, left, arrowLeft };
};

const Tooltip = ({
  label,
  position,
  children,
  scrollRef,
  showOnClick,
  forceShow,
  showOnOverflow,
  showDelay,
  hideDelay,
  className,
  level
}) => {
  const element = useRef(document.createElement('div'));
  const tooltipContainer = useRef();
  const tooltip = useRef();
  const [isVisible, setVisible] = useState(false);
  const [positionInfo, setPositionInfo] = useState({});
  const showTimeout = useRef(0);
  const hideTimeout = useRef(0);
  const _handleScrollAndResize = useRef();

  /**
   * Returns true when we are expecting the container to overflow and it doesn't.
   * Added the minus two to scrollHeight to treat false positive edge case caused by the browser's calculation of scrollHeight based on some font properties.
   * Detailed explanation here: https://code-examples.net/en/q/325e78e
   * For example: For font-size:16px, line-height:1 (16px), scrollHeight is calculated at (18) and the clientHeight is the line height value (16)
   * When the line-height is below 1 or the font-size gets bigger the difference between scrollHeight and clientHeight increases.
   */
  const shouldAndDoesntOverflow = () =>
    showOnOverflow &&
    tooltipContainer.current.scrollWidth <= tooltipContainer.current.clientWidth &&
    tooltipContainer.current.scrollHeight - 2 <= tooltipContainer.current.clientHeight;

  const draw = useCallback(() => {
    if (tooltip.current) {
      const rect = tooltipContainer?.current?.getBoundingClientRect();
      const tooltipRect = tooltip?.current?.getBoundingClientRect();
      const scrollXOffset =
        (scrollRef?.current || window).scrollX || (scrollRef?.current || window).pageXOffset;
      const scrollYOffset =
        (scrollRef?.current || window).scrollY || (scrollRef?.current || window).pageYOffset;

      let finalPosition = position;
      let { top, left, arrowLeft } = getCoordsForPosition(
        finalPosition,
        rect,
        tooltipRect,
        scrollXOffset,
        scrollYOffset
      );

      if (arrowLeft !== 0 && (position === 'left' || position === 'right')) {
        finalPosition = 'above';
        ({ top, left, arrowLeft } = getCoordsForPosition(
          finalPosition,
          rect,
          tooltipRect,
          scrollXOffset,
          scrollYOffset
        ));
      }

      setPositionInfo({ top, left, arrowLeft, finalPosition });
    }
  }, [position, scrollRef]);

  useEffect(() => {
    _handleScrollAndResize.current = draw;
  }, [draw]);

  const handleMouseClick = () => {
    if (!showOnClick || !label || shouldAndDoesntOverflow()) {
      return;
    }
    setVisible(!isVisible);
  };

  const handleMouseEnter = () => {
    if (showOnClick || forceShow || !label || shouldAndDoesntOverflow()) {
      return;
    }
    clearTimeout(hideTimeout.current);
    if (showDelay) {
      showTimeout.current = setTimeout(() => setVisible(true), showDelay);
    } else {
      setVisible(true);
    }
  };

  const handleMouseLeave = () => {
    if (showOnClick || forceShow || !label || shouldAndDoesntOverflow()) {
      return;
    }
    clearTimeout(showTimeout.current);
    if (hideDelay) {
      hideTimeout.current = setTimeout(() => setVisible(false), hideDelay);
    } else {
      setVisible(false);
    }
  };

  const handleOutsideClick = ({ target }) => {
    if (tooltipContainer.current.contains(target)) {
      return;
    }
    setVisible(false);
  };

  // TODO: @GeKorm extract to custom hook or install package
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleScrollAndResize = useCallback(
    debounce(() => {
      if (_handleScrollAndResize) _handleScrollAndResize.current();
    }, DEBOUNCE_TIME),
    []
  );

  // Unmount
  useEffect(
    () => () => {
      handleScrollAndResize.cancel();
      clearTimeout(hideTimeout.current);
      clearTimeout(showTimeout.current);
    },
    [handleScrollAndResize]
  );

  useLayoutEffect(() => {
    draw();
  }, [draw, label, isVisible]);

  useEffect(() => {
    const elementRef = element.current;
    document.getElementById('tooltipPortal').appendChild(elementRef);

    return () => {
      document.getElementById('tooltipPortal')?.removeChild(elementRef);
    };
  }, []);

  useEffect(() => {
    const scrollContainer = scrollRef?.current || window;
    if (isVisible) {
      scrollContainer.addEventListener('scroll', handleScrollAndResize, {
        passive: true
      });
      window.addEventListener('resize', handleScrollAndResize, { passive: true });
      if (showOnClick) {
        document.addEventListener('mousedown', handleOutsideClick);
      }
    }
    return () => {
      scrollContainer.removeEventListener('scroll', handleScrollAndResize);
      window.removeEventListener('resize', handleScrollAndResize);
      if (showOnClick) {
        document.removeEventListener('mousedown', handleOutsideClick);
      }
    };
  }, [isVisible, handleScrollAndResize, scrollRef, showOnClick]);

  useEffect(() => {
    if (forceShow) {
      setVisible(!!label);
    }
  }, [forceShow, label]);

  /**
   * Use Transition component instead of CSSTransition component so that we can easily implement custom animations if needed.
   * For example:
   * - changing the animation duration.
   * - changing the animation type from opacity to scale.
   */
  const defaultStyle = {
    transition: `opacity 300ms ease`,
    opacity: 1
  };

  const transitionStyles = {
    entering: { opacity: 0 },
    entered: { opacity: 1 },
    exiting: { opacity: 0 },
    exited: { opacity: 0 }
  };

  const tooltipClasses = cc(['tooltip', className, { 'tooltip--ellipsis': showOnOverflow }]);

  const tooltipContentClasses = cc([
    'tooltip__content',
    `tooltip__content--${positionInfo.finalPosition}`,
    `tooltip__content--${level}`
  ]);

  return (
    <div
      ref={tooltipContainer}
      className={tooltipClasses}
      onClick={handleMouseClick}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      {children}
      {createPortal(
        <Transition
          in={isVisible}
          timeout={{ appear: 0, enter: 0, exit: 300 }}
          unmountOnExit
          mountOnEnter
        >
          {(state) => (
            <div
              ref={tooltip}
              className={tooltipContentClasses}
              style={{
                top: positionInfo.top,
                left: positionInfo.left,
                ...defaultStyle,
                ...transitionStyles[state]
              }}
            >
              {label}
              <span className="tooltip__arrow" style={{ marginLeft: positionInfo.arrowLeft }} />
            </div>
          )}
        </Transition>,
        element.current
      )}
    </div>
  );
};

Tooltip.propTypes = {
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  position: PropTypes.oneOf(['above', 'below', 'left', 'right']),
  children: PropTypes.node,
  scrollRef: PropTypes.exact({ current: PropTypes.node }),
  showOnClick: PropTypes.bool,
  forceShow: PropTypes.bool,
  showOnOverflow: PropTypes.bool,
  showDelay: PropTypes.number,
  hideDelay: PropTypes.number,
  className: PropTypes.string,
  level: PropTypes.oneOf(['info', 'warn', 'danger'])
};

Tooltip.defaultProps = {
  position: 'right',
  scrollRef: null,
  label: null,
  children: null,
  showOnClick: false,
  forceShow: false,
  showOnOverflow: false,
  showDelay: 0,
  hideDelay: 100,
  className: null,
  level: 'info'
};

export default withLazyStyle(style)(Tooltip);
