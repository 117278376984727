export enum PANEL_SIDE {
  TOP = 'top',
  RIGHT = 'right',
  BOTTOM = 'bottom',
  LEFT = 'left'
}

export enum PANEL_CORNER {
  TOP_LEFT = 'topLeft',
  TOP_RIGHT = 'topRight',
  RIGHT_TOP = 'rightTop',
  RIGHT_BOTTOM = 'rightBottom',
  BOTTOM_RIGHT = 'bottomRight',
  BOTTOM_LEFT = 'bottomLeft',
  LEFT_BOTTOM = 'leftBottom',
  LEFT_TOP = 'leftTop'
}

export const PANEL_CORNERS: PANEL_CORNER[] = Object.values(PANEL_CORNER);

export const PANEL_CORNER_SIDE = {
  [PANEL_CORNER.TOP_LEFT]: PANEL_SIDE.TOP,
  [PANEL_CORNER.TOP_RIGHT]: PANEL_SIDE.TOP,
  [PANEL_CORNER.RIGHT_TOP]: PANEL_SIDE.RIGHT,
  [PANEL_CORNER.RIGHT_BOTTOM]: PANEL_SIDE.RIGHT,
  [PANEL_CORNER.BOTTOM_RIGHT]: PANEL_SIDE.BOTTOM,
  [PANEL_CORNER.BOTTOM_LEFT]: PANEL_SIDE.BOTTOM,
  [PANEL_CORNER.LEFT_BOTTOM]: PANEL_SIDE.LEFT,
  [PANEL_CORNER.LEFT_TOP]: PANEL_SIDE.LEFT
} as const;

export const PANEL_SIDE_OPPOSITE = {
  [PANEL_SIDE.TOP]: PANEL_SIDE.BOTTOM,
  [PANEL_SIDE.RIGHT]: PANEL_SIDE.LEFT,
  [PANEL_SIDE.BOTTOM]: PANEL_SIDE.TOP,
  [PANEL_SIDE.LEFT]: PANEL_SIDE.RIGHT
} as const;
