import { nanoid } from 'nanoid';
import Modals from 'modules/Modals';
import notificationUserPrompts from './src/notificationUserPrompts';
import * as promptActionCreators from './src/promptActionCreators';

const NOTIFY = '221IV24E06';

export function _getSeverity(code) {
  if (code < 2000) {
    return 1;
  } else if (code < 3000) {
    return 2;
  } else if ((code >= 3000 && code < 4000) || code >= 5000) {
    return 3;
  } else {
    return 1;
  }
}

export const _createGetPrompt = (dispatch, prompts) => (action) => {
  const { code, prompt } = action.meta;
  if (!code) return null;
  if (prompt) {
    const [actionText, actionCreatorName, ...args] = prompt;
    const userAction = promptActionCreators[actionCreatorName](action, ...args);
    if (!actionText) {
      dispatch(userAction);
      return false;
    }
    return [actionText, userAction];
  }
  return notificationUserPrompts[code] && notificationUserPrompts[code](action);
};

export function notificationMiddleware(prompts = notificationUserPrompts) {
  return (store) => {
    const getPrompt = _createGetPrompt(store.dispatch, prompts);
    return (next) => (action) => {
      if (action.type === Modals.actionTypes.T.CREATE && action.payload.notification) {
        setTimeout(() => store.dispatch(Modals.actions.open(action.payload.name)), 0);
      }
      if (!action.meta) {
        return next(action);
      }
      // TODO: Rename expiration to duration
      const {
        visible,
        msg,
        code,
        globally,
        msgRef,
        expiration = 5000,
        announcement = false,
        severity
      } = action.meta;
      if (msg && visible && (globally || msgRef === NOTIFY)) {
        store.dispatch(
          Modals.actions.create(nanoid(), {
            notification: true,
            creationDate: Date.now(),
            message: msg,
            expiration: expiration,
            announcement: announcement,
            pinned: true,
            blocking: false,
            severity: severity || _getSeverity(code),
            prompt: getPrompt(action),
            destroyOnClose: true
          })
        );
      }
      return next(action);
    };
  };
}
