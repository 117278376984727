import Slot, { actionTypes as slotTypes } from 'modules/Slot';
import Modals from 'modules/Modals';

/**
 * THE PAYLOAD DATA ARE THE JUST DEFAULT VALUES. The (pre-translated) action's payload is MERGED INTO the translation
 * ONLY add mappings for internal <-> external actions. Never external <-> external or internal <-> internal
 * keyMapping defines the **bidirectional** translation of the event/action keys.
 *
 * This is 2 way mapping!
 */

export default {
  keyMapping: {
    type: 'event',
    flattenPayload: false
  },
  entities: {
    // << incoming
    'EVO:APPLICATION_READY': (dispatch, { meta }) => {
      dispatch(Slot.actions.gameReady(meta.refName));
      dispatch(Slot.actions.subscribe(meta.provider));
    },
    'EVO:GAME_LIFECYCLE': (dispatch, { payload }) =>
      dispatch({
        type: payload.interruptAllowed ? slotTypes.T.ROUND_ENDED : slotTypes.T.ROUND_STARTED
      }),
    'EVO:RETURN_HOME': (dispatch, action) => dispatch(Slot.actions.closeGame(action)),
    'EVO:SESSION_INVALID': (dispatch, action) => dispatch(Slot.actions.closeGame(action)),
    'EVO:SHOW_DEPOSIT': (dispatch) => dispatch(Modals.actions.open('deposit')),
    // outgoing >>
    [slotTypes.T.SUBSCRIBE]: {
      command: 'EVO:EVENT_SUBSCRIBE',
      event: 'EVO:GAME_LIFECYCLE'
    },
    [slotTypes.T.STOP_AUTOPLAY]: {
      command: 'EVO:STOP_AUTOPLAY'
    }
  }
};
