import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { isNil, isEmpty } from 'lodash';
import { Switch, Route, Redirect } from 'react-router-dom';
import loadable from '@loadable/component';
import { compose } from 'redux';
import { withRouter } from 'react-router';
import { isLoading } from 'lib/redux-utils';
import { actionTypes as userTypes } from 'modules/User';
import Analytics from 'modules/Analytics';
import Auth, { actionTypes as authTypes } from 'modules/Auth';
import FullscreenLoading from 'components/Loading/Fullscreen';
import App from 'App';
import Public from 'Public';
import EcospendTransaction from 'components/DepositEcospendFlow/EcospendTransaction';
import HooyuSuccess from 'components/HooyuSuccess';

const LiveChat = loadable(() => import('components/LiveChat'));

const isAuthenticating = (state) =>
  isNil(state.Auth.authenticated) ||
  isLoading(state, [userTypes.AT.GET_CURRENT._, authTypes.AT.CHECK_AUTH._]);

class BaseRoutes extends Component {
  static getDerivedStateFromProps(
    { authenticated, pathname, search, hash },
    { authenticated: wasAuthenticated }
  ) {
    const result = {};

    if (authenticated !== wasAuthenticated) {
      result.authenticated = authenticated;
    }
    if (authenticated !== true && pathname.startsWith('/secure') && !search.includes('redirect=')) {
      result.newSearch = `?redirect=${encodeURIComponent(pathname + search + hash)}`;
    }
    if (wasAuthenticated && !authenticated) {
      result.reloading = true;
    }

    return isEmpty(result) ? null : result;
  }

  constructor(props) {
    super(props);
    this.state = { newSearch: '', reloading: false, authenticated: props.authenticated };
  }

  componentDidUpdate({ authenticated: wasAuthenticated }) {
    const { authenticated, trackLogout } = this.props;
    // on logout anonymize
    if (wasAuthenticated && !authenticated) {
      // Username and other info: undefined so they get reset in the datalayer
      // Otherwise you’ll be logged out but datalayer will still have the username and ref
      trackLogout({ authenticated: false });

      window.location.reload();
    }
  }

  render() {
    const { authenticated, authenticating, search } = this.props;
    const { newSearch, reloading } = this.state;

    return (
      <>
        {authenticated ? (
          <Switch>
            <Route path="/secure/hooyu" component={HooyuSuccess} exact />
            <Route path="/secure/ecospend" component={EcospendTransaction} exact />
            <Route path="/secure/*" component={App} />
            <Redirect exact from="*" to={`/secure/lobby${search}`} />
          </Switch>
        ) : !authenticated && !authenticating && !reloading ? (
          <Switch>
            <Route path="/secure/hooyu" component={HooyuSuccess} exact />
            <Route path="/secure/ecospend" component={EcospendTransaction} exact />
            {!search.includes('redirect=') && newSearch && newSearch !== search ? (
              <Redirect from="/login" to={`/login${newSearch}`} />
            ) : null}
            <Route component={Public} />
          </Switch>
        ) : (
          <FullscreenLoading />
        )}
        {__ENV__.ZENDESK_WEB_WIDGET_KEY ? <LiveChat /> : null}
      </>
    );
  }
}

BaseRoutes.propTypes = {
  pathname: PropTypes.string.isRequired,
  search: PropTypes.string,
  authenticated: PropTypes.bool,
  authenticating: PropTypes.bool.isRequired,
  trackLogout: PropTypes.func.isRequired
};

BaseRoutes.defaultProps = {
  search: undefined,
  authenticated: null
};

const mapStateToProps = (state, { location: { search, pathname, hash } }) => ({
  pathname,
  search,
  hash,
  authenticated: Auth.selectors.get(state).authenticated,
  authenticating: isAuthenticating(state)
});

const mapDispatchToProps = (dispatch) => ({
  trackLogout: (user) => dispatch(Analytics.actions.trackLogout(user))
});

export default compose(withRouter, connect(mapStateToProps, mapDispatchToProps))(BaseRoutes);
