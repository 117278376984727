import React, { useCallback } from 'react';
import Live from 'modules/Live';
import { useAppDispatch, useAppSelector } from 'hooks/redux';
import type { Section } from 'modules/Lobby';
import Analytics from 'modules/Analytics';
import LiveTile from 'components/LiveTileV2';

interface LiveGameProps {
  refName: string;
  category: string;
}

const LiveGame = ({ refName, category }: LiveGameProps) => {
  const dispatch = useAppDispatch();
  const { name, lobbyImageUrl, tileInfo, blur, provider, platform } = useAppSelector((state) =>
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return -- Redux modules missing full TS support */
    Live.selectors.getRoom(state, refName)
  );
  const { minBet, playerCount, displayProvider, takenSeats, tileType, results, dealer } =
    tileInfo || {};

  const tile = {
    refName,
    blur,
    title: name,
    minBet,
    dealer,
    tileType,
    playerCount,
    results,
    takenSeats: takenSeats,
    imageURL: lobbyImageUrl,
    providerLabel: displayProvider
  };

  const trackGameLaunched = useCallback(() => {
    dispatch(
      Analytics.actions.gameLaunched({
        refName,
        name,
        provider,
        section: 'live' as Section,
        platform,
        source: 'Lobby',
        category
      })
    );
  }, [dispatch, name, platform, provider, refName, category]);

  return <LiveTile tile={tile} path={`/secure/games/${refName}`} onClick={trackGameLaunched} />;
};

export default LiveGame;
