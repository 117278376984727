import { nanoid } from 'nanoid';
import Cookies from 'js-cookie';
import importScript from 'lib/importScript';

let cachedToken = null;
const UUID = nanoid();
const COOKIE_KEY = 'SeonSession';
const WAITING_TIMEOUT = 2 * 1000;
const sim = __ENV__.MRQ_SIMULATION === 'true';
const seonHosts = ['seondf.com', 'deviceinf.com', 'getdeviceinf.com'];
let selectedSeonHost = seonHosts[0];

export class Seon {
  timeoutId = null;

  constructor() {
    this.loadDeviceFingerprint();
  }

  getCookieSessionId = () => {
    const cookieSessionId = Cookies.get(COOKIE_KEY);

    if (cookieSessionId) {
      return cookieSessionId;
    }

    Cookies.set(COOKIE_KEY, UUID);
    return UUID;
  };

  getSeonUrl = (proxy) =>
    proxy
      ? `/assets/external/seon/${selectedSeonHost}/v5/agent.js`
      : `https://cdn.${selectedSeonHost}/js/v5/agent.js`;

  getSeonToken = (seon, sessionId) =>
    new Promise((resolve, reject) => {
      if (this.timeoutId) {
        clearTimeout(this.timeoutId);
      }
      this.timeoutId = setTimeout(() => {
        if (!cachedToken) {
          reject();
        }
      }, WAITING_TIMEOUT);

      if (cachedToken) {
        clearTimeout(this.timeoutId);
        resolve(cachedToken);
      } else {
        seon.config({
          host: selectedSeonHost,
          session_id: sessionId,
          audio_fingerprint: true,
          canvas_fingerprint: true,
          webgl_fingerprint: true,
          onSuccess: () => {
            seon.getBase64Session((data) => {
              clearTimeout(this.timeoutId);
              if (data) {
                cachedToken = data;
                resolve(data);
              } else {
                reject();
              }
            });
          },
          onError: () => {
            clearTimeout(this.timeoutId);
            reject();
          }
        });
      }
    });

  loadDeviceFingerprint = async () => {
    try {
      if (selectedSeonHost) {
        return await importScript(
          sim ? 'https://cdn.seon.io/js/v5/agent.js' : this.getSeonUrl(),
          'seon'
        );
      }
      return null;
    } catch (e) {
      const index = seonHosts.indexOf(selectedSeonHost);
      selectedSeonHost = index > -1 ? seonHosts[index + 1] : null;
      return this.loadDeviceFingerprint();
    }
  };

  getDeviceFingerprint = async () => {
    try {
      const seon = await this.loadDeviceFingerprint();
      const sessionId = this.getCookieSessionId();
      const res = await this.getSeonToken(seon, sessionId);
      return res;
    } catch (e) {
      return null;
    }
  };
}
