import React, { Component } from 'react';
import { connect } from 'react-redux';
import FlipMove from 'react-flip-move';
import Modals from 'modules/Modals';
import makeModal from 'components/Modals';
import { easing } from 'lib/constants';
import Notification from './Notification';

const INITIAL_DURATION = 450;

class _Announcements extends Component {
  render() {
    const { notifications } = this.props;
    const notificationModals =
      notifications &&
      notifications.length > 0 &&
      notifications
        .map((notification) => {
          const Modal = makeModal(
            notification.name,
            {
              name: 'fake-transition',
              unmountOnExit: true,
              timeout: INITIAL_DURATION
            },
            { swipeable: true }
          )(Notification);
          return notification.visible && notification.announcement ? (
            <Modal
              key={notification.name}
              message={notification.message}
              severity={notification.severity}
              prompt={notification.prompt}
            />
          ) : null;
        })
        .filter(Boolean);
    return (
      <div className="announcements">
        <FlipMove
          delay={0}
          duration={INITIAL_DURATION}
          easing={easing}
          staggerDelayBy={80}
          className="notifications__list"
          leaveAnimation
          enterAnimation
          appearAnimation
        >
          {notificationModals}
        </FlipMove>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  notifications: Modals.selectors.getNotifications(state)
});

const mapDispatchToProps = (dispatch) => ({
  open: (name) => dispatch(Modals.actions.open(name))
});

export default connect(mapStateToProps, mapDispatchToProps)(_Announcements);
