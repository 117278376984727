import React, { memo } from 'react';
import loadable from '@loadable/component';
import type {
  GameTile as GameTileType,
  SlotTile as SlotTileType,
  LiveTile as LiveTileType
} from 'lib/LobbyEntities';
import isTileOfType, { shapeSets } from '../../helpers/isTileOfType';
import LiveTile from '../LiveTile';
import { TileSkeleton } from '../Skeleton';

const SlotTile = loadable(
  () => import(/* webpackPrefetch: true, webpackChunkName: "LobbySlotTile" */ '../SlotTile'),
  {
    fallback: <TileSkeleton className="dynamicLobby__skeleton-slotTile" />
  }
);

interface GameTileProps {
  entity: GameTileType;
  position: number;
}

const GameTile = ({ entity: tile, position }: GameTileProps) => (
  <>
    {isTileOfType<SlotTileType>(tile, shapeSets.slot) && (
      <SlotTile entity={tile} position={position} />
    )}
    {isTileOfType<LiveTileType>(tile, shapeSets.live) && (
      <LiveTile entity={tile} position={position} />
    )}
  </>
);

export default memo(GameTile);
