import React from 'react';

const RewardOfferCardLoading = () => (
  <div className="offerCard">
    <div className="offerCard__image">
      <div className="offerCard__loadingBlock offerCard__loadingImg">
        <div className="shine shine--neutral10" />
      </div>
    </div>
    <div className="offerCard__content">
      <h6 className="offerCard__textLoading offerCard__textLoading--heading">
        <div className="shine shine--neutral10" />
      </h6>
      <div className="offerCard__textLoading">
        <div className="shine shine--neutral10" />
      </div>
      <div className="offerCard__textLoading offerCard__textLoading--tc">
        <div className="shine shine--neutral10" />
      </div>
      <div className="offerCard__cta">
        <div className="offerCard__buttonLoading">
          <div className="shine shine--neutral10" />
        </div>
        <div className="offerCard__buttonLoading">
          <div className="shine shine--neutral10" />
        </div>
      </div>
    </div>
  </div>
);

export default RewardOfferCardLoading;
