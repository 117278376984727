export const getRefName = (action) =>
  action && action.meta && action.meta.args && action.meta.args.refName;
export const getInstance = (action) =>
  (action &&
    action.meta &&
    action.meta.args &&
    (action.meta.args.instance || action.meta.instanceIdentifier)) ||
  undefined;
export const getWinningUsernames = (action) =>
  (action && action.payload && action.payload.winners && Object.keys(action.payload.winners)) ||
  undefined;
export const getAmount = (action) =>
  (action && action.payload && action.payload.amount) || undefined;
export const getWinners = (action) =>
  (action && action.payload && action.payload.winners) || undefined;
