import React from 'react';
import cc from 'classcat';
import { constants } from 'modules/User';

interface StatusMarkerProps {
  rewardStatus: constants.RewardStatus;
}

const StatusMarker = ({ rewardStatus }: StatusMarkerProps) => {
  const markerClasses = cc([
    'marker',
    rewardStatus === constants.RewardStatus.Seen && 'marker--hidden',
    rewardStatus === constants.RewardStatus.Unseen && 'marker--small'
  ]);
  const dotClasses = cc([
    'dot',
    rewardStatus === constants.RewardStatus.Unseen && 'dot--red',
    constants.RewardStatus.Locked && 'dot--black'
  ]);

  return (
    <div className={markerClasses}>
      <div className={dotClasses}>
        {rewardStatus === constants.RewardStatus.Locked && (
          <img alt="lock icon" className="marker__icon" src="/assets/images1/dfg-lock.svg" />
        )}
      </div>
    </div>
  );
};

export default StatusMarker;
