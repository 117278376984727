import { actionTypeCreator, SYNC, ASYNC } from 'lib/redux-utils';
import { NAME } from './constants';

const namespace = actionTypeCreator(NAME);

export const AT = namespace({
  REFRESH: ASYNC,
  BY_REF: ASYNC,
  ASSETS: ASYNC,
  LIST: ASYNC,
  LIST_ASSETS: ASYNC,
  LIST_PROVIDERS: ASYNC,
  PROVIDER_BY_REF: ASYNC,
  ROOM: ASYNC,
  WS_SUBSCRIBE: ASYNC,
  WS_UNSUBSCRIBE: ASYNC
});

export const T = namespace({
  STOP_AUTOPLAY: SYNC,
  START_AUTOPLAY: SYNC,
  FRAME_RESIZED: SYNC,
  FRAME_ORIENTATION_CHANGED: SYNC,
  SUBSCRIBE: SYNC,
  ROUND_STARTED: SYNC,
  ROUND_ENDED: SYNC,
  GAME_READY: SYNC
});
