import React, { forwardRef, memo, useEffect, useRef, useState, useCallback } from 'react';
import cc from 'classcat';
import PropTypes from 'prop-types';
import FlipMove from 'react-flip-move';
import { isFunction } from 'lodash';
import Button from 'components/Button';
import ClickOutside from 'components/ClickOutside';
import { easing as defaultEasing } from 'lib/constants';
import { useFuseSearch } from 'hooks/useFuseSearch';
import withLazyStyle from 'components/LazyStyle';
import './searchableDropdown.css';
import style from 'components/Dropdown/dropdown.css?lazy';

const nextId = (array, current) => {
  const index = array.findIndex((e) => e.refIndex === current);
  return array.length > index + 1 ? array[index + 1].refIndex : array[0].refIndex;
};

const prevId = (array, current) => {
  const index = array.findIndex((e) => e.refIndex === current);
  return index - 1 >= 0 ? array[index - 1].refIndex : array[array.length - 1].refIndex;
};

const DropdownOptions = memo(
  forwardRef((props, ref) => (
    <div
      className="dropdown__options dropdown__options--menu"
      key="options"
      role="listbox"
      ref={ref}
    >
      {props.children}
    </div>
  ))
);

const SearchableDropdown = ({
  easing,
  duration,
  OptionComponent,
  options,
  onSelect,
  onClear,
  label,
  fuseConfigOptions: config
}) => {
  // Refer to fuse.js docs for "fuseConfigOptions" prop: https://fusejs.io/api/options.html#keys
  const [isFocused, setIsFocused] = useState(false);
  const [focusedIndex, setFocusedIndex] = useState(null);
  const [selectedIndex, setSelectedIndex] = useState(null);
  const [query, setQuery] = useState('');
  const {
    result: fuseSearchResult,
    updateQuery: setFuseQuery,
    updateOptions: setFuseOptions
  } = useFuseSearch({ options, config, query });

  const inputRef = useRef();
  const containerRef = useRef();
  const focusedRef = useRef();

  useEffect(() => {
    setFuseOptions(options);
  }, [options, setFuseOptions]);

  const updateQuery = useCallback(
    (newQuery) => {
      setQuery(newQuery);
      setFuseQuery(newQuery);
    },
    [setFuseQuery]
  );

  const handleFocusNext = useCallback(() => {
    if (fuseSearchResult.length) {
      if (focusedIndex === null) {
        setFocusedIndex(fuseSearchResult[0].refIndex);
      } else {
        setFocusedIndex(nextId(fuseSearchResult, focusedIndex));
      }
    }
  }, [focusedIndex, setFocusedIndex, fuseSearchResult]);

  const handleFocusPrev = useCallback(() => {
    if (fuseSearchResult.length) {
      if (focusedIndex === null) {
        setFocusedIndex(fuseSearchResult[fuseSearchResult.length - 1].refIndex);
      } else {
        setFocusedIndex(prevId(fuseSearchResult, focusedIndex));
      }
    }
  }, [focusedIndex, setFocusedIndex, fuseSearchResult]);

  const handleBlur = useCallback(() => {
    setIsFocused(null);
    setFocusedIndex(null);
    inputRef.current?.blur();
  }, []);

  const handleChangeQuery = useCallback(
    (e) => {
      updateQuery(e.target.value);
      setFocusedIndex(null);
    },
    [updateQuery]
  );

  const handlePick = useCallback(
    (index) => {
      setSelectedIndex(index);
      handleBlur();
      if (isFunction(onSelect)) onSelect(options[index]);
    },
    [onSelect, handleBlur, options]
  );

  const handleClearPick = useCallback(() => {
    setFocusedIndex(null);
    setSelectedIndex(null);
    setIsFocused(true);
    updateQuery('');
    if (isFunction(onClear)) onClear();
    inputRef.current?.focus();
  }, [updateQuery, onClear]);

  // When using keyboard to navigate, scroll OptionElement in view if isn't already
  useEffect(() => {
    if (focusedRef.current) {
      focusedRef.current.scrollIntoView(false);
    }
  }, [focusedIndex]);
  // Keyboard Navigation
  useEffect(() => {
    const handleKeyDown = (e) => {
      switch (e.key) {
        case 'ArrowUp':
          handleFocusPrev();
          break;
        case 'ArrowDown':
          handleFocusNext();
          break;
        case 'Enter':
          if (isFocused) e.preventDefault();
          if (focusedIndex !== null) handlePick(focusedIndex);
          break;
        case 'Escape':
        case 'Tab':
          handleBlur();
          break;
        default:
          break;
      }
    };
    document.addEventListener('keydown', handleKeyDown);
    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [focusedIndex, handleFocusNext, handleFocusPrev, handleBlur, isFocused, handlePick]);

  const containerClasses = cc([
    'dropdown',
    'fieldset--dynamic',
    'searchableDropdown',
    (selectedIndex !== null || query.length) && 'notEmpty'
  ]);

  return (
    <ClickOutside onClickOutside={handleBlur}>
      <div className={containerClasses} ref={containerRef}>
        <input
          className={cc(['userText', selectedIndex !== null && 'virtualHide'])}
          name="searchableDropdownInput"
          id="searchableDropdownInput"
          type="text"
          autoComplete="off"
          placeholder=""
          data-test-id="searchableDropdownInput"
          disabled={selectedIndex !== null}
          onChange={handleChangeQuery}
          onFocus={() => setIsFocused(true)}
          value={query}
          ref={inputRef}
        />
        {selectedIndex !== null && (
          <OptionComponent classes={['selected']} data={options[selectedIndex]} />
        )}
        <div className="valueActions">
          {!(selectedIndex === null && !query.length) && (
            <Button
              name="clear"
              id="searchableDropdown-clear"
              onClick={handleClearPick}
              className="button__grey button__smaller button"
              icon="/assets/images1/close-primary.svg"
            />
          )}
        </div>
        <label className="fieldset__label" htmlFor="searchableDropdownInput">
          {label}
        </label>
        <div className="dropdown__absolute-container searchableDropdown__absolute" role="listbox">
          <FlipMove
            duration={duration}
            easing={easing}
            appearAnimation="accordionVertical"
            enterAnimation="accordionVertical"
            leaveAnimation="accordionVertical"
          >
            {isFocused && (
              <DropdownOptions>
                {fuseSearchResult.map((e) => (
                  <OptionComponent
                    classes={[focusedIndex === e.refIndex && 'focused']}
                    data={e.item}
                    key={e.refIndex}
                    ref={focusedIndex === e.refIndex ? focusedRef : undefined}
                    onClick={() => handlePick(e.refIndex)}
                  />
                ))}
                {fuseSearchResult?.length === 0 && (
                  <div key="notfound" className="notFound">
                    Not found
                  </div>
                )}
              </DropdownOptions>
            )}
          </FlipMove>
        </div>
      </div>
    </ClickOutside>
  );
};

SearchableDropdown.defaultProps = {
  easing: defaultEasing,
  duration: 250,
  options: [],
  onSelect: null,
  onClear: null,
  label: '',
  fuseConfigOptions: {}
};

SearchableDropdown.propTypes = {
  duration: PropTypes.number,
  easing: PropTypes.string,
  OptionComponent: PropTypes.elementType.isRequired,
  options: PropTypes.array,
  onSelect: PropTypes.func,
  onClear: PropTypes.func,
  label: PropTypes.string,
  fuseConfigOptions: PropTypes.object
};

export default withLazyStyle(style)(SearchableDropdown);
