import React, { memo } from 'react';
import Button from 'components/Button';
import FormError from 'components/FormError';
import { NO_LIMIT } from 'lib/depositLimit';
import type { getFormatted } from './helpers';

interface AcceptViewProps {
  values: { current: number | null; next: number | null };
  formattedValues: ReturnType<typeof getFormatted>;
  // FIXME: Prop drilling, leverage the redux state
  actionRequestErrorMessage: string | null;
  onAcceptLimitRaise: () => void;
  onCancel: () => void;
}

const AcceptView = ({
  values,
  formattedValues: formatted,
  actionRequestErrorMessage,
  onAcceptLimitRaise,
  onCancel
}: AcceptViewProps) => (
  <div>
    {values.next === NO_LIMIT.VALUE ? (
      <h4>Remove your {formatted.interval} deposit limit?</h4>
    ) : (
      <h4>
        Change deposit limit to {formatted.next} a {formatted.period}?
      </h4>
    )}

    <p>
      Your deposit limit is currently{' '}
      <b>
        {formatted.current} a {formatted.period}.
      </b>
    </p>

    {values.next === NO_LIMIT.VALUE ? (
      <p>
        Now, you recently asked us to <b>remove your {formatted.interval} limit.</b>
      </p>
    ) : (
      <p>
        Now, you recently asked us to change it to{' '}
        <b>
          {formatted.next} a {formatted.period}.
        </b>
      </p>
    )}

    <p>Was this a mistake, or would you like to update your limits?</p>

    <FormError errorMessage={actionRequestErrorMessage} />

    <br />

    <form>
      <Button className="submit" onClick={onAcceptLimitRaise} name="acceptLimitChange">
        {values.next === NO_LIMIT.VALUE ? `Remove limit` : `Confirm new ${formatted.next} limit`}
      </Button>
      <Button className="submit button__grey" onClick={onCancel} name="cancelLimitChange">
        Cancel
      </Button>
    </form>
  </div>
);

export default memo(AcceptView);
