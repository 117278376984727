import React, { memo } from 'react';
import cc from 'classcat';
import Markdown from 'components/Markdown';
import useDynamicAction from '../src/useDynamicAction';
import './dialogArticle.css';

export enum Variant {
  NORMAL = 'NORMAL',
  XXS = 'XXS'
}

interface DialogArticleProps {
  /** The markdown text */
  children?: string;
  onLinkClick?: (actionRef?: string) => void;
  variant?: Variant;
}

const DialogArticle = ({ children, onLinkClick, variant = Variant.NORMAL }: DialogArticleProps) => {
  const action = useDynamicAction();

  return (
    <article className={cc(['dialogArticle', `dialogArticle--${variant}`])}>
      <Markdown content={children} onLinkClick={onLinkClick || action} />
    </article>
  );
};

export default memo(DialogArticle);
