import type { ModernNumberFormatOptions } from './types';

let _canUseNarrowSymbol: boolean;

try {
  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    currencyDisplay: 'narrowSymbol',
    maximumFractionDigits: 2,
    minimumFractionDigits: 2
  } as ModernNumberFormatOptions);
  const formatted = formatter.format(1);
  _canUseNarrowSymbol = formatted.startsWith('$1');
} catch (e) {
  /* Istanbul ignore next */
  _canUseNarrowSymbol = false;
}

/**
 * Browser supports the `Intl.Numberformat`
 * [`currencyDisplay: 'narrowSymbol'` option](https://caniuse.com/mdn-javascript_builtins_intl_numberformat_numberformat_options_currencydisplay_parameter)
 */
const canUseNarrowSymbol = _canUseNarrowSymbol;

export default canUseNarrowSymbol;
