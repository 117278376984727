import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Redirect, Route, Switch } from 'react-router-dom';
import { compose } from 'redux';
import { withRouter } from 'react-router';
import { withSentryRouting } from '@sentry/react';
import CarouselModule from 'modules/Carousel';
import CarouselContent from './src/CarouselContent';
import './carousel.css';

const { selectors } = CarouselModule;

// Parameterized transaction names
const SentryRoute = withSentryRouting(Route);

export const _Carousel = ({
  activeTop,
  activeBottomRef,
  match: { path, url },
  location,
  dispatch,
  history,
  staticContext,
  ...contentProps
}) => {
  const urlBottomTab = activeBottomRef ? `/${activeBottomRef}` : '';

  return (
    <Switch>
      <SentryRoute
        sensitive
        path={`${path}/:urlTopTab/:urlBottomTab?`}
        render={(routeProps) => <CarouselContent {...contentProps} {...routeProps} />}
      />
      <Redirect
        from={path}
        to={`${url}/${activeTop}${urlBottomTab}${location.search}${location.hash}`}
      />
    </Switch>
  );
};

_Carousel.propTypes = {
  activeTop: PropTypes.string,
  activeBottomRef: PropTypes.string,
  match: PropTypes.object.isRequired
};

_Carousel.defaultProps = {
  activeTop: null,
  activeBottomRef: null
};

const mapStateToProps = (state, ownProps) => ({
  activeTop: selectors.getActiveTopRef(state, ownProps.name),
  activeBottomRef: selectors.getActiveBottomRef(state, ownProps.name)
});

export default compose(connect(mapStateToProps), withRouter)(_Carousel);
