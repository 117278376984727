import { truncate } from 'lodash';

const BR_TABLET = 1250;
const BR_MOBILE = 570;

function isTouchDevice() {
  return 'ontouchstart' in document.documentElement;
}

const TYPES = Object.freeze({
  MOBILE: 'MOBILE',
  TABLET: 'TABLET',
  DESKTOP: 'DESKTOP'
});

export function detectDevice() {
  const orientation =
    window.screen.orientation || window.screen.mozOrientation || window.screen.msOrientation;
  const isLandscape = orientation && orientation.type && orientation.type.startsWith('landscape');
  const width = isLandscape ? window.outerHeight : window.innerWidth;
  const isTouch = isTouchDevice();

  let deviceType;
  if (width >= BR_TABLET || !isTouch) {
    deviceType = TYPES.DESKTOP;
  } else if (width < BR_TABLET && width >= BR_MOBILE) {
    deviceType = TYPES.TABLET;
  } else {
    deviceType = TYPES.MOBILE;
  }

  return {
    'ws.userAgent': truncate(navigator.userAgent, { length: 500 }),
    'ws.deviceType': deviceType
  };
};
