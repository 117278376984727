import * as t from './actionTypes';

let shakeTimeout;

export const addModifier = (name, modifier) => ({
  type: t.ADD_MODIFIER,
  payload: { name, modifier }
});

export const removeModifier = (name, modifier) => ({
  type: t.REMOVE_MODIFIER,
  payload: { name, modifier }
});

export const clearModifiers = (name) => ({
  type: t.CLEAR_MODIFIERS,
  payload: { name }
});

export const shakeModal = (dispatch) => {
  clearTimeout(shakeTimeout);
  dispatch(addModifier('overflowWrap', 'animate__wrap--shake'));
  setTimeout(() => dispatch(removeModifier('overflowWrap', 'animate__wrap--shake')));
};

export const copy = (payload) => ({
  type: t.COPY,
  payload
});

// WARNING: EXPERIMENTAL, DO NOT USE. PREFER THE addModifer or removeModifier methods
// WARNING: EXPERIMENTAL, DO NOT USE. PREFER THE addModifer or removeModifier methods
// WARNING: EXPERIMENTAL, DO NOT USE. PREFER THE addModifer or removeModifier methods

/**
 * @deprecated
 */
export const setModifier = (name, modifier) => ({
  type: t.SET_MODIFIER,
  payload: { name, modifier }
});

// WARNING: EXPERIMENTAL, DO NOT USE. PREFER THE addModifer or removeModifier methods
// WARNING: EXPERIMENTAL, DO NOT USE. PREFER THE addModifer or removeModifier methods
// WARNING: EXPERIMENTAL, DO NOT USE. PREFER THE addModifer or removeModifier methods
/**
 * @deprecated
 */
export const unsetModifier = (name, modifier) => ({
  type: t.UNSET_MODIFIER,
  payload: { name, modifier }
});
