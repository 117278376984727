/* eslint-disable jsx-a11y/no-noninteractive-tabindex */
import React, { PureComponent, cloneElement } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classcat';
import FlipMove from 'react-flip-move';
import focusNext from 'lib/focusNext';
import withLazyStyle from 'components/LazyStyle';
import amplitude from 'lib/analytics';
import style from './dropdown.css?lazy';

const fromElement = (elem) => ({ target: elem });

const accordionVerticalUpwardsEnter = {
  from: { transform: 'scaleY(0)', transformOrigin: 'center bottom' },
  to: { transform: '', transformOrigin: 'center bottom' }
};
const accordionVerticalUpwardsLeave = {
  from: { transform: 'scaleY(1)', transformOrigin: 'center bottom' },
  to: { transform: 'scaleY(0)', transformOrigin: 'center bottom' }
};

const DEFAULT_UPWARDS_MAX_HEIGHT = 220;

/**
 * @typedef {import("./types").DropdownExpandedAnalytics} DropdownExpandedAnalytics
 */
/**
 * @param {DropdownExpandedAnalytics} properties
 * @returns {import("@amplitude/analytics-types").AmplitudeReturn<import("@amplitude/analytics-types").Result>}
 */
const trackDropdownExpanded = (properties) => amplitude.track('Dropdown Expanded', properties);
/**
 * @typedef {import("./types").DropdownOptionSelectedAnalytics} DropdownOptionSelectedAnalytics
 */
/**
 * @param {DropdownOptionSelectedAnalytics} properties
 * @returns {import("@amplitude/analytics-types").AmplitudeReturn<import("@amplitude/analytics-types").Result>}
 */
const trackDropdownOptionSelected = (properties) =>
  amplitude.track('Dropdown Option Selected', properties);

class Dropdown extends PureComponent {
  constructor(props, context) {
    super(props, context);
    // The current selected
    this.state = {
      selected: this.getInitial(props),
      expanded: props.expanded || false,
      focused: props.expanded || false
    };
  }

  componentDidMount() {
    this.mounted = true;
    const { onChange, name } = this.props;
    const { selected, expanded } = this.state;
    if (selected && onChange && this.elem) {
      onChange(fromElement(this.elem), { value: selected, name: name });
    }
    if (expanded) {
      this.handleClick();
      if (this.elem) {
        this.elem.focus();
      }
    }
    document.addEventListener('keydown', this.handleKeyPress, true);
  }

  static getDerivedStateFromProps({ value }, { selected }) {
    if (value !== selected) {
      return { selected: value };
    }
    return null;
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.expanded !== this.state.expanded && this.state.expanded && this.props.name) {
      trackDropdownExpanded({ 'Dropdown Name': this.props.name });
    }
  }

  componentWillUnmount() {
    this.mounted = false;
    document.removeEventListener('keydown', this.handleKeyPress, true);
  }

  handleKeyPress = (e) => {
    const { children } = this.props;
    const { expanded, selected } = this.state;
    if (!expanded) return false;
    e.stopPropagation();
    e.preventDefault();

    const selectedIndex = children.map((child) => child.key).indexOf(selected);
    if (e.keyCode === 13) {
      const value = children[selectedIndex].key;
      this.handleSelectValue(value);
    }
    if (e.keyCode === 38) {
      if (selectedIndex > 0) {
        this.setState({ selected: children[selectedIndex - 1].key });
      }
    }
    if (e.keyCode === 40) {
      if (selectedIndex < children.length - 1) {
        this.setState({ selected: children[selectedIndex + 1].key });
      }
    }
    if (e.keyCode === 27) {
      this.toggleDropdown();
      if (this.elem) {
        this.elem.blur();
        focusNext(this.elem, 50);
      }
    }
  };

  getInitial = (props) => {
    const { placeholder, children } = props || this.props;
    return !Number.isNaN(placeholder)
      ? (children[placeholder] && children[placeholder].key) || children.key
      : null;
  };

  getChildren = (children) =>
    children &&
    children.map(
      (child) =>
        child &&
        cloneElement(child, {
          onClick: child.props.disabled ? undefined : this.handleChildClick,
          role: 'option',
          'data-__value': child.key,
          'aria-selected': this.state.selected === child.key,
          className: `dropdown__option ${(child.props && child.props.className) || ''}${
            this.state.selected === child.key ? ' selected' : ''
          }`
        })
    );

  setRef = (_) => {
    this.elem = _;
  };

  setOptionsRef = (_) => {
    this.optionsElem = _;
  };

  setExpanded = (val) => {
    this.setState({ expanded: val });
  };

  toggleDropdown = () => {
    this.setState((state) => ({ expanded: !state.expanded }));
  };

  setTopOffset = () => {
    const { openUpwards, children } = this.props;
    const rect = this.elem.getBoundingClientRect();
    // Take max-height from .dropdown__options--menu into account.
    // If we ever need different max-height, we can add prop a prop and override the default one.
    const openUpwardsOffset = -Math.min(
      rect.height * children.length - 1,
      DEFAULT_UPWARDS_MAX_HEIGHT - 1
    );
    const openDownOffset = rect.height - 1;
    const topOffset = openUpwards ? openUpwardsOffset : openDownOffset;
    this.optionsElem.style.setProperty('top', `${topOffset}px`, '');
  };

  handleClick = () => {
    const { expanded, focused, canBlur } = this.state;
    if (this.elem && this.optionsElem) {
      this.setTopOffset();
      if (expanded && focused && canBlur) {
        clearTimeout(this.focusTimeout);
        this.elem.blur();
      }
    }
  };

  handleFocus = () => {
    if (!this.props.getShouldExpand()) return;

    if (this.elem && this.optionsElem) {
      this.setTopOffset();
    }
    this.setState({ expanded: true, focused: true });
    clearTimeout(this.focusTimeout);
    this.focusTimeout = setTimeout(() => {
      if (this.mounted) this.setState({ canBlur: true });
    }, this.props.duration);
  };

  handleBlur = () => {
    // blur should always happen after the focus timout
    // additional 10ms are added to the duration
    clearTimeout(this.blurTimeout);
    this.blurTimeout = setTimeout(() => {
      if (this.mounted) this.setState({ expanded: false, focused: false, canBlur: false });
    }, this.props.duration + 10);
  };

  handleChildClick = (event) => {
    const { asList } = this.props;
    if (asList) return;

    const value = event.currentTarget.getAttribute('data-__value');
    this.handleSelectValue(value);
  };

  handleSelectValue = (value) => {
    const { onChange, name } = this.props;
    this.setState({ selected: value });
    this.toggleDropdown();
    if (onChange && this.elem) {
      onChange(fromElement(this.elem), { value, name });
    }
    if (this.elem) {
      this.elem.blur();
      focusNext(this.elem, 50);
    }
    if (this.props.name) {
      trackDropdownOptionSelected({
        'Dropdown Name': this.props.name,
        'Dropdown Selected Value': value
      });
    }
  };

  render() {
    const {
      className,
      children,
      placeholder,
      elevator,
      name,
      value,
      tabIndex,
      duration,
      staggerDurationBy,
      staggerDelayBy,
      easing,
      asList,
      openUpwards,
      disabled
    } = this.props;
    const expanded = this.props.expanded || this.state.expanded;
    const dropdownClass = classNames([
      'dropdown',
      className,
      {
        openUpwards: openUpwards,
        expanded: expanded,
        asList: asList,
        disabled: disabled || children === null
      }
    ]);
    const selected = value || this.state.selected;
    const selectedChild =
      (Array.isArray(children) && children.find((child) => child && child.key === selected)) ||
      (!Array.isArray(children) && children);
    const Elevator = elevator && (
      <div
        className="dropdown__options dropdown__absolute-container"
        ref={this.setOptionsRef}
        role="listbox"
      >
        <FlipMove
          duration={duration}
          easing={easing}
          staggerDurationBy={staggerDurationBy}
          staggerDelayBy={staggerDelayBy}
          appearAnimation="elevator"
          enterAnimation="elevator"
          leaveAnimation={false}
        >
          {expanded ? this.getChildren(children) : null}
        </FlipMove>
      </div>
    );
    const Menu = !elevator && (
      <div className="dropdown__absolute-container" ref={this.setOptionsRef}>
        <FlipMove
          duration={duration}
          easing={easing}
          appearAnimation={openUpwards ? accordionVerticalUpwardsEnter : 'accordionVertical'}
          enterAnimation={openUpwards ? accordionVerticalUpwardsEnter : 'accordionVertical'}
          leaveAnimation={openUpwards ? accordionVerticalUpwardsLeave : 'accordionVertical'}
        >
          {expanded ? (
            <div className="dropdown__options dropdown__options--menu" key="options" role="listbox">
              {this.getChildren(children)}
            </div>
          ) : null}
        </FlipMove>
      </div>
    );
    return (
      <div
        className={dropdownClass}
        value={selected}
        ref={this.setRef}
        onFocus={!disabled ? this.handleFocus : null}
        onBlur={!disabled ? this.handleBlur : null}
        tabIndex={!disabled ? tabIndex : 0}
        name={name}
        // Comboboxes are used differently in reality than what the spec strictly suggests
        // This fulfills all requirements for accessibility */}
        // eslint-disable-next-line jsx-a11y/role-has-required-aria-props
        role="combobox"
        aria-haspopup="listbox"
        aria-label={placeholder}
        aria-expanded={expanded}
      >
        <FlipMove
          className="dropdown__selected-item"
          onClick={!disabled ? this.handleClick : null}
          enterAnimation="fade"
          leaveAnimation="fade"
          duration={100}
        >
          {selected && selectedChild ? selectedChild : <div key="placeholder">{placeholder}</div>}
        </FlipMove>
        {elevator ? Elevator : Menu}
      </div>
    );
  }
}

Dropdown.propTypes = {
  className: PropTypes.string,
  placeholder: PropTypes.oneOfType([PropTypes.node, PropTypes.number]),
  value: PropTypes.string,
  name: PropTypes.string.isRequired,
  easing: PropTypes.string,
  autoComplete: PropTypes.bool,
  openUpwards: PropTypes.bool,
  duration: PropTypes.number,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  onFocus: PropTypes.func,
  getShouldExpand: PropTypes.func,
  tabIndex: PropTypes.number,
  staggerDurationBy: PropTypes.number,
  staggerDelayBy: PropTypes.number,
  // eslint-disable-next-line react/forbid-prop-types
  /** Cannot mix arrays with elements */
  children: PropTypes.any.isRequired,
  expanded: PropTypes.bool,
  asList: PropTypes.bool,
  elevator: PropTypes.bool,
  disabled: PropTypes.bool
};

Dropdown.defaultProps = {
  className: '',
  value: '',
  autoComplete: false,
  openUpwards: false,
  tabIndex: -1,
  placeholder: 'Select',
  easing: 'cubic-bezier(0.5, 0, 0.1, 1.4)',
  duration: 250,
  staggerDurationBy: 0,
  staggerDelayBy: 0,
  elevator: false,
  asList: false,
  expanded: false,
  onChange: null,
  disabled: false,
  getShouldExpand: () => true
};

export default withLazyStyle(style)(Dropdown);
