import { Authority } from 'lib/constants';
import { contentMGA, contentUKGC } from './constants';

export type Data = {
  [key in Authority]: {
    content?: string;
  };
};

const data: Data = {
  [Authority.UKGC]: {},
  [Authority.MGA]: {}
};

// isTest flag helps to have content for all authorities during running tests
const isTest = __ENV__.NODE_ENV === 'test';

if (isTest || __ENV__.MRQ_GAMING_AUTHORITY === 'UKGC') {
  Object.assign(data, {
    [Authority.UKGC]: {
      content: contentUKGC
    }
  });
}

if (isTest || __ENV__.MRQ_GAMING_AUTHORITY === 'MGA') {
  Object.assign(data, {
    [Authority.MGA]: {
      content: contentMGA
    }
  });
}

export default data;
