import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Api from 'services/Api';
import User from 'modules/User';
import Modals from 'modules/Modals';
import { isLoading } from 'lib/redux-utils';
import ActionButton from 'components/ActionButton';

const YotiButton = ({ createDocScanSession, loading }) => {
  const [errorMessage, setErrorMessage] = useState(null);

  const handleClickVerify = () => {
    createDocScanSession({ onError: setErrorMessage });
  };

  return (
    <ActionButton
      loading={loading}
      error={errorMessage}
      onClick={handleClickVerify}
      name="kycVerify"
      id="yotiVerify"
    >
      Upload your documents
    </ActionButton>
  );
};

const mapStateToProps = (state) => ({
  loading: isLoading(state, User.actionTypes.AT.CREATE_DOC_SCAN_SESSION._)
});

const mapDispatchToProps = (dispatch, { close }) => ({
  createDocScanSession: async ({ onError }) => {
    try {
      const res = await Api.actions.user.createDocScanSession(null, {})(dispatch);
      if (close) {
        close();
      }
      dispatch(Modals.actions.open('docScan', { docScanUrl: res.data }));
    } catch (e) {
      onError("Can't connect with Yoti, try again");
    }
  }
});

YotiButton.propTypes = {
  loading: PropTypes.string.isRequired,
  createDocScanSession: PropTypes.func.isRequired
};

export default connect(mapStateToProps, mapDispatchToProps)(YotiButton);
