import { actionTypeCreator, SYNC, ASYNC } from 'lib/redux-utils';
import { NAME } from './constants';

const namespace = actionTypeCreator(NAME);

export const T = namespace({
  OPEN: SYNC,
  UPDATE: SYNC,
  PAUSE: SYNC,
  CLOSE: SYNC,
  CREATE: SYNC
});

export const AT = namespace({
  WS_SUBSCRIBE: ASYNC,
  WS_UNSUBSCRIBE: ASYNC,
  ACK: ASYNC,
  LIST_SINGLE: ASYNC,
  LIST: ASYNC
});
