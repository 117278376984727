const buildActionRefUrl = (href: string) => {
  if (!href) return { actionRef: null, cleanHref: '' };

  const url = new URL(href, window.location.origin);
  const isRelativePath = !href.includes(url.origin);
  const { searchParams, pathname } = url;
  const actionRef = searchParams.get('actionRef');
  searchParams.delete('actionRef');

  const cleanHref = isRelativePath
    ? url.href.replace(url.origin + (href.startsWith(pathname) ? '' : '/'), '')
    : url.href;

  return { actionRef, cleanHref };
};

export default buildActionRefUrl;
