/* eslint-disable @typescript-eslint/no-unsafe-return -- No full TS support in redux */
import React, { memo } from 'react';
import { DailyFreeGamesList } from 'components/DailyFreeGamesList';
import SlotRewardsList from 'components/SlotRewardsList';
import User from 'modules/User';
import { useAppSelector } from 'hooks/redux';
import NoRewards from 'components/RewardCard/NoRewards';
import RewardsListLoading from './RewardsListLoading';
import './rewardsList.css';

const RewardsList = () => {
  const loadingAllRewards = useAppSelector((state) => User.selectors.getAllRewardsLoading(state));
  const hasLoadedAllRewards = useAppSelector((state) =>
    User.selectors.getHasLoadedAllRewards(state)
  );

  const hasAnyRewards = useAppSelector((state) => User.selectors.getRewardsExist(state));
  const isLoading = loadingAllRewards && !hasLoadedAllRewards;
  const showNoRewards = !isLoading && !hasAnyRewards;

  return (
    <div className="rewardsList hide-scrollbar">
      {isLoading ? (
        <RewardsListLoading count={1} />
      ) : (
        <>
          <SlotRewardsList />
          <DailyFreeGamesList />
        </>
      )}
      {showNoRewards && <NoRewards />}
    </div>
  );
};

export default memo(RewardsList);
