/* eslint-disable func-names, no-restricted-syntax, no-prototype-builtins */
// eslint-disable-next-line max-classes-per-file
import { isNil, throttle } from 'lodash';
import lru from 'tiny-lru';
import getType from 'lib/getType';

const CACHE_SIZE = 50;
const THROTTLE_MS = 1500;

let apiRoot = '';
const throttleMap = lru(CACHE_SIZE);

export class OperationTransformer {
  static transform(endpoints, root) {
    apiRoot = root;
    const result = { ...endpoints };
    Object.keys(endpoints).forEach((serviceKey) => {
      Object.keys(endpoints[serviceKey]).forEach((operationKey) => {
        result[serviceKey][operationKey] = this._replaceParameters(
          endpoints[serviceKey][operationKey]
        );
      });
    });
    return result;
  }

  static _replaceParameters(endpoint) {
    return function (args) {
      return OperationTransformer._doReplace(endpoint, args);
    };
  }

  static _doReplace() {
    const argList = [...arguments];
    // Check if > 1 since url is always passed
    const tpl = argList.shift();
    if (!isNil(argList[0])) {
      return this.parseTemplate(tpl, argList[0]);
    } else {
      return tpl;
    }
  }

  static parseTemplate(tpl, args) {
    const argumentIndex = tpl.indexOf('?');
    let plainUrl = tpl.includes('?') ? tpl.substring(0, argumentIndex - 1) : tpl;
    // Plus and minus 1 to remove the { } characters
    const argumentUrl = tpl.includes('?') ? tpl.substring(plainUrl.length + 1, tpl.length - 1) : '';
    let argumentUrlResult = '';
    let argNumber = 0;
    let plainUrlResult = '';
    for (const key in args) {
      if (args.hasOwnProperty(key)) {
        const value = args[key];
        plainUrl = plainUrl.replace(`{${key}}`, encodeURIComponent(value));
        if (plainUrl.includes(`{${key}}`)) {
          plainUrlResult += plainUrl.substring(0, plainUrl.indexOf(key) + key.length + 1);
          plainUrl = plainUrl.substring(plainUrl.indexOf(key) + key.length + 1, plainUrl.length);
        } else {
          plainUrlResult = plainUrl;
        }
        if (argumentUrl.includes(key) && !isNil(value)) {
          const paramValue =
            getType(value) === 'Array'
              ? value.map(encodeURIComponent).join()
              : encodeURIComponent(value);
          argumentUrlResult += argNumber === 0 ? `?${key}=${paramValue}` : `&${key}=${paramValue}`;
          argNumber++;
        }
      }
    }
    return plainUrlResult + argumentUrlResult;
  }

  static extractRequest(str) {
    let text = str.slice(0);
    let method = 'get';
    const colonIndex = text.indexOf(':');
    if (colonIndex < text.indexOf('/') && text[colonIndex + 1] === ' ') {
      method = text.slice(0, colonIndex).toLowerCase();
      text = text.slice(colonIndex + 2, text.length);
    } else if (colonIndex < text.indexOf('/')) {
      // Allow 1 or 0 spaces after colon
      method = text.slice(0, colonIndex).toLowerCase();
      text = text.slice(colonIndex + 1, text.length);
    }
    text = apiRoot + text;
    return { method, text };
  }
}

export class ActionTransformer {
  /**
   * Transforms operations (not raw endpoints) into actions. Throttled to 1 action type/second
   *
   * @param {Object} opEndpoints
   * @return {{}} the actions as functions
   * @param {Function} callback callback for promise
   */
  static transform(opEndpoints, callback) {
    const result = { ...opEndpoints };
    Object.keys(opEndpoints).forEach((serviceKey) => {
      const casedServiceKey =
        serviceKey[0].toUpperCase() + serviceKey.substr(1, serviceKey.length).toLowerCase();
      Object.keys(opEndpoints[serviceKey]).forEach((operationKey) => {
        const casedOperationKey = operationKey.split(/(?=[A-Z])/).join('_');
        result[serviceKey][operationKey] = this._replaceParameters(
          opEndpoints[serviceKey][operationKey],
          `${casedServiceKey}/${casedOperationKey.toUpperCase()}`,
          callback
        );
      });
    });
    return result;
  }

  static _replaceParameters(endpoint, key, callback) {
    // List <String, Function> endpoints
    return (
      args,
      data,
      // Properties here for documentation. Will use proper defaults when we add more
      options = {
        skipCache: undefined,
        skipSession: undefined,
        headers: undefined
      }
    ) => {
      const extracted = OperationTransformer.extractRequest(endpoint(args));
      const formObject = data instanceof FormData ? Object.fromEntries(data) : undefined;
      const headers = options.skipSession
        ? { ...options.headers, 'X-Session-No-Refresh': 'true' }
        : options.headers;

      // Skip certain headers to avoid unnecessary cache duplication.
      // The headers are part of the key that is used for request identification.
      const { 'X-Session-No-Refresh': _, ...cacheableHeaders } = headers ?? {};

      const actionRequest = (dispatch, local) => {
        const request = callback(extracted.method, extracted.text, data, headers);

        const reduxAction = {
          type: key,
          payload: {
            promise: request
          },
          meta: {
            generated: true,
            globally: !local,
            method: extracted.method,
            args: args,
            data: formObject || data
          }
        };
        if (!dispatch) {
          return reduxAction;
        }
        dispatch(reduxAction);
        // TODO: Document that this returns the raw request. It allows short-circuiting
        return request;
      };

      const dataString = !isNil(data) && JSON.stringify(formObject || data);
      const headerString = !isNil(cacheableHeaders) && JSON.stringify(cacheableHeaders);
      const id = key + (!isNil(args) && JSON.stringify(args)) + dataString + headerString;

      if (!throttleMap.has(id)) {
        throttleMap.set(id, throttle(actionRequest, THROTTLE_MS, { trailing: false }));
      }

      if (options.skipCache) return actionRequest;

      return throttleMap.get(id);
    };
  }
}

export class RequestTransformer {
  /**
   * Transforms operations (not raw endpoints) into requests
   *
   * @param {Object} opEndpoints
   * @return {{}} the actions as functions
   * @param {Function} callback callback for promise
   */
  static transform(opEndpoints, callback) {
    const result = { ...opEndpoints };
    Object.keys(opEndpoints).forEach((serviceKey) => {
      Object.keys(opEndpoints[serviceKey]).forEach((operationKey) => {
        result[serviceKey][operationKey] = this._replaceParameters(
          opEndpoints[serviceKey][operationKey],
          callback
        );
      });
    });
    return result;
  }

  static _replaceParameters(endpoint, callback) {
    // List <String, Function> endpoints
    return function (args, data, headers) {
      const extracted = OperationTransformer.extractRequest(endpoint(args));
      return callback(extracted.method, extracted.text, data, headers);
    };
  }
}
