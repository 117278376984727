import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import User from 'modules/User';
import sendVerificationDocuments from 'lib/sendVerificationDocuments';
import UserBox from 'components/UserBox';
import makeModal from 'components/Modals';
import Button from 'components/Button';
import './verifyDocuments.css';

const _VerifyDocuments = ({ username, close }) => (
  <UserBox title="Verify your account" closeCallback={close}>
    <article>
      <p>
        To verify your account, we need a copy of <strong>both</strong>:
      </p>
      <ul>
        <li>
          Your valid photo ID (a driving licence or passport); <strong>AND</strong>
        </li>
        <li>
          A proof of address document dated within the last 3 months (a utility bill, council tax
          bill etc.)
        </li>
      </ul>
      <p>
        <strong>However</strong>, a valid driving licence with your current address would satisfy
        our identity and address check in one go!
      </p>
      <p>Please take a photo of your documents without anything cropped and send them via email.</p>
      <Button
        title="Upload documents"
        buttonText="Upload documents"
        name="sendDocuments"
        onClick={() => {
          sendVerificationDocuments({ username });
        }}
      />
      <p className="verifyDocuments__faq">
        <a
          href="https://help.mrq.com/hc/en-gb/articles/9891913741204-What-proof-of-identity-and-address-documents-do-you-accept-"
          target="_blank"
          rel="noopener noreferrer"
        >
          What documents do you accept?
        </a>
      </p>
    </article>
  </UserBox>
);

const mapStateToProps = (state) => ({
  username: User.selectors.model(state).username
});

_VerifyDocuments.propTypes = {
  username: PropTypes.string.isRequired,
  close: PropTypes.func.isRequired
};

export default compose(
  makeModal(
    'verifyDocuments',
    { name: 'modal-fade', timeout: 200 },
    { className: 'verifyDocuments modal' }
  ),
  connect(mapStateToProps)
)(_VerifyDocuments);
