import { createStore, applyMiddleware, compose } from 'redux';
import type { FluxStandardAction } from 'redux-promise-middleware';
import promiseMiddleware from 'redux-promise-middleware';
import immutableStateInvariant from 'redux-immutable-state-invariant';
// @ts-ignore
import { reduxTimeout } from 'redux-timeout';
import thunkMiddleware from 'redux-thunk';
import { createBrowserHistory } from 'history';
import { createActionLog } from 'redux-action-log';
import soundsMiddleware from 'redux-sounds';
import { effectMiddleware } from 'services/Effects';
import { normalizerMiddleware } from 'services/Normalizer';
import slotBalanceMiddleware from 'services/SlotBalance';
import { notificationMiddleware } from 'services/Notification';
import { authMiddleware } from 'services/Auth';
import analyticsMiddleware from 'services/Analytics';
import { ignoreActionMiddleware } from 'services/Ignore';
import performanceMiddleware from 'services/Performance';
import Socket from 'services/Socket';
import { translatorMiddleware } from 'services/Translator';
import localSessionMiddleware from 'services/LocalSession';
import { middleware as globalErrorMiddleware } from 'services/Error';
import { apiMiddleware } from 'services/Api';
import deferUntilMiddleware from 'services/DeferUntilAction';
import routerMiddleware from 'services/Router';
import rootReducer from './rootReducer';

export const history = createBrowserHistory();

export type History = typeof history;

const isDev = __ENV__.NODE_ENV === 'development';
const debug = __ENV__.MRQ_DEBUG === 'true';
const perf = __ENV__.REACT_PERF === 'true';

export const actionLog = debug && createActionLog({ limit: 100 });

const shouldDefer = (action: FluxStandardAction) =>
  action.meta?.defer as boolean | undefined | null;
const until = (caught: FluxStandardAction, nextAction: FluxStandardAction) =>
  caught.meta?.defer === nextAction.type;

const composeEnhancers =
  ((isDev || debug) && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) || compose;
const middlewares = [
  thunkMiddleware,
  ignoreActionMiddleware(),
  authMiddleware(),
  Socket.middleware(),
  translatorMiddleware(),
  slotBalanceMiddleware(),
  globalErrorMiddleware(),
  promiseMiddleware,
  apiMiddleware(),
  normalizerMiddleware(),
  localSessionMiddleware,
  notificationMiddleware(),
  soundsMiddleware(),
  reduxTimeout(),
  deferUntilMiddleware(shouldDefer, until),
  effectMiddleware(),
  ...analyticsMiddleware,
  routerMiddleware(history)
];

// No filter() empty like in webpack/babel configs so that this block gets eliminated during minification
if (isDev) {
  middlewares.push(immutableStateInvariant());
}

if (perf) middlewares.push(performanceMiddleware);

export const getStore = () =>
  debug === false
    ? createStore(rootReducer, {} as any, composeEnhancers(applyMiddleware(...middlewares)))
    : createStore(
        rootReducer,
        {} as any,
        composeEnhancers(applyMiddleware(...middlewares), actionLog && actionLog.enhancer)
      );

const store = getStore();

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;

export default store;
