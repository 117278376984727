import { T } from './actionTypes';

// Expiration and callbacks set in middleware
export const open = (refName, message, { expiration, title, dismissible, severity = 'info' }) => ({
  type: T.OPEN,
  payload: { refName, message, title, dismissible, severity }
});

export const update = (
  refName,
  message,
  { expiration, title, dismissible, severity = 'info' }
) => ({
  type: T.UPDATE,
  payload: { refName, message, title, dismissible, severity }
});

export const create = (refName, message, { title, dismissible, severity = 'info', paths }) => ({
  type: T.CREATE,
  payload: { refName, message, title, dismissible, severity, paths }
});

export const pause = (refName) => ({
  type: T.PAUSE,
  payload: { refName }
});

export const close = (refName) => ({
  type: T.CLOSE,
  payload: { refName }
});
