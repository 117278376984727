import { useEffect } from 'react';
import type { AiRecommendationsExperimentVariant } from 'modules/Lobby';
import aiGameRecommendations from 'experiments/aiGameRecommendations';
import useExperimentData from 'lib/analytics/hooks/useExperimentData';

const useAiGameRecommendations = (shouldExposeExperiment = false) => {
  const { exposeExperiment } = useExperimentData(aiGameRecommendations.flag) ?? {};

  useEffect(() => {
    if (shouldExposeExperiment) {
      exposeExperiment?.();
    }
  }, [exposeExperiment, shouldExposeExperiment]);

  const experimentVariant: AiRecommendationsExperimentVariant =
    aiGameRecommendations.isLatestVariant ? 'default' : 'most';

  return { experimentVariant };
};

export default useAiGameRecommendations;
