import { useCallback, useMemo } from 'react';
import { useAppDispatch, useAppSelector } from 'hooks/redux';
import CmsContent from 'modules/CmsContent';
import usePublicCmsData from 'hooks/usePublicCmsData';
import type { types as CmsContentTypes } from 'modules/CmsContent';

const usePublicCmsDataAppFooter = (hasContent?: boolean) => {
  const dispatch = useAppDispatch();
  const content = useAppSelector(CmsContent.selectors.getContentAppFooter());
  const updateContent = useCallback(
    (cmsResponse) => {
      dispatch(CmsContent.actions.cacheContent('appFooter', cmsResponse.data));
    },
    [dispatch]
  );
  const { hasError, isLoading } = usePublicCmsData<{ data: CmsContentTypes.AppFooter }>({
    hasContent: Boolean(hasContent || content),
    contentPath: '/api/app-footer',
    updateContent
  });

  return useMemo(() => ({ content, hasError, isLoading }), [content, hasError, isLoading]);
};

export default usePublicCmsDataAppFooter;
