import React, { useCallback, useEffect, useState } from 'react';
import { useContainerQuery } from 'react-container-query';
import { InView } from 'react-intersection-observer';
import cc from 'classcat';
import Markdown, { ReactMarkdown } from 'components/Markdown';
import { Typography, TypographyVariant } from 'components/Typography';
import FooterLogos from 'components/FooterLogos';
import FooterTrustFactors from 'components/FooterTrustFactors';
import FooterLegalDetails from 'components/FooterLegalDetails';
import LoadingElement from 'components/Loading/LoadingElement';
import FormError from 'components/FormError';
import FooterMenu from './src/FooterMenu';
import usePublicCmsDataAppFooter from './usePublicCmsDataAppFooter';
import './footer.css';

const query = {
  'min-width-540': {
    minWidth: 540
  }
};

const empty = <>{null}</>;

export const Footer = () => {
  const [params, containerRef] = useContainerQuery(query, { width: 0 });
  const { content: data, isLoading, hasError } = usePublicCmsDataAppFooter();

  useEffect(() => {
    // Avoid waterfall from loading CMS content
    ReactMarkdown.preload();
  }, []);

  return (
    <footer role="contentinfo" ref={containerRef} className={cc(['footer', params])}>
      {isLoading && !data ? <LoadingElement /> : null}
      {hasError && (
        <div className="footer__container">
          <FormError errorMessage="Whoops, something went wrong..." />
        </div>
      )}
      {data ? (
        <>
          <section className="footer__section">
            <div className="footer__container">
              <FooterLogos logos={data.paymentMethods} className="footer__paymentMethodLogos" />
              <FooterMenu />
            </div>
          </section>
          <section className="footer__section">
            <div className="footer__container">
              <FooterTrustFactors
                className="footer__trustFactors"
                trustFactors={data.trustFactors}
              />
            </div>
          </section>
          <section className="footer__section">
            <Typography variant={TypographyVariant.BodyXs}>
              <div className="footer__container footer__paragraph" aria-label="Copyright info">
                <Markdown content={data.responsibleGamingMessage} fallback={empty} />
                <FooterLegalDetails copyrightContent={data.copyright} />
              </div>
            </Typography>
          </section>
        </>
      ) : null}
    </footer>
  );
};

// FIXME: By default the root is the entire document. This works in Account, but in Lobby for example, the
//  parent which occludes Footer is not `documentbody`, but Carousel. To track visibility correctly, we need to
//  be able to pass the parent ref here, and necessary margins.
//  See [`root` option](https://github.com/thebuilder/react-intersection-observer#options).
//  If tracking visibility is even necessary.
const FooterInView = () => {
  const [isInView, setIsInView] = useState(false);

  const handleInView = useCallback((inView) => {
    if (inView) setIsInView(true);
  }, []);

  return (
    <InView as="div" triggerOnce onChange={handleInView} className="footer__inView">
      {isInView ? <Footer /> : null}
    </InView>
  );
};

export default FooterInView;
