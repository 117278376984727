import { normalize } from 'normalizr';
import update from 'update-immutable';
import { isNil, get } from 'lodash';
import defaultSchema from './src/defaults';

export function normalizerMiddleware(schemaDefinition = defaultSchema) {
  return () => (next) => (action) => {
    const actionType = action.type;
    const types = Object.keys(schemaDefinition);
    if (types.includes(actionType) && !isNil(action.payload) && isNil(action.payload.success)) {
      // Replace with isNil errorCode
      const { path, schema } = schemaDefinition[actionType];
      const data = get(action.payload, path, action.payload);
      const result = normalize(data || {}, schema || schemaDefinition[actionType]);
      if (result.entities) {
        result.entities = Object.values(result.entities)[0];
      }
      const payload = path
        ? {
            $merge: {
              [path]: result
            }
          }
        : {
            $set: result
          };
      return next(
        update(action, {
          type: { $set: actionType },
          payload: payload,
          meta: {
            $merge: {
              normalized: true
            }
          }
        })
      );
    }
    return next(action);
  };
}
