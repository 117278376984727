export const NAME = 'Modals';

export const CARD_TYPES = {
  VISA: 'Visa',
  AMEX: 'American Express',
  MASTER: 'Mastercard',
  MAESTRO: 'Maestro',
  DINERS: 'Diners Club',
  PAYSAFE: 'Paysafe card'
};

export const PAYMENT_METHODS = {
  CREDIT: 'Credit card',
  DEBIT: 'Debit card',
  PAYPAL: 'PayPal',
  MOBILE: 'Mobile phone',
  BITCOIN: 'Bitcoin',
  BANK: 'Bank transfer'
};
