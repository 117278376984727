import React from 'react';
import UserBox from 'components/UserBox';
import makeModal from 'components/Modals';
import AddPaypal from 'components/AddPayment/AddPaypal'; // TODO: Move to own dir

const _AddPaypal = ({ close }) => (
  <UserBox
    id="addPaypalBox"
    className="addPaypal modal"
    title="Add PayPal account"
    closeCallback={close}
  >
    <AddPaypal onDone={close} />
  </UserBox>
);

export default makeModal(
  'addPaypal',
  { name: 'modal-fade', timeout: 200 },
  { className: 'addPaypal modal' }
)(_AddPaypal);
