// Let caller handle rejections
const loadBraintree = () =>
  import(/* webpackChunkName: "data-colllector" */ 'braintree-web/data-collector');

export const collectData = async (clientToken, paypal = true, kount = false) => {
  const {
    default: { create }
  } = await loadBraintree();
  return create({ paypal, authorization: clientToken, kount });
};

const loadThreeDSecure = () =>
  import(/* webpackChunkName: "three-d-secure" */ 'braintree-web/three-d-secure');

export const createThreeDsecure = async (clientToken) => {
  const {
    default: { create }
  } = await loadThreeDSecure();
  return create({ authorization: clientToken, version: '2' });
};

export const braintreeStyle = {
  input: {
    'font-size': '16px',
    'line-height': '26px',
    'font-family': '"Gilroy", sans-serif',
    color: '#0e1120'
  },
  ':focus': {
    color: 'black'
  }
};

export const asyncHandler = () => {
  let resolver;
  let rejector;
  const promise = new Promise((resolve, reject) => {
    resolver = resolve;
    rejector = reject;
  });
  promise.resolve = resolver;
  promise.reject = rejector;

  promise.callback = (err, result) => {
    if (err) {
      promise.resolve(err);
    } else {
      promise.resolve(result);
    }
  };

  return promise;
};

// export const collectDeviceData = async (clientToken, isPaypal) => {
//   const collected = await collectData(clientToken, isPaypal);
//   return collected.deviceData;
// };
