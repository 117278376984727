/* eslint-disable @typescript-eslint/no-unsafe-return -- Disabled because the "Cannot find wallet module" (Line 25)
and Redux not being written in Typescript causes many false errors */
import React, { useCallback, useEffect, useState } from 'react';
import type { MapDispatchToProps, MapStateToProps } from 'react-redux';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { isMobile } from 'react-device-detect';
import makeModal from 'components/Modals';
import Modals from 'modules/Modals';
import User from 'modules/User';
import UserBox from 'components/UserBox';
import { MagicMove } from 'components/Transitions';
import SMSNumber from 'components/DepositEcospendFlow/src/SMSNumber';
import usePrevious from 'hooks/usePrevious';
import { isLoading } from 'lib/redux-utils';
import Wallet, { constants } from 'modules/Wallet';
import Api from 'services/Api';
import type { EcospendStep } from './types';
import AuthOptionChooser from './src/AuthOptionChooser';
import SMSSent from './src/SMSSent';
import PaymentDetails from './src/PaymentDetails';
import PaymentConfirmation from './src/PaymentConfirmation';
import QRAuthorisation from './src/QRAuthorisation';

const RESEND_COOLDOWN_TIME = 60_000;

interface EcospendDeposit {
  paylinkId: string;
  depositAmount: number;
  paymentUrl: string;
  smsSentAt: number;
  smsSentTo: string;
  sentSmsCount: number;
  qrCode: string;
}

interface DepositEcospendFlowProps {
  clearAndClose: () => void;
  bankLogo: string;
  openTermsConditions: () => void;
  openPrivacyPolicy: () => void;
  verifiedPhoneNumber: string;
  unverifiedPhoneNumber: string;
  bankName: string;
  initialStep: EcospendStep;
  sendSms: (number: string, paylinkId: string) => void;
  loadingSms: boolean;
  ecospendDeposit: EcospendDeposit;
}

const DepositEcospendFlow = ({
  clearAndClose,
  bankLogo,
  openTermsConditions,
  openPrivacyPolicy,
  verifiedPhoneNumber,
  unverifiedPhoneNumber,
  bankName,
  initialStep,
  sendSms,
  loadingSms,
  ecospendDeposit
}: DepositEcospendFlowProps) => {
  const { paylinkId, paymentUrl, smsSentAt, smsSentTo, sentSmsCount, qrCode, depositAmount } =
    ecospendDeposit;
  const [currentStep, setCurrentStep] = useState<EcospendStep>(
    initialStep || constants.ECOSPEND_STEP.FIRST_DEPOSIT_CONFIRMATION
  );
  const prevStep = usePrevious<EcospendStep>(currentStep);
  const prevSmsSentAt = usePrevious(smsSentAt);
  const nextSMSTime = sentSmsCount > 1 ? smsSentAt + RESEND_COOLDOWN_TIME : 0;

  // Switch to "SMSSent" view if user is in the "AuthOptions" or "SMSNumber" view.
  useEffect(() => {
    if (
      (currentStep === constants.ECOSPEND_STEP.AUTH_OPTIONS ||
        currentStep === constants.ECOSPEND_STEP.SMS_NEW_NUMBER) &&
      smsSentAt !== prevSmsSentAt
    ) {
      setCurrentStep(constants.ECOSPEND_STEP.SMS);
    }
  }, [setCurrentStep, currentStep, smsSentAt, prevSmsSentAt]);

  const disabledOptions = {
    [constants.ECOSPEND_STEP.QR_AUTH]: !qrCode,
    [constants.ECOSPEND_STEP.URL_AUTH]: !paymentUrl
  };

  const onOpenUrl = useCallback(() => window.location.replace(paymentUrl), [paymentUrl]);
  const onWrongNumber = useCallback(
    () => setCurrentStep(constants.ECOSPEND_STEP.SMS_NEW_NUMBER),
    []
  );
  const onBack = useCallback(() => {
    setCurrentStep(() => {
      if (currentStep === constants.ECOSPEND_STEP.SMS_NEW_NUMBER && sentSmsCount > 0)
        return constants.ECOSPEND_STEP.SMS;
      if (currentStep === constants.ECOSPEND_STEP.QR_AUTH)
        return prevStep || constants.ECOSPEND_STEP.FIRST_DEPOSIT_CONFIRMATION;
      if (currentStep === constants.ECOSPEND_STEP.AUTH_OPTIONS)
        return constants.ECOSPEND_STEP.FIRST_DEPOSIT_CONFIRMATION;
      return constants.ECOSPEND_STEP.AUTH_OPTIONS;
    });
  }, [currentStep, prevStep, sentSmsCount]);

  const onSendSms = useCallback(
    (phoneNumber) => sendSms(phoneNumber, paylinkId),
    [sendSms, paylinkId]
  );

  const onSendSmsToNewPhone = useCallback(
    ({ smsPhoneNumber }) => {
      onSendSms(smsPhoneNumber);
    },
    [onSendSms]
  );

  const onAuthoriseWithPhoneDesktop = useCallback(() => {
    setCurrentStep(constants.ECOSPEND_STEP.QR_AUTH);
  }, []);

  const onResendSms = useCallback(() => {
    if (sentSmsCount > 0) onSendSms(smsSentTo);
  }, [sentSmsCount, onSendSms, smsSentTo]);

  const onChooseOption = useCallback(
    (option: EcospendStep) => {
      if (option === constants.ECOSPEND_STEP.URL_AUTH) {
        window.location.replace(paymentUrl);
      } else if (option === constants.ECOSPEND_STEP.SMS) {
        if (!sentSmsCount && verifiedPhoneNumber) {
          onSendSms(verifiedPhoneNumber);
        } else if (!sentSmsCount) {
          setCurrentStep(constants.ECOSPEND_STEP.SMS_NEW_NUMBER);
        } else {
          setCurrentStep(constants.ECOSPEND_STEP.SMS);
        }
      } else setCurrentStep(option);
    },
    [verifiedPhoneNumber, paymentUrl, onSendSms, sentSmsCount]
  );

  const title =
    currentStep === constants.ECOSPEND_STEP.FIRST_DEPOSIT_CONFIRMATION
      ? 'Authorise payment'
      : 'Payment request';

  const onBackInUserbox =
    currentStep !== constants.ECOSPEND_STEP.FIRST_DEPOSIT_CONFIRMATION ? onBack : null;

  return (
    <UserBox
      id="depositEcospendFlow"
      title={title}
      onBack={onBackInUserbox}
      closeCallback={clearAndClose}
    >
      <PaymentDetails depositAmount={depositAmount} bankName={bankName} bankLogoUrl={bankLogo} />
      <MagicMove childKey={currentStep}>
        <>
          {currentStep === constants.ECOSPEND_STEP.AUTH_OPTIONS && (
            <AuthOptionChooser
              disabledOptions={disabledOptions}
              key={constants.ECOSPEND_STEP.AUTH_OPTIONS}
              smsLoading={loadingSms}
              onClick={onChooseOption}
            />
          )}
          {currentStep === constants.ECOSPEND_STEP.SMS && (
            <SMSSent
              key={constants.ECOSPEND_STEP.SMS}
              smsMobileNumber={smsSentTo}
              onResendSms={onResendSms}
              onWrongNumber={onWrongNumber}
              nextSMSTime={nextSMSTime}
              smsLoading={loadingSms}
            />
          )}
          {currentStep === constants.ECOSPEND_STEP.SMS_NEW_NUMBER && (
            <SMSNumber
              key={constants.ECOSPEND_STEP.SMS_NEW_NUMBER}
              loading={loadingSms}
              initialPhoneNumber={unverifiedPhoneNumber || ''}
              onSubmit={onSendSmsToNewPhone}
              nextSMSTime={nextSMSTime}
            />
          )}
          {currentStep === constants.ECOSPEND_STEP.QR_AUTH && (
            <QRAuthorisation qrCode={qrCode} key={constants.ECOSPEND_STEP.QR_AUTH} />
          )}
          {(currentStep === constants.ECOSPEND_STEP.FIRST_DEPOSIT_CONFIRMATION ||
            currentStep === null) && (
            <PaymentConfirmation
              key={constants.ECOSPEND_STEP.FIRST_DEPOSIT_CONFIRMATION}
              onAuthWithUrl={onOpenUrl}
              onViewOtherOptions={onBack}
              openTermsConditions={openTermsConditions}
              openPrivacyPolicy={openPrivacyPolicy}
              onAuthoriseWithPhoneDesktop={onAuthoriseWithPhoneDesktop}
              isMobile={isMobile}
            />
          )}
        </>
      </MagicMove>
    </UserBox>
  );
};

const mapStateToProps = (state: MapStateToProps<any, any>) => ({
  verifiedPhoneNumber: User.selectors.getVerifiedPhoneNumber(state),
  unverifiedPhoneNumber: User.selectors.getUnverifiedPhoneNumber(state),
  ecospendDeposit: Wallet.selectors.getEcospendDeposit(state),
  // eslint-disable-next-line import/no-named-as-default-member -- Redux not in TypeScript
  loadingSms: isLoading(state, Wallet.actionTypes.AT.ECOSPEND_SMS._)
});

const mapDispatchToProps = (
  dispatch: MapDispatchToProps<any, any>,
  { close }: { close: () => void }
) => ({
  openDeposit: (extraProps: any) => dispatch(Modals.actions.open('deposit', extraProps)),
  openTermsConditions: () => dispatch(Modals.actions.open('termsConditions')),
  openPrivacyPolicy: () => dispatch(Modals.actions.open('privacyPolicy')),
  clearAndClose: () => {
    dispatch(Wallet.actions.clearEcospendDeposit);
    close();
  },
  sendSms: (phoneNumber: string, paylinkId: string) => {
    Api.actions.wallet.ecospendSms(null, { phoneNumber, paylinkId })(dispatch);
  }
});

export default compose(
  makeModal('depositEcospendFlow', { name: 'modal-fade', timeout: 200 }, { className: 'modal' }),
  connect(mapStateToProps, mapDispatchToProps)
)(DepositEcospendFlow);
