import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { pick } from 'lodash';
import { isLoading } from 'lib/redux-utils';
import { formatCurrency } from 'lib/formatters';
import Api from 'services/Api';
import Wallet from 'modules/Wallet';
import Modals from 'modules/Modals';
import UserBox from 'components/UserBox';
import Button from 'components/Button';
import VirtualList from 'components/VirtualList';
import { Transaction } from 'components/Account';
import makeModal from 'components/Modals';
import './selectReversible.css';

const ROW_HEIGHT = 58;
const EMPTY_HEIGHT = 58;
const MAX_LIST_HEIGHT = 540;

const entityProps = [
  'type',
  'amount',
  'instrument',
  'gameRef',
  'date',
  'cancel',
  'checkbox',
  'onCheck',
  'operator',
  'deposit',
  'status'
];

class SelectReversibleWithdrawals extends Component {
  handleCheckSelectAll = () => {
    const {
      cancellableBankTransactionRefs,
      selectedTransactionsRefs,
      selectAllTransactions,
      deselectAllTransactions
    } = this.props;

    const isSelectAllChecked =
      cancellableBankTransactionRefs && selectedTransactionsRefs
        ? cancellableBankTransactionRefs.length === selectedTransactionsRefs.length
        : false;

    if (isSelectAllChecked) {
      deselectAllTransactions();
    } else {
      selectAllTransactions(cancellableBankTransactionRefs);
    }
  };

  handleOnCheckTransaction = (refName, selected) => {
    const { selectTransactions, deselectTransactions } = this.props;
    if (selected) {
      deselectTransactions([refName]);
    } else {
      selectTransactions([refName]);
    }
  };

  handleClickReverseTransactions = () => {
    const { cancelMultipleWithdraws, selectedTransactionsRefs, canceledTransactionsAmount, close } =
      this.props;
    cancelMultipleWithdraws(selectedTransactionsRefs, canceledTransactionsAmount, close);
  };

  handleOnBack = () => {
    const { close, goToDeposit } = this.props;
    goToDeposit(close);
  };

  mapEntityProps = (ownerProps) =>
    pick(
      {
        ...ownerProps,
        instrument: 'CARD',
        checkbox: true,
        onCheck: this.handleOnCheckTransaction,
        deposit: true,
        cancel: false
      },
      entityProps
    );

  render() {
    const {
      canceledTransactionsAmount,
      loadingCancelMultipleWithdraws,
      cancellableBankTransactionRefs,
      selectedTransactionsRefs,
      bankTransactions,
      bankTransactionsCount,
      close
    } = this.props;

    const isSelectAllChecked =
      cancellableBankTransactionRefs && selectedTransactionsRefs
        ? cancellableBankTransactionRefs.length === selectedTransactionsRefs.length
        : false;

    return (
      <UserBox
        id="selectReversible"
        className="selectReversible modal"
        title="Reverse withdrawals"
        closeCallback={close}
        onBack={this.handleOnBack}
      >
        <div className="transactions-list__header checkbox">
          <input
            type="checkbox"
            id="selectAll"
            onChange={this.handleCheckSelectAll}
            checked={isSelectAllChecked}
          />
          <span className="checker" />
          <label htmlFor="selectAll">Select all</label>
        </div>
        <VirtualList
          className="transactions-list"
          defaultRowHeight={ROW_HEIGHT}
          emptyListHeight={EMPTY_HEIGHT}
          maxListHeight={MAX_LIST_HEIGHT}
          refs={cancellableBankTransactionRefs}
          entities={bankTransactions}
          propsMapper={this.mapEntityProps}
          totalElements={bankTransactionsCount}
        >
          {Transaction}
        </VirtualList>
        {selectedTransactionsRefs.length > 0 ? (
          <div className="transactions-list__footer">
            <p>
              Total of reversed withdrawals:{' '}
              <span className="transactions-list__reverseAmount">
                {formatCurrency(canceledTransactionsAmount)}
              </span>
            </p>
            <Button
              className="transactions-list__button"
              title="Reverse transaction"
              type="button"
              name="reverseTransaction"
              id="transactionsList-reverse"
              buttonText="Reverse"
              onClick={this.handleClickReverseTransactions}
              loading={loadingCancelMultipleWithdraws}
            />
          </div>
        ) : null}
      </UserBox>
    );
  }
}

const mapStateToProps = (state) => ({
  bankTransactionsCount: Wallet.selectors.getBankTransactionsCountDeposit(state),
  bankTransactions: Wallet.selectors.getBankTransactionsDeposit(state),
  cancellableBankTransactionRefs: Wallet.selectors.getCancellableBankTransactionRefsDeposit(state),
  selectedTransactionsRefs: Wallet.selectors.getSelectedTransactionsRefsDeposit(state),
  canceledTransactionsAmount: Wallet.selectors.getCanceledTransactionsAmountDeposit(state),
  loading: isLoading(state, [
    Wallet.actionTypes.AT.BANK_TRANSACTIONS_DEPOSIT._,
    Wallet.actionTypes.AT.NET_DEPOSITS._
  ]),
  loadingCancelMultipleWithdraws: isLoading(state, [
    Wallet.actionTypes.AT.CANCEL_MULTIPLE_WITHDRAWS._
  ])
});

const mapDispatchToProps = (dispatch) => ({
  cancelMultipleWithdraws: async (refs, amount, close) => {
    const result = await Api.actions.wallet.cancelMultipleWithdraws(null, { ids: refs })(dispatch);
    if (result.success) {
      close();
      dispatch(Modals.actions.open('reversalSuccess', { amount }));
    }
  },
  selectTransactions: (transactionRefs) =>
    dispatch(Wallet.actions.selectTransactionsDeposit(transactionRefs)),
  selectAllTransactions: (transactionRefs) =>
    dispatch(Wallet.actions.selectAllTransactionsDeposit(transactionRefs)),
  deselectTransactions: (transactionRefs) =>
    dispatch(Wallet.actions.deselectTransactionsDeposit(transactionRefs)),
  deselectAllTransactions: () => dispatch(Wallet.actions.deselectAllTransactionsDeposit),
  showConfirmation: () => dispatch(Modals.actions.open('confirm')),
  goToDeposit: (close) => {
    close();
    dispatch(Modals.actions.open('deposit'));
  }
});

export default compose(
  makeModal(
    'selectReversible',
    { name: 'modal-fade', timeout: 200 },
    { className: 'selectReversible modal' }
  ),
  connect(mapStateToProps, mapDispatchToProps)
)(SelectReversibleWithdrawals);
