import { useEffect } from 'react';
import { useAppDispatch } from 'hooks/redux';
import Carousel from 'modules/Carousel';

const useSlotCategories = () => {
  const dispatch = useAppDispatch();
  useEffect(() => {
    dispatch(Carousel.actions.listSlotCategories());
  }, [dispatch]);
};

export default useSlotCategories;
