import type { RootState } from 'src/store';
import createCachedSelector, { LruObjectCache } from 're-reselect';
import type { types as BingoTypes } from 'modules/Bingo';
import type { AppFooterColumn } from './constants';
import { columnStoreKey, NAME } from './constants';
import * as m from './model';
import type { CmsContentState, Navigation } from './types';

type Selector<A extends undefined | keyof CmsContentState = undefined> = (
  state: RootState
) => A extends keyof CmsContentState ? CmsContentState[A] : CmsContentState;

export const model: Selector = (state) => (state[NAME] || m.initialState) as CmsContentState;

export const getOccupations = (state: RootState) => model(state).occupations;
export const getContentAppFooter = () => (state: RootState) => model(state).appFooter;
export const getContentAppFooterColumn = (column: AppFooterColumn) => (state: RootState) =>
  model(state)[columnStoreKey[column]] as Navigation | null;
export const getBingoRules = (state: RootState) => model(state).bingoRules;
export const getBingoRulesByType = createCachedSelector(
  getBingoRules,
  (state: RootState, bingoType: BingoTypes.GameType | BingoTypes.JackpotType) => bingoType,
  (bingoRules, bingoType) => {
    if (!bingoRules) {
      return null;
    }
    const bingoRulesComponent = bingoRules.components.find(
      ({ __component }) => __component === 'modal.bingo-rules-list'
    );
    if (!bingoRulesComponent) {
      return null;
    }
    return bingoRulesComponent.items?.find((bingoRule) => bingoRule.bingoType === bingoType);
  }
)(
  (state, bingoType) => bingoType || 'bingo-rules', // Use instance as cache key
  {
    cacheObject: new LruObjectCache({ cacheSize: 4 }) // next, current and 2 date keys will be cached
  }
);
