import React, { memo } from 'react';
import cc from 'classcat';
import CvvIcon from 'components/Icons/CvvIcon';
import type { SecureFieldElement } from '../types';
import SecureField from './SecureField';
import './cvvField.css';

type CvvFieldProps = {
  cvv: SecureFieldElement;
  isInsideSlot?: boolean;
  handleFocus: () => void;
};

const CvvField = ({ cvv, isInsideSlot, handleFocus }: CvvFieldProps) => (
  <div className={cc(['cvvField', isInsideSlot && 'cvvField--slot'])}>
    <SecureField
      field={cvv}
      focused={cvv.focus}
      notEmpty={!cvv.empty}
      handleFocus={handleFocus}
      icon={<CvvIcon />}
      iconWidth={isInsideSlot ? 30 : 42}
      isOutsideIcon={!isInsideSlot}
    />
  </div>
);

export default memo(CvvField);
