import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { Link } from 'react-router-dom';
import Api from 'services/Api';
import Modals from 'modules/Modals';
import UserBox from 'components/UserBox';
import Wallet from 'modules/Wallet';
import makeModal from 'components/Modals';
import { MagicMove } from 'components/Transitions';
import LoadingElement from 'components/Loading/LoadingElement';
import requiresData from 'components/RequiresData';
import { providerStatus } from 'lib/constants';
import User from 'modules/User';
import Callout, { CalloutVariant } from 'components/Callout';
import VerificationRequired from './VerificationRequired';
import WithdrawForm from './WithdrawForm';
import ConfirmWithdrawal from './ConfirmWithdrawal';
import NoMethodAllowed from './components/NoMethodAllowed';

const FRESHNESS = 4000;

export class _Withdraw extends Component {
  constructor(props) {
    super(props);
    this.state = {
      confirm: false,
      shouldContinue: false
    };
  }

  componentDidMount() {
    this.props.init();
  }

  handleClickConfirm = () => {
    this.setState({ confirm: true });
  };

  handleClickContinue = () => {
    this.setState({ shouldContinue: true });
  };

  render() {
    const {
      hasLoaded,
      openAddBankAccount,
      openDeposit,
      withdrawMethod,
      openLoopAlternativeMethod,
      close,
      requiresVerification
    } = this.props;
    const { confirm, shouldContinue } = this.state;

    const title = withdrawMethod && confirm ? 'Make a withdrawal' : 'Hold tight…';
    const walletAnchor = <Link to="/secure/user/account/payment-methods">Wallet</Link>;
    return requiresVerification && !shouldContinue ? (
      <VerificationRequired close={close} onClickContinue={this.handleClickContinue} />
    ) : (
      <UserBox id="withdrawBox" className="withdraw modal" title={title} closeCallback={close}>
        <MagicMove>
          {!hasLoaded ? (
            <LoadingElement key="LoadingElement" />
          ) : !withdrawMethod ? (
            <NoMethodAllowed
              description="You will need to add a payment method to make a withdrawal"
              buttonText="Add payment method"
              onClick={openDeposit}
            />
          ) : withdrawMethod && withdrawMethod.primary && withdrawMethod.openLoop ? (
            // This is only for the open loop cards. It's a little bit convoluted, but it's the only way to make it work
            // with the current structure of the components
            <div key="openLoop">
              {openLoopAlternativeMethod ? (
                !confirm ? (
                  <ConfirmWithdrawal openWithdraw={this.handleClickConfirm} close={close} />
                ) : (
                  <WithdrawForm openLoop method={openLoopAlternativeMethod} close={close} />
                )
              ) : (
                <NoMethodAllowed
                  /* eslint-disable-next-line max-len -- Doesn't make sense to split the string in two... */
                  description={
                    <Callout shouldShowIcon variant={CalloutVariant.INFORMATIONAL}>
                      Your deposit card doesn’t support withdrawals, but no worries – simply link
                      the bank account connected to it, and you're all sorted!
                      <br />
                      <a
                        href="https://help.mrq.com/hc/en-gb/articles/31452696182420-I-m-seeing-Your-desposit-card-doesn-t-support-withdrawals"
                        target="_blank"
                        rel="noreferrer"
                      >
                        Why can't I withdraw to my deposit card?
                      </a>
                    </Callout>
                  }
                  onClick={openAddBankAccount}
                  buttonText="Add bank account"
                />
              )}
            </div>
          ) : withdrawMethod && !withdrawMethod.allowed ? (
            <NoMethodAllowed
              /* eslint-disable-next-line max-len -- Doesn't make sense to split the string in two... */
              description="Oops! You need to deposit before requesting a withdrawal so we can validate your payment method."
              buttonText="Make a deposit"
              onClick={openDeposit}
            />
          ) : withdrawMethod &&
            withdrawMethod.allowed &&
            withdrawMethod.status !== providerStatus.AVAILABLE ? (
            <NoMethodAllowed
              description={
                <>
                  Your current primary method is unavailable. Please add new payment method or
                  change your primary method from your {walletAnchor}
                </>
              }
              buttonText="Add new payment method"
              onClick={openDeposit}
            />
          ) : !confirm ? (
            <ConfirmWithdrawal openWithdraw={this.handleClickConfirm} close={close} />
          ) : (
            <WithdrawForm method={withdrawMethod} close={close} />
          )}
        </MagicMove>
      </UserBox>
    );
  }
}

_Withdraw.propTypes = {
  close: PropTypes.func.isRequired,
  init: PropTypes.func.isRequired,
  openDeposit: PropTypes.func.isRequired,
  withdrawMethod: PropTypes.object,
  openLoopAlternativeMethod: PropTypes.object,
  openAddBankAccount: PropTypes.func.isRequired,
  hasLoaded: PropTypes.bool.isRequired
};

_Withdraw.defaultProps = {
  withdrawMethod: null,
  openLoopAlternativeMethod: null
};

const mapStateToProps = (state) => ({
  requiresVerification: User.selectors.getRequiresVerification(state),
  openLoopAlternativeMethod: Wallet.selectors.getOpenLoopAlternativeMethod(state),
  withdrawMethod: Wallet.selectors.getPrimaryMethod(state)
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  init: () => {
    Api.actions.wallet.listPaymentMethodsDetailed()(dispatch);
  },
  openDeposit: () => {
    ownProps.close();
    dispatch(Modals.actions.open('deposit'));
  },
  openAddBankAccount: () => {
    ownProps.close();
    dispatch(Modals.actions.open('addPayment', { method: 'ecospend' }));
  }
});

export default compose(
  makeModal('withdraw', { name: 'modal-fade', timeout: 200 }, { className: 'deposit modal' }),
  connect(mapStateToProps, mapDispatchToProps),
  requiresData(Wallet.actionTypes.AT.LIST_PAYMENT_METHODS_DETAILED._, FRESHNESS)
)(_Withdraw);
