const iOSversion = () => {
  let v = null;
  // For Chrome (or non Safari ?) browser on IOS.
  if (/iP(hone|od|ad)/.test(navigator.platform)) {
    v = navigator.appVersion.match(/OS (\d+)_(\d+)_?(\d+)?/);
    // For Safari browser on IOS.
  } else if (/MacIntel/.test(navigator.platform)) {
    v = navigator.userAgent.match(/Version\/(\d+).(\d+)?/);
  }
  if (!v) {
    return { version: false };
  }
  return {
    version: parseInt(v[1], 10)
  };
};

export default iOSversion;
