import React, { memo } from 'react';
import PropTypes from 'prop-types';
import cc from 'classcat';
import { isObject } from 'lodash';
import { NUMBER_LIST_TYPE } from '../constants';
import NumberItem from './NumberItem';

const NumberList = ({ numbers, type, maxLength }) => {
  const classes = cc([
    'numberItems',
    type === NUMBER_LIST_TYPE.BLACKJACK && 'numberItems--blackjack',
    type === NUMBER_LIST_TYPE.ROULETTE && 'numberItems--roulette',
    type === NUMBER_LIST_TYPE.UNIFORM_SQUARES && 'numberItems--uniform'
  ]);
  return (
    <div className={classes}>
      {Array.isArray(numbers) &&
        numbers.slice(0, maxLength).map((numberItem, i) => {
          if (isObject(numberItem)) {
            const withBorder =
              numberItem.border ||
              (i === 0 && type === NUMBER_LIST_TYPE.ROULETTE) ||
              (i === 0 && type === NUMBER_LIST_TYPE.UNIFORM_SQUARES) ||
              (type === NUMBER_LIST_TYPE.BLACKJACK && !numberItem.isTaken);
            const mul = numberItem.multiplier;
            const bonus = Number.isFinite(mul) && mul > 0 ? mul.toString() : '';
            return (
              <NumberItem
                // Disabled react/no-array-index-key because there is no other way to do this...
                // eslint-disable-next-line react/no-array-index-key
                key={i}
                border={withBorder}
                opaque={numberItem.isTaken}
                bonus={bonus}
                number={numberItem.value ?? numberItem.seat ?? null}
                type={type}
              />
            );
          }
          const withBorder =
            (i === 0 && type === NUMBER_LIST_TYPE.ROULETTE) ||
            (i === 0 && type === NUMBER_LIST_TYPE.UNIFORM_SQUARES);
          // Disabled react/no-array-index-key because there is no other way to do this...
          // eslint-disable-next-line react/no-array-index-key
          return <NumberItem number={numberItem} border={withBorder} key={i} type={type} />;
        })}
    </div>
  );
};

NumberList.propTypes = {
  type: PropTypes.oneOf(Object.values(NUMBER_LIST_TYPE)),
  numbers: PropTypes.array,
  maxLength: PropTypes.number
};

NumberList.defaultProps = {
  type: null,
  numbers: [],
  maxLength: 8
};

export default memo(NumberList);
