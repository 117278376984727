import * as actions from './src/actions';
import * as selectors from './src/selectors';
import * as actionTypes from './src/actionTypes';
import { NAME } from './src/constants';
import * as constants from './src/constants';
import * as model from './src/model';
import * as types from './src/types';
import reducer from './src/reducer';

export default { actions, actionTypes, NAME, reducer, model, selectors, constants };
export { constants, actionTypes, types };
