import React from 'react';
import './ribbonLabel.css';

interface RibbonLabelProps {
  label: string;
}

const RibbonLabel = ({ label }: RibbonLabelProps) => (
  <div className="ribbonLabel">
    <div className="ribbonLabel__content">
      <img alt="gift icon" src="/assets/images1/dfg-gift.svg" />
      <span className="text-truncate">{label}</span>
    </div>
  </div>
);

export default RibbonLabel;
