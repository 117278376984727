import React from 'react';
import Markdown from 'components/Markdown';
import Button from 'components/Button';
import './rewardOfferInfo.css';

interface RewardOfferInfoProps {
  title?: string;
  content?: string;
  heading?: string;
  termsAndConditions?: string;
  cta?: string;
  ctaText?: string;
  imageUrl?: string;
  onClaimOfferClick: () => void;
}

const RewardOfferInfo = ({
  title = '',
  heading = '',
  content = '',
  termsAndConditions = '',
  imageUrl = '',
  cta = '',
  ctaText,
  onClaimOfferClick
}: RewardOfferInfoProps) => (
  <div className="offerCard">
    <div className="offerCard__image offerCard__image--expanded">
      <img alt="offer" src={imageUrl} />
    </div>
    <div className="offerCard__content offerCard__content--expanded">
      <h4 className="text-truncate">{title}</h4>
      <h5 className="offerCard__label text-truncate">{heading}</h5>
      <article className="offerCard__markdownContent">
        <Markdown content={content} />
      </article>
      {(cta || ctaText) && (
        <Button
          className="button--fullWidth offerCard__claimCta"
          name="claimOffer"
          id="offerCard-cta"
          tag={cta ? 'Link' : 'Button'}
          onClick={onClaimOfferClick}
          to={cta}
          disabled={!cta}
        >
          {ctaText || 'Claim offer'}
        </Button>
      )}
    </div>
    <div className="offerCard__markdownTC">
      <Markdown content={termsAndConditions} />
    </div>
  </div>
);

export default RewardOfferInfo;
