import React, { memo } from 'react';
import RewardCardLoading from 'components/RewardCard/RewardCardLoading';

interface RewardsListLoadingProps {
  count: number;
}

const RewardsListLoading = ({ count }: RewardsListLoadingProps) => (
  <>
    {[...Array(count)].map((_, idx) => (
      // eslint-disable-next-line react/no-array-index-key -- placeholder cards
      <RewardCardLoading key={idx} />
    ))}
  </>
);

export default memo(RewardsListLoading);
