import ky from 'ky';
import { cloneDeep } from 'lodash';
import endpoints from '../../../../endpoints.json';
import { OperationTransformer } from './Transformers';

export default class EndpointManager {
  static getEndpoints(url) {
    return ky.get(url).json();
  }

  constructor(stage) {
    this.root = stage || window.location.origin;
    this.endpoints = OperationTransformer.transform(cloneDeep(endpoints), this.root);
  }
}
