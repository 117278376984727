import { useState, useEffect, useCallback } from 'react';

export const useUserInteraction = () => {
  const [interacted, setInteracted] = useState(false);

  const handleInteraction = useCallback((event) => {
    setInteracted(true);
  }, []);

  useEffect(() => {
    window.addEventListener('touchstart', handleInteraction, false);
    window.addEventListener('pointerdown', handleInteraction, false);

    return () => {
      window.removeEventListener('touchstart', handleInteraction, false);
      window.removeEventListener('pointerdown', handleInteraction, false);
    };
  }, [handleInteraction]);

  return interacted;
};
