import { Authority } from 'lib/constants';

export type TimeoutSelectOptions = Record<number, string>;

export const optionsUKGC: TimeoutSelectOptions = {
  1: '24 hours',
  2: '48 hours',
  7: '1 week',
  14: '2 weeks',
  21: '3 weeks',
  28: '4 weeks',
  35: '5 weeks',
  42: '6 weeks'
};
export const optionsMGA: TimeoutSelectOptions = {
  1: '24 hours',
  7: '1 week',
  28: '4 weeks'
};

export type Data = {
  [key in Authority]: {
    options?: TimeoutSelectOptions;
  };
};

const data: Data = {
  [Authority.UKGC]: {},
  [Authority.MGA]: {}
};

// isTest flag helps to have content for all authorities during running tests
const isTest = __ENV__.NODE_ENV === 'test';

if (isTest || __ENV__.MRQ_GAMING_AUTHORITY === 'UKGC') {
  Object.assign(data, {
    [Authority.UKGC]: {
      options: optionsUKGC
    }
  });
}

if (isTest || __ENV__.MRQ_GAMING_AUTHORITY === 'MGA') {
  Object.assign(data, {
    [Authority.MGA]: {
      options: optionsMGA
    }
  });
}

export default data;
