export enum Day {
  MONDAY = 'MONDAY',
  TUESDAY = 'TUESDAY',
  WEDNESDAY = 'WEDNESDAY',
  THURSDAY = 'THURSDAY',
  FRIDAY = 'FRIDAY',
  SATURDAY = 'SATURDAY',
  SUNDAY = 'SUNDAY'
}

export enum DayStatus {
  PLAYED = 'PLAYED',
  NOT_PLAYED = 'NOT_PLAYED',
  TODAY = 'TODAY',
  FUTURE = 'FUTURE'
}

export type DfgDayStatus = {
  [key in Day]: DayStatus;
};
