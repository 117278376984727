import { actionTypes as slotTypes } from 'modules/Slot';

/**
 * THE PAYLOAD DATA ARE THE JUST DEFAULT VALUES. The (pre-translated) action's payload is MERGED INTO the translation
 * ONLY add mappings for internal <-> external actions. Never external <-> external or internal <-> internal
 * keyMapping defines the **bidirectional** translation of the event/action keys.
 *
 * This is 2 way mapping!
 */

export default {
  keyMapping: {
    type: 'event'
  },
  entities: {
    gameLoaded: (dispatch, { meta }) =>
      dispatch({
        type: slotTypes.T.GAME_READY,
        payload: { refName: meta.refName }
      }),

    gameBusy: { type: slotTypes.T.ROUND_STARTED },
    gameNotBusy: { type: slotTypes.T.ROUND_ENDED }
  }
};
