import { Variant as SlotShape } from 'components/Tile';
import { type GameTile as GameTileType, LiveShape } from 'lib/LobbyEntities';

export const shapeSets = {
  slot: new Set<SlotShape>(Object.values(SlotShape)),
  live: new Set<LiveShape>(Object.values(LiveShape))
};

const isTileOfType = <T extends GameTileType>(
  gameTile: GameTileType,
  shapeSet: Set<SlotShape | LiveShape>
): gameTile is T => shapeSet.has(gameTile.shape);

export default isTileOfType;
