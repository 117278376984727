export const NAME = 'Analytics';

// -- Google Optimise experiment IDs (LIVE AND STAGING)
// There must be identical keys present in both LIVE_EXPERIMENTS & STAGING_EXPERIMENTS.
// Please include a URL to the experiment Dashboard also to ease debugging/ Maintenaince

const LIVE_EXPERIMENTS = {
  // Production
  // https://optimize.google.com/optimize/home/#/accounts/4457076921/containers/10392524/experiments/57
  EXPERIMENT_SIGNUP_THEME: 'xL_xqxeBSxOmvJZZGGITcA'
};

const STAGING_EXPERIMENTS = {
  // https://optimize.google.com/optimize/home/#/accounts/4703250394/containers/13480517/experiments/38
  EXPERIMENT_SIGNUP_THEME: 'L_RBuQugQ9-3hTY2lohBTA'
};

const isProd = process.env.NODE_STAGE === 'production';
export const ACTIVE_EXPERIMENTS = isProd ? LIVE_EXPERIMENTS : STAGING_EXPERIMENTS;
