import React, { useState } from 'react';
import PropTypes from 'prop-types';
import cc from 'classcat';
import Button from 'components/Button';
import './src/ecospend.css';

const EcospendButton = ({ onClick, ecospendButtonClass }) => {
  const [isOpen, setOpen] = useState(false);

  const handleInfo = () => {
    setOpen((prevIsOpen) => !prevIsOpen);
  };
  return (
    <div
      className={cc(['ecospend__first', isOpen && 'ecospend__first--open', ecospendButtonClass])}
    >
      <div>
        <Button
          buttonText="Easy Bank Transfer"
          icon="/assets/images1/mrq-coin.svg"
          className="button--speedyPay"
          onClick={onClick}
          name="addPaymentMethod"
          value="ecospend"
        />
        <div className="infoLabel" onClick={handleInfo}>
          What&apos;s this?
        </div>
        {isOpen && (
          <p>
            You can now deposit and withdraw directly from your bank account, by using Ecospend.
            It&apos;s secure, easy to use and the fastest way to get your withdrawals.
          </p>
        )}
      </div>
    </div>
  );
};

EcospendButton.propTypes = {
  ecospendButtonClass: PropTypes.string,
  onClick: PropTypes.func.isRequired
};

EcospendButton.defaultProps = {
  ecospendButtonClass: ''
};

export default EcospendButton;
