import type { LocationDescriptorObject, Action } from 'history';
import { T } from './actionTypes';
import type { Method, NavigationActionCreator } from './types';

const navigate =
  <M extends Method>(method: M): NavigationActionCreator<M> =>
  (...args) => ({
    type: T.CALL_HISTORY_METHOD,
    payload: { args, method }
  });

export const push = navigate('push');
export const replace = navigate('replace');
export const go = navigate('go');
export const goBack = navigate('goBack');
export const goForward = navigate('goForward');

export const locationChanged = (location: LocationDescriptorObject, action: Action) => ({
  type: T.LOCATION_CHANGE,
  payload: { location, action }
});

export const stripSearch = (location: LocationDescriptorObject) =>
  replace({
    ...location,
    search: ''
  });

export const redirectLoginMessage = (message: string) =>
  replace({
    pathname: '/login',
    search: `?message=${message}`,
    key: 'FAKE_PAYLOAD'
  });
