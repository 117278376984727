import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import classNames from 'classcat';
import update from 'update-immutable';
import Api from 'services/Api';
import { isLoading } from 'lib/redux-utils';
import Auth from 'modules/Auth';
import Wallet from 'modules/Wallet';
import Button from 'components/Button';
import reform from 'components/Reform';
import Fieldset from 'components/Fieldset';
import FormError from 'components/FormError';
import { providerStatus } from 'lib/constants';
import {
  withdrawAmount as withdrawAmountValidation,
  MAX_WITHDRAWAL,
  MIN_WITHDRAWAL
} from 'lib/valFunctors';
import PaymentMethod from 'components/PaymentMethod';
import { currencyFormatter, formatCurrency } from 'lib/formatters';
import Callout, { CalloutVariant } from 'components/Callout';

export const Form = reform()(
  ({
    openLoop,
    withdrawMethod,
    withdrawAmount,
    loading,
    formValid,
    handleChange,
    handleFocus,
    method,
    errorMessage,
    processingTime,
    onSubmit
  }) => {
    const buttonClass = classNames([{ 'button--loading': loading }]);
    // const allowedPaymentMethodRefs = paymentMethodRefs?.filter(
    //   (ref) => paymentMethods[ref].allowedAmount
    // );
    // let paymentOptions;
    //
    // if (!isEmpty(allowedPaymentMethodRefs)) {
    //   paymentOptions = allowedPaymentMethodRefs.map((ref) => {
    //     const method = paymentMethods[ref];
    //     if (method.allowedAmount) {
    //       const amountIn =
    //         withdrawAmount.value && method.allowedAmount * 0.01 * withdrawAmount.value;
    //       return (
    //         <div key={ref} className="deposit-method deposit-method--withdraw deposit-method--card">
    //           <img
    //             className="deposit-method__icon"
    //             src={
    //               logos[
    //                 method.operator ||
    //                   (method.type === types.MOBILE ? 'defaultMobile' : 'defaultCard')
    //               ]
    //             }
    //             alt={method.operator || 'Card'}
    //           />
    //           <span className="deposit-method__identifier">
    //             {method.type === types.CARD && 'Ending in'} <b>{method.identifier}</b>
    //           </span>
    //           {amountIn ? (
    //             <span className="deposit-method__detail">In: {format(amountIn)}</span>
    //           ) : null}
    //         </div>
    //       );
    //     } else {
    //       return null;
    //     }
    //   });
    // }

    return (
      <form id="withdrawForm" onSubmit={onSubmit}>
        <p className="userBox__intro">
          {openLoop ? (
            <Callout shouldShowIcon variant={CalloutVariant.WARNING}>
              Your deposit card doesn’t support withdrawals, but no worries - you can withdraw to
              your bank account!
              <br />
              <a
                href="https://help.mrq.com/hc/en-gb/articles/31452696182420-I-m-seeing-Your-desposit-card-doesn-t-support-withdrawals"
                target="_blank"
                rel="noreferrer"
              >
                Why can't I withdraw to my deposit card?
              </a>
            </Callout>
          ) : (
            'Your withdrawal is processed to your primary payment method '
          )}
          {processingTime && (
            <b>
              within {processingTime} {processingTime > 1 ? 'hours' : 'hour'}!
            </b>
          )}
        </p>
        <div className="fieldset fieldset--fullflex">
          <PaymentMethod
            className="paymentMethod--withdraw"
            key={method.ref}
            type={method.type}
            operator={method.operator}
            identifier={method.identifier}
            expireDate={method.expireDate}
            cardholder={method.cardholder}
            isUnavailable={method.status === providerStatus.UNAVAILABLE}
            bankLogo={method.bankLogo}
            method={method}
          />
        </div>
        <Fieldset
          field={withdrawAmount}
          inputType="number"
          min={MIN_WITHDRAWAL.toString()}
          max={MAX_WITHDRAWAL.toString()}
          step="1"
          name="withdrawAmount"
          labelText={`Withdraw amount (${currencyFormatter.symbol})`}
          onChange={handleChange}
          onFocus={handleFocus}
        />
        <div className="fieldset fieldset--centerflex">
          <Button
            className={buttonClass}
            disabled={!formValid}
            loading={loading}
            type="submit"
            formNoValidate
            title={
              !formValid
                ? withdrawMethod.error ||
                  withdrawAmount.error ||
                  'Please fill the form or contact support'
                : ''
            }
          >
            Withdraw
          </Button>
        </div>
        <FormError errorMessage={errorMessage} />
      </form>
    );
  }
);

const fieldsTemplate = {
  // withdrawMethod: {
  //   required: true,
  //   initial: '',
  //   onChange: (_, { withdrawAmount }) => (withdrawAmount.value ? ['withdrawAmount'] : true), // Revalidate withdrawAmount on change
  //   error: 'Required'
  // },
  withdrawAmount: {
    required: true,
    error: `Minimum amount ${formatCurrency(10)}`,
    onChange: withdrawAmountValidation
  }
};

export const _WithdrawForm = ({ init, withdraw, ...props }) => {
  const allowedPaymentMethodRefs = props.paymentMethodRefs?.filter(
    (ref) => props.paymentMethods[ref].allowedAmount
  );
  const fields = update(fieldsTemplate, {
    withdrawAmount: {
      onChange: { $set: withdrawAmountValidation(props.balance) }
    },
    withdrawMethod: {
      initial: { $set: (allowedPaymentMethodRefs && allowedPaymentMethodRefs[0]) || '' }
    }
  });

  useEffect(() => {
    init();
  }, [init]);

  return <Form fields={fields} submit={withdraw} {...props} />;
};

_WithdrawForm.propTypes = {
  openLoop: PropTypes.bool,
  init: PropTypes.func.isRequired,
  withdraw: PropTypes.func.isRequired,
  paymentMethods: PropTypes.object.isRequired,
  paymentMethodRefs: PropTypes.array
};

_WithdrawForm.defaultProps = {
  paymentMethodRefs: [],
  openLoop: false
};

const mapStateToProps = (state) => ({
  loading: isLoading(state, [Auth.AT.FINGERPRINT._, Wallet.actionTypes.AT.WITHDRAW._]),
  balance: Wallet.selectors.getBalance(state),
  paymentMethods: Wallet.selectors.getPaymentMethods(state),
  paymentMethodRef: Wallet.selectors.getPrimaryMethodRef(state),
  processingTime: Wallet.selectors.getWithdrawProcessingTime(state)
});

const mapDispatchToProps = (dispatch, { close }) => ({
  init: () => {
    Api.actions.wallet.withdrawProcessingTime()(dispatch);
  },
  withdraw: async ({ withdrawAmount }) => {
    const { value: deviceInfo } = await dispatch(Auth.actions.getDeviceFingerprint());
    const res = await Api.actions.wallet.withdraw(null, {
      amount: withdrawAmount,
      deviceInfo: deviceInfo
    })(dispatch);
    close();
    return res;
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(_WithdrawForm);
