import React from 'react';
import PropTypes from 'prop-types';
import cc from 'classcat';
import Button from 'components/Button';

interface PaymentConfirmationProps {
  onAuthWithUrl: () => void;
  onAuthoriseWithPhoneDesktop: () => void;
  onViewOtherOptions: () => void;
  openTermsConditions: () => void;
  openPrivacyPolicy: () => void;
  isMobile: boolean;
}

const PaymentConfirmation = ({
  onAuthWithUrl,
  onViewOtherOptions,
  onAuthoriseWithPhoneDesktop,
  openTermsConditions,
  openPrivacyPolicy,
  isMobile
}: PaymentConfirmationProps) => (
  <div>
    <div className="ecospendConfirmation__cta">
      {!isMobile && (
        <Button
          title="View other options"
          name="viewOtherOptions"
          type="button"
          className={cc(['action combo--horizontal', 'button--fullWidth', 'button__grey'])}
          buttonText="View other options"
          onClick={onViewOtherOptions}
        />
      )}
      <Button
        title={isMobile ? 'Authorise' : 'Authorise with phone'}
        name="authorizePayment"
        value={isMobile ? 'mobile' : 'desktop'}
        type="button"
        className={cc(['action combo--horizontal', 'button--fullWidth'])}
        buttonText={isMobile ? 'Authorise' : 'Authorise with phone'}
        onClick={isMobile ? onAuthWithUrl : onAuthoriseWithPhoneDesktop}
      />
    </div>
    <div className="toc-text ecospend-toc-privacy">
      <div className="checkbox checkbox--wide">
        <label htmlFor="iUnderstand">
          We will ask your bank for your <b>name</b>, your <b>sort code</b> and{' '}
          <b>account number</b>. This information will be stored securely and it will not be used
          for any other purpose.
        </label>
      </div>
    </div>
    <div className="toc-text ecospend-toc-privacy">
      <div className="checkbox checkbox--wide">
        <label htmlFor="iUnderstand">
          This service is provided by Ecospend Technologies Limited, an authorised payment
          institution regulated by the Financial Conduct Authority (FCA), needs your permission to
          securely initiate a payment order from your bank. By selecting authorise you accept
          our&nbsp;
          <a className="underline" onClick={openTermsConditions}>
            terms & conditions
          </a>{' '}
          and{' '}
          <a className="underline" onClick={openPrivacyPolicy}>
            privacy policy
          </a>
        </label>
      </div>
    </div>
  </div>
);

PaymentConfirmation.propTypes = {
  onAuthWithUrl: PropTypes.func.isRequired,
  onViewOtherOptions: PropTypes.func.isRequired,
  openTermsConditions: PropTypes.func.isRequired,
  openPrivacyPolicy: PropTypes.func.isRequired,
  isMobile: PropTypes.bool.isRequired
};

export default PaymentConfirmation;
