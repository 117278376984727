import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import classNames from 'classcat';
import Media from 'components/Media';
import { formatDate, formatCurrency } from 'lib/formatters';
import { isLoading } from 'lib/redux-utils';
import { logos } from 'lib/constants';
import Bingo from 'modules/Bingo'; // TODO: Remove Bingo related code to optimize bundle size
import Slot from 'modules/Slot';
import Wallet from 'modules/Wallet';
import Button from 'components/Button';
import cfImage from 'lib/cfImage';
import { POSITIVE, NEGATIVE, INSTRUMENT } from '../../lib/transactionTypes';
import './transactions.css';

const creditIcon = '/assets/images1/lobby/credit.svg';
const negativeAdjustmentIcon = '/assets/images1/lobby/credit-gray.svg';
const withdrawIcon = '/assets/images1/lobby/withdraw.svg';
const withdrawDeniedIcon = '/assets/images1/account/withdrawal-decline.svg';
const depositVoidIcon = '/assets/images1/account/deposit-void.svg';
const tournamentWinIcon = '/assets/images1/account/tournament-win.svg';
const tournamentEntryFeeIcon = '/assets/images1/account/tournament-entry-fee.svg';
const defaultGameIcon = '/assets/images1/account/game-thumb.png';

class _Transaction extends Component {
  handleClick = () => {
    const { onCheck, refName, selected } = this.props;
    onCheck(refName, selected);
  };

  render() {
    const {
      accountType,
      type,
      amount,
      icon,
      title,
      date,
      link,
      refName,
      cancel,
      style,
      containerRef,
      showPlaceholder,
      checkbox,
      selected,
      status,
      loadingReverse
    } = this.props;

    const isFree = amount === 0 || (accountType === 'FREE_ROUND' && NEGATIVE[type]);
    const formattedAmount = isFree
      ? 'FREE'
      : NEGATIVE[type]
      ? `-${formatCurrency(amount, { precise: false })}`
      : formatCurrency(amount, { precise: false });
    const transactionClass = classNames([
      'transaction',
      {
        'transaction--positive': Object.keys(POSITIVE).includes(type) || isFree,
        'transaction--negative': Object.keys(NEGATIVE).includes(type) && !isFree
      }
    ]);
    const iconStyle = icon && { backgroundImage: `url('${icon}')` };
    const isCancelled = status === 'Cancelled';

    return (
      <div className="transaction-container" style={style} ref={containerRef}>
        <div className={transactionClass}>
          {checkbox ? (
            <div className="checkbox">
              <input type="checkbox" onChange={this.handleClick} checked={selected} />
              <span className="checker" />
            </div>
          ) : null}
          {link ? (
            <Link
              to={link}
              className="transaction__icon"
              style={iconStyle}
              alt={title}
              title={title}
            >
              {icon ? '' : title}
            </Link>
          ) : (
            <div className="transaction__icon" style={iconStyle} />
          )}
          <div className="transaction__details">
            <Media query="(max-width: 570px)">
              {(matches) =>
                !matches ? (
                  <span className="transaction__date">
                    {showPlaceholder ? 'Loading...' : formatDate(date, "do MMM yyyy 'at' HH:mm")}
                  </span>
                ) : (
                  <span className="transaction__date">
                    {showPlaceholder ? 'Loading...' : formatDate(date, "dd/MM/yy 'at' HH:mm")}
                  </span>
                )
              }
            </Media>
            <span className="transaction__title">{title}</span>
          </div>
          <span className="transaction__type">{POSITIVE[type] || NEGATIVE[type]}</span>
          <span className="transaction__amount">
            {showPlaceholder ? 'Loading...' : formattedAmount}
          </span>
        </div>
        <div className={`transaction--clone ${transactionClass}`}>
          {checkbox ? (
            <div className="checkbox">
              <input type="checkbox" onChange={this.handleClick} checked={selected} />
              <span className="checker" />
            </div>
          ) : null}
          {link ? (
            <Link
              to={link}
              className="transaction__icon"
              style={iconStyle}
              alt={title}
              title={title}
            >
              {icon ? '' : title}
            </Link>
          ) : (
            <div className="transaction__icon" style={iconStyle} />
          )}
          <div className="transaction__details">
            {showPlaceholder ? (
              'Loading...'
            ) : (
              <>
                <span className="transaction__title">ID: {refName}</span>
                <span className="transaction__type">{`${POSITIVE[type] || NEGATIVE[type]}${
                  isCancelled ? ' (cancelled)' : ''
                }`}</span>
              </>
            )}
          </div>
          {cancel && !selected ? (
            <span className="transaction__cancel">
              <Button
                name={refName}
                className="cancel close"
                onClick={cancel}
                title="Reverse withdrawal"
                loading={loadingReverse}
              />
            </span>
          ) : null}
          <span className="transaction__amount">
            {showPlaceholder ? 'Loading...' : formattedAmount}
          </span>
        </div>
      </div>
    );
  }
}

// Nothing is required since in ListInfiniteScroll it is called with only showPlaceholder prop which defaults to false
_Transaction.propTypes = {
  // eslint-disable-next-line react/require-default-props -- nothing is required according to the comment above
  type: PropTypes.string,
  // eslint-disable-next-line react/require-default-props -- nothing is required according to the comment above
  date: PropTypes.string,
  amount: PropTypes.number,
  icon: PropTypes.string,
  // eslint-disable-next-line react/no-unused-prop-types -- prop type is used
  operator: PropTypes.string,
  // eslint-disable-next-line react/require-default-props -- nothing is required according to the comment above
  refName: PropTypes.string,
  title: PropTypes.string,
  // eslint-disable-next-line react/no-unused-prop-types -- prop type is used
  instrument: PropTypes.string,
  link: PropTypes.string,
  status: PropTypes.string,
  // eslint-disable-next-line react/no-unused-prop-types -- prop type is used
  deposit: PropTypes.bool,
  showPlaceholder: PropTypes.bool,
  // eslint-disable-next-line react/no-unused-prop-types -- prop type is used
  gameRef: PropTypes.string
};

_Transaction.defaultProps = {
  amount: 0,
  icon: defaultGameIcon,
  title: '',
  operator: 'unknown',
  instrument: null,
  link: null,
  status: null,
  showPlaceholder: false,
  deposit: false,
  gameRef: null
};

const mapStateToProps = (state, ownProps) => {
  const { instrument, gameRef, operator, type, deposit } = ownProps;
  if (type === 'WITHDRAW_DENIED') {
    return {
      icon: withdrawDeniedIcon
    };
  }
  if (type === 'REFUND') {
    return {
      icon: withdrawIcon
    };
  }
  if (type === 'REFUND_DENIED') {
    return {
      icon: withdrawDeniedIcon
    };
  }
  if (type === 'NEGATIVE_ADJUSTMENT') {
    return {
      icon: negativeAdjustmentIcon
    };
  }
  if (type === 'DEPOSIT_VOID') {
    return {
      icon: depositVoidIcon
    };
  }
  if (type === 'SLOT_TOURNAMENT_ENTRY_FEE') {
    return {
      icon: tournamentEntryFeeIcon,
      title: 'Tournament'
    };
  }
  if (type === 'SLOT_TOURNAMENT_WIN') {
    return {
      icon: tournamentWinIcon,
      title: 'Tournament'
    };
  }
  switch (instrument) {
    case INSTRUMENT.BINGO: {
      const room = Bingo.selectors.getInstance(state, gameRef, 'assets');
      return {
        icon: (room && room.logoImageUrl) || defaultGameIcon,
        title: room && room.name,
        link: room && room.type && `/secure/bingo/${room.type}/${gameRef}`
      };
    }
    case INSTRUMENT.SLOT: {
      let room = Slot.selectors.getRoom(state, gameRef);
      if (!room) {
        room = Slot.selectors.getRoomById(state, gameRef);
      }
      return {
        // Since we switched to the new square thumbnails, some of the old games which have been disabled before the switch
        // won't have updated "lobbySquareImageUrl". That's why we use lobbyImageUrl as a fallback. We don't use cloudflare CDN for those images
        // because the old URLs (lobbyImageUrl) don't specify the format of the image and this breaks cloudflare's caching mechanism
        icon:
          (room &&
            (room.lobbySquareImageUrl
              ? cfImage(room.lobbySquareImageUrl, {
                  width: 64,
                  height: 64,
                  quality: 88
                })
              : room.lobbyImageUrl)) ||
          defaultGameIcon,
        title: room && room.name,
        link: room && room.ref && `/secure/games/${room.ref}`
      };
    }
    case INSTRUMENT.CARD: {
      const selectedRefs =
        Wallet.selectors[
          deposit ? 'getSelectedTransactionsRefsDeposit' : 'getSelectedTransactionsRefs'
        ](state);
      return {
        icon: type === 'CREDIT' ? creditIcon : logos[operator],
        title: '',
        selected: selectedRefs && selectedRefs.includes(ownProps.refName),
        loadingReverse: isLoading(state, [Wallet.actionTypes.AT.WITHDRAW_CANCEL._])
      };
    }
    case INSTRUMENT.MOBILE: {
      return {
        icon: type === 'CREDIT' ? creditIcon : logos[operator],
        title: ''
      };
    }
    case INSTRUMENT.PAYPAL: {
      return {
        icon: type === 'CREDIT' ? creditIcon : logos[operator],
        title: ''
      };
    }
    case INSTRUMENT.BANK_ACCOUNT: {
      return {
        icon: type === 'CREDIT' ? creditIcon : logos[instrument],
        title: ''
      };
    }
    case INSTRUMENT.CREDIT: {
      return {
        icon: creditIcon,
        title: ''
      };
    }
    default:
      return {};
  }
};

// PureComponent: SIMPLE props only, ALL children must be PURE too!
export default connect(mapStateToProps)(_Transaction);
