import { cloneElement, Children, memo } from 'react';
import cc from 'classcat';
import type { TypographyVariant } from 'components/Typography/constants';
import { defaultVariant, classMap } from 'components/Typography/constants';
import './typography.css';

export interface TypographyProps {
  variant?: TypographyVariant;
  tabletVariant?: TypographyVariant;
  desktopVariant?: TypographyVariant;
  children: JSX.Element;
}

const Typography = ({
  variant = defaultVariant,
  tabletVariant,
  desktopVariant,
  children
}: TypographyProps) => {
  const finalClassName = cc({
    typography: true,
    [classMap[variant]]: !!variant,
    [`mqt-${classMap[tabletVariant!]}`]: !!tabletVariant,
    [`mqd-${classMap[desktopVariant!]}`]: !!desktopVariant,
    [children.props.className]: !!children.props.className
  });

  return cloneElement(Children.only(children), {
    className: finalClassName
  });
};

export default memo(Typography);
