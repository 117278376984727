import React from 'react';
import { Helmet } from 'react-helmet-async';
import { Route, Switch } from 'react-router';
import loadable from '@loadable/component';
import MainLayout from 'components/MainLayout';
import LobbyMiddle from 'components/LobbyMiddle';
import MaintenanceNotice from 'components/MaintenanceNotice';
import Announcements from 'components/Notifications/Announcements';
import FullscreenLoading from 'components/Loading/Fullscreen';
import './lobby.css';

const ViewAll = loadable(
  () =>
    import(
      /* webpackPrefetch: true, webpackChunkName: "LobbyViewAll" */ `components/DynamicLobby/ViewAll`
    ),
  {
    fallback: <FullscreenLoading />
  }
);

interface LobbyProps {
  location: Location;
}

const Lobby = ({ location }: LobbyProps) => (
  <MainLayout
    className="lobby"
    middle={
      <>
        <MaintenanceNotice pathname={location.pathname} />
        <Announcements />
        <Switch>
          <Route path="/secure/lobby/:section/:category/:entityRef" component={ViewAll} />
          <Route path="/secure/lobby" component={LobbyMiddle} />
        </Switch>
      </>
    }
  >
    <Helmet>
      <title>MrQ</title>
    </Helmet>
  </MainLayout>
);

export default Lobby;
