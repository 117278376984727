export const initialState = {
  totalElements: {},
  categoryRefs: {},
  categoryOffset: {},
  categoryLoading: {},
  entities: {},
  result: null
};

export const rooms = (model) => model.entities ?? undefined;
export const refs = (model) => model.result ?? undefined;
