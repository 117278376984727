const focusable = ['INPUT', 'SELECT'];

export default function focusNext(elem: HTMLElement, delay: number) {
  let next = elem.nextElementSibling;
  let levels = 0;
  let found = false;
  while (next && !found && levels < 3) {
    if (focusable.includes(next?.tagName)) {
      found = true;
      if (delay) {
        // eslint-disable-next-line no-loop-func
        setTimeout(() => (next as HTMLElement)?.focus(), delay);
      } else {
        (next as HTMLElement)?.focus();
      }
    } else {
      next = next.firstElementChild;
      levels++;
    }
  }
}
