import React, { memo, useCallback } from 'react';
import type { ReactNode } from 'react';
import loadable from '@loadable/component';
import cc from 'classcat';
import Heading from 'components/Heading';
import Button from 'components/Button';
import useDynamicAction from '../src/useDynamicAction';
import './dialogHeader.css';

const DialogClose = loadable(() => import('../Close'));

interface DialogHeaderProps {
  /** The icon image url */
  icon?: string;
  /** Action after the Header Close button is clicked */
  onClose?: () => void;
  /** Controls text alignment */
  titleCentered?: boolean;
  /** The heading level, for example 2 corresponds to `<h2 />` */
  level?: 1 | 2 | 3 | 4 | 5 | 6;
  children?: ReactNode;
}

interface DialogHeaderAdapterProps extends DialogHeaderProps {
  closeButtonActionRef?: string;
}

const DialogHeaderBase = ({
  icon,
  level = 2,
  titleCentered = false,
  onClose,
  children = null
}: DialogHeaderProps) => (
  <header className="dialogHeader">
    {icon ? <img alt="" src={icon} className="dialogHeader__icon" /> : null}
    {children ? (
      <Heading
        className={cc([
          'dialogHeader__heading',
          titleCentered && 'dialogHeader__heading--centered'
        ])}
        level={level}
      >
        {children}
      </Heading>
    ) : null}
    {onClose ? (
      <DialogClose onClose={onClose}>
        <Button
          title="Close"
          name="close"
          id="dialog-close"
          className="dialogHeader__close"
          icon="/assets/images1/close-secondary.svg"
          unstyled
        />
      </DialogClose>
    ) : null}
  </header>
);

export const DialogHeader = memo(DialogHeaderBase);

const DialogHeaderAdapter = ({ closeButtonActionRef, ...rest }: DialogHeaderAdapterProps) => {
  const action = useDynamicAction();
  const handleClose = useCallback(() => {
    action(closeButtonActionRef);
  }, [action, closeButtonActionRef]);

  return <DialogHeader onClose={closeButtonActionRef ? handleClose : undefined} {...rest} />;
};

export default memo(DialogHeaderAdapter);
