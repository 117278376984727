import { actionTypeCreator, ASYNC } from 'lib/redux-utils';
import { NAME } from './constants';

export const SELECT_TOP = `${NAME}/SELECT_TOP`;
export const SELECT_BOTTOM = `${NAME}/SELECT_BOTTOM`;
export const RESET = `${NAME}/RESET`;
export const UPDATE_CHAT_COUNTER = `${NAME}/UPDATE_CHAT_COUNTER`;

const namespace = actionTypeCreator(NAME);

export const AT = namespace({
  LIST_SLOT_CATEGORIES: ASYNC,
  LIST_LIVE_CATEGORIES: ASYNC
});
