import React, { memo, useEffect, useRef } from 'react';
import type { RefObject } from 'react';
// eslint-disable-next-line max-len -- long package name ¯\_(ツ)_/¯
import * as constants from '@lindar-joy/plugin-default-event-tracking-advanced-browser/lib/cjs/constants';
import amplitude from 'lib/analytics';
import withLazyStyle from 'components/LazyStyle';
import fieldsetStyle from 'css/components/fieldset.css?lazy';
import type { FormErrorAnalytics } from './types';

/**
 * Extract properties and prepare them for the "Form Error" event. Falsy values turn to undefined so that they're
 * excluded from the event payload.
 */
type ExtractPropertiesProps = {
  labelRef: RefObject<HTMLLabelElement>;
  sanitizedFieldValue?: string;
  errorMessage?: string;
};
export const extractProperties = ({
  labelRef,
  sanitizedFieldValue,
  errorMessage
}: ExtractPropertiesProps): FormErrorAnalytics | undefined => {
  const fieldElement: HTMLInputElement = labelRef.current?.control as HTMLInputElement;
  const fieldName = fieldElement.name;
  if (!fieldName) return;
  const formName = labelRef.current?.form?.name;
  const labelElement = fieldElement.labels?.length
    ? Array.from(fieldElement.labels).find((label) => label?.role !== 'alert') // alert role is for error labels
    : null;
  return {
    [constants.AMPLITUDE_EVENT_PROP_ELEMENT_ID]: fieldElement?.id,
    [constants.AMPLITUDE_EVENT_PROP_ELEMENT_CLASS]: fieldElement?.className,
    [constants.AMPLITUDE_EVENT_PROP_ELEMENT_LABEL]: labelElement?.innerText,
    [constants.AMPLITUDE_EVENT_PROP_FORM_NAME]: formName || undefined,
    [constants.AMPLITUDE_EVENT_PROP_ELEMENT_NAME]: fieldName,
    [constants.AMPLITUDE_EVENT_PROP_ELEMENT_VALUE]: sanitizedFieldValue,
    'Field Error': errorMessage || undefined
  };
};

type FieldErrorProps = {
  errorMessage?: string;
  /** **Warning:** Only pass sanitized values, as this will be tracked in analytics tools. */
  sanitizedFieldValue?: string;
  fieldDomId: string;
  fieldRef?: RefObject<any>;
};

const FieldError = ({
  errorMessage,
  fieldDomId,
  sanitizedFieldValue,
  fieldRef
}: FieldErrorProps) => {
  const labelRef = useRef<HTMLLabelElement>(null);
  // Ref used to capture latest values for unmount/cleanup
  const latestFieldValue = useRef<string | undefined>('');
  const lastErrorMessage = useRef<string | undefined>('');

  useEffect(
    () => () => {
      // Track when error clears on unmount
      const properties = extractProperties({
        labelRef,
        sanitizedFieldValue: latestFieldValue.current,
        errorMessage: lastErrorMessage.current
      });
      if (properties && fieldRef?.current) amplitude.track('Field Input Cleared', properties);
    },
    [fieldRef]
  );

  useEffect(() => {
    latestFieldValue.current = sanitizedFieldValue;
  }, [sanitizedFieldValue]);

  useEffect(() => {
    lastErrorMessage.current = errorMessage;
  }, [errorMessage]);

  useEffect(() => {
    if (errorMessage) {
      const properties = extractProperties({
        labelRef,
        sanitizedFieldValue: latestFieldValue.current,
        errorMessage: lastErrorMessage.current
      });
      if (properties) amplitude.track('Field Input Rejected', properties);
    }
  }, [errorMessage]);

  return (
    <label ref={labelRef} htmlFor={fieldDomId} className="errorMsg" role="alert">
      <span>{errorMessage}</span>
    </label>
  );
};

export default memo(withLazyStyle(fieldsetStyle)(FieldError));
