import { useEffect, useRef } from 'react';
import { useAppDispatch, useAppSelector } from 'hooks/redux';
import Analytics from 'modules/Analytics';
import { isLoading } from 'lib/redux-utils';
import getExperimentData from '../../helpers/getExperimentData';
import type { ExperimentData } from '../../types';

export interface UseExperimentData extends ExperimentData {
  exposeExperiment: () => void;
}

export const useExperimentData = (flag: string): UseExperimentData | undefined => {
  const dispatch = useAppDispatch();
  const experimentDataRef = useRef<UseExperimentData | undefined>();
  const shouldRefreshExperimentFlag = useAppSelector((state) =>
    isLoading(state, [Analytics.AT.REFRESH_EXPERIMENT._])
  );

  useEffect(() => {
    if (shouldRefreshExperimentFlag) {
      const experimentData = getExperimentData(flag);
      experimentDataRef.current = {
        ...experimentData,
        exposeExperiment: () => dispatch(Analytics.actions.exposeExperiment(experimentData))
      };
    }
  }, [dispatch, flag, shouldRefreshExperimentFlag]);

  if (!experimentDataRef.current) {
    const experimentData = getExperimentData(flag);
    experimentDataRef.current = {
      ...experimentData,
      exposeExperiment: () => dispatch(Analytics.actions.exposeExperiment(experimentData))
    };
  }

  return experimentDataRef.current;
};

export default useExperimentData;
