import { useCallback, useEffect, useRef, useState } from 'react';

/**
 * @param {number} time Total time to countdown.
 * @param {number} updateRate The update rate of the countdown in milliseconds.
 */

const useCountdown = (time: number, updateRate = 1000): [number, (time: number) => void] => {
  const initialTime = useRef(time);
  const [remainingTime, setRemainingTime] = useState(time);
  const [reset, setReset] = useState(true);

  const resetTime = useCallback(
    (newTime = initialTime) => {
      setReset((r) => !r); // just a simple toggle to trigger the useEffect bellow
      setRemainingTime(newTime);
    },
    [setRemainingTime, setReset]
  );

  useEffect(() => {
    const interval = setInterval(() => {
      setRemainingTime((currentTime) => {
        const newRemaining = currentTime - updateRate;
        if (newRemaining <= 0) {
          clearInterval(interval);
        }
        return Math.max(0, newRemaining);
      });
    }, updateRate);
    return () => clearInterval(interval);
  }, [reset, updateRate]);
  return [remainingTime, resetTime];
};

export default useCountdown;
