import React from 'react';
import 'components/AddPayment/src/ecospend.css';

interface QRAuthorisationProps {
  qrCode: string;
}

const QRAuthorisation = ({ qrCode }: QRAuthorisationProps) => (
  <div className="ecospendAuth">
    <div className="fieldset--centerflex">
      <div className="ecospendAuth__qrCode">
        <img alt="QR Code" src={`data:image/png;base64,${qrCode}`} />
      </div>
    </div>
    <div className="ecospend__instruction">
      <b>Scan the QR code with your phone’s camera</b>
    </div>
    <span className="ecospend__tip">You will be redirected to your mobile banking.</span>
  </div>
);

export default QRAuthorisation;
