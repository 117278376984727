import Api from 'services/Api';
import * as t from './actionTypes';

async function _connect() {
  return Promise.all([
    Api.request.user.getAvatar(),
    Api.request.user.getTheme(),
    Api.request.user.getSettings()
  ]).then(([avatar, theme, settings]) => ({
    avatar: avatar.data,
    theme: theme.data,
    enableGameSounds: settings.data.enableGameSounds === true,
    gameSoundVoice: settings.data.gameSoundVoice,
    enableStickyChat: settings.data.enableStickyChat === true,
    enableStatefulChat: settings.data.enableStatefulChat === true
  }));
}

export const send = (provider, subscription, message) => ({
  type: `${provider}/${t.WS_SEND}`,
  payload: {
    subscription,
    message
  }
});

export const connect = (provider) => ({
  type: `${provider}/${t.WS_CONNECT}`
});

export const connected = (provider) => ({
  type: `${provider}/${t.WS_CONNECT}_FULFILLED`
});

export const connecting = (provider) => ({
  type: `${provider}/${t.WS_CONNECT}_PENDING`
});

export const connectionFailed = (provider, command, subscription, reason) => ({
  type: `${provider}/${t.WS_CONNECT}_REJECTED`,
  payload: { command, subscription, reason }
});

export const subscribed = (provider, topic) => ({
  type: `${provider}/${t.WS_SUBSCRIBE}_FULFILLED`,
  payload: topic
});

export const subscribing = (provider) => ({
  type: `${provider}/${t.WS_SUBSCRIBE}_PENDING`
});

export const unsubscribing = (provider) => ({
  type: `${provider}/${t.WS_SUBSCRIBE}_PENDING`
});

export const unsubscribed = (provider, topic) => ({
  type: `${provider}/${t.WS_UNSUBSCRIBE}_FULFILLED`,
  payload: topic
});

export const unsubscribeReject = (provider, topic) => ({
  type: `${provider}/${t.WS_UNSUBSCRIBE}_REJECTED`,
  payload: topic
});

export const disconnectAll = {
  type: '@@Ws/WS_DISCONNECT_ALL'
};
