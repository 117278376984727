import { merge, isEmpty, difference, partialRight } from 'lodash';
import update from 'update-immutable';
import { handleActions } from 'redux-actions';
import getType from 'lib/getType';
import module from 'lib/module';
import Socket from 'services/Socket';
import * as Router from 'modules/Router';
import {
  getRefName,
  getInstance,
  getWinningUsernames,
  getWinners,
  getAmount
} from '../lib/action-utils';
import { initialState, refs } from './model';
import { AT, T } from './actionTypes';
import { NAME, STATUS, ephemeral } from './constants';

const NOT_FOUND = '5585';

const _ = partialRight.placeholder;

const roomExists = (state, refName) => refs(state) && refs(state).includes(refName);

const createRoom = (refName, instance, key, payload) => ({
  entities: {
    $merge: {
      // Using $set here erases the other rooms, useful to save memory
      [refName]: {
        [instance]: {
          [key]: payload
        }
      }
    }
  }
});

const createInstance = (refName, instance, key, payload) => ({
  entities: {
    [refName]: {
      $merge: {
        [instance]: {
          [key]: payload
        }
      }
    }
  }
});

const createKey = (refName, instance, key, payload) => ({
  entities: {
    [refName]: {
      [instance]: {
        $merge: {
          [key]: payload
        }
      }
    }
  }
});

const setKey = (refName, instance, key, payload) => ({
  entities: {
    [refName]: {
      [instance]: {
        [key]: { $set: payload }
      }
    }
  }
});

const mergeKey = (refName, instance, key, payload) => ({
  entities: {
    [refName]: {
      [instance]: { $merge: payload }
    }
  }
});

const pushToKey = (refName, instance, key, payload) => ({
  entities: {
    [refName]: {
      [instance]: {
        [key]: { $push: payload }
      }
    }
  }
});

const spliceFromKey = (refName, instance, key, payload, state) => {
  const arr = state.entities[refName][instance][key];
  const index = arr.indexOf(payload[0]);
  const length = payload.length;
  return {
    entities: {
      [refName]: {
        [instance]: {
          [key]: { $splice: [[index, length]] }
        }
      }
    }
  };
};

const emptyKey = (refName, instance, key, payload, state) => {
  const arr = state.entities[refName][instance][key];
  const length = arr.length;
  return {
    entities: {
      [refName]: {
        [instance]: {
          [key]: { $splice: [[0, length]] }
        }
      }
    }
  };
};

const updateInstanceKey = (func, state, action, key) => {
  const refName = getRefName(action);
  if (refName) {
    const instance = getInstance(action) || 'next';
    const room = state.entities[refName];
    const args = [refName, instance, key, action.payload, state];
    if (!room) {
      const updatedEntities = update(state, createRoom(...args));
      return update(updatedEntities, {
        result: {
          $apply: (val) => {
            if (!Array.isArray(val)) {
              return [refName];
            } else if (Array.isArray(val) && !val.includes(refName)) {
              val.push(refName);
              return val;
            } else {
              return val;
            }
          }
        }
      });
    } else if (!room[instance]) {
      return update(state, createInstance(...args));
    } else if (!room[instance][key]) {
      return update(state, createKey(...args));
    } else if (room[instance][key]) {
      return update(state, func(...args));
    } else {
      return state;
    }
  } else {
    return state;
  }
};

const updateRoomRef = (refName) => ({
  result: {
    $apply: (val) => {
      if (!Array.isArray(val)) {
        return [refName];
      } else if (Array.isArray(val) && !val.includes(refName)) {
        val.push(refName);
        return val;
      } else {
        return val;
      }
    }
  }
});

const mergeToRoom = (refName, state, obj, instance) => {
  const updatedEntities = update(state, {
    entities: {
      [refName]: {
        [instance]: { $merge: obj } // TODO: Explicit $set for every property is more performant
      }
    }
  });
  return update(updatedEntities, updateRoomRef(refName));
};

const clearInstance = (refName, state, obj, instance, status) => {
  const updatedEntities = update(state, {
    entities: {
      [refName]: {
        $unset: instance,
        notFound: { $set: status === NOT_FOUND }
      }
    }
  });
  return update(updatedEntities, updateRoomRef(refName));
};

const mergeToInstance = (refName, instance, state, obj) => {
  const updatedEntities = update(state, {
    entities: {
      [refName]: {
        [instance]: { $merge: obj }
      }
    }
  });
  return update(updatedEntities, updateRoomRef(refName));
};

const resetRoom = (state, action) => {
  const refName = getRefName(action);
  if (refName) {
    const arr = state.entities && state.entities[refName] && state.entities[refName].drawnNumbers;
    const arrSelected =
      state.entities &&
      state.entities[refName] &&
      state.entities[refName].next &&
      state.entities[refName].next.selectedTicketRefs;
    const autoSelected =
      state.entities &&
      state.entities[refName] &&
      state.entities[refName].next &&
      state.entities[refName].next.autoSelectedTicketCount;
    let updatedEntities = state;
    if (refName && getType(arr) === 'Array') {
      updatedEntities = update(updatedEntities, {
        entities: {
          [refName]: {
            drawnNumbers: {
              $splice: [[0, arr.length]]
            }
          }
        }
      });
    }
    if (getType(arrSelected) === 'Array') {
      updatedEntities = update(updatedEntities, {
        entities: {
          [refName]: {
            next: {
              selectedTicketRefs: {
                $splice: [[0, arrSelected.length]]
              }
            }
          }
        }
      });
    }
    if (autoSelected) {
      updatedEntities = update(updatedEntities, {
        entities: {
          [refName]: {
            next: {
              autoSelectedTicketCount: { $set: 0 }
            }
          }
        }
      });
    }
    return update(updatedEntities, updateRoomRef(refName));
  }
  return state;
};

const jackpotWinners = (state, action, key) => {
  let status = null;
  const isRollover = key === 'rolloverEnd';
  if (key === 'oneLineWinners') {
    status = STATUS.ONE_LINE_JACKPOT_WON;
  } else if (key === 'twoLinesWinners') {
    status = STATUS.TWO_LINES_JACKPOT_WON;
  } else if (key === 'gameWinners' || key === 'progressiveGameWinners' || isRollover) {
    status = STATUS.GAME_FINISHED;
  }
  const refName =
    (action.payload && action.payload.refName) ||
    (action.meta && action.meta.args && action.meta.args.refName);
  const names = getWinningUsernames(action);
  const instance = getInstance(action) || 'current';
  if (refName && (names || isRollover)) {
    return update(state, {
      entities: {
        [refName]: {
          [instance]: {
            [key]: {
              $set: isRollover
                ? true
                : {
                    entities: getWinners(action),
                    result: names,
                    amount: getAmount(action)
                  }
            },
            inProgressStatus: { $set: status }
          }
        }
      }
    });
  } else {
    return state;
  }
};

const roomInstance = (state, action) => {
  const payload = action.payload;
  const refName = getRefName(action);
  const isError = action.error;
  const instance = getInstance(action) || 'next';
  if (isError) {
    return clearInstance(refName, state, payload, instance, action.meta.code);
  } else if (refName && !isEmpty(payload)) {
    return mergeToRoom(refName, state, payload, instance);
  } else {
    return state;
  }
};

const patternFulfilled = (state, action) => updateInstanceKey(setKey, state, action, 'pattern');

const updatePurchased = (state, action) => {
  const refName = getRefName(action);
  const instance = getInstance(action) || 'next';
  const updatedWithTickets = updateInstanceKey(setKey, state, action, 'purchasedTickets');
  const hasTickets = action.payload && !isEmpty(action.payload.result);
  return instance === 'current'
    ? update(updatedWithTickets, {
        entities: {
          [refName]: {
            [instance]: {
              inPlay: { $set: hasTickets }
            }
          }
        }
      })
    : updatedWithTickets;
};

const listFulfilled = (state, action) => {
  const { entities } = action.payload || {};
  if (entities) {
    const instance = getInstance(action) || 'next';
    const updatedRooms = Object.assign(
      {},
      ...Object.keys(entities).map(
        (ref) =>
          ref !== state.active && {
            [ref]: update(entities[ref], {
              $set: {
                ...(state.entities[ref] || {}), // Merge to existing ref
                [instance]: {
                  logoImageUrl:
                    state.entities[ref] &&
                    state.entities[ref][instance] &&
                    state.entities[ref][instance].logoImageUrl,
                  backgroundImageUrl:
                    state.entities[ref] &&
                    state.entities[ref][instance] &&
                    state.entities[ref][instance].backgroundImageUrl,
                  ...entities[ref]
                }
              }
            })
          }
      )
    );
    const updatedPayload = update(action.payload, {
      entities: {
        $set: updatedRooms
      },
      result: {
        $set: Object.keys(entities)
      }
    });
    if (state.preserve && state.result && state.result.length > 0) {
      return merge({}, state, updatedPayload);
    } else {
      return update(state, {
        // A shallow merge like this resets the state for all rooms. _.merge preserves it
        entities: {
          $merge: updatedPayload.entities
        },
        result: { $set: updatedPayload.result }
      });
    }
  } else {
    return state;
  }
};

const assetsFulfilled = (state, action) => {
  const { entities } = action.payload || {};
  if (entities) {
    const instance = 'assets';
    const updatedRooms = Object.assign(
      {},
      ...Object.keys(entities).map(
        (ref) =>
          ref !== state.active && {
            [ref]: update(entities[ref], {
              $set: {
                [instance]: {
                  logoImageUrl:
                    state.entities[ref] &&
                    state.entities[ref][instance] &&
                    state.entities[ref][instance].logoImageUrl,
                  backgroundImageUrl:
                    state.entities[ref] &&
                    state.entities[ref][instance] &&
                    state.entities[ref][instance].backgroundImageUrl,
                  ...entities[ref]
                }
              }
            })
          }
      )
    );
    const updatedPayload = update(action.payload, {
      entities: {
        $set: updatedRooms
      }
    });
    if (state.preserve && state.result && state.result.length > 0) {
      return merge({}, state, updatedPayload);
    } else {
      return update(state, {
        // A shallow merge like this resets the state for all rooms. _.merge preserves it
        entities: {
          $merge: merge(updatedPayload.entities, state.entities)
        },
        result: { $set: updatedPayload.result.filter((ref) => ref !== state.active) }
      });
    }
  } else {
    return state;
  }
};

const listReset = (state) => {
  const activeRoom = state.active;
  if (
    activeRoom &&
    state.entities[activeRoom] &&
    (state.entities[activeRoom].next || state.entities[activeRoom].current)
  ) {
    let res = state;
    if (state.entities[activeRoom].next) {
      res = update(res, {
        // A shallow merge like this resets the state for all rooms. _.merge preserves it
        entities: {
          [state.active]: {
            next: {
              $unset: ephemeral
            }
          }
        }
      });
    }
    if (state.entities[activeRoom].current) {
      res = update(res, {
        // A shallow merge like this resets the state for all rooms. _.merge preserves it
        entities: {
          [state.active]: {
            current: {
              $unset: ephemeral
            }
          }
        }
      });
    }
    return res;
  } else {
    return state;
  }
};

const jackpotFulfilled = (state, action) => {
  const refName = getRefName(action);
  const payload = action.payload;
  if (refName && !isEmpty(payload)) {
    const instance = getInstance(action) || 'next';
    if (instance) {
      return mergeToInstance(refName, instance, state, payload);
    }
  } else {
    return state;
  }
  return state;
};

const progressiveInfo = (state, action) => {
  const refName = getRefName(action);
  const payload = { ...action.payload, progressiveTitle: action.payload && action.payload.name };
  if (payload && payload.name) {
    delete payload.name;
  }

  if (refName && payload.progressiveTitle) {
    const instance = getInstance(action) || 'next';
    if (instance) {
      return mergeToInstance(refName, instance, state, payload);
    }
  }

  return state;
};

const purchasedRejected = (state, action) => {
  const syntheticAction = update(action, {
    payload: {
      $set: {
        entities: {},
        result: []
      }
    }
  });
  return updatePurchased(state, syntheticAction);
};

const deselectTickets = (state, action) => {
  if (action.payload) {
    return updateInstanceKey(spliceFromKey, state, action, 'selectedTicketRefs');
  } else {
    return updateInstanceKey(emptyKey, state, action, 'selectedTicketRefs');
  }
};

const drawBall = (state, action) => {
  const number = action.payload && action.payload[0];
  const refName = getRefName(action);
  if (refName && number) {
    return updateInstanceKey(pushToKey, state, action, 'drawnNumbers');
  } else {
    return state;
  }
};

// If we were in an active room and navigated to anywhere but the same room,
// then reset its state
const locationChange = (state, action) => {
  const activeRoomRef = state.active;
  let newActiveRoomRef;
  if (activeRoomRef) {
    const { pathname } = action.payload.location;
    if (pathname) {
      const pathParts = pathname.split('/').slice(1);
      const isBingoRoom = pathParts[1] === 'bingo';
      const roomRefName = pathParts[3];
      if (isBingoRoom && roomRefName && activeRoomRef === roomRefName) {
        return state;
      } else if (isBingoRoom && roomRefName && activeRoomRef !== roomRefName) {
        newActiveRoomRef = roomRefName;
      }
    }
    const resetState = listReset(state);
    if (newActiveRoomRef) {
      return update(resetState, {
        active: { $set: newActiveRoomRef }
      });
    }

    return resetState;
  }
  return state;
};

const reducer = module(
  handleActions(
    {
      [AT.LIST.FULFILLED]: listFulfilled,
      [AT.LIST_ASSETS.FULFILLED]: assetsFulfilled,
      [AT.ROOM.FULFILLED]: roomInstance,
      [AT.ROOM.REJECTED]: roomInstance,
      [AT.START_DATE.FULFILLED]: (state, action) =>
        updateInstanceKey(setKey, state, action, 'startDate'),
      [AT.PATTERN.FULFILLED]: patternFulfilled,
      [AT.ASSIGNED_TICKETS.FULFILLED]: (state, action) =>
        updateInstanceKey(setKey, state, action, 'assignedTickets'),
      [AT.JACKPOT.FULFILLED]: jackpotFulfilled,
      [AT.PURCHASED_TICKETS.FULFILLED]: updatePurchased,
      [AT.PURCHASED_TICKETS.REJECTED]: purchasedRejected,
      [AT.PROGRESSIVE_INFO.FULFILLED]: progressiveInfo,
      [AT.REFRESH.PENDING]: resetRoom,
      [T.ONE_LINE_WINNER]: partialRight(jackpotWinners, _, _, 'oneLineWinners'),
      [T.TWO_LINES_WINNER]: partialRight(jackpotWinners, _, _, 'twoLinesWinners'),
      [T.BINGO_GAME_JACKPOT_WINNER]: partialRight(jackpotWinners, _, _, 'gameWinners'),
      [T.BINGO_PROGRESSIVE_JACKPOT_WINNER]: partialRight(
        jackpotWinners,
        _,
        _,
        'progressiveGameWinners'
      ),
      [T.BINGO_GAME_JACKPOT_ROLLOVER]: partialRight(jackpotWinners, _, _, 'rolloverEnd'),
      [T.SET_ACTIVE]: (state, action) => update(state, { active: { $set: action.payload } }),
      [T.PRESERVE]: (state, action) => update(state, { preserve: { $set: action.payload } }),
      [T.AUTOSELECT_TICKETS]: (state, action) =>
        updateInstanceKey(setKey, state, action, 'autoSelectedTicketCount'),
      [T.SELECT_TICKETS]: (state, action) =>
        action.payload ? updateInstanceKey(pushToKey, state, action, 'selectedTicketRefs') : state,
      [T.DESELECT_TICKETS]: deselectTickets,
      [T.DRAW_BALL]: drawBall
    },
    initialState
  ),
  NAME
);

export default Router.reducer(Socket.reducer(reducer, NAME), locationChange);
