import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Modals from 'modules/Modals';

export const _ModalOverlay = ({ isCurrentlyBlocking }) =>
  isCurrentlyBlocking ? (
    <div className={`modal-overlay${isCurrentlyBlocking ? ' blocking' : ''}`} />
  ) : null;

_ModalOverlay.propTypes = {
  isCurrentlyBlocking: PropTypes.bool.isRequired
};

const mapStateToProps = (state) => ({
  isCurrentlyBlocking: Modals.selectors.isCurrentlyBlocking(state)
});

export default connect(mapStateToProps)(_ModalOverlay);
