import { format as dateFnsFormat, parseISO, isValid } from 'date-fns';

type DateFnsFormatArgs = Parameters<typeof dateFnsFormat>;
type ParseISOArgs = Parameters<typeof parseISO>;

export interface FormatDateOptions {
  dateFnsFormatOptions?: DateFnsFormatArgs[2];
  parseISOOptions?: ParseISOArgs[1];
  shouldThrow?: boolean;
}

const format = (
  date: string | number | Date | null | undefined,
  dateFormat: string,
  options: FormatDateOptions = {}
) => {
  const { shouldThrow, dateFnsFormatOptions, parseISOOptions } = options;
  const dateNotFoundMessage = 'Date not found';
  if (date === null || date === undefined || date === '') {
    if (shouldThrow) throw new Error(dateNotFoundMessage);
    return dateNotFoundMessage;
  }
  const invalidDateMessage = `${date.toString()} - Date seems invalid`;
  const theDate = typeof date === 'string' ? parseISO(date, parseISOOptions) : date;
  if (!isValid(theDate)) {
    if (shouldThrow) throw new Error(invalidDateMessage);
    return invalidDateMessage;
  }
  return dateFnsFormat(theDate, dateFormat, dateFnsFormatOptions);
};

export default format;
