import React from 'react';
import PropTypes from 'prop-types';
import './loading.css';

const LoadingElement = ({ display }) => (
  <div className={`loading-element loading-spinner--element-${display}`} data-chromatic="ignore" />
);

LoadingElement.propTypes = {
  display: PropTypes.oneOf(['block', 'inline-block', 'overlay', 'sized-overlay', 'medium'])
};

LoadingElement.defaultProps = {
  display: 'block'
};
export default LoadingElement;
