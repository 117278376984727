import React from 'react';
import './pwa.css';

const IPhoneNotification = () => (
  <div onClick={(e) => e.stopPropagation()} className="pwaNotification">
    <div className="pwaNotification__content">
      <img
        className="pwaNotification__content--logo"
        src="/assets/images1/pwalogo.png"
        alt="logo"
      />
      <div>
        Add MrQ to Home Screen. simply tap <span className="iphone-share-icon" /> then{' '}
        <span className="emphasizeText">Add to Home Screen.</span>
      </div>
    </div>
    <div className="pwaNotification--arrow">
      <svg fill="white" width="20" height="20">
        <path d="M 0 0 10 10 20 0Z" />
      </svg>
    </div>
  </div>
);

export default IPhoneNotification;
