import cc from 'classcat';
import React, { useCallback } from 'react';
import { useAppDispatch } from 'hooks/redux';
import Button from 'components/Button';
import Modals from 'modules/Modals';
import './searchButton.css';

interface SearchButtonProps {
  onClick: () => void;
}

export const SearchButton = ({ onClick }: SearchButtonProps) => (
  <Button
    unstyled
    horizontal
    tag="div"
    buttonText={
      <svg width="1.5em" height="1.5em" viewBox="0 0 24 24">
        <use href="/assets/images1/lobby/search-inherit-size-color.svg#icon" />
      </svg>
    }
    name="openSearch"
    role="button"
    title="Open Search"
    className={cc(['tab', 'lobbyButtonSearch'])}
    onClick={onClick}
  />
);

const SearchButtonAdapter = () => {
  const dispatch = useAppDispatch();
  const openSearchOverlay = useCallback(
    () => dispatch(Modals.actions.open('searchOverlay')),
    [dispatch]
  );
  return <SearchButton onClick={openSearchOverlay} />;
};

export default SearchButtonAdapter;
