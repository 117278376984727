import React from 'react';
import { ContainerQuery } from 'react-container-query';

export const applyContainerQuery = (Component, query) => (props) => (
  <ContainerQuery query={query}>
    {(params) => <Component containerQuery={params} {...props} />}
  </ContainerQuery>
);

export { ContainerQuery } from 'react-container-query';
