import React, { useCallback } from 'react';
import Modals from 'modules/Modals';
import { useAppDispatch, useAppSelector } from 'hooks/redux';
import Dialog from 'components/Dialog';
import { DialogHeader } from 'components/Dialog/Header';
import DialogArticle from 'components/Dialog/Article';
import DialogFooter from 'components/Dialog/Footer';
import { DialogButton, Variant as DialogButtonVariant } from 'components/Dialog/Button';
import User from 'modules/User';

const VerificationRequired = ({
  isVerificationEnabled,
  onVerify,
  onContinue,
  onClose
}: {
  isVerificationEnabled: boolean;
  onVerify: () => void;
  onContinue: () => void;
  onClose: () => void;
}) => (
  <div className="overflow-wrap--modal">
    <div className="modal modal--dialog">
      <Dialog name="KYC">
        <DialogHeader icon="/assets/images1/dialogIcon.svg" onClose={onClose}>
          Verification Team
        </DialogHeader>
        <DialogArticle>
          #### Your account must be verified for you to receive withdrawn funds.
        </DialogArticle>
        <DialogArticle>
          {`Whilst you may request withdrawals, MrQ cannot send your funds over before your account gets verified.
          
Verify your account for uninterrupted deposits and withdrawals.`}
        </DialogArticle>
        <DialogFooter>
          {isVerificationEnabled && (
            <DialogButton onClick={onVerify}>Upload your documents</DialogButton>
          )}
          <DialogButton onClick={onContinue} variant={DialogButtonVariant.SECONDARY}>
            Request withdrawal
          </DialogButton>
        </DialogFooter>
      </Dialog>
    </div>
  </div>
);

const VerificationRequiredAdapter = ({
  close,
  onClickContinue
}: {
  close: () => void;
  onClickContinue: () => void;
}) => {
  const dispatch = useAppDispatch();
  const isVerificationEnabled = useAppSelector(
    (state) =>
      User.selectors.getVerificationStatus(state) === User.constants.VerificationStatus.REQUESTED
  );
  const handleClickVerify = useCallback(() => {
    close();
    dispatch(Modals.actions.open('KYC'));
  }, [close, dispatch]);

  return (
    <VerificationRequired
      onVerify={handleClickVerify}
      onContinue={onClickContinue}
      onClose={close}
      isVerificationEnabled={isVerificationEnabled}
    />
  );
};

export default VerificationRequiredAdapter;
