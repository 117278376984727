export enum CalloutVariant {
  INFORMATIONAL = 'INFORMATIONAL',
  SUCCESS = 'SUCCESS',
  WARNING = 'WARNING',
  ERROR = 'ERROR',
  GENERIC = 'GENERIC',
  NOT_FOUND = 'NOT_FOUND'
}

export enum ContentType {
  RAW = 'RAW',
  UNSAFE = 'UNSAFE',
  MARKDOWN = 'MARKDOWN'
}
