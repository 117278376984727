import { useEffect } from 'react';
import { useAppDispatch } from 'hooks/redux';
import Carousel from 'modules/Carousel';

const useLiveCategories = () => {
  const dispatch = useAppDispatch();
  useEffect(() => {
    dispatch(Carousel.actions.listLiveCategories());
  }, [dispatch]);
};

export default useLiveCategories;
