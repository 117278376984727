import React, { memo } from 'react';
import Button from 'components/Button';

export interface ListSolicitationsErrorMessageProps {
  close: () => void;
}

const ListSolicitationsErrorMessage = ({ close }: ListSolicitationsErrorMessageProps) => (
  <div>
    <h4>Something went wrong...</h4>
    <br />
    <p>
      There seems to be a problem loading the deposit limits, so we cannot process any deposits
      right now.
    </p>
    <p>Please refresh your browser and try again.</p>
    <form>
      <div className="fieldset fieldset--centerflex">
        <Button
          className="submit button__grey"
          onClick={close}
          name="close"
          id="solicitations-close"
        >
          Try later
        </Button>
      </div>
    </form>
  </div>
);

export default memo(ListSolicitationsErrorMessage);
