import { Component, cloneElement } from 'react';
import PropTypes from 'prop-types';
import update from 'update-immutable';
import cc from 'classcat';

class Countdown extends Component {
  constructor(props) {
    super(props);
    this.state = {
      diff: 0,
      retryCount: 0,
      stopped: false
    };
    this.lifecycleInterval = null;
  }

  componentDidMount() {
    this.lifecycleInterval = setInterval(
      this.getRemainingTime.bind(this),
      this.props.updateInterval
    );
    this.getRemainingTime();
  }

  // TODO: Support onStart
  // componentDidUpdate(nextProps, nextState) {
  //   const { onStart } = this.props;
  //   if (this.state.diff === 0 && nextState.diff > 0 && !isNil(onStart)) {
  //     //      onStart(nextState.diff);
  //   }
  // }

  componentWillUnmount() {
    clearInterval(this.lifecycleInterval);
  }

  getRemainingTime = () => {
    const { expirationDate, onEnd } = this.props;
    const { stopped, retryCount } = this.state;
    const expirationTime = new Date(expirationDate).getTime();
    const now = Date.now();
    const diff = expirationTime - now;
    const tryAgain = () => {
      if ((retryCount % 4 === 1 || retryCount === 0) && retryCount < 24) {
        // If interval is 1000, retry every 4 seconds, up to 6 times
        onEnd();
        this.setState({ retryCount: retryCount + 1 });
      } else if (retryCount >= 24) {
        clearInterval(this.lifecycleInterval); // Give up
        this.setState({ givenUp: true });
      } else {
        this.setState({ retryCount: retryCount + 1 });
      }
    };
    if (diff <= 0) {
      if (!stopped) {
        // We need these updates to be synchronous
        this.setState(
          (prevState) =>
            update(prevState, {
              stopped: { $set: true }
            }),
          tryAgain
        );
      } else {
        tryAgain();
      }
    } else {
      this.setState((prevState) =>
        update(prevState, {
          diff: { $set: diff },
          retryCount: { $set: 0 },
          stopped: { $set: false }
        })
      );
    }
  };

  render() {
    const { diff, givenUp } = this.state;
    const { children, className } = this.props;

    if (givenUp) return null;

    if (typeof children === 'function') {
      return children(diff);
    } else {
      return cloneElement(children, {
        timeLeft: diff,
        className: cc([className, children.props.className])
      });
    }
  }
}

Countdown.propTypes = {
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.func]).isRequired,
  className: PropTypes.string,
  expirationDate: PropTypes.oneOfType([
    PropTypes.instanceOf(Date),
    PropTypes.string,
    PropTypes.number
  ]).isRequired,
  onEnd: PropTypes.func.isRequired,
  updateInterval: PropTypes.number.isRequired
};

Countdown.defaultProps = {
  className: ''
};

export default Countdown;
