import isPromise from 'is-promise';
import { isNil } from 'lodash';
import User from 'modules/User';
import * as errorTypes from './constants';
import oneOfType from './oneOfType';

/**
 * @return {boolean}
 */
function ClientError(e) {
  return !isNil(e.msg);
}

export default function globalErrorMiddleware() {
  return (store) => (next) => (action) => {
    // If not a promise, continue on
    const result = next(action);
    if (!isPromise(result)) {
      return result;
    }

    /**
     * Because it iterates on an array for every async action, this
     * oneOfType function could be expensive to call in production.
     * Another solution would be to include a property in `meta`
     * and evaluate that property.
     *
     * if (action.meta.globalError === true) {
     *   // handle error
     * }
     *
     * The error middleware serves to dispatch the initial pending promise to
     * the promise middleware, but adds a `catch`.
     */
    if (result && result.catch) {
      // TODO: Use action.meta.generated
      // Dispatch initial pending promise, but catch any errors
      return result.catch((error) => {
        if (error.code === '5574') {
          store.dispatch(User.actions.setNotAgreedToLatestTerms);
        }
        if (__ENV__.NODE_ENV === 'development') {
          console.warn(
            `${action.type} caught at middleware with reason: ${JSON.stringify(
              error.msg || error.message
            )}.`
          );
        }

        return error;
      });
    }

    return result;
  };
}
