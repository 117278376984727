import localforage from 'localforage';

const GLOBAL = 'mrq_global';

class LocalStore {
  public instances: Map<string, LocalForage>;

  constructor() {
    const globalStore = localforage.createInstance({
      name: GLOBAL
    });
    this.instances = new Map([[GLOBAL, globalStore]]);
  }

  createInstance = (name: string) => {
    if (!name) throw Error('No name provided for the new instance');
    let _instance = this.instances.get(name);
    if (!_instance) {
      _instance = localforage.createInstance({ name });
      this.instances.set(name, _instance);
    }
    return _instance;
  };

  /**
   * Completely deletes a named instance
   * @method
   *
   * @param {string} name - instance identifier.
   */
  drop = async (name = GLOBAL) => {
    if (this.instances.has(name)) {
      await localforage.dropInstance({ name });
      this.instances.delete(name);
    }
  };

  /**
   * Deletes all local storage instances for the domain. Use with care.
   * @method
   */
  reset = async () => {
    if (this.instances.size <= 0) return;

    const names = [...this.instances.keys()];
    await Promise.all(names.map((name) => this.drop(name)));
  };
}

const localStore = new LocalStore();

export default localStore;
export { GLOBAL };
