import { RefObject, useEffect } from 'react';

const options = { passive: true };

const isolateTouch = (e: TouchEvent) => {
  e.stopPropagation();
};

interface UseTouchIsolationProps {
  containerRef: RefObject<HTMLElement>;
  isActive: boolean;
}

/**
 * Prevents touch events from bubbling up to parent elements.
 * @param {UseTouchIsolationProps} - The properties for configuring the hook. Includes:
 *   - `containerRef`: A ref object pointing to the target HTML element.
 *   - `isActive`: Boolean flag to activate or deactivate the touch isolation.
 */
export const useTouchIsolation = ({ containerRef, isActive }: UseTouchIsolationProps) =>
  useEffect(() => {
    const container = containerRef?.current;
    if (!container || !isActive) return;

    container.addEventListener('touchstart', isolateTouch, options);
    container.addEventListener('touchmove', isolateTouch, options);
    container.addEventListener('touchend', isolateTouch, options);

    return () => {
      container.removeEventListener('touchstart', isolateTouch);
      container.removeEventListener('touchmove', isolateTouch);
      container.removeEventListener('touchend', isolateTouch);
    };
  }, [isActive]);
