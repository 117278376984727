// TODO: Normalize with entities, result
// Every modal must be 1 level deep

export const initialState = {
  latestTerms: {
    visible: false,
    blocking: true,
    pinned: true,
    expiration: 0
  },
  changeCard: {
    visible: false,
    blocking: true,
    pinned: false,
    expiration: 0
  },
  withdraw: {
    visible: false,
    blocking: true,
    pinned: false,
    title: 'Withdraw',
    expiration: 0
  },
  addPayment: {
    visible: false,
    blocking: true,
    pinned: true,
    expiration: 0
  },
  docScan: {
    visible: false,
    blocking: true,
    pinned: true,
    expiration: 0
  },
  hooyu: {
    visible: false,
    blocking: true,
    pinned: false,
    expiration: 0
  },
  deposit: {
    visible: false,
    blocking: true,
    pinned: false,
    title: 'Deposit',
    expiration: 0
  },
  depositEcospendFlow: {
    visible: false,
    blocking: true,
    pinned: true,
    expiration: 0
  },
  pci3dChallengeModal: {
    visible: false,
    blocking: true,
    pinned: true,
    expiration: 0
  },
  reversalSuccess: {
    visible: false,
    blocking: true,
    pinned: false,
    expiration: 0
  },
  selectReversibleWithdrawals: {
    visible: false,
    blocking: true,
    pinned: false,
    expiration: 0
  },
  referAFriend: {
    visible: false,
    blocking: true,
    pinned: false,
    title: 'Refer a friend',
    expiration: 0
  },
  depositLimit: {
    visible: false,
    blocking: true,
    pinned: false,
    expiration: 0
  },
  depositLimitSolicitations: {
    visible: false,
    blocking: true,
    pinned: false,
    expiration: 0,
    title: 'Deposit limit adjustment'
  },
  coolOff: {
    visible: false,
    blocking: true,
    pinned: false,
    expiration: 0
  },
  selfExclusion: {
    visible: false,
    blocking: true,
    pinned: false,
    expiration: 0
  },
  closeAccount: {
    visible: false,
    blocking: true,
    pinned: false,
    expiration: 0
  },
  addProfilePhone: {
    visible: false,
    blocking: true,
    pinned: true,
    expiration: 0
  },
  addDepositPhone: {
    visible: false,
    blocking: true,
    pinned: true,
    expiration: 0
  },
  addCard: {
    visible: false,
    blocking: false,
    pinned: true,
    expiration: 0
  },
  addPaypal: {
    visible: false,
    blocking: true,
    pinned: false,
    expiration: 0
  },
  contactSupport: {
    visible: false,
    blocking: true,
    pinned: false,
    expiration: 0
  },
  ...(__ENV__.MRQ_BINGO_ENABLED === 'true'
    ? {
        howToPlay: {
          visible: false,
          blocking: true,
          pinned: false,
          expiration: 0
        }
      }
    : {}),
  servicePolicies: {
    visible: false,
    blocking: true,
    pinned: false,
    expiration: 0
  },
  termsConditions: {
    visible: false,
    blocking: true,
    pinned: false,
    expiration: 0
  },
  responsiblePolicy: {
    visible: false,
    blocking: true,
    pinned: false,
    expiration: 0
  },
  privacyPolicy: {
    visible: false,
    blocking: true,
    pinned: false,
    expiration: 0
  },
  editAvatar: {
    visible: false,
    blocking: true,
    pinned: false,
    expiration: 0
  },
  mobileVerification: {
    visible: false,
    blocking: true,
    pinned: false,
    expiration: 0
  },
  sideMenu: {
    visible: false,
    blocking: false,
    pinned: false,
    closeOnNavigation: true,
    expiration: 0
  },
  KYC: {
    visible: false,
    blocking: true,
    pinned: false,
    expiration: 0
  },
  searchOverlay: {
    visible: false,
    blocking: true,
    pinned: false,
    closeOnNavigation: true,
    expiration: 0
  },
  realityCheck: {
    visible: false,
    blocking: true,
    pinned: true,
    title: 'Continue playing?',
    expiration: 0
  },
  verifyMobile: {
    visible: false,
    blocking: true,
    pinned: true,
    expiration: 0
  },
  verifyMobileSuccess: {
    visible: false,
    blocking: true,
    pinned: true,
    expiration: 0
  },
  verifyDepositMobile: {
    visible: false,
    blocking: true,
    pinned: true,
    expiration: 0
  },
  userMenu: {
    visible: false,
    blocking: false,
    pinned: false,
    closeOnNavigation: true,
    expiration: 0
  },
  confirm: {
    visible: false,
    blocking: false,
    pinned: true,
    expiration: 0
  },
  confirmCloseAccount: {
    visible: false,
    blocking: false,
    pinned: true,
    expiration: 0
  },
  verifyYourAccount: {
    visible: false,
    blocking: true,
    pinned: false,
    expiration: 0
  },
  verifyDocuments: {
    visible: false,
    blocking: true,
    pinned: false,
    expiration: 0
  },
  rewardOffer: {
    visible: false,
    blocking: true,
    pinned: false,
    expiration: 0
  },
  rewardTermsConditions: {
    visible: false,
    blocking: true,
    pinned: true,
    expiration: 0
  },
  keepAliveSlotError: {
    visible: false,
    blocking: true,
    pinned: true,
    expiration: 0
  }
};

export const modals = (model) => Object.keys(model).map((modal) => model[modal]);

export const defaultModal = {
  visible: false,
  blocking: true,
  pinned: false,
  expiration: 0
};
