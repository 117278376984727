import React, { memo } from 'react';
import cc from 'classcat';
import type { types as CmsContentTypes } from 'modules/CmsContent';
import RouterLink from 'components/RouterLink';

interface FooterLogosProps {
  className?: string;
  logos?: CmsContentTypes.FooterLogo[];
}

const FooterLogos = ({ logos, className }: FooterLogosProps) =>
  logos ? (
    <div className={cc(['footerLogos', className])}>
      {logos?.map(({ id, href, image: { url, alternativeText } }) =>
        href ? (
          <RouterLink key={id} href={href} title={alternativeText} className="footerLogos__logo">
            <img key={id} src={url} alt={alternativeText} />
          </RouterLink>
        ) : (
          <img key={id} src={url} alt={alternativeText} className="footerLogos__logo" />
        )
      )}
    </div>
  ) : null;

export default memo(FooterLogos);
