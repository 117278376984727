import Bingo from 'modules/Bingo';

const notificationUserPrompts = {
  // 2200: (action) => [
  //   'Undo',
  //   Bingo.actions.favorite(action.meta && action.meta.args && action.meta.args.refName)
  // ]
};

export default notificationUserPrompts;
