import React, { useState, forwardRef, useCallback, memo, useMemo } from 'react';
import PropTypes from 'prop-types';
import loadable from '@loadable/component';
import classNames from 'classcat';
import { AccordionVertical } from 'components/Transitions';

const HostedField = loadable(() => import('components/ReactBraintreeFields'), {
  resolveComponent: (components) => components.HostedField
});

const BraintreeField = forwardRef(
  (
    {
      dynamic,
      name,
      className,
      field,
      inputType,
      id,
      inline,
      labelText,
      editable,
      style,
      icon,
      isOutsideIcon,
      iconWidth,
      isLoading,
      onChange,
      onValidityChange,
      onFocus,
      onBlur,
      ...braintreeProps
    },
    ref
  ) => {
    const handleValidityChange = useCallback(
      ({ isPotentiallyValid, isValid }, { emittedBy }) => {
        if (isValid) return onValidityChange({ target: emittedBy, valid: true });
        if (!isValid && !isPotentiallyValid) {
          return onValidityChange({ target: emittedBy, valid: false });
        }
        return onValidityChange({ target: emittedBy, valid: null });
      },
      [onValidityChange]
    );
    const showServerError = !field.dirty && field.valid && field.serverError;
    // No need for useMemo below. HostedField isn't even PureComponent
    // setFilled: TRUE means has value. FALSE means empty. As in `notEmpty: true`. It breaks convention
    // because `setNotEmpty` would be weird, and `notEmpty` itself is used in both
    // Braintree and MrQ, so it can't change
    const [notEmpty, setFilled] = useState(editable && field.initial);
    const rootStyle = useMemo(() => ({ '--iconWidth': `${iconWidth}px` }), [iconWidth]);

    const fieldClass = classNames([
      'fieldset',
      name,
      className,
      {
        'fieldset--dynamic': dynamic,
        'fieldset--fullwidth': !inline,
        'fieldset--outsideIcon': isOutsideIcon,
        'fieldset--insideIcon': !isOutsideIcon,
        'fieldset--hasIcon': icon,
        'fieldset--clickableIcon': icon?.props.onClick,
        noEdit: !editable,
        notEmpty: editable && notEmpty
      }
    ]);
    const inputClass = classNames([name, 'userText']);
    const domId = id || name;

    return (
      <div className={fieldClass} style={rootStyle}>
        <div className="fieldset__iconAndInput">
          {icon && <div className="fieldset__icon">{icon}</div>}
          <HostedField
            className={inputClass}
            name={name}
            id={domId}
            type={inputType}
            disabled={!editable}
            placeholder={editable ? '' : field.value}
            onChange={onChange}
            onValidityChange={handleValidityChange}
            onFocus={onFocus}
            onBlur={onBlur}
            onEmpty={() => setFilled(false)}
            onNotEmpty={() => setFilled(true)}
            style={style}
            prefill={field.initial}
            ref={ref}
            isLoading={isLoading}
            {...braintreeProps}
          />
          {!editable && (field.value || field.initial) ? null : (
            <label className="fieldset__label" htmlFor={domId}>{`${
              field.required ? '* ' : ''
            }${labelText}`}</label>
          )}
        </div>
        <AccordionVertical childKey={`${name}-errorMsg`} className="errorMsg-container">
          {editable && (field.valid === false || field.warn || showServerError) ? (
            <label htmlFor={domId} className="errorMsg" key={`${name}-errorMsg`} role="alert">
              <span>
                {(() => {
                  if (field.valid && showServerError) {
                    return field.serverError;
                  } else if (field.valid) {
                    return field.warn;
                  } else {
                    return field.error;
                  }
                })()}
              </span>
            </label>
          ) : null}
        </AccordionVertical>
      </div>
    );
  }
);

BraintreeField.propTypes = {
  dynamic: PropTypes.bool,
  editable: PropTypes.bool,
  id: PropTypes.string,
  inline: PropTypes.bool,
  name: PropTypes.string.isRequired,
  className: PropTypes.string,
  labelText: PropTypes.string,
  style: PropTypes.object,
  icon: PropTypes.element,
  isOutsideIcon: PropTypes.bool,
  iconWidth: PropTypes.number,
  field: PropTypes.object.isRequired,
  inputType: PropTypes.oneOf([
    'number',
    'expirationDate',
    'expirationMonth',
    'expirationYear',
    'cvv',
    'cardholderName',
    'postalCode'
  ]).isRequired,
  onFocus: PropTypes.func
};

BraintreeField.defaultProps = {
  dynamic: true,
  editable: true,
  inline: false,
  className: '',
  id: null,
  style: null,
  icon: null,
  isOutsideIcon: false,
  iconWidth: 30,
  onFocus: null,
  labelText: ''
};

export default memo(BraintreeField);
