import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import UserBox from 'components/UserBox';
import makeModal from 'components/Modals';
import AddMobile from 'components/AddPayment/AddMobile';
import Modals from 'modules/Modals';
import Wallet from 'modules/Wallet';

const _VerifyDepositMobileModal = ({
  close,
  openVerificationSuccess,
  requiresReverification,
  depositAmount
}) => {
  const handleDone = () => {
    if (depositAmount && !requiresReverification) {
      openVerificationSuccess();
    }
    close();
  };

  return (
    <UserBox
      id="verifyDepositMobile"
      className="verifyDepositMobile modal"
      title="Verify payment phone number"
      closeCallback={close}
    >
      <AddMobile onDone={handleDone} verifyOnly depositAmount={depositAmount} />
    </UserBox>
  );
};

const mapStateToProps = (state) => ({
  requiresReverification: Wallet.selectors.model(state).requiresReverification
});

const mapDispatchToProps = (dispatch, { depositAmount, promotionCode }) => ({
  openVerificationSuccess: () =>
    dispatch(
      Modals.actions.open('verifyMobileSuccess', {
        depositAmount,
        promotionCode
      })
    )
});

_VerifyDepositMobileModal.propTypes = {
  close: PropTypes.func.isRequired,
  openVerificationSuccess: PropTypes.func.isRequired,
  requiresReverification: PropTypes.bool.isRequired,
  depositAmount: PropTypes.number.isRequired
};

export default compose(
  makeModal(
    'verifyDepositMobile',
    { name: 'modal-fade', timeout: 200 },
    { className: 'verifyDepositMobile modal' }
  ),
  connect(mapStateToProps, mapDispatchToProps)
)(_VerifyDepositMobileModal);
