import type { ReactNode } from 'react';
import React from 'react';
import { Typography, TypographyVariant } from 'components/Typography';
import * as ListItem from '../ListItem';

const ChevronRightIcon = () => (
  <svg width="16" height="16" color="var(--neutral20)">
    <use href="/assets/images1/chevron-right.svg#icon" />
  </svg>
);

interface LinkForwardProps {
  children: ReactNode;
  to?: string;
  onClick?: () => void;
  prefix?: ReactNode;
}

const LinkForward = ({ children, to, onClick, prefix }: LinkForwardProps) => (
  <ListItem.Root href={to} onClick={onClick} prefix={prefix} suffix={<ChevronRightIcon />}>
    <Typography variant={TypographyVariant.BodyMd}>
      <span className="listItem__text--secondary">{children}</span>
    </Typography>
  </ListItem.Root>
);

export default LinkForward;
