export const initialState = {
  loggedThisWindow: false,
  activated: false,
  partialUserRef: null,
  authenticated: null,
  loginError: null,
  registerError: null
};

export const partialUserRef = (model) => (model && model.partialUserRef) || undefined;
export const loggedThisWindow = (model) => model && model.loggedThisWindow;
export const isAuthenticated = (model) => model?.authenticated;
