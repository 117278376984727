import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Api from 'services/Api';
import Wallet from 'modules/Wallet';
import Button from 'components/Button';
import DepositLimitStats from 'components/DepositLimitStats';
import './confirmWithdrawal.css';

export const _ConfirmWithdrawal = ({ init, openWithdraw, close, depositLimitAmount }) => {
  useEffect(() => {
    init();
  }, [init]);
  return (
    <div className="confirmWithdrawal">
      <img
        className="userBox__image"
        src="/assets/images1/withdrawal.svg"
        alt=""
        width="140"
        height="128"
        data-chromatic="ignore"
      />
      <p>
        Withdrawals are typically processed within 2 hours, occasionally they may take up to 2 days.
      </p>
      <p>
        If you haven&apos;t received an update regarding your withdrawal after 2 days, please get in
        touch with us.
      </p>
      {depositLimitAmount?.wording && (
        <DepositLimitStats
          amountLeft={depositLimitAmount.amountLeft}
          totalAmount={depositLimitAmount.totalAmount}
          content={depositLimitAmount.wording}
        />
      )}
      <div className="confirmWithdrawal__buttons">
        <Button
          title="Continue"
          buttonText="Agree and request withdrawal"
          name="openWithdrawal"
          id="confirmWithdrawal-openWithdrawal"
          onClick={openWithdraw}
        />
        <Button
          className="button__grey"
          title="Cancel"
          buttonText="Cancel"
          onClick={close}
          name="close"
          id="confirmWithdrawal-close"
        />
      </div>
    </div>
  );
};

_ConfirmWithdrawal.propTypes = {
  init: PropTypes.func.isRequired,
  // TODO: Placeholder for info that will come from the backend, it might really be required
  depositLimitAmount: PropTypes.shape({
    amountLeft: PropTypes.number,
    totalAmount: PropTypes.number,
    wording: PropTypes.string
  }),
  openWithdraw: PropTypes.func.isRequired,
  close: PropTypes.func.isRequired
};

_ConfirmWithdrawal.defaultProps = {
  depositLimitAmount: null
};

const mapStateToProps = (state) => ({
  depositLimitAmount: Wallet.selectors.getDepositLimitAmount(state)
});

const mapDispatchToProps = (dispatch) => ({
  init: () => {
    Api.actions.wallet.depositLimitAmount()(dispatch);
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(_ConfirmWithdrawal);
