import { actions as routerActions, constants as routerConstants } from 'modules/Router';
import { NAME } from './constants';
import { T } from './actionTypes';

const { push, replace } = routerActions;
const { NO_SCROLL } = routerConstants;

export const update = (name, extraProps) => ({
  type: T.UPDATE,
  payload: { name, ...extraProps }
});

export const reset = (name) => ({
  type: T.RESET,
  payload: { name }
});

export const _open = (name, extraProps) => ({
  type: T.OPEN,
  payload: { name, ...extraProps }
});

export const _close = (name) => ({
  type: T.CLOSE,
  payload: { name }
});

export const destroy = (name) => ({
  type: T.DESTROY,
  payload: { name }
});

export const close = (name) => (dispatch, getState) => {
  const { pathname, search } = window.location;
  const modal = getState()[NAME][name];
  if (modal) {
    const { blocking, pinned } = modal;
    if (blocking && !pinned) {
      let urlSearch = new URLSearchParams(search);
      urlSearch.delete('promoCode');
      if (!urlSearch.has('modals') || !urlSearch.getAll('modals').includes(name)) {
        dispatch(_close(name));
        dispatch(reset(name));
        return;
      }
      if (urlSearch.getAll('modals').length === 1) {
        urlSearch.delete('modals');
      } else {
        urlSearch = new URLSearchParams(search.replace(`modals=${name}`, ''));
      }
      let result = urlSearch.toString();
      result = result && `?${result}`;
      dispatch(replace(pathname + result, NO_SCROLL));
      dispatch(reset(name));
    } else {
      dispatch(_close(name));
      dispatch(reset(name));
    }
  }
};

export const toggle = (name) => ({
  type: T.TOGGLE,
  payload: { name }
});

export const create = (name, opts) => ({
  type: T.CREATE,
  payload: { name, ...opts }
});

export const open = (name, extraProps) => (dispatch, getState) => {
  const { pathname, search } = window.location;
  const modal = getState()[NAME][name];
  if (modal) {
    const { blocking, pinned } = modal;
    if (blocking && !pinned) {
      const urlSearch = new URLSearchParams(search);
      if (!urlSearch.has('modals') || !urlSearch.getAll('modals').includes(name)) {
        urlSearch.append('modals', name);
      }
      dispatch(push(`${pathname}?${urlSearch.toString()}`, NO_SCROLL));
      dispatch(update(name, extraProps));
    } else {
      dispatch(_open(name, extraProps));
    }
  } else {
    dispatch(create(name, extraProps));
  }
};

export const openPci3dChallengeModal = (transactionId, url) => (dispatch, getState) => {
  const state = getState();
  const currentTranscationId = state.Wallet.currentPci3dChallengeTransactionId;
  if (currentTranscationId === transactionId) {
    dispatch(open('pci3dChallengeModal', { url }));
  }
};
