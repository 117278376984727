import { schema } from 'normalizr';
import { actionTypes as Carousel } from 'modules/Carousel';
import { actionTypes as Wallet } from 'modules/Wallet';
import { actionTypes as User } from 'modules/User';
import { actionTypes as Bingo } from 'modules/Bingo';
import { actionTypes as Slot } from 'modules/Slot';
import { actionTypes as Chat } from 'modules/Chat';
import { actionTypes as Live } from 'modules/Live';
import { actionTypes as Dialog } from 'modules/Dialog';

const bottomTabs = [new schema.Entity('bottomTabs', {}, { idAttribute: 'refName' })];
const tickets = [new schema.Entity('tickets', {}, { idAttribute: 'ref' })];
const slots = [new schema.Entity('slots', {}, { idAttribute: 'ref' })];
const live = [new schema.Entity('live', {}, { idAttribute: 'ref' })];
const bingo = [new schema.Entity('bingo', {}, { idAttribute: 'ref' })];
const chat = [new schema.Entity('chat', {}, { idAttribute: 'ref' })];
const dialog = [new schema.Entity('dialog', {}, { idAttribute: 'refName' })];
const users = [new schema.Entity('users', {}, { idAttribute: 'username' })];
const transactions = [new schema.Entity('transactions', {})];
const referees = [new schema.Entity('referees', {}, { idAttribute: 'refereeIdentifier' })];
const paymentAmounts = [new schema.Entity('presets', {}, { idAttribute: 'provider' })];
const paymentMethods = [new schema.Entity('paymentMethods', {}, { idAttribute: 'ref' })];
const paymentMethodsDetailed = [
  new schema.Entity('paymentMethodsDetailed', {}, { idAttribute: 'ref' })
];
const paymentPoviders = [new schema.Entity('paymentPoviders', {}, { idAttribute: 'provider' })];
const paymentPovidersAvailable = [
  new schema.Entity('paymentPovidersAvailable', {}, { idAttribute: 'provider' })
];
const ecospendBanks = [new schema.Entity('ecospendBanks', {}, { idAttribute: 'bankId' })];
const dailyFreeGames = [new schema.Entity('dailyFreeGames', {}, { idAttribute: 'ref' })];

// path is dot delimited based on lodash '_.get'
export default {
  [Carousel.AT.LIST_SLOT_CATEGORIES.FULFILLED]: {
    path: 'data',
    schema: bottomTabs
  },
  [Carousel.AT.LIST_LIVE_CATEGORIES.FULFILLED]: {
    path: 'data',
    schema: bottomTabs
  },
  [User.AT.GET_DAILY_FREE_GAMES.FULFILLED]: dailyFreeGames,
  [Live.AT.LIST.FULFILLED]: {
    path: 'contents',
    schema: live
  },
  [Slot.AT.LIST.FULFILLED]: {
    path: 'contents',
    schema: slots
  },
  [Slot.AT.LIST_ASSETS.FULFILLED]: slots,
  [Chat.AT.LIST_ROOMS.FULFILLED]: chat,
  [Chat.AT.SUBSCRIBERS.FULFILLED]: users,
  [Chat.T.UPDATE_USERS]: {
    path: 'subscribers',
    schema: users
  },
  [Dialog.AT.LIST.FULFILLED]: dialog,
  [Bingo.AT.LIST.FULFILLED]: bingo,
  [Bingo.AT.LIST_ASSETS.FULFILLED]: bingo,
  [Bingo.AT.ASSIGNED_TICKETS.FULFILLED]: tickets,
  [Bingo.AT.PURCHASED_TICKETS.FULFILLED]: tickets,
  [User.AT.GET_REFERRAL_STATUS.FULFILLED]: {
    path: 'referees',
    schema: referees
  },
  [Wallet.AT.PAYMENT_AMOUNTS.FULFILLED]: paymentAmounts,
  [Wallet.AT.LIST_PAYMENT_METHODS.FULFILLED]: paymentMethods,
  [Wallet.AT.LIST_PAYMENT_METHODS_DETAILED.FULFILLED]: paymentMethodsDetailed,
  [Wallet.AT.LIST_PAYMENT_PROVIDERS.FULFILLED]: paymentPoviders,
  [Wallet.AT.LIST_PAYMENT_PROVIDERS_AVAILABLE.FULFILLED]: paymentPovidersAvailable,
  [Wallet.AT.GAME_TRANSACTIONS.FULFILLED]: {
    path: 'contents',
    schema: transactions
  },
  [Wallet.AT.BANK_TRANSACTIONS_DEPOSIT.FULFILLED]: {
    path: 'contents',
    schema: transactions
  },
  [Wallet.AT.BANK_TRANSACTIONS.FULFILLED]: {
    path: 'contents',
    schema: transactions
  },
  [Wallet.AT.ECOSPEND_BANKS.FULFILLED]: {
    path: 'banks',
    schema: ecospendBanks
  }
};
