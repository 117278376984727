import type { ReactNode } from 'react';
import React, { useCallback, useState } from 'react';
import { isLoading } from 'lib/redux-utils';
import type { constants as UserConstants } from 'modules/User';
import User from 'modules/User';
import Api from 'services/Api';
import Modals from 'modules/Modals';
import ActionButton from 'components/ActionButton';
import { useAppDispatch, useAppSelector } from 'hooks/redux';

interface HooyuButtonProps {
  close: () => void;
  journey: UserConstants.VerificationType;
  children: ReactNode;
}
const HooyuButton = ({ close, journey, children }: HooyuButtonProps) => {
  const [errorMessage, setErrorMessage] = useState('');
  const dispatch = useAppDispatch();
  const isActionLoading = useAppSelector((state) =>
    isLoading(state, User.actionTypes.AT.CREATE_HOOYU_SESSION._)
  );

  const handleClickVerify = useCallback(async () => {
    try {
      await Api.actions.user.createHooyuSession({ journey })(dispatch);
      if (close) {
        close();
      }
      dispatch(Modals.actions.open('hooyu', { journey }));
    } catch (e) {
      setErrorMessage("Can't connect with Mitek, try again");
    }
  }, [close, dispatch, journey]);

  return (
    <ActionButton
      loading={isActionLoading}
      error={errorMessage}
      onClick={handleClickVerify}
      name="kycVerify"
      id="hooyuVerify"
    >
      {children}
    </ActionButton>
  );
};

export default HooyuButton;
