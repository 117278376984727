import React, { Component } from 'react';
import { connect } from 'react-redux';
import Api from 'services/Api';
import reform from 'components/Reform';
import Fieldset from 'components/Fieldset';
import Button from 'components/Button';
import Wallet from 'modules/Wallet';
import User from 'modules/User';
import { phoneCode } from 'lib/valFuncs';
import { isLoading } from 'lib/redux-utils';

const Form = reform()(
  ({
    verificationCode,
    phoneNumber,
    onSubmit,
    handleFocus,
    loading,
    loadingSend,
    formValid,
    handleChange,
    selectAdd,
    resend
  }) => (
    <form className="add-mobile add-mobile--verify" onSubmit={onSubmit}>
      <p>
        We just sent you an SMS on {phoneNumber} with a verification code. Messages may take up to 1
        minute to be delivered.
      </p>
      <Fieldset
        field={verificationCode}
        inputType="text"
        name="verificationCode"
        labelText={`Verification code for ${phoneNumber}`}
        autoComplete="one-time-code"
        onChange={handleChange}
        onFocus={handleFocus}
      />
      <a className="add-mobile__new-number" onClick={selectAdd}>
        Wrong number?
      </a>
      <div className="fieldset fieldset--centerflex">
        <Button
          className="submit"
          disabled={!!loading}
          loading={loadingSend}
          title="Resend code"
          name="resendMobileCode"
          type="button"
          buttonText="Resend"
          onClick={resend}
        />
        <Button
          className="submit"
          disabled={!formValid || loadingSend}
          loading={loading}
          title="Verify"
          name="verifyMobileCode"
          buttonText="Submit"
          type="submit"
        />
      </div>
    </form>
  )
);

const fields = {
  verificationCode: {
    onChange: phoneCode,
    error: 'Verification code is 4 digits'
  }
};

export class _VerifyMobileForm extends Component {
  selectAdd = () => {
    this.props.handleSelect('add');
    this.props.unsetMobile();
  };

  render() {
    return <Form fields={fields} selectAdd={this.selectAdd} {...this.props} />;
  }
}

const mapStateToProps = (state, ownProps) => ({
  loading: isLoading(
    state,
    ownProps.user
      ? [User.actionTypes.AT.SMS_VERIFICATION_STATE._, User.actionTypes.AT.VERIFY_SMS._]
      : [Wallet.actionTypes.AT.SMS_VERIFICATION_STATE._, Wallet.actionTypes.AT.VERIFY_SMS._]
  ),
  loadingSend: isLoading(
    state,
    ownProps.user
      ? User.actionTypes.AT.SEND_SMS_VERIFICATION._
      : Wallet.actionTypes.AT.SEND_SMS_VERIFICATION._
  ),
  phoneNumber: ownProps.user
    ? User.selectors.model(state).phoneNumber
    : Wallet.selectors.getTemporaryMobileNumber(state)
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  submit: (data) => {
    if (ownProps.user) {
      return Api.actions.user.verifySms(null, { verificationCode: data.verificationCode })(
        dispatch
      );
    } else {
      return Api.actions.wallet.verifySms(null, { verificationCode: data.verificationCode })(
        dispatch
      );
    }
  },
  resend: () => {
    if (ownProps.user) {
      return Api.actions.user.sendSmsVerification()(dispatch);
    } else {
      return Api.actions.wallet.sendSmsVerification()(dispatch);
    }
  },
  unsetMobile: () =>
    ownProps.user ? dispatch(User.actions.unsetMobile) : dispatch(Wallet.actions.unsetMobile)
});

export default connect(mapStateToProps, mapDispatchToProps)(_VerifyMobileForm);
