import React, { memo, isValidElement } from 'react';
import cc from 'classcat';
import { Typography, TypographyVariant } from 'components/Typography';
import Markdown from 'components/Markdown';
import {
  InformationalIcon,
  AttentionCircleIcon,
  CheckmarkIcon,
  WarningTriangleIcon,
  NotFoundIcon
} from 'components/Icons';
import { CalloutVariant, ContentType } from './constants';
import './callout.css';

/*
Figma:
https://www.figma.com/file/k3a2l4uTwqB5Urdfp2LSq2/Casino-DS%3A-Design-system%3A-Molecules-(Product-Design)?node-id=363%3A3010
* */

const classMap = {
  [CalloutVariant.INFORMATIONAL]: 'callout--informational',
  [CalloutVariant.SUCCESS]: 'callout--success',
  [CalloutVariant.WARNING]: 'callout--warning',
  [CalloutVariant.ERROR]: 'callout--error',
  [CalloutVariant.GENERIC]: 'callout--generic',
  [CalloutVariant.NOT_FOUND]: 'callout--not-found'
};

const iconMap = {
  [CalloutVariant.INFORMATIONAL]: InformationalIcon,
  [CalloutVariant.SUCCESS]: CheckmarkIcon,
  [CalloutVariant.WARNING]: AttentionCircleIcon,
  [CalloutVariant.ERROR]: WarningTriangleIcon,
  [CalloutVariant.GENERIC]: InformationalIcon,
  [CalloutVariant.NOT_FOUND]: NotFoundIcon
};

export interface CalloutProps {
  title?: JSX.Element | string;
  children?: JSX.Element | string;
  bodyTypographyVariant?: TypographyVariant;
  variant?: CalloutVariant;
  shouldShowIcon?: boolean;
  className?: string;
  contentType?: ContentType;
}

function isJSXElement(item: any): item is JSX.Element {
  return isValidElement(item);
}

const renderContent = (content: JSX.Element | string, contentType: ContentType) => {
  if (isJSXElement(content)) return content;
  if (contentType === ContentType.UNSAFE)
    return <div dangerouslySetInnerHTML={{ __html: content }} />;
  if (contentType === ContentType.MARKDOWN) {
    /* div (basically any tag) is required for Typography to put the class on
       because Markdown returns React.Fragment which does not accept a className prop */
    return (
      <div>
        <Markdown content={content} />
      </div>
    );
  }
  // ContentType.RAW (string)
  return <div>{content}</div>;
};

const Callout = ({
  title,
  children,
  variant = CalloutVariant.GENERIC,
  bodyTypographyVariant = TypographyVariant.BodyMd,
  shouldShowIcon = false,
  contentType = ContentType.RAW,
  className
}: CalloutProps) => {
  const classOfVariant = classMap[variant];
  const Icon = iconMap[variant];

  return (
    <div key="callout" className={cc(['callout', className, classOfVariant])}>
      {shouldShowIcon && (
        <div className="callout__icon-wrapper">
          <Icon className="callout__icon" />
        </div>
      )}
      <div className="callout__content">
        {title && (
          <div className="callout__title">
            <Typography variant={TypographyVariant.BodyLgStrong}>
              {renderContent(title, contentType)}
            </Typography>
          </div>
        )}
        {children && (
          <div className="callout__body">
            <Typography variant={bodyTypographyVariant}>
              {renderContent(children, contentType)}
            </Typography>
          </div>
        )}
      </div>
    </div>
  );
};

export default memo(Callout);
