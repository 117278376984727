export const initialState = {
  preserve: false, // TODO: DANGEROUS Add setTimeout equal to timeLeft to set this to false
  // **IMPORTANT**
  entities: {},
  result: null,
  active: ''
};

export const rooms = (model) => (model && model.entities) || undefined;
export const refs = (model) => (model && model.result) || undefined;
export const active = (model) => (model && model.active) || undefined;
export const notFound = (room) => (room && room.notFound) || false;
export const inPlay = (instance) => (instance && instance.inPlay) || false;
export const inProgress = (instance) => (instance && instance.inProgress) || false;
export const inProgressStatus = (instance) => (instance && instance.inProgressStatus) || undefined;
export const pattern = (instance) => (instance && instance.patternPositions) || undefined;
export const progressiveJackpotAmount = (instance) =>
  (instance && instance.progressiveJackpotAmount) || undefined;
export const progressiveJackpotMaxCalls = (instance) =>
  (instance && instance.progressiveJackpotMaxCalls) || undefined;
export const oneLineJackpotAmount = (instance) =>
  (instance && instance.oneLineJackpotAmount) || undefined;
export const twoLinesJackpotAmount = (instance) =>
  (instance && instance.twoLinesJackpotAmount) || undefined;
export const gameJackpotAmount = (instance) =>
  (instance && instance.gameJackpotAmount) || undefined;
export const startDate = (instance) => (instance && instance.startDate) || undefined;
export const purchasedTickets = (instance) =>
  (instance && instance.purchasedTickets && instance.purchasedTickets.entities) || undefined;
export const purchasedTicketRefs = (instance) =>
  (instance && instance.purchasedTickets && instance.purchasedTickets.result) || undefined;
export const assignedTickets = (instance) =>
  (instance && instance.assignedTickets && instance.assignedTickets.entities) || undefined;
export const assignedTicketRefs = (instance) =>
  (instance && instance.assignedTickets && instance.assignedTickets.result) || undefined;
export const assignedTicketCount = (instance) => (instance && instance.maxTicketsPerPlayer) || 0;
export const selectedTicketRefs = (instance) =>
  (instance && instance.selectedTicketRefs) || undefined;
export const autoSelectedTicketCount = (instance) =>
  (instance && instance.autoSelectedTicketCount) || undefined;
export const nextStartDate = (instance) => (instance && instance.startDate) || undefined;
export const dropped = (instance) => (instance && instance.drawnNumbers) || undefined;
export const gameType = (instance) => (instance && instance.type) || undefined;
export const rolloverEnd = (instance) => instance && instance.rolloverEnd;
export const oneLineWinners = (instance) => (instance && instance.oneLineWinners) || undefined;
export const twoLinesWinners = (instance) => (instance && instance.twoLinesWinners) || undefined;
export const gameWinners = (instance) => (instance && instance.gameWinners) || undefined;
export const progressiveGameWinners = (instance) =>
  (instance && instance.progressiveGameWinners) || undefined;
export const players = (instance) => (instance && instance.players) || undefined;
export const locked = (instance) => (instance && instance.locked) || false;
export const lockedMsg = (instance) => (instance && instance.lockedMsg) || undefined;
export const promptDeposit = (instance) => (instance && instance.promptDeposit) || false;
export const wagerContribution = (instance) =>
  (instance && instance.wagerContribution) || undefined;
export const seedAmount = (instance) => (instance && instance.seedAmount) || undefined;
export const winningCondition = (instance) => (instance && instance.winningCondition) || undefined;
export const rtpLabel = (instance) => (instance && instance.rtpLabel) || undefined;
export const progressiveTitle = (instance) => (instance && instance.progressiveTitle) || undefined;
export const rows = (ticket) => (ticket && ticket.rows) || undefined;
export const audio = (instance) => instance?.audio;
export const pictureNumbersImageUrl = (instance) => instance?.pictureNumbersImageUrl;
export const animatedNumbersImageUrls = (instance) => instance?.animatedNumbersImageUrls;
