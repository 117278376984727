import Api from 'services/Api';
import getType from 'lib/getType';
import { T, AT } from './actionTypes';
// TODO @GeKorm: Refactor these things to thunks WTF was I thinking
export const setPreserve = (val) => ({
  type: T.PRESERVE,
  payload: val
});

export const disablePreserve = (milliseconds) => (dispatch) => {
  setTimeout(() => dispatch(setPreserve(false)), milliseconds);
};

export const mute = (refName, isMuted = true) => ({
  type: T.MUTE,
  meta: {
    sound: {
      mute: [refName, isMuted]
    }
  }
});

export const setActive = (refName) => ({
  type: T.SET_ACTIVE,
  payload: refName
});

export const oneLineWon = (refName, winningUsernames, amount, winners) => ({
  type: T.ONE_LINE_WINNER,
  payload: { refName, winningUsernames, amount, winners }
});

export const twoLinesWon = (refName, winningUsernames, amount, winners) => ({
  type: T.TWO_LINES_WINNER,
  payload: { refName, winningUsernames, amount, winners }
});

export const gameWon = (refName, winningUsernames, amount, winners) => ({
  type: T.BINGO_GAME_JACKPOT_WINNER,
  payload: { refName, winningUsernames, amount, winners }
});

export const progressiveGameWon = (refName, winningUsernames, amount, winners) => ({
  type: T.BINGO_PROGRESSIVE_JACKPOT_WINNER,
  payload: { refName, winningUsernames, amount, winners }
});

export const rollover = (refName) => ({
  type: T.BINGO_GAME_JACKPOT_ROLLOVER,
  payload: { refName }
});

export const subscribe = (refName) => ({
  type: AT.WS_SUBSCRIBE._,
  payload: refName
});

export const unsubscribe = (refName) => ({
  type: AT.WS_UNSUBSCRIBE._,
  payload: refName
});

// TODO: Use this for pending state

// Action creators
export const _refresh = (dispatch, refName, options) =>
  Promise.all([
    Api.actions.bingo.room({ refName: refName, instance: 'next' }, undefined, options)(dispatch),
    Api.actions.bingo.room({ refName: refName, instance: 'current' }, undefined, options)(dispatch),
    Api.actions.bingo.purchasedTickets(
      { refName: refName, instance: 'current' },
      undefined,
      options
    )(dispatch),
    // TODO: Opportunity to short-circuit some requests since we might show inPlay
    Api.actions.bingo.purchasedTickets(
      { refName: refName, instance: 'next' },
      undefined,
      options
    )(dispatch)
  ]);

export const refresh = (dispatch, refName, options) => ({
  type: AT.REFRESH._,
  payload: _refresh(dispatch, refName, options),
  meta: {
    args: {
      refName: refName
    }
  }
});

export const _startGame = (dispatch, refName) =>
  Promise.all([
    Api.actions.bingo.purchasedTickets({ refName: refName, instance: 'current' })(dispatch),
    Api.actions.bingo.assignedTickets({ refName: refName, instance: 'current' })(dispatch),
    Api.actions.bingo.room({ refName: refName, instance: 'current' })(dispatch),
    // TODO: Opportunity to short-circuit some requests since we might show inPlay
    Api.actions.bingo.purchasedTickets({ refName: refName, instance: 'next' })(dispatch),
    Api.actions.bingo.assignedTickets({ refName: refName, instance: 'next' })(dispatch)
  ]);

export const startGame = (dispatch, refName) => ({
  type: AT.START_GAME._,
  payload: _startGame(dispatch, refName)
});

export const autoselectTickets = (refName, ticketCount) => ({
  type: T.AUTOSELECT_TICKETS,
  payload: ticketCount,
  meta: {
    args: { refName } // Ugh not very clean to structure this like the API requests. Should be in
    // the payload
  }
});

export const selectTickets = (refName, ticketRefs) => ({
  type: T.SELECT_TICKETS,
  payload: ticketRefs,
  meta: {
    args: { refName } // Ugh not very clean to structure this like the API requests. Should be in
    // the payload
  }
});

export const deselectTickets = (refName, ticketRefs) => ({
  type: T.DESELECT_TICKETS,
  payload: ticketRefs,
  meta: {
    args: { refName }
  }
});

export const buyTickets = async (dispatch, refName, tickets, instance = 'next') => {
  dispatch(autoselectTickets(refName, 0));
  dispatch(deselectTickets(refName));
  const ticketsType = getType(tickets);
  let data;
  if (ticketsType === 'Number') {
    // Autobuy
    data = { autoSelectedTicketsNumber: tickets };
    await Api.actions.bingo.quickBuy({ refName, instance }, data)(dispatch);
  } else if (ticketsType === 'Array') {
    data = { selectedTicketIds: tickets };
    await Api.actions.bingo.buySelected({ refName, instance }, data)(dispatch);
  }
  return Api.actions.bingo.purchasedTickets({ refName, instance }, null, { skipCache: true })(
    dispatch
  );
};
