import { getExperimentData } from 'lib/analytics';

const variants = {
  LATEST: 'latest',
  MOST: 'most'
};

const experiment = getExperimentData('ai-game-recommendations');
export const isLatestVariant = experiment.variant !== variants.MOST;
export const isMostVariant = experiment.variant === variants.MOST;

export default { ...experiment, isLatestVariant, isMostVariant };
