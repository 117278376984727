import { useCallback, useEffect, useMemo, useState } from 'react';
import Api from 'services/Api';

export const getCmsLocale = (mrqLocale: string): string =>
  mrqLocale === 'en-GB' ? 'en' : mrqLocale;
const locale = getCmsLocale(__ENV__.MRQ_LOCALE);

interface usePublicCmsDataProps<T> {
  hasContent?: boolean;
  contentPath: string;
  updateContent: (content: T) => void;
}

const usePublicCmsData = <T>({
  hasContent,
  contentPath,
  updateContent
}: usePublicCmsDataProps<T>) => {
  const [state, setState] = useState<{
    hasError?: boolean;
    isLoading: boolean;
  }>({
    hasError: false,
    isLoading: true
  });

  const fetchData = useCallback(async () => {
    try {
      const path = new URL(`${__ENV__.MRQ_URL_CMS}${contentPath}`);
      path.searchParams.append('locale', locale);
      const response = await Api.ky(path.toString());
      const result: T = await response.json();

      if (!result) throw new Error('No result found');

      setState({ hasError: false, isLoading: false });
      updateContent(result);
    } catch (e) {
      setState({ hasError: true, isLoading: false });
    }
  }, [contentPath, updateContent]);

  useEffect(() => {
    if (hasContent) {
      setState({ isLoading: false });
    } else {
      void fetchData();
    }
  }, [fetchData, contentPath, hasContent]);

  const { hasError, isLoading } = state;

  return useMemo(() => ({ hasError, isLoading }), [hasError, isLoading]);
};

export default usePublicCmsData;
