import React, { useCallback, useEffect } from 'react';
import loadable from '@loadable/component';
import type { constants as UserConstants } from 'modules/User';
import Dialog from 'components/Dialog';
import makeModal from 'components/Modals';
import { DialogHeader } from 'components/Dialog/Header';
import { useAppDispatch, useAppSelector } from 'hooks/redux';
import User from 'modules/User';
import { isLoading } from 'lib/redux-utils';
import * as Animation from 'components/Animation';
import Api from 'services/Api';
import './kyc.css';

const VerificationRequested = loadable(
  () =>
    import(
      /* webpackPreload: true, webpackChunkName: "VerificationRequested" */ './VerificationRequested'
    )
);

interface KYCProps {
  /** Close modal function */
  close: () => void;
  /** Wait for data to render correct modal state and avoid flashing content */
  isLoadingData: boolean;
  /** Switch modal type based on provider flag */
  provider: UserConstants.VerificationProvider;
  /** Used when creating the hooyu journey */
  journey: UserConstants.VerificationType;
  onClose: () => void;
}

export const KYC = ({ close, provider, journey, isLoadingData, onClose }: KYCProps) => (
  <Dialog name="KYC" onClose={onClose}>
    <DialogHeader icon="/assets/images1/dialogIcon.svg" onClose={close}>
      Verification Team
    </DialogHeader>

    {isLoadingData ? (
      <Animation.Root className="kyc__spinner">
        <Animation.Spinner />
      </Animation.Root>
    ) : (
      <VerificationRequested journey={journey} provider={provider} close={close} />
    )}
  </Dialog>
);

const KYCAdapter = ({ close }: { close: () => void }) => {
  const dispatch = useAppDispatch();
  const provider: UserConstants.VerificationProvider = useAppSelector(
    User.selectors.getVerificationProvider
  );
  const journey: UserConstants.VerificationType = useAppSelector(
    User.selectors.getVerificationType
  );
  const requiresVerification = useAppSelector(User.selectors.getRequiresVerification);
  const isLoadingData = useAppSelector((state) =>
    isLoading(state, User.actionTypes.AT.KYC_VERIFICATION._)
  );

  const handleClose = useCallback(() => {
    dispatch(Api.actions.user.kycVerification());
  }, [dispatch]);

  useEffect(() => {
    if (!requiresVerification) {
      close();
    }
  }, [requiresVerification, close]);

  return (
    <KYC
      provider={provider}
      journey={journey}
      close={close}
      isLoadingData={isLoadingData}
      onClose={handleClose}
    />
  );
};
export default makeModal(
  'KYC',
  { name: 'modal-fade', timeout: 200 },
  { className: 'modal modal--dialog' }
)(KYCAdapter);
