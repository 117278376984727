import React, { useCallback } from 'react';
import type { ReactNode } from 'react';
import { useHistory } from 'react-router-dom';
import cc from 'classcat';
import Button from 'components/Button';
import { EffectTypes } from 'services/Effects';
import { useAppDispatch } from 'hooks/redux';
import Analytics from 'modules/Analytics';
import './offerCard.css';

export interface RewardOfferCardProps {
  refName: string;
  type?: string;
  title?: string;
  heading?: string;
  shortDescription?: string;
  imageUrl?: string;
  cta?: string;
  ctaText?: string;
  openModal?: () => void;
  className?: string;
  children?: ReactNode;
}

const RewardOfferCard = ({
  refName,
  title = '',
  heading = '',
  shortDescription = '',
  imageUrl = '',
  cta = '',
  ctaText = 'Claim offer',
  type = '',
  openModal,
  className,
  children
}: RewardOfferCardProps) => {
  // Why does the root div have a onClick Handler?
  // The stakeholders of this feature want a click anywhere on the card to trigger the default action,
  // in this case, opening the "Expanded Offer View" (OfferCardExpanded).
  // Making the div have an onClick will give that behaviour, while buttons inside may have another
  // onClick functionality which will stop the onClick propagation (bubbling).
  // With this we get the desired behavior and still get to keep the accessibility working properly
  const dispatch = useAppDispatch();
  const history = useHistory();

  const offerClicked = useCallback(
    ({ interactionType }: { interactionType: EffectTypes.OfferClickedInteractionType }) => {
      dispatch(
        Analytics.actions.offerClicked({
          refName,
          type,
          source: EffectTypes.OfferClickedSourceType.Rewards,
          interactionType
        })
      );
    },
    [dispatch, refName, type]
  );

  const handleCardClick = useCallback(() => {
    offerClicked({ interactionType: EffectTypes.OfferClickedInteractionType.CardClick });
    openModal?.();
  }, [openModal, offerClicked]);

  const handleClaimOfferClick = useCallback(
    (e) => {
      e.stopPropagation();
      offerClicked({ interactionType: EffectTypes.OfferClickedInteractionType.PrimaryCTAClick });
      history.push(cta);
    },
    [cta, history, offerClicked]
  );

  const handleReadMoreClick = useCallback(
    (e) => {
      e.stopPropagation();
      offerClicked({ interactionType: EffectTypes.OfferClickedInteractionType.SecondaryCTAClick });
      openModal?.();
    },
    [openModal, offerClicked]
  );

  return (
    // eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions  -- Disabled because of the reason described above.
    <div className={cc(['offerCard offerCard--inLobby', className])} onClick={handleCardClick}>
      <div className="offerCard__image">
        <img alt="offer" src={imageUrl} />
      </div>
      <div className="offerCard__content">
        <h6 className="text-truncate">{title}</h6>
        <div className="offerCard__subtitle text-truncate">{heading}</div>
        {shortDescription && <p className="offerCard__tcLabel text-truncate">{shortDescription}</p>}
        {children && <div className="offerCard__children">{children}</div>}
        <div className="offerCard__cta">
          {openModal && (
            <Button
              className="button__grey button--fullWidth"
              name="readMore"
              onClick={handleReadMoreClick}
            >
              Read More
            </Button>
          )}
          {cta && (
            <Button className="button--fullWidth" name="claimOffer" onClick={handleClaimOfferClick}>
              {ctaText}
            </Button>
          )}
        </div>
      </div>
    </div>
  );
};

export default RewardOfferCard;
