import type { ReactNode } from 'react';
import React from 'react';

export interface ListItemContentProps {
  children: ReactNode;
  prefix?: ReactNode;
  suffix?: ReactNode;
  onClick?: () => void;
}

const ListItemContent = ({ children, prefix, suffix, onClick }: ListItemContentProps) => (
  <div className="listItem__container" onClick={onClick}>
    {prefix && <div className="listItem__prefix">{prefix}</div>}
    <div className="listItem__content">{children}</div>
    {suffix && <div className="listItem__suffix">{suffix}</div>}
  </div>
);

export default ListItemContent;
