import React from 'react';
import { constants } from 'modules/Wallet';
import type { EcospendStep } from '../types';
import AuthOption from './AuthOption';

interface AuthOptionChooserProps {
  onClick: (option: EcospendStep) => void;
  disabledOptions: { [key in EcospendStep]?: boolean };
  smsLoading: boolean;
}

const opts = [
  {
    option: constants.ECOSPEND_STEP.QR_AUTH,
    tags: ['Fastest'],
    title: 'QR Code',
    label: 'Just point your phone camera to the weird image, and authorise though your phone.'
  },

  {
    option: constants.ECOSPEND_STEP.SMS,
    title: 'Send me an SMS',
    label:
      'We can send you an SMS with a link that takes you to authorise your transaction through your phone.'
  },

  {
    option: constants.ECOSPEND_STEP.URL_AUTH,
    title: 'Authorise here on my computer',
    label: 'If your phone is not within reach, you can authorise your transaction here.'
  }
];

const AuthOptionChooser = ({ onClick, disabledOptions, smsLoading }: AuthOptionChooserProps) => (
  <div>
    {opts.map((opt) => (
      <AuthOption
        loading={opt.option === constants.ECOSPEND_STEP.SMS && smsLoading}
        disabled={disabledOptions[opt.option]}
        key={opt.option}
        option={opt.option}
        label={opt.label}
        title={opt.title}
        onClick={onClick}
        tags={opt.tags}
      />
    ))}
  </div>
);

export default AuthOptionChooser;
