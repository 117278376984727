import React, { type ChangeEventHandler, type FocusEventHandler } from 'react';
import reform from 'components/Reform';
import Button from 'components/Button';
import FormError from 'components/FormError';
import SimpleSelect from 'components/SimpleSelect';
import Markdown from 'components/Markdown';
import { formatMonths } from 'lib/formatters';
import type { ReformField } from 'components/Reform/types';
import data from './data';

export interface SelfExclusionFormProps {
  months: ReformField;
  agreeCheckbox: ReformField;
  formValid: boolean;
  errorMessage: string;
  loading: boolean;
  handleChange: ChangeEventHandler;
  handleFocus: FocusEventHandler;
  onSubmit: SubmitEvent;
  close: () => void;
}

const SelfExclusionForm = reform()(
  ({
    months,
    agreeCheckbox,
    formValid,
    errorMessage,
    loading,
    handleChange,
    handleFocus,
    onSubmit,
    close
  }: SelfExclusionFormProps) => (
    <div className="selfExclusion__markdown">
      <Markdown content={data.content} />
      <SimpleSelect
        className="dropdown"
        name="months"
        placeholder="Select self-exclusion period"
        value={months.value}
        options={data.options}
        numberFormatter={formatMonths}
        onChange={handleChange}
        onFocus={handleFocus}
      />
      <p className="userBox__paragraph--danger">
        I understand that by self-excluding, I’ll no longer be able to play at MrQ.
      </p>
      <div className="checkbox checkbox--wide">
        <input
          id="agreeCheckbox"
          name="agreeCheckbox"
          type="checkbox"
          onChange={handleChange}
          checked={Boolean(agreeCheckbox.value)}
        />
        <span className="checker" />
        <label htmlFor="agreeCheckbox">I understand and want to continue</label>
      </div>
      <div className="fieldset fieldset--centerflex selfExclusion__footer">
        <Button
          title="Cancel"
          buttonText="Cancel"
          onClick={close}
          name="close"
          id="selfExclusion-close"
          className="submit"
        />
        <Button
          disabled={!formValid}
          loading={loading}
          title="You will be logged out immediately"
          buttonText="Set self-exclusion"
          name="selfExclude"
          type="button"
          className="submit"
          onClick={onSubmit}
        />
      </div>
      <FormError errorMessage={errorMessage} />
    </div>
  )
);

export default SelfExclusionForm;
