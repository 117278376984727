import type { ReactNode } from 'react';
import React, { memo, isValidElement } from 'react';
import cc from 'classcat';
import { TypographyCQ } from 'components/Typography';
import './info.css';

interface InfoProps {
  children: ReactNode;
  icon: string;
  className?: string;
}

const Info = ({ children, icon, className }: InfoProps) => (
  <div className={cc(['bingoCardInfo', className])}>
    <img src={icon} alt="" />
    <TypographyCQ name="info">
      {isValidElement(children) ? children : <span>{children}</span>}
    </TypographyCQ>
  </div>
);

Info.displayName = 'BingoCard.Info';

export default memo(Info);
