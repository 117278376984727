import React from 'react';
import SetLimits from './SetLimits';

interface SetLimitsAdapterProps {
  onDoneDepositLimit: () => void;
}

const SetLimitsAdapter = ({ onDoneDepositLimit }: SetLimitsAdapterProps) => (
  <SetLimits onDoneDepositLimit={onDoneDepositLimit} />
);
export default SetLimitsAdapter;
