import Api from 'services/Api';
import status from 'services/Api/http';
import { Seon } from 'lib/deviceFingerprint';
import { AT } from './actionTypes';

// AC Implementation functions
// Cam be split into an 'actionImpl.js' file

// Action creators
const seon = __ENV__.SEON_ENABLED === 'true' ? new Seon() : null;

const checkAuthRequest =
  ({ skipSession } = {}) =>
  async () => {
    const result = await Api.request.auth.checkAuth(undefined, undefined, { skipSession });

    if (result.status === status.UNAUTHORIZED) {
      return new Promise().reject;
    } else {
      return result;
    }
  };

export const checkAuth = (options) => ({
  type: AT.CHECK_AUTH._,
  payload: checkAuthRequest(options)
});

export const getDeviceFingerprint = () => ({
  type: AT.FINGERPRINT._,
  payload: __ENV__.SEON_ENABLED === 'true' ? seon.getDeviceFingerprint() : Promise.resolve(null)
});
