import * as actions from './src/actions';
import * as actionTypes from './src/actionTypes';
import * as constants from './src/constants';
import * as selectors from './src/selectors';
import reducer from './src/reducer';

export default { actions, constants, reducer, selectors, actionTypes, NAME: constants.NAME };
export * as constants from './src/constants';
export * as actionTypes from './src/actionTypes';
export * as types from './src/types';
