import importScript from 'lib/importScript';
import type { SecureFieldOptions, SecureFieldsApi, SecureFieldsConfig } from './types';

const isProd = __ENV__.NODE_ENV === 'production' && __ENV__.NODE_STAGE === 'production';
const merchantId = __ENV__.PCI_PROXY_MERCHANT_ID;

const getUrl = () =>
  isProd
    ? 'https://pay.datatrans.com/upp/payment/js/secure-fields-2.0.0.min.js'
    : 'https://pay.sandbox.datatrans.com/upp/payment/js/secure-fields-2.0.0.min.js';

const options = {
  styles: {
    '*': {
      fontFamily: 'Gilroy, sans-serif',
      fontSize: '16px',
      lineHeight: '26px',
      fontWeight: '400',
      color: '#0e1120',
      padding: '0',
      outline: 'none'
    },
    '@font-face': {
      '*': {
        fontFamily: 'Gilroy',
        fontStyle: 'normal',
        fontWeight: '400',
        src: "url('gilroy-regular.woff2') format('woff2')" // font is uploaded on our merchant space by pci proxy
      }
    }
  },
  paymentMethods: ['ECA', 'VIS'] // allowing Mastercard and Visa only
};

const SecureFieldsClientApi: SecureFieldsApi = {
  load: () => importScript(getUrl(), 'pci-proxy') as Promise<void>,
  isScriptLoaded: () => Boolean(window.SecureFields),
  initSecureFields: (fields: SecureFieldsConfig) => {
    const secureFields = new window.SecureFields();
    secureFields?.initTokenize(merchantId, fields, options);
    return secureFields as SecureFieldOptions;
  }
};

export default SecureFieldsClientApi;
