const onIdle =
  window.requestIdleCallback ||
  function (callback) {
    let callbackExecuted = false;
    const start = Date.now();

    // Won't run in background tab
    requestAnimationFrame(() => {
      if (!callbackExecuted) {
        callbackExecuted = true;
        callback({
          didTimeout: false,
          timeRemaining: function () {
            return Math.max(0, 50 - (Date.now() - start));
          }
        });
      }
    });

    setTimeout(() => {
      if (!callbackExecuted) {
        callbackExecuted = true;
        callback({
          didTimeout: false,
          timeRemaining: function () {
            return Math.max(0, 50 - (Date.now() - start));
          }
        });
      }
    }, 1);
  };

export default onIdle;
