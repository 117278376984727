import React, { Component } from 'react';
import PropTypes from 'prop-types';
import update from 'update-immutable';
import { connect } from 'react-redux';
import { isLoading } from 'lib/redux-utils';
import { MagicMove } from 'components/Transitions';
import Wallet from 'modules/Wallet';
import User from 'modules/User';
import Api from 'services/Api';
import AddMobileForm from './AddMobileForm';
import VerifyMobileForm from './VerifyMobileForm';

export class _AddMobile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      // add, verify
      selected:
        (props.canSendVerification && !props.forceAdd) || props.verifyOnly ? 'verify' : 'add'
    };
  }

  componentDidMount() {
    const { canSendVerification, forceAdd, getVerificationState } = this.props;
    if (!forceAdd) {
      getVerificationState();
    }
    if (canSendVerification && !forceAdd) {
      this.props.sendVerification();
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { canSendVerification, canAttemptVerification } = this.props;
    if (nextProps.canSendVerification && !canSendVerification) {
      this.setState({ selected: 'verify' });
    }
    if (nextProps.canAttemptVerification && !canAttemptVerification) {
      this.props.sendVerification();
    }
  }

  componentDidUpdate(prevProps) {
    const { phoneVerified, onDone, forceAdd, requiresReverification } = this.props;

    if (!forceAdd && phoneVerified && !requiresReverification) {
      onDone();
    }
    if (!prevProps.phoneVerified && phoneVerified && !requiresReverification) {
      onDone();
    }
  }

  select = (value) => {
    this.setState((state) => update(state, { selected: { $set: value } }));
  };

  render() {
    const { selected } = this.state;
    const { user, onDone } = this.props;

    return (
      <MagicMove>
        {selected === 'add' ? (
          <AddMobileForm key="add" handleSelect={this.select} user={user} onDone={onDone} />
        ) : (
          <VerifyMobileForm key="verify" handleSelect={this.select} user={user} />
        )}
      </MagicMove>
    );
  }
}

_AddMobile.propTypes = {
  onDone: PropTypes.func.isRequired,
  getVerificationState: PropTypes.func,
  sendVerification: PropTypes.func,
  phoneVerified: PropTypes.bool,
  /** Forces number verification */
  forceAdd: PropTypes.bool,
  canAttemptVerification: PropTypes.bool,
  requiresReverification: PropTypes.bool,
  user: PropTypes.bool,
  verifyOnly: PropTypes.bool,
  canSendVerification: PropTypes.bool
};

_AddMobile.defaultProps = {
  getVerificationState: null,
  sendVerification: null,
  phoneVerified: false,
  user: false,
  forceAdd: false,
  canAttemptVerification: false,
  requiresReverification: false,
  verifyOnly: false,
  canSendVerification: false
};

const mapStateToProps = (state, ownProps) =>
  ownProps.user
    ? {
        phoneVerified: User.selectors.model(state).phoneVerified,
        canSendVerification: User.selectors.model(state).canSendVerification,
        canAttemptVerification: User.selectors.model(state).canAttemptVerification,
        requiresReverification: User.selectors.model(state).requiresReverification,
        loading: isLoading(state, User.actionTypes.AT.SMS_VERIFICATION_STATE._)
      }
    : {
        phoneVerified: Wallet.selectors.phoneVerified(state),
        canSendVerification: Wallet.selectors.model(state).canSendVerification,
        canAttemptVerification: Wallet.selectors.model(state).canAttemptVerification,
        requiresReverification: Wallet.selectors.model(state).requiresReverification,
        loading: isLoading(state, Wallet.actionTypes.AT.SMS_VERIFICATION_STATE._)
      };

const mapDispatchToProps = (dispatch, ownProps) => ({
  sendVerification: () =>
    ownProps.user
      ? Api.actions.user.sendSmsVerification()(dispatch)
      : Api.actions.wallet.sendSmsVerification()(dispatch),
  getVerificationState: () =>
    ownProps.user
      ? Api.actions.user.smsVerificationState()(dispatch)
      : Api.actions.wallet.smsVerificationState()(dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(_AddMobile);
