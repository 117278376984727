import { useRef, useEffect } from 'react';

const usePrevious = <T>(value: T): T | null => {
  const ref = useRef<T | null>(null);

  useEffect(() => {
    ref.current = value;
  }, [value]);

  return ref.current;
};

export type UsePrevious = typeof usePrevious;
export default usePrevious;
