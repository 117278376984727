import React from 'react';
import type { FormEvent, MouseEvent, FocusEventHandler } from 'react';
import FlipMove from 'react-flip-move';
import reform from 'components/Reform';
import FormError from 'components/FormError';
import Button from 'components/Button';
import { easing } from 'lib/constants';
import { screens, type ContactFieldType } from '../constants';
import RefereeItem from '../RefereeItem';

interface FriendsFormProps {
  type: ContactFieldType;
  addField: () => void;
  goToScreen: (screen: string) => void;
  removeField: (field: string) => void;
  handleFocus: FocusEventHandler<HTMLInputElement>;
  handleChange: (e: FormEvent<HTMLFormElement>) => void;
  onSubmit: (e: FormEvent<HTMLFormElement>) => Promise<any>;
  updateInvitedFriends: (validRowCount: number) => void;
  loading: boolean;
  errorMessage: string;
  fieldRefs: string[];
  formValid: boolean;
}

const FriendsForm = ({
  type,
  addField,
  removeField,
  goToScreen,
  handleFocus,
  handleChange,
  onSubmit,
  updateInvitedFriends,
  loading,
  errorMessage,
  fieldRefs,
  formValid,
  ...rest
}: FriendsFormProps) => {
  const formName = `referAFriend${type[0].toUpperCase() + type.slice(1)}`;

  const handleUpdateInvitedFriends = () => {
    setTimeout(() => {
      const validRowCount: number = fieldRefs.filter((fieldRef: string) => {
        const nameProp: { valid: boolean } = rest[`name_${fieldRef}` as keyof typeof rest];
        const contactDetailsProp: { valid: boolean } =
          rest[`contactDetails_${fieldRef}` as keyof typeof rest];
        return nameProp.valid && contactDetailsProp.valid;
      }).length;
      updateInvitedFriends(validRowCount);
    }, 0);
  };

  const handleFormChange = (e: FormEvent<HTMLFormElement>) => {
    handleChange(e);
    handleUpdateInvitedFriends();
  };

  const handleDeleteRow = (e: MouseEvent<HTMLButtonElement, MouseEvent>) => {
    removeField(e.currentTarget.name);
    handleUpdateInvitedFriends();
  };

  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const res = await onSubmit(e);
    if (res === true) {
      goToScreen(screens.completed);
    }
  };

  return (
    // eslint-disable-next-line @typescript-eslint/no-misused-promises -- expected async handleSubmit
    <form onSubmit={handleSubmit} name={formName}>
      <div className="referAFriend__rowsContainer">
        <FlipMove delay={0} duration={350} easing={easing} staggerDelayBy={80}>
          {fieldRefs.map((fieldRef) => (
            <div key={fieldRef}>
              <RefereeItem
                fieldId={fieldRef}
                nameField={rest[`name_${fieldRef}` as keyof typeof rest]}
                contactField={rest[`contactDetails_${fieldRef}` as keyof typeof rest]}
                contactFieldType={type}
                showDeleteButton={fieldRefs.length > 1}
                handleFormChange={handleFormChange}
                handleFocus={handleFocus}
                handleDeleteRow={handleDeleteRow}
              />
            </div>
          ))}
        </FlipMove>
      </div>
      <div className="referAFriend__addAnother">
        <Button
          icon="/assets/images1/plus-primary.svg"
          className="button--iconButton button__grey"
          onClick={addField}
          name="referFriendAddRow"
          type="button"
        />
        <span onClick={addField}>Add another friend</span>
      </div>
      <div className="referAFriend__centeredContainer">
        <Button
          className="referAFriend__button"
          disabled={!formValid}
          loading={loading}
          title="Send"
          name="referFriendSend"
          buttonText="Send"
          type="submit"
        />
      </div>
      <FormError errorMessage={errorMessage} />
    </form>
  );
};

export default reform()(FriendsForm);
