export interface OfferData {
  ref: string;
  type: OfferType;
  title?: string;
  heading?: string;
  shortDescription?: string;
  cta?: string;
  ctaText?: string;
  imageUrl?: string;
  thumbnailUrl?: string;
  subOffers: SubOffer[];
}

export interface SubOffer extends OfferData {
  status: RewardsCalendarDayStatus;
}

export enum OfferType {
  SIMPLE = 'SIMPLE',
  CALENDAR = 'CALENDAR'
}

export enum RewardsCalendarDayStatus {
  PAST = 'PAST',
  CURRENT = 'CURRENT',
  FUTURE = 'FUTURE'
}
