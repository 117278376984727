import React, { memo } from 'react';
import cc from 'classcat';
import withLazyStyle from 'components/LazyStyle';
import style from 'css/components/fieldset.css?lazy';

interface ICheckboxProps {
  name: string;
  label: string;
  checked: boolean;
  disabled?: boolean;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  className?: string;
}

const Checkbox = ({ className, name, label, checked, disabled, onChange }: ICheckboxProps) => (
  <div className={className ? cc(['checkbox', className]) : 'checkbox'}>
    <input
      id={name}
      name={name}
      type="checkbox"
      disabled={disabled}
      checked={checked}
      onChange={onChange}
    />
    <span className="checker" />
    <label htmlFor={name}>{label}</label>
  </div>
);

export default memo(withLazyStyle(style)(Checkbox));
