import { actionTypeCreator, SYNC } from 'lib/redux-utils';
import { NAME } from './constants';

const namespace = actionTypeCreator(NAME);

export const T = namespace({
  OPEN: SYNC,
  TOGGLE: SYNC,
  CLOSE: SYNC,
  DESTROY: SYNC,
  UPDATE: SYNC,
  RESET: SYNC,
  CREATE: SYNC
});
