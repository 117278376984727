import React, { memo, useCallback, useRef } from 'react';
import RouterLink from 'components/RouterLink';
import Heading from 'components/Heading';
import cfImage from 'lib/cfImage';
import { Typography, TypographyVariant } from 'components/Typography';
import { useTouchIsolation } from 'lib/useTouchIsolation';
import { useAppDispatch } from 'hooks/redux';
import Analytics from 'modules/Analytics';
import './listTile.css';

export interface ListTileProps {
  refName: string;
  name: string;
  displayProvider: string;
  lobbyImageUrl: string;
  blur: boolean;
  useCFImage?: boolean;
}

const ListTile = ({
  refName,
  name,
  displayProvider,
  lobbyImageUrl,
  blur,
  useCFImage = true
}: ListTileProps) => {
  const containerRef = useRef(null);
  const dispatch = useAppDispatch();
  const imageURL = !useCFImage
    ? lobbyImageUrl
    : cfImage(lobbyImageUrl, {
        width: 48, // in sync with CSS --imageSize;
        height: 48,
        ignoreGif: !blur,
        ...(blur && {
          blur: 25,
          brightness: 0.8
        })
      });

  const trackGameLaunched = useCallback(
    (e) => {
      let searchTerm;
      const tileElement = e.target;
      // We generally don't want analytics to cause rerendering or dictate our componnt tree,
      // so picking from the DOM is OK when reliable enough.
      const searchOverlay = document.querySelector('[data-amp-id="searchOverlay"]');
      if (searchOverlay?.contains(tileElement)) {
        const searchInput: HTMLInputElement | null = searchOverlay?.querySelector('input#search');
        searchTerm = searchInput?.value;
      }
      dispatch(
        Analytics.actions.gameLaunched({
          refName,
          name,
          provider: displayProvider,
          source: 'Search',
          searchTerm
        })
      );
    },
    [dispatch, displayProvider, name, refName]
  );

  const id = `${name}|${displayProvider}`;
  useTouchIsolation({ containerRef, isActive: true });

  return (
    <RouterLink
      id={id}
      className="listTile"
      href={`/secure/games/${refName}`}
      innerRef={containerRef}
      onClick={trackGameLaunched}
    >
      {!imageURL ? null : <img className="listTile__image" alt={name} src={imageURL} />}
      <div className="listTile__info">
        <Typography variant={TypographyVariant.BodySm}>
          <Heading level={4} className="listTile__title">
            {name}
          </Heading>
        </Typography>
        <Typography variant={TypographyVariant.BodyXs}>
          <div className="listTile__extra">{displayProvider || ' '}</div>
        </Typography>
      </div>
    </RouterLink>
  );
};

export default memo(ListTile);
