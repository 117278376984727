import React from 'react';
import { applyContainerQuery } from 'lib/react-container-query';

const query = {
  'max-width-78': { maxWidth: 78 },
  'max-width-69': { maxWidth: 69 }
};

export const _ButtonMenuWrap = ({ children }) => <div className="buttonMenu-wrap">{children}</div>;

export default applyContainerQuery(_ButtonMenuWrap, query);
