import React, { memo } from 'react';
import PropTypes from 'prop-types';
import LoadingLiveTile from 'components/LiveTile/LoadingLiveTile';

const LoadingSlotList = ({ count }) =>
  Array(count)
    .fill()
    // eslint-disable-next-line react/no-array-index-key
    .map((_, i) => <LoadingLiveTile key={i} />);

LoadingSlotList.propTypes = {
  count: PropTypes.number
};

LoadingSlotList.defaultProps = {
  count: 1
};

export default memo(LoadingSlotList);
