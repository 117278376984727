import type { ReactNode } from 'react';
import React from 'react';
import { Typography, TypographyVariant } from 'components/Typography';

const TextTertiary = ({ children, color }: { children: ReactNode; color?: string }) => (
  <Typography variant={TypographyVariant.BodyMd}>
    <span className="listItem__text--tertiary" style={{ color }}>
      {children}
    </span>
  </Typography>
);

export default TextTertiary;
