import update from 'update-immutable';

export default function apiMiddleware() {
  return () => (next) => (action) =>
    action.meta && action.meta.generated && action.payload // Is constructed API response
      ? next(
          update(action, {
            payload: { $set: action.payload.data },
            meta: {
              $merge: {
                errors: action.payload.errors,
                msg: action.payload.msg,
                msgRef: action.payload.msgRef,
                status: action.payload.status,
                visible: action.payload.visible,
                severity: action.payload.severity,
                code: action.payload.code
              }
            }
          })
        )
      : next(action);
}
