import { isEmpty, uniq } from 'lodash';
import { getNamespace } from 'lib/redux-utils';
import getType from 'lib/getType';
import * as actions from './actions';
import * as t from './actionTypes';
import * as providers from './providers';
import { parse } from './error-message';

const MIN_RETRY = 1000;
const MAX_RETRY = 10000;

const providerClients = {};

const providerTimeouts = {};

/**
 * Contains the subscription clients
 *
 * @example
 * {
 *   Bingo: {
 *     refName: subscriptionClient
 *   }
 * }
 */
const subscriptions = {};

/**
 * Contains the subscription refs
 *
 * @example
 * {
 *   Bingo: ['alpha', 'beta', 'charlie']
 * }
 */
const queuedSubscriptions = {};

// FIXME: Use the store's logged in loading state instead of this
const authenticating = {};

const onMessage = (client, dispatch, message, args) => {
  // Data will be handled by the translator middleware
  dispatch({
    type: 'SocketMessage/INCOMING',
    payload: message.body,
    meta: {
      provider: 'mrqSockets',
      incoming: true,
      args: args
    }
  });
};

const disconnect = (provider, cb, clearQueue) => {
  const cleanup = () => {
    if (providerClients[provider]) {
      providerClients[provider].disconnecting = false;
      delete providerClients[provider];
    }
    if (clearQueue !== false) {
      delete queuedSubscriptions[provider]; // clear
    }
    if (cb) {
      cb();
    }
  };
  if (providerClients[provider] && providerClients[provider].connected) {
    providerClients[provider].disconnecting = true;
    providerClients[provider].disconnect(() => {
      cleanup();
    });
  } else {
    cleanup();
  }
};

const onOpen = (provider, dispatch) => {
  dispatch(actions.connected(provider));
  if (providerClients[provider] && providerClients[provider].disconnecting) {
    disconnect(provider);
    return;
  }
  providerTimeouts[provider] = MIN_RETRY;
  const queuedSubs = queuedSubscriptions[provider];
  if (queuedSubs && queuedSubs.length > 0) {
    queuedSubs.forEach((subscription) => {
      const client = providerClients[provider];
      const subscriber = providers.subscriber[provider];
      if (!(subscriptions[provider] && subscriptions[provider][subscription])) {
        if (!subscriptions[provider]) {
          subscriptions[provider] = {};
        }
        subscriptions[provider][subscription] = subscriber(
          onMessage,
          subscription,
          dispatch
        )(client);
        dispatch(actions.subscribed(provider, subscription));
      }
    });
    queuedSubs.length = 0;
  }
};

const onConnect = (provider, dispatch) => {
  // Tell the store we're connected
  //  setInterval(() => {
  //    console.log(subscriptions);
  //  }, 4000);
  const client = providerClients[provider];
  if (client.connected) {
    onOpen(provider, dispatch);
  } else {
    setTimeout(() => onConnect(provider, dispatch), MIN_RETRY); // Screw everything about this
  }
};

const onError = (provider, dispatch, err) => {
  console.log(err);
  if (getType(err) === 'CloseEvent') {
    dispatch(actions.connectionFailed(provider, null, null, err.reason || err.headers?.message));
    return;
  }

  const parsed = parse(err);
  dispatch(actions.connectionFailed(provider, parsed.command, parsed.subscription, parsed.reason));
  if (parsed.command === 'BAN') {
    disconnect(provider, null, true);
    return;
  } else if (parsed.command === 'KICK') {
    return;
  }

  if (!providerTimeouts[provider]) {
    providerTimeouts[provider] = MIN_RETRY;
  }
  const shouldRetry =
    !providerClients[provider].connected && !providerClients[provider].disconnecting;
  setTimeout(() => {
    if (shouldRetry) {
      if (!isEmpty(subscriptions[provider])) {
        queuedSubscriptions[provider] = uniq([
          ...queuedSubscriptions[provider],
          ...Object.keys(subscriptions[provider])
        ]);
        delete subscriptions[provider];
      }
      disconnect(
        provider,
        () => {
          if (!authenticating[provider]) {
            authenticating[provider] = true;
            providers
              .isAuthenticated(dispatch)
              .then(() => {
                authenticating[provider] = false;
                providerClients[provider] = providers[provider]();
                providerClients[provider].connect(
                  {},
                  onConnect.bind(this, provider, dispatch),
                  (error) => onError(provider, dispatch, error)
                );
                dispatch(actions.connecting(provider));
              })
              .catch(() => {
                authenticating[provider] = false;
                dispatch(
                  actions.connectionFailed(provider, null, null, `${provider} authentication error`)
                );
              });
          }
        },
        false
      );
    }
  }, providerTimeouts[provider]);
  providerTimeouts[provider] =
    providerTimeouts[provider] * 2 < MAX_RETRY ? providerTimeouts[provider] * 2 : MAX_RETRY;
};

const onClose = (ws, store) => (evt) => {
  // Tell the store we've disconnected
};

// FIXME: PLEASE
export default function socketMiddleware() {
  // Place before promise middleware
  return (store) => (next) => (action) => {
    const type = action.type;
    const refName =
      action.payload && getType(action.payload) === 'String'
        ? action.payload
        : '__UNIQUE_OR_PRIVATE__';
    if (type === '@@Ws/WS_DISCONNECT_ALL') {
      // eslint-disable-next-line no-restricted-syntax
      for (const providerName in providerClients) {
        if (providerClients.hasOwnProperty(providerName)) {
          disconnect(providerName);
        }
      }
      return next(action);
    }
    const state = store.getState();
    const authenticated = state.Auth.authenticated === true;
    if (type && type.endsWith(t.WS_CONNECT)) {
      const moduleName = getNamespace(type);
      if (moduleName && providers[moduleName]) {
        // **INSTANTIATE** client
        if (!providerClients[moduleName] && !authenticating[moduleName]) {
          authenticating[moduleName] = true;
          providers
            .isAuthenticated(store.dispatch)
            .then(() => {
              authenticating[moduleName] = false;
              providerClients[moduleName] = providers[moduleName]();
              const client = providerClients[moduleName];
              if (client && !client.connected) {
                // WS client exists for this module
                if (client.disconnecting || !authenticated) {
                  return;
                }
                client.connect({}, onConnect.bind(this, moduleName, store.dispatch), (err) =>
                  onError(moduleName, store.dispatch, err)
                );
              }
            })
            .catch(() => {
              authenticating[moduleName] = false;
              store.dispatch(
                actions.connectionFailed(
                  moduleName,
                  null,
                  null,
                  `${moduleName} authentication error`
                )
              );
            });
        } else {
          const client = providerClients[moduleName];
          if (client && !client.connected) {
            // WS client exists for this module
            if (client.disconnecting || !authenticated) {
              return;
            }
            client.connect({}, onConnect.bind(this, moduleName, store.dispatch), (err) =>
              onError(moduleName, store.dispatch, err)
            );
          }
        }
        return next(actions.connecting(moduleName));
      }
    } else if (type && type.endsWith(t.WS_UNSUBSCRIBE)) {
      const moduleName = getNamespace(type);
      const subscription =
        moduleName && subscriptions[moduleName] && subscriptions[moduleName][refName];
      if (providerClients[moduleName] && providers[moduleName] && subscription) {
        if (providerClients[moduleName].disconnecting) {
          return;
        }
        subscription.unsubscribe({ destination: providers.destination[moduleName](refName).in });
        delete subscriptions[moduleName][refName];
        if (isEmpty(subscriptions[moduleName]) && isEmpty(queuedSubscriptions[moduleName])) {
          disconnect(moduleName);
        }
        return next(actions.unsubscribed(moduleName, refName));
      }
      return next(actions.unsubscribeReject(moduleName, refName));
    } else if (type && type.endsWith(t.WS_SUBSCRIBE)) {
      const moduleName = getNamespace(type);
      if (moduleName && providers[moduleName]) {
        if (!subscriptions[moduleName]) {
          subscriptions[moduleName] = {};
        }
        const client = providerClients[moduleName];
        if (!client) {
          if (!authenticated) {
            return;
          }
          // **INSTANTIATE** client
          if (!client && !authenticating[moduleName]) {
            authenticating[moduleName] = true;
            providers
              .isAuthenticated(store.dispatch)
              .then(() => {
                authenticating[moduleName] = false;
                providerClients[moduleName] = providers[moduleName]();
                const newClient = providerClients[moduleName];
                if (newClient && !newClient.connected) {
                  // WS client exists for this module
                  newClient.connect({}, onConnect.bind(this, moduleName, store.dispatch), (err) =>
                    onError(moduleName, store.dispatch, err)
                  );
                }
                if (!queuedSubscriptions[moduleName]) {
                  queuedSubscriptions[moduleName] = [];
                }
                queuedSubscriptions[moduleName].push(refName);
              })
              .catch(() => {
                authenticating[moduleName] = false;
                store.dispatch(
                  actions.connectionFailed(
                    moduleName,
                    null,
                    null,
                    `${moduleName} authentication error`
                  )
                );
              });
          } else {
            if (!queuedSubscriptions[moduleName]) {
              queuedSubscriptions[moduleName] = [];
            }
            queuedSubscriptions[moduleName].push(refName);
          }
          return next(actions.connecting(moduleName));
        } else if (!client.connected) {
          if (client.disconnecting || !authenticated) {
            return;
          }
          client.connect({}, onConnect.bind(this, moduleName, store.dispatch), (err) =>
            onError(moduleName, store.dispatch, err)
          );
          if (!queuedSubscriptions[moduleName]) {
            queuedSubscriptions[moduleName] = [];
          }
          queuedSubscriptions[moduleName].push(refName);
          return next(actions.connecting(moduleName));
        } else if (!(subscriptions[moduleName] && subscriptions[moduleName][refName])) {
          if (providerClients[moduleName].disconnecting || !authenticated) {
            return;
          }
          const subscriber = providers.subscriber[moduleName];
          subscriptions[moduleName][refName] = subscriber(
            onMessage,
            refName,
            store.dispatch
          )(client);
          return next(actions.subscribed(moduleName, refName));
        }
      }
    } else if (type && type.endsWith(t.WS_SEND)) {
      const moduleName = getNamespace(type);
      const client = providerClients[moduleName];
      const { message, subscription = '' } = action.payload;
      if (subscription && client && client.connected) {
        client.send(providers.destination[moduleName](subscription).out, message);
      }
    }
    return next(action);
  };
}
