/* eslint-disable import/no-mutable-exports,prefer-const */
import update from 'update-immutable';
import { getNamespace, noSuffix, noPrefix } from 'lib/redux-utils';
import registry from 'services/Api/registry';

export let asyncSuffixes = {
  PENDING: '_PENDING',
  FULFILLED: '_FULFILLED',
  REJECTED: '_REJECTED'
};

// Modifies the registry as a side effect!
function getLoadState(state, type) {
  if (type.endsWith(asyncSuffixes.PENDING)) {
    const pureType = noPrefix(noSuffix(type));
    return update(state, {
      loading: { $push: [pureType] }
    });
  } else if (type.endsWith(asyncSuffixes.FULFILLED) || type.endsWith(asyncSuffixes.REJECTED)) {
    const pureType = noPrefix(noSuffix(type));
    const arr = state.loading;
    if (arr && Array.isArray(arr) && arr.length > 0) {
      const index = arr.indexOf(pureType);
      if (index >= 0) {
        registry.set(noSuffix(type), Date.now()); // FIXME: SIDE EFFECT, MOVE TO MIDDLEWARE

        return update(state, {
          loading: { $splice: [[index, 1]] }
        });
      }
    }
    return state;
  } else {
    return null;
  }
}

export default function loading(reducer, name) {
  const initialState = update(reducer(undefined, {}), {
    loading: { $set: null }
  });

  return function (state = initialState, action) {
    const type = action.type;
    const operation = getNamespace(type);
    let res = null;
    if (name === operation || name === undefined) {
      // Name undefined is catch-all (global loading)
      res = getLoadState(reducer(state, action), type);
    }
    return res || reducer(state, action);
  };
}
