import { isNil, flowRight } from 'lodash';
import type { Reducer } from 'redux';

export const asyncSuffixes = ['_FULFILLED', '_PENDING', '_REJECTED'];

/**
 * Remove the (Redux Promise Middleware) suffixes, if there.
 *
 * @function
 * @param {string} type - The action type
 * @param {[string]} suffixes - The suffixes to remove (default here)
 * @returns {string} - The action type without suffixes
 */
export const noSuffix = (type: string, suffixes = asyncSuffixes) => {
  // eslint-disable-next-line no-restricted-syntax
  for (const suffix of suffixes) {
    if (type.endsWith(suffix)) {
      return type.replace(new RegExp(`${suffix}$`), '');
    }
  }
  return type;
};

/**
 * Remove any prefixes `(P)refix/ACTION_TYPE`, if there. Remove the slash too
 *
 * @function
 * @param {string} type - The action type
 * @returns {string} - The action type without the module prefix
 */
export const noPrefix = (type: string): string => type.substring(type.indexOf('/') + 1);

/**
 * Get the namespace (module name) from the action type.
 *
 * @function
 * @param {string} type - The action type
 * @returns {string} - The namespace
 */
export const getNamespace = (type: string): string => type.substring(0, type.indexOf('/'));

/**
 * Remove any prefixes `(P)refix/ACTION_TYPE`, if there. Remove the slash too
 *
 * @function
 * @param {string} type - The action type
 * @returns {string} - The action type without its namespace and RPM suffixes
 */
export const getSyncType = flowRight([noPrefix, noSuffix]);

/**
 * A higher order reducer to assign state slices to reducers
 *
 * @function
 * @param {Function} reducer
 * @param {Object} initialState - Initial state will be sliced too
 * @param {string} name - The key for the slice. Must be top level
 * @returns {Function} - function (state, action) {} Where state is the slice
 */
export const slicer =
  (reducer: Reducer, initialState: any, name: string): Reducer =>
  (state = initialState[name], action: any) =>
    action && !isNil(action.payload) && action.payload.name === name
      ? reducer(state, action)
      : state;

/**
 * A higher order reducer with external one to assign state slices to reducers
 *
 * @function
 * @param {Function} reducer
 * @param {Function} externalReducer
 * @param {Object} initialState - Initial state will be sliced too
 * @param {string} name - The key for the slice. Must be top level
 * @returns {Function} - function (state, action) {} Where state is the slice
 */
export const externalSlicer =
  (reducer: Reducer, externalReducer: Reducer, initialState: any, name: string): Reducer =>
  (state = initialState[name], action: any) =>
    action && !isNil(action.payload) && action.payload.name === name
      ? reducer(state, action)
      : externalReducer(state, action);

export { default as actionTypeCreator, SYNC, ASYNC } from './src/action-types';

function _hasType(state: any, type: string) {
  const name = getNamespace(type);
  return state[name].loading && state[name].loading.includes(getSyncType(type));
}

// TODO: Generic state
export const isLoading = (state: any, types: string | string[]) =>
  !!(Array.isArray(types) ? types.some((type) => _hasType(state, type)) : _hasType(state, types));
