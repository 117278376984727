import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import loadable from '@loadable/component';
import LoadingElement from 'components/Loading/LoadingElement';

const DropIn = loadable(() => import('components/BraintreeDropIn'), {
  fallback: <LoadingElement />
});

class Paypal extends PureComponent {
  // shouldComponentUpdate({ clientToken: prevClientToken, commitToPay: prevCommitToPay }) {
  //   const { clientToken, commitToPay } = this.props;
  //   return clientToken !== prevClientToken || commitToPay !== prevCommitToPay;
  // }

  setInstance = (instance) => {
    this.instance = instance;
    this.props.onInstance();
  };

  registerPaymentMethod = () => {
    this.instance.requestPaymentMethod().then(({ nonce, deviceData }) => {
      this.props.onPaymentMethod({ nonce, deviceData });
    });
  };

  render() {
    const { clientToken, commitToPay } = this.props;

    return (
      <DropIn
        options={{
          authorization: clientToken,
          paymentOptionPriority: ['paypal'],
          paypal: {
            flow: 'vault',
            billingAgreementDescription: 'Linking PayPal to your MrQ account',
            locale: 'en_GB',
            enableShippingAddress: false,
            buttonStyle: {
              label: commitToPay ? 'pay' : 'paypal',
              tagline: false,
              shape: 'rect',
              size: 'responsive'
            }
          }
        }}
        onPaymentMethodRequestable={this.registerPaymentMethod}
        onInstance={this.setInstance}
      />
    );
  }
}

Paypal.propTypes = {
  commitToPay: PropTypes.bool,
  clientToken: PropTypes.string.isRequired,
  onPaymentMethod: PropTypes.func.isRequired,
  onInstance: PropTypes.func.isRequired
};

Paypal.defaultProps = {
  commitToPay: false
};

export default Paypal;
