import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import RouterLink from 'components/RouterLink';
import makeModal from 'components/Modals';
import Button from 'components/Button';
import FooterTrustFactors from 'components/FooterTrustFactors';
import FooterLegalDetails from 'components/FooterLegalDetails';
import Modals from 'modules/Modals';
import User from 'modules/User';
import Api from 'services/Api';
import SidePWAButton from './SidePWAButton';
import './menu.css';
import './sideMenu.css';

export class _SideMenu extends Component {
  constructor(props) {
    super(props);
    // State is optimistic
    this.state = {
      gameSoundsEnabled: props.gameSoundsEnabled
    };
  }

  componentDidMount() {
    this.props.init();
  }

  static getDerivedStateFromProps(prevProps, prevState) {
    if (prevProps.gameSoundsEnabled !== prevState.gameSoundsEnabled) {
      return { gameSoundsEnabled: prevProps.gameSoundsEnabled };
    }
    return null;
  }

  toggleGameSounds = (e) => {
    this.setState({ gameSoundsEnabled: e.target.checked });
    this.props.toggleGameSoundsEnabled({ gameSoundsEnabled: e.target.checked });
  };

  render() {
    const {
      close,
      openHowToPlayModal,
      openPrivacyPolicy,
      openResponsibleGambling,
      openTermsConditions
    } = this.props;

    return (
      <div className="sideMenu">
        <header className="sideMenu__header sideMenu__padItem">
          <Button
            icon="/assets/images1/close-primary.svg"
            className="button--iconButton button__grey sideMenu__close"
            onClick={close}
            type="button"
            name="close"
            id="sideMenu-close"
            title="Close"
          />
        </header>
        <nav>
          <ul className="sideMenu__nav">
            {__ENV__.MRQ_BINGO_ENABLED === 'true' && (
              <li>
                <a title="Find out how to play" onClick={openHowToPlayModal}>
                  How to play bingo
                </a>
              </li>
            )}
            <li>
              <a title="Our safer gambling guide" onClick={openResponsibleGambling}>
                Safer Gambling Policy
              </a>
            </li>
            <li>
              <a title="Read our privacy policy" onClick={openPrivacyPolicy}>
                Customer Privacy Policy
              </a>
            </li>
            <li>
              <a title="Read our terms and conditions" onClick={openTermsConditions}>
                General Terms & Conditions
              </a>
            </li>
            <li>
              <RouterLink href="https://help.mrq.com">Help & FAQ</RouterLink>
            </li>
            <li>
              <RouterLink href="/contact-us">Get in touch</RouterLink>
            </li>
            <li>
              <SidePWAButton />
            </li>
          </ul>
        </nav>
        <div className="sideMenu__controls sideMenu__padItem">
          <label htmlFor="gameSounds">
            Bingo sounds
            <input
              id="gameSounds"
              name="gameSounds"
              className="switchType"
              type="checkbox"
              checked={this.state.gameSoundsEnabled}
              onChange={this.toggleGameSounds}
            />
            <span className="switch" />
          </label>
        </div>
        <div className="sideMenu__footer sideMenu__padItem">
          <FooterTrustFactors className="sideMenu__trustFactors" />
          <div className="sideMenu__licence">
            <FooterLegalDetails />
          </div>
        </div>
      </div>
    );
  }
}

_SideMenu.propTypes = {
  gameSoundsEnabled: PropTypes.bool.isRequired
};

const mapStateToProps = (state) => ({
  gameSoundsEnabled: User.selectors.model(state).gameSoundsEnabled
});

const mapDispatchToProps = (dispatch, { close }) => ({
  toggleGameSoundsEnabled: ({ gameSoundsEnabled }) =>
    Api.actions.user.changeGameSounds(null, { gameSoundsEnabled })(dispatch),
  init: () => Api.actions.user.getSettings()(dispatch),
  ...(__ENV__.MRQ_BINGO_ENABLED === 'true'
    ? {
        openHowToPlayModal: () => {
          close();
          dispatch(Modals.actions.open('howToPlay'));
        }
      }
    : {}),
  openReferAfriend: () => {
    close();
    dispatch(Modals.actions.open('referAFriend'));
  },
  openDeposit: () => {
    close();
    dispatch(Modals.actions.open('deposit'));
  },
  openTermsConditions: () => {
    close();
    dispatch(Modals.actions.open('termsConditions'));
  },
  openResponsibleGambling: () => {
    close();
    dispatch(Modals.actions.open('responsiblePolicy'));
  },
  openPrivacyPolicy: () => {
    close();
    dispatch(Modals.actions.open('privacyPolicy'));
  }
});

export default compose(
  makeModal(
    'sideMenu',
    {
      name: 'modal-slideEdgeFrom-left',
      timeout: 400 // same as transition duration for modal-slideEdgeFrom-left
    },
    { id: 'sideMenu' }
  ),
  connect(mapStateToProps, mapDispatchToProps)
)(_SideMenu);
