import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import Api from 'services/Api';
import ActionButton from 'components/ActionButton';
import LoadingElement from 'components/Loading/LoadingElement';
import { actions as routerActions } from 'modules/Router';
import { useQuery } from 'hooks/useQuery';
import './hooyuSuccess.css';

const { replace } = routerActions;

const HooyuSuccess = () => {
  const query = useQuery();
  const reference = query.get('reference');
  const errorMessage = 'Uh oh, something broke... please try again in a few minutes!';
  const [error, setError] = useState(false);
  const dispatch = useDispatch();

  const sendReference = useCallback(async () => {
    setError(false);
    try {
      await Api.actions.user.hooyuSuccess({ reference })(dispatch);
      window.parent.postMessage('hooyuSuccess', window.location.origin);
    } catch (e) {
      window.parent.postMessage('hooyuError', window.location.origin);
      setError(true);
    }
  }, [dispatch, reference]);

  useEffect(() => {
    if (reference && window.frameElement) {
      void sendReference();
    } else {
      dispatch(replace('/login'));
    }
  }, [dispatch, sendReference, reference]);

  const handleClickRetry = useCallback(() => {
    void sendReference();
  }, [sendReference]);

  return (
    <div className="hooyuSuccess" data-testid="hooyuSuccess">
      <div className="hooyuSuccess__container">
        {error ? (
          <ActionButton
            error={errorMessage}
            onClick={handleClickRetry}
            name="kycRetry"
            id="hooyuRetry"
          >
            Retry
          </ActionButton>
        ) : (
          <LoadingElement />
        )}
      </div>
    </div>
  );
};

export default HooyuSuccess;
