import { flowRight } from 'lodash';
import type { RootState } from '../../../../store';
import { NAME } from './constants';
import * as m from './model';
import type { LobbyState } from './types';

type Selector<A extends undefined | keyof LobbyState = undefined> = (
  state: RootState
) => A extends keyof LobbyState ? LobbyState[A] : LobbyState;

export const model: Selector = (state) => (state[NAME] || m.initialState) as LobbyState;
export const getSearchResult: Selector<'searchResult'> = flowRight([m.searchResult, model]);
export const getSearchPopularResult: Selector<'searchPopularResult'> = flowRight([
  m.searchPopularResult,
  model
]);
export const getQuery: Selector<'query'> = flowRight([m.query, model]);
export const getEntities: Selector<'entities'> = flowRight([m.entities, model]);
export const getEntityByKey = (state: RootState, key?: string | null) =>
  key ? getEntities(state)?.[key] : null;
