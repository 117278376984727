import type { ReactNode } from 'react';
import React from 'react';
import cc from 'classcat';
import * as Item from '../ListItem';
import LinkBack from '../LinkBack';
import LinkExternal from '../LinkExternal';
import LinkForward from '../LinkForward';
import { ListVariant, classMap, defaultVariant } from './constants';
import './list.css';

interface ListProps {
  children?: ReactNode;
  variant?: ListVariant;
  className?: string;
}
const List = ({ children, variant = defaultVariant, className }: ListProps) => (
  <ul className={cc(['list', classMap[variant], className])}>{children}</ul>
);

export { List as Root, Item, LinkBack, LinkForward, LinkExternal, ListVariant as Variant };
