import React, { memo, useCallback } from 'react';
import RewardCard from 'components/RewardCard';
import User, { constants } from 'modules/User';
import { useAppDispatch, useAppSelector } from 'hooks/redux';
import Modals from 'modules/Modals';
import { formatCurrency } from 'lib/formatters';

interface SlotRewardSlot {
  name: string;
  lobbySquareImageUrl: string;
  lobbyImageUrl: string;
  ref: string;
}

interface RewardTemplate {
  ref: string;
  slots: SlotRewardSlot[];
  validTo: string;
  description?: string;
  amountString: string;
  remainingBalance?: number;
  type?: constants.RewardType;
  termsAndConditions: string;
  isDfg: boolean;
}

const SlotRewardsList = () => {
  const rewards: RewardTemplate[] = useAppSelector(User.selectors.getRewards);
  const dispatch = useAppDispatch();

  const openTCModal = useCallback(
    (content) => dispatch(Modals.actions.open('rewardTermsConditions', { content })),
    [dispatch]
  );
  return (
    <>
      {rewards.map(
        ({ ref, type, slots, description, amountString, remainingBalance, termsAndConditions }) => (
          <RewardCard
            key={ref}
            title={slots?.[0]?.name}
            description={description}
            rewardAmount={
              type === constants.RewardType.MONETARY
                ? formatCurrency(remainingBalance || 0)
                : amountString
            }
            status={constants.RewardStatus.Seen}
            imageUrl={slots?.[0]?.lobbySquareImageUrl || slots?.[0]?.lobbyImageUrl}
            path={slots?.[0]?.ref}
            openTCModal={openTCModal}
            termsAndConditions={termsAndConditions}
          />
        )
      )}
    </>
  );
};

export default memo(SlotRewardsList);
