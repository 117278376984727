import React from 'react';
import PropTypes from 'prop-types';
import ProgressCircle from 'components/ProgressCircle';
import Markdown from 'components/Markdown';
import './depositLimitStats.css';

const Root = ({ children }) => <>{children}</>;

const renderers = {
  paragraph: Root
};

const DepositLimitStats = ({ amountLeft, totalAmount, content }) => (
  <div className="depositLimitStats">
    <ProgressCircle percentage={100 - (amountLeft / totalAmount) * 100} />
    <div className="depositLimitStats__content">
      <Markdown content={content} renderers={renderers} />
    </div>
  </div>
);

DepositLimitStats.propTypes = {
  content: PropTypes.string.isRequired,
  amountLeft: PropTypes.number.isRequired,
  totalAmount: PropTypes.number.isRequired
};

export default DepositLimitStats;
