import React, { Component } from 'react';
import { matchPath } from 'react-router';
import PropTypes from 'prop-types';
import { filter } from 'lodash';
import { connect } from 'react-redux';
import localStore, { GLOBAL } from 'lib/localStore';
import Api from 'services/Api';
import Notifications from 'modules/Notifications';
import Notification from './Notification';
import './maintenanceNotice.css';

const globalStore = localStore.instances.get(GLOBAL);
const isPathEnabled = (paths, pathname) => paths.some((path) => matchPath(pathname, { path }));

export class _MaintenanceNotice extends Component {
  state = {
    dismissedNotifications: null
  };

  componentDidMount() {
    this.mounted = true;
    const { getMaintenanceNotification } = this.props;
    getMaintenanceNotification();

    globalStore.getItem('dismissedNotifications').then((localValue) => {
      if (this.mounted) {
        this.setState({ dismissedNotifications: localValue || [] });
      }
    });
  }

  componentWillUnmount() {
    this.mounted = false;
  }

  render() {
    const { notifications, notificationsRefs, pathname } = this.props;
    const { dismissedNotifications } = this.state;

    if (!dismissedNotifications) {
      return null;
    }

    const availableNotifications = filter(
      notificationsRefs,
      (notification) => dismissedNotifications.indexOf(notification) < 0
    );
    const notificationModals =
      notifications &&
      availableNotifications &&
      availableNotifications.length > 0 &&
      availableNotifications.map((ref) => {
        const notification = notifications[ref];
        if (isPathEnabled(notification.paths, pathname)) {
          return (
            <Notification
              key={notification.ref}
              refName={notification.ref}
              message={notification.message}
              severity={notification.severity}
              dismissible={notification.dismissible}
            />
          );
        }
        return null;
      });

    if (!notificationModals) {
      return null;
    }
    return (
      <div className="maintenanceNotice">
        <div className="notifications__list">{notificationModals}</div>
      </div>
    );
  }
}

_MaintenanceNotice.propTypes = {
  notificationsRefs: PropTypes.arrayOf(PropTypes.string).isRequired,
  notifications: PropTypes.object.isRequired,
  getMaintenanceNotification: PropTypes.func.isRequired,
  pathname: PropTypes.string.isRequired
};

const mapStateToProps = (state) => ({
  notificationsRefs: Notifications.selectors.getNotificationsRefs(state),
  notifications: Notifications.selectors.getNotifications(state)
});

const mapDispatchToProps = (dispatch) => ({
  getMaintenanceNotification: () => Api.actions.notifications.maintenance()(dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(_MaintenanceNotice);
