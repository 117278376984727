import React, { Fragment } from 'react';
import ListButton from 'components/ListButton';
import { Typography, TypographyVariant } from 'components/Typography';
import Chip from 'components/Chip';
import './paymentMethodsProvidersList.css';
import type { PaymentMethodOption } from './types';

interface PaymentMethodsProvidersListProps {
  onClickHandler: (e: any) => void;
  options: PaymentMethodOption[];
}

const PaymentMethodsProvidersList = ({
  onClickHandler,
  options
}: PaymentMethodsProvidersListProps) => (
  <form className="paymentMethodsProvidersList">
    <Typography variant={TypographyVariant.HeaderXxs}>
      <div className="paymentMethodsProvidersList__title">Add a deposit method</div>
    </Typography>
    {options.map(({ type, icon, title, tag, value }) => (
      <Fragment key={type}>
        <ListButton
          className="paymentMethodsProvidersList__button"
          key={type}
          buttonText={title}
          icon={icon}
          onClick={onClickHandler}
          value={value}
          name="addPaymentMethod"
        >
          <div className="paymentMethodsProvidersList__buttonContent text-truncate">
            <span className="text-truncate">{title}</span>
            {tag && <Chip label={tag} variant="secondary" />}
          </div>
        </ListButton>
        <div className="separator-h-100" />
      </Fragment>
    ))}
  </form>
);

export default PaymentMethodsProvidersList;
