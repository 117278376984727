import React from 'react';

const NoRewards = () => (
  <div className="noRewards">
    <div className="noRewards__card">
      <div className="noRewards__thumb" />
      <div className="rewardCard__info text-truncate">
        <div className="noRewards__textBox" />
        <div className="noRewards__smallTextBox" />
      </div>
      <div className="noRewards__rewardCount" />
    </div>
    <p className="noRewards__label">
      You don&apos;t have any Free Spins or Free Games at the moment - but stay tuned for our
      awesome offers &#129304;
    </p>
  </div>
);

export default NoRewards;
