import { T, AT } from './actionTypes';
import { SUBSCRIPTION_TTL } from './constants';

const unsubMap = new Map();

export const registerHandler = () => ({
  type: T.REGISTER_HANDLER
});

export const subscribe = (refName) => (dispatch) => {
  // Cancel expiration if next channel is expiring
  if (!refName && window.privateChannelTimeout) {
    clearTimeout(window.privateChannelTimeout);
    delete window.privateChannelTimeout;
  } else if (refName && unsubMap.has(refName)) {
    clearTimeout(unsubMap.get(refName));
    unsubMap.delete(refName);
  } else {
    dispatch({
      type: AT.WS_SUBSCRIBE._,
      payload: refName
    });
  }
};

export const send = (message, subscription) => ({
  type: T.WS_SEND,
  payload: {
    message: JSON.stringify(message),
    subscription: subscription
  }
});

export const receiveMessage = (message, subscription) => ({
  type: T.WS_RECEIVE,
  payload: {
    message: JSON.stringify(message),
    subscription: subscription || '__UNIQUE_OR_PRIVATE__'
  }
});

// lastSubTimeout is [refName, timeoutId]
export const unsubscribe = (refName) => (dispatch) => {
  const action = {
    type: AT.WS_UNSUBSCRIBE._,
    payload: refName
  };

  const unsubscribeCurrent = () =>
    unsubMap.set(
      refName,
      setTimeout(() => {
        dispatch(action);
        unsubMap.delete(refName);
      }, SUBSCRIPTION_TTL)
    );

  // Private channel
  if (!refName) {
    clearTimeout(window.privateChannelTimeout);
    window.privateChannelTimeout = setTimeout(() => {
      dispatch(action);
      delete window.privateChannelTimeout;
    }, SUBSCRIPTION_TTL);
    return;
  }

  const currentChannelTimeout = unsubMap.get(refName);

  if (currentChannelTimeout) {
    clearTimeout(currentChannelTimeout);
    // Delete so it gets inserted last
    unsubMap.delete(refName);
    unsubscribeCurrent();

    return;
  }

  if (unsubMap.size < 2) {
    unsubscribeCurrent();
    return;
  }

  // Map already full
  const [firstChannelRef, firstTimeout] = unsubMap.entries().next().value;
  clearTimeout(firstTimeout);
  // Unsub immediately
  dispatch({ ...action, payload: firstChannelRef });
  unsubMap.delete(firstChannelRef);
  unsubscribeCurrent();
};
