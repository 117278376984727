export enum BadgeSize {
  XL = 'XL',
  LG = 'LG',
  MD = 'MD',
  SM = 'SM'
}

export enum BadgeVariant {
  PRIMARY = 'PRIMARY',
  ACCENT = 'ACCENT',
  SUCCESS = 'SUCCESS',
  ATTENTION = 'ATTENTION',
  WARNING = 'WARNING',
  NEUTRAL = 'NEUTRAL'
}
