import React, { memo } from 'react';
import { logos } from 'lib/constants';
import { formatCurrency } from 'lib/formatters';

interface TransactionDetailsProps {
  depositAmount: number;
  bankLogoUrl?: string;
  bankName?: string;
}

const PaymentDetails = ({ depositAmount, bankLogoUrl, bankName }: TransactionDetailsProps) => (
  <div className="ecospendPaymentDetails">
    <div className="ecospendPaymentDetails__banklogo">
      <img alt="Logo" src={bankLogoUrl || logos.BANK_ACCOUNT} />
    </div>
    <div>
      <div className="ecospendPaymentDetails__bankName">{bankName}</div>
      <div className="ecospendPaymentDetails__amount">Transfer {formatCurrency(depositAmount)}</div>
    </div>
  </div>
);

export default memo(PaymentDetails);
