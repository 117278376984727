import React, { Component } from 'react';
import cc from 'classcat';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import reform from 'components/Reform';
import Fieldset from 'components/Fieldset';
import FormError from 'components/FormError';
import Button from 'components/Button';
import AmountPresetPicker from 'components/AmountPresetPicker';
import { formatCurrency } from 'lib/formatters';
import { promotionCode as promotionCodeValidation } from 'lib/valFuncs';
import {
  MIN_DEPOSIT,
  MAX_DEPOSIT_CARD,
  depositAmount as depositAmountValidation
} from 'lib/valFunctors';
import Wallet from 'modules/Wallet';
import User from 'modules/User';
import EcospendSearchableBanks from './EcospendSearchableBanks';
import './ecospend.css';

const radioDefaultValues = [20, 50, 100, 150];

const enhance = compose(reform());

class BaseForm extends Component {
  handleAmountPresetChange = (value) => {
    const { handleChange } = this.props;
    handleChange({}, { name: 'depositAmount', value });
  };

  handleBankChange = (value) => {
    const { handleChange, onAnotherMethod } = this.props;
    if (value?.anotherMethod) {
      onAnotherMethod();
      return;
    }
    handleChange({}, { name: 'bank', value: value });
  };

  render() {
    const {
      isPromoCodeDisabled,
      depositAmount,
      promotionCode,
      radioValues,
      loading,
      disabled,
      formValid,
      handleChange,
      handleFocus,
      handleBlur,
      minAmount,
      maxAmount,
      errorMessage,
      onSubmit,
      openTermsConditions,
      providerUnavailableDescription,
      fundsProtectionCheck,
      disableDepositButton,
      bank,
      ecospendBanks
    } = this.props;
    const buttonClass = cc([{ 'button--loading': loading }]);
    const bankUnavailable = bank?.value && !bank.value?.available;
    const isDepositButtonDisabled =
      disabled || !formValid || !depositAmount.valid || bankUnavailable || disableDepositButton;

    return (
      <div className="addEcospendFormContainer">
        <form
          id="depositCardForm"
          name="addEcospend"
          onSubmit={onSubmit}
          className={disabled ? 'form form--disabled' : 'form'}
        >
          <div className={cc(['field field--form', disabled && 'noEdit'])}>
            <span className="field__title">Deposit amount</span>
            <div className="field__content">
              <div className="fieldset fieldset--fullwidth depositPreset">
                <AmountPresetPicker
                  values={radioValues || radioDefaultValues}
                  onChange={this.handleAmountPresetChange}
                  value={+depositAmount.value}
                />
              </div>
              <Fieldset
                field={depositAmount}
                editable={!disabled}
                inputType="number"
                min={(minAmount || MIN_DEPOSIT).toString()}
                max={(maxAmount || MAX_DEPOSIT_CARD).toString()}
                step="1"
                name="depositAmount"
                className="fieldset--pound"
                labelText="Other"
                onChange={handleChange}
                onFocus={handleFocus}
              />
            </div>
          </div>
          {!isPromoCodeDisabled && (
            <Fieldset
              field={promotionCode}
              inputType="text"
              name="promotionCode"
              labelText="Promo code"
              onChange={handleChange}
              onFocus={handleFocus}
              onBlur={handleBlur}
              editable={!disabled}
            />
          )}
          <EcospendSearchableBanks
            bankOptions={Object.values(ecospendBanks)}
            onSelect={this.handleBankChange}
            onClear={() => this.handleBankChange(null)}
          />
          {fundsProtectionCheck}
          <div className="fieldset fieldset--centerflex">
            <Button
              className={buttonClass}
              disabled={isDepositButtonDisabled}
              loading={loading}
              type="submit"
              onClick={onSubmit}
              buttonText={cc([
                'Deposit',
                depositAmount.value && formatCurrency(depositAmount.value)
              ])}
            />
          </div>
          <FormError
            errorMessage={
              errorMessage ||
              (bankUnavailable && `${bank?.value?.friendlyName} ${providerUnavailableDescription}`)
            }
          />
        </form>
        {!fundsProtectionCheck && (
          <>
            <div className="toc-text">
              MrQ holds all player funds in a separate bank account in accordance with the Gambling
              Commission&apos;s basic level of protection. This means that in case of insolvency
              customer funds will not be protected.
            </div>
            <a className="toc-text underline" onClick={openTermsConditions}>
              Terms and conditions apply
            </a>
          </>
        )}
      </div>
    );
  }
}

const Form = enhance(BaseForm);

export const _AddEcospendForm = (props) => {
  const fields = {
    bank: {
      required: true
    },
    depositAmount: {
      initial: props.defaultAmount || 50,
      required: true,
      error: `Minimum amount ${formatCurrency(10)}`,
      onChange: depositAmountValidation(props.minAmount, props.maxAmount)
    },
    promotionCode: {
      initial: props.promoCode || '',
      required: false,
      onChange: promotionCodeValidation,
      error: "This doesn't look like a valid code"
    }
  };
  return <Form fields={fields} {...props} />;
};

_AddEcospendForm.propTypes = {
  ecospendBanks: PropTypes.object,
  fundsProtectionCheck: PropTypes.node.isRequired,
  onAnotherMethod: PropTypes.func.isRequired
};

_AddEcospendForm.defaultProps = {
  ecospendBanks: {}
};

const mapStateToProps = (state, { provider = 'ECOSPEND' }) => ({
  isPromoCodeDisabled: User.selectors.getIsPromoCodeDisabled(state),
  defaultAmount: provider && Wallet.selectors.getPaymentPresetPresets(state, provider)?.[0],
  minAmount: provider && Wallet.selectors.getPaymentPresetMinAmount(state, provider),
  maxAmount: provider && Wallet.selectors.getPaymentPresetMaxAmount(state, provider),
  radioValues: provider && Wallet.selectors.getPaymentPresetPresets(state, provider),
  providerUnavailableDescription:
    provider && Wallet.selectors.getPaymentProviderUnavailableDescription(state, provider)
});

export default connect(mapStateToProps)(_AddEcospendForm);
