import { useCallback, useRef, useState } from 'react';
import Fuse from 'fuse.js';

/* 
  Hristijan:
  This hook is used in SearchableDropdown, but it can be used in other places as well. 
  It uses the Fuse.js library to do searching. If necessary, some of the options can circumvent the filtering by adding the attribute "isPersistent". 
  The "query", "options", and "configOptions" (the fuse.js config) can all be set on the hook initialization or re-set at runtime 
  by calling the returned update methods.

  This can be enhaced by adding addition configurable options, such as configurable position of the persistent options (E.g on Top/Bottom) ...
*/

const splitOptions = (options) => {
  const persistent = options.filter((e) => e.isPersistent);
  const normal = options.filter((e) => !e.isPersistent);
  return { normal, persistent };
};

const enhancedSearch = (query, options, fuseInstance) => {
  /* In case of empty query, fuse.js search result is empty. Instead of that, we want to return the complete array
    https://github.com/krisk/Fuse/issues/229
    */
  if (!query) {
    // if the result items need to include additional parameters, we need to add them here manually
    return options.normal.concat(options.persistent).map((item, refIndex) => ({
      item,
      refIndex
    }));
  }
  const searchResult = fuseInstance.search(query);
  // Add persisten options to the end of the list
  const persistentOptions = options.persistent.map((item, i) => ({
    item: item,
    refIndex: options.normal.length + i
  }));

  return searchResult.concat(persistentOptions);
};

export const useFuseSearch = ({
  options: initialOptions = [],
  config: initialConfig = {},
  query: initialQuery = ''
}) => {
  const optionsRef = useRef(); // Initialized later for performance reasons
  const fuseInstanceRef = useRef(); // Initialized later for performance reasons
  const queryRef = useRef(initialQuery);
  const configRef = useRef(initialConfig);
  const [fuseSearchResult, setFuseSearchResult] = useState(() => {
    const splitedOptions = splitOptions(initialOptions);
    optionsRef.current = splitedOptions;
    const normalOptions = splitedOptions.normal;
    fuseInstanceRef.current = new Fuse(normalOptions, configRef.current);
    return enhancedSearch(queryRef.current, optionsRef.current, fuseInstanceRef.current);
  });

  const search = useCallback(() => {
    setFuseSearchResult(
      enhancedSearch(queryRef.current, optionsRef.current, fuseInstanceRef.current)
    );
  }, []);

  const handleUpdateQuery = useCallback(
    (newQuery) => {
      queryRef.current = newQuery;
      search();
    },
    [search]
  );

  const handleUpdateOptions = useCallback(
    (newOptions) => {
      optionsRef.current = splitOptions(newOptions);
      fuseInstanceRef.current = new Fuse(optionsRef.current.normal, configRef.current);
      search();
    },
    [search]
  );

  const handleUpdateConfigOptions = useCallback(
    (newConfigOptions) => {
      configRef.current = newConfigOptions;
      fuseInstanceRef.current = new Fuse(optionsRef.current.normal.normal, configRef.current);
      search();
    },
    [search]
  );

  return {
    result: fuseSearchResult,
    query: queryRef.current,
    updateQuery: handleUpdateQuery,
    updateOptions: handleUpdateOptions,
    updateConfig: handleUpdateConfigOptions
  };
};
