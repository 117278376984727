import { useCallback, useEffect, useRef } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { constants } from 'modules/Router';

const { NO_SCROLL, ACTION } = constants;

/**
 * Simulates native browser scrolling behavior
 *   - Scroll to top when navigating to a new URL
 *   - Scroll to stored scroll height on forward/back (POP)
 *
 *   It also supports opting out of scroll restoration using state stored in the URL ('no-scroll')
 * @constructor
 */
const ScrollRestoration = () => {
  const { action } = useHistory();
  const { key = 'initial', state } = useLocation();
  const scrollPosition = useRef<Record<string, number>>({
    [key]: window.scrollY
  });

  useEffect(() => {
    // URL state allows us to opt out of scroll restoration
    if (state !== NO_SCROLL) {
      window.scroll({
        top: action === ACTION.POP ? scrollPosition.current[key] ?? 0 : 0,
        behavior: 'smooth'
      });
    }
  }, [action, key, state]);

  const handleScroll = useCallback(() => {
    scrollPosition.current[key] = window.scrollY;
  }, [key]);

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [handleScroll]);

  return null;
};

export default ScrollRestoration;
