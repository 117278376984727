import loadable from '@loadable/component';

export const BankingIcon = loadable(() => import(`./BankingIcon`));
export const BingoIcon = loadable(() => import(`./BingoIcon`));
export const FeedbackIcon = loadable(() => import(`./FeedbackIcon`));
export const GameHistoryIcon = loadable(() => import(`./GameHistoryIcon`));
export const LiveIcon = loadable(() => import(`./LiveIcon`));
export const MarketingPreferencesIcon = loadable(() => import(`./MarketingPreferencesIcon`));
export const PlayResponsibleIcon = loadable(() => import(`./PlayResponsibleIcon`));
export const ProfileIcon = loadable(() => import(`./ProfileIcon`));
export const RafIcon = loadable(() => import(`./RafIcon`));
export const RevealPasswordIcon = loadable(() => import(`./RevealPasswordIcon`));
export const RewardsIcon = loadable(() => import(`./RewardsIcon`));
export const RewardsTabIcon = loadable(() => import(`./RewardsTabIcon`));
export const SearchIcon = loadable(() => import(`./SearchIcon`));
export const SlotsIcon = loadable(() => import(`./SlotsIcon`));
export const WalletIcon = loadable(() => import(`./WalletIcon`));
export const CheckmarkIcon = loadable(() => import('./CheckmarkIcon'));
export const WarningTriangleIcon = loadable(() => import('./WarningTriangleIcon'));
export const AttentionCircleIcon = loadable(() => import('./AttentionCircleIcon'));
export const InformationalIcon = loadable(() => import('./InformationalIcon'));
export const NotFoundIcon = loadable(() => import('./NotFoundIcon'));
export const LiveOnAirIcon = loadable(() => import('./LiveOnAirIcon'));
