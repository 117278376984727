import type { DepositLimitSolicitations } from './types';

export enum DepositLimitSolicitationsRequestsState {
  IS_GET_SUCCESSFUL = 'IS_GET_SUCCESSFUL'
}

export enum DepositLimitIntervalsInPayload {
  Daily = 'Daily',
  Weekly = 'Weekly',
  Monthly = 'Monthly'
}
// enum is a type as well as a constant
export const DEPOSIT_LIMIT_SOLICITATIONS_REQUESTS_STATE = DepositLimitSolicitationsRequestsState;
export const NAME = 'Wallet';
export const DELETE_PAYPAL = '5525';

export const INITIAL_DEPOSIT_LIMIT_SOLICITATIONS: DepositLimitSolicitations = {
  requestsState: {
    [DEPOSIT_LIMIT_SOLICITATIONS_REQUESTS_STATE.IS_GET_SUCCESSFUL]: undefined
  },
  solicitationsPayload: undefined
};

export enum Provider {
  PAYPAL = 'PAYPAL',
  ECOSPEND = 'ECOSPEND',
  FONIX = 'FONIX'
}

export enum ECOSPEND_STEP {
  FIRST_DEPOSIT_CONFIRMATION = 'FIRST_DEPOSIT_CONFIRMATION',
  AUTH_OPTIONS = 'AUTH_OPTIONS',
  SMS = 'SMS',
  SMS_NEW_NUMBER = 'SMS_NEW_NUMBER',
  QR_AUTH = 'QR_AUTH',
  URL_AUTH = 'URL_AUTH'
}

export const POSITIVE = {
  DEPOSIT: 'Deposit',
  FONIX_DEPOSIT: 'Deposit',
  WITHDRAW_CANCELLATION: 'Cancelled withdrawal',
  WITHDRAW_DENIED: 'Withdrawal denied',
  CREDIT: 'Adjustment',
  SLOT_ROLLBACK_BET: 'Bet refund',
  SLOT_WIN: 'Win',
  BINGO_ROLLBACK_BET: 'Bet refund',
  BINGO_WIN: 'Win',
  SLOT_TOURNAMENT_WIN: 'Win',
  FREE_ROUND: 'Free round'
} as const;

export const NEGATIVE = {
  WITHDRAW: 'Withdrawal',
  SLOT_BET: 'Bet',
  BINGO_BET: 'Bet',
  SLOT_ROLLBACK_WIN: 'Cancelled win',
  BINGO_ROLLBACK_WIN: 'Cancelled win',
  REFUND: 'Refund',
  REFUND_DENIED: 'Refund denied',
  NEGATIVE_ADJUSTMENT: 'Adjustment',
  DEPOSIT_VOID: 'Deposit Void',
  SLOT_TOURNAMENT_ENTRY_FEE: 'Entry fee'
} as const;

export const INSTRUMENT = {
  BINGO: 'BINGO',
  SLOT: 'SLOT',
  CARD: 'CARD',
  MOBILE: 'MOBILE',
  PAYPAL: 'PAYPAL',
  BANK_ACCOUNT: 'BANK_ACCOUNT',
  CREDIT: 'CREDIT'
} as const;
