import React from 'react';
import cc from 'classcat';
import { Typography, TypographyVariant } from 'components/Typography';
import './chip.css';

interface ChipProps {
  label: string;
  variant?: 'default' | 'secondary';
}

const Chip = ({ label, variant = 'default' }: ChipProps) => (
  <Typography variant={TypographyVariant.BodyXxsStrong}>
    <span className={cc(['chip', `chip--${variant}`])}>{label}</span>
  </Typography>
);

export default Chip;
