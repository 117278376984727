import type { Variant as SlotShape } from 'components/Tile';
import type { OfferBannerTheme } from 'components/OfferBanner';

interface NormalizedEntity {
  __typename: string;
  refName: string;
}

export type Normalized<T> = T & NormalizedEntity;

export type AccessRef = {
  __access: string;
};

export const ListShape = {
  vertical: 'vertical',
  row: 'row'
} as const;
export type ListShape = (typeof ListShape)[keyof typeof ListShape];

export const LiveShape = {
  liveNormal: 'live_normal',
  liveLarge: 'live_large',
  liveWide: 'live_wide'
} as const;
export type LiveShape = (typeof LiveShape)[keyof typeof LiveShape];

export type List = {
  component: string;
  contentType?: 'game' | 'offer';
  contentShape?: LiveShape | SlotShape;
  shape: ListShape;
  limit: number;
  offset: number;
  orderBy: string;
  children: AccessRef[];
  collection: AccessRef;
  showTitle?: boolean;
  showSeeAll?: boolean;
  seeAllText?: string;
};

export type Collection = {
  title: string;
  totalElements: number;
};

export type Slot = {
  title: string;
  imageURL: string;
  orientation: '' | 'LANDSCAPE' | 'PORTRAIT';
  isFavourite: boolean;
  blur: boolean;
  jackpotAmount: number | null;
  provider: AccessRef;
};

export type OfferTile = {
  component: string;
  offer: AccessRef;
};

export type Offer = {
  ref: string;
  refName: string;
  label: string;
  title: string;
  subtitle: string;
  heading: string;
  shortDescription: string;
  theme: OfferBannerTheme;
  lobbyImageURL: string;
  modalImageURL: string;
  smallImageUrl?: string;
  content: string;
  terms: string;
  ctaText: string;
  ctaURL: string;
  type?: 'SIMPLE' | 'CALENDAR';
};

export type Provider = {
  label: string;
  logo: string;
  platform: AccessRef;
};

export type Platform = {
  label: string;
  logo: string;
};

export type TakenSeat = {
  seat: number;
  isTaken: boolean;
};

export const TILE_TYPE = {
  DEFAULT: 'DEFAULT_TILE',
  BLACKJACK: 'BLACKJACK_TILE',
  ROULETTE: 'ROULETTE_TILE',
  ROULETTE_MULTIPLIER: 'ROULETTE_MULTIPLIER_TILE',
  MONEYWHEEL: 'MONEYWHEEL_TILE'
} as const;

export type SlotTile = {
  component: string;
  shape: SlotShape;
  game: AccessRef;
};

export type LiveTile = {
  component: string;
  shape: LiveShape;
  game: AccessRef;
};

export type GameTile = SlotTile | LiveTile;

export type TableInfo = {
  tileType?: (typeof TILE_TYPE)[keyof typeof TILE_TYPE];
  playerCount?: number | null;
  minBet?: number | null;
  takenSeats?: TakenSeat[];
  results?: (number | string)[] | { value: number | string; multiplier?: number }[] | null;
  dealer?: string;
};

export type LiveGame = {
  refName: string;
  title: string;
  blur: boolean;
  imageURL: string;
  provider: AccessRef;
  tableInfo?: TableInfo;
};

export type EntityObject =
  | Normalized<List>
  | Normalized<Collection>
  | Normalized<GameTile>
  | Normalized<Slot>
  | Normalized<OfferTile>
  | Normalized<Offer>
  | Normalized<Provider>
  | Normalized<Platform>
  | Normalized<LiveGame>
  | AccessRef;

export type Entities = {
  [key: string]: EntityObject;
} | null;
