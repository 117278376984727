/** How many dialogs, which may contain actions, to hold in the store */
const HOLD_MAX = 6;
/** Milliseconds before evicting dialog actions from store */
export const HOLD_TTL = 10 * 60 * 1000;

export const initialState = {
  actions: {},
  /**
   * When a dialog is removed from the state, it is added to the action evicting queue.
   * When the max size or TTL is reached, evict that dialog's actions.
   */
  actionsEvictingQueue: {
    /** @type {[string, number][] | []} */
    items: [],
    max: HOLD_MAX,
    ttl: HOLD_TTL
  },
  entities: {},
  result: []
};

export const dialogs = (model) => model && model.entities;
export const dialogsRefs = (model) => model && model.result;
