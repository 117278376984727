import { actionTypeCreator, SYNC, ASYNC } from 'lib/redux-utils';
import { NAME } from './constants';

const namespace = actionTypeCreator(NAME);

export const AT = namespace({
  LIST_ROOMS: ASYNC,
  CLOSE_PRIVATE_ROOM: ASYNC,
  SUBSCRIBERS: ASYNC,
  WS_CONNECT: ASYNC,
  WS_SUBSCRIBE: ASYNC,
  WS_UNSUBSCRIBE: ASYNC
});

export const T = namespace({
  WS_SEND: SYNC,
  WS_RECEIVE: SYNC,
  GET_MESSAGE: SYNC,
  SUBSCRIBE_USER: SYNC,
  UNSUBSCRIBE_USER: SYNC,
  UPDATE_USERS: SYNC,
  REFRESH: SYNC,
  REGISTER_HANDLER: SYNC
});
