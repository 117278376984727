import { isNil, isEmpty, uniq } from 'lodash';
import update from 'update-immutable';
import { handleActions } from 'redux-actions';
import { add, merge } from 'lib/update-normalized';
import { T, AT } from './actionTypes';
import { NAME } from './constants';
import { initialState } from './model';

const addNotification = (state, { payload }) => {
  if (!isEmpty(payload)) {
    const refName = payload.ref;
    const nextResult = uniq([...state.result, refName]);
    return update(state, {
      entities: {
        [refName]: {
          $set: {
            ref: payload.ref,
            message: payload.message,
            severity: payload.severity,
            paths: payload.paths,
            dismissible: payload.dismissible
          }
        }
      },
      result: { $set: nextResult }
    });
  } else {
    return state;
  }
};

const createNotification = (state, { payload }) => {
  if (!isEmpty(payload)) {
    const refName = payload.refName;
    const nextResult = uniq([...state.result, refName]);
    return update(state, {
      entities: {
        [refName]: {
          $set: {
            ref: payload.refName,
            message: payload.message,
            severity: payload.severity,
            paths: payload.paths,
            dismissible: payload.dismissible
          }
        }
      },
      result: { $set: nextResult }
    });
  } else {
    return state;
  }
};

const closeNotification = (state, refName) => {
  if (refName) {
    const index = state.result.indexOf(refName);
    if (index >= 0) {
      return update(state, {
        entities: {
          $unset: refName
        },
        result: {
          $splice: [[index, 1]]
        }
      });
    }
  }
  return state;
};

const moduleReducer = handleActions(
  {
    [T.OPEN]: (state, action) =>
      add(state, action.payload, action.payload && action.payload.refName),
    [T.CLOSE]: (state, action) =>
      closeNotification(state, action.payload && action.payload.refName),
    [T.UPDATE]: (state, action) =>
      merge(state, action.payload, action.payload && action.payload.refName),
    [T.CREATE]: (state, action) => createNotification(state, action),
    [AT.MAINTENANCE.FULFILLED]: (state, action) => addNotification(state, action)
  },
  initialState
);

const reducer = (state = initialState, action) =>
  isNil(action.type) || !action.type.includes(NAME) ? state : moduleReducer(state, action);

export default reducer;
