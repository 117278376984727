import { contentMGA, contentUKGC } from './constants';

export type Data = {
  content: string;
  options: number[];
};

let data: Data | undefined;

if (__ENV__.MRQ_GAMING_AUTHORITY === 'UKGC') {
  data = {
    options: [6, 9, 12, 60],
    content: contentUKGC
  };
} else {
  data = {
    options: [6, 12, 24, 60, -1],
    content: contentMGA
  };
}

const finalData = data;

export default finalData;
