import update from 'update-immutable';
import { intersection, isEmpty, isNil, partialRight, uniq } from 'lodash';
import { createModuleReducer } from 'lib/module';
import { initialState } from './model';
import { AT } from './actionTypes';
import { NAME } from './constants';

const _ = partialRight.placeholder;

const getCategory = (action, key) => key || action.meta?.args?.categ;
const setLoadingCategory = (state, action, key) => {
  const category = getCategory(action, key);
  if (!category) return state;
  return update(state, {
    categoryLoading: {
      [category]: { $set: true }
    }
  });
};

function updateSlotList(state, action, key) {
  const category = getCategory(action, key);
  if (!category) return state;
  const contents = action.payload?.contents ?? action.payload;
  if (isEmpty(contents)) {
    return update(state, {
      categoryLoading: {
        [category]: { $set: false }
      }
    });
  }
  const { entities } = contents;
  if (entities) {
    // If the slots from action's payload already exists in store reset the categoryOffset and re-fetch slots from beginning
    const hasDuplicates = intersection(state.categoryRefs[category], contents.result).length > 0;
    // Do not reset offset if the category offset is less than how many slots exists in store
    const canResetOffset =
      state.categoryRefs[category] &&
      state.categoryRefs[category].length <= state.categoryOffset[category];

    const updatedRooms = Object.assign(
      {},
      ...Object.keys(entities).map(
        (ref) =>
          ref !== state.active && {
            [ref]: update(entities[ref], {
              $set: {
                logoImageUrl: state.entities[ref] && state.entities[ref].logoImageUrl,
                backgroundImageUrl: state.entities[ref] && state.entities[ref].backgroundImageUrl,
                ...state.entities[ref],
                ...entities[ref]
              }
            })
          }
      )
    );
    const updatedPayload = update(contents, {
      entities: {
        $set: updatedRooms
      }
    });
    return update(state, {
      // A shallow merge like this resets the state for all rooms. _.merge preserves it
      entities: {
        $merge: updatedPayload.entities
      },
      result: {
        $apply: (prevResult) =>
          prevResult ? uniq([...prevResult, ...contents.result]) : contents.result
      },
      categoryRefs: {
        [category]: {
          $apply: (prevResult) =>
            prevResult ? uniq([...prevResult, ...contents.result]) : contents.result
        }
      },
      totalElements: {
        [category]: {
          $set: isNil(action.payload.totalElements)
            ? contents.result.length
            : action.payload.totalElements
        }
      },
      categoryOffset: {
        [category]: {
          $apply: (prevResult = 0) =>
            hasDuplicates && prevResult !== 0 && canResetOffset
              ? 0
              : prevResult + contents.result.length
        }
      },
      categoryLoading: {
        [category]: { $set: false }
      }
    });
  } else {
    return update(state, {
      categoryRefs: {
        [category]: {
          $apply: (prevResult) =>
            prevResult ? uniq([...prevResult, ...contents.result]) : contents.result
        }
      },
      totalElements: {
        [category]: {
          $set: isNil(action.payload.totalElements)
            ? contents.result.length
            : action.payload.totalElements
        }
      },
      categoryLoading: {
        [category]: { $set: false }
      }
    });
  }
}

const reducer = createModuleReducer(
  {
    [AT.LIST.PENDING]: partialRight(setLoadingCategory, _, _),
    [AT.LIST.FULFILLED]: updateSlotList
  },
  initialState,
  NAME
);

export default reducer;
