import {
  format,
  formatDuration,
  intervalToDuration,
  monthsToYears,
  addMilliseconds
} from 'date-fns';
import type { Locale } from '@gekorm/date-fns-types/src/locale/types';

export const TIME = Object.freeze({
  DAY: 8.64e7,
  HOUR: 3600000,
  MINUTE: 60000,
  SECOND: 1000
});

export const formatSeconds = (seconds: number) =>
  formatDuration(intervalToDuration({ start: 0, end: seconds * 1000 }), {
    delimiter: ' and '
  });

export const formatMinutes = (minutes: number) => formatSeconds(minutes * 60);

const zeroPad = (num: number) => String(num).padStart(2, '0');

/*
 * `HH:MM:SS`. Time is based on a 24 hour system. This is frequently referred to as "military time".
 */
export const formatSecondsToMilitaryTime = (seconds: number) =>
  // TODO: Add tests
  formatDuration(intervalToDuration({ start: 0, end: seconds * 1000 }), {
    format: ['hours', 'minutes', 'seconds'],
    zero: true,
    delimiter: ':',
    locale: {
      formatDistance: (_token, count) => zeroPad(count)
    } satisfies Partial<Locale>
  });

/*
 * Format months into Years and Months. `-1` returns `'Forever'`
 */
export const formatMonths = (months: number) => {
  // TODO: Add tests
  if (months < 0) {
    return 'Forever';
  }
  const years = monthsToYears(months);
  return formatDuration({ years, months: months - years * 12 }, { format: ['years', 'months'] });
};

/*
 * Converts numerical timestamp into string of one of the following formats:
 * dd:hh:mm:ss (if days > 0), hh:mm:ss (if hours > 0), mm:ss (otherwise)
 */
export const formatInterval = (milliseconds: number) =>
  formatDuration(intervalToDuration({ start: 0, end: milliseconds }), {
    format:
      milliseconds < TIME.HOUR
        ? ['minutes', 'seconds']
        : milliseconds < TIME.DAY
        ? ['hours', 'minutes', 'seconds']
        : ['days', 'hours', 'minutes', 'seconds'],
    zero: true,
    delimiter: ':',
    locale: {
      formatDistance: (_token, count) => zeroPad(count)
    } satisfies Partial<Locale>
  });

export const formatFriendly = (milliseconds: number) => {
  if (milliseconds >= TIME.DAY) {
    return format(addMilliseconds(new Date(), milliseconds), 'ccc HH:mm');
  }

  const isLessThanHour = milliseconds < TIME.HOUR;

  return formatDuration(intervalToDuration({ start: 0, end: milliseconds }), {
    format: isLessThanHour ? ['minutes', 'seconds'] : ['hours'],
    zero: true,
    delimiter: ':',
    locale: isLessThanHour
      ? ({
          formatDistance: (_token, count) => zeroPad(count)
        } satisfies Partial<Locale>)
      : undefined
  });
};
