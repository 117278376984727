import { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from 'hooks/redux';
import type { constants as UserConstants } from 'modules/User';
import User from 'modules/User';
import Api from 'services/Api';
import Modals from 'modules/Modals';
import Auth from 'modules/Auth';

const useKycVerification = () => {
  const dispatch = useAppDispatch();
  const hasLoggedIn: boolean = useAppSelector(Auth.selectors.getLoggedThisWindow);
  const isVerificationRequired: boolean = useAppSelector(User.selectors.getRequiresVerification);
  const status: UserConstants.VerificationStatus = useAppSelector(
    User.selectors.getVerificationStatus
  );

  useEffect(() => {
    if (isVerificationRequired) {
      dispatch(Api.actions.user.kycVerification());
      if (hasLoggedIn) {
        if (status === User.constants.VerificationStatus.REQUESTED) {
          dispatch(Modals.actions.open('KYC'));
        }
      }
    }
  }, [dispatch, isVerificationRequired, hasLoggedIn, status]);
};

export default useKycVerification;
