import React, { useEffect, useRef, memo } from 'react';
import reform from 'components/Reform';
import Fieldset from 'components/Fieldset';
import Heading from 'components/Heading';
import Button from 'components/Button';
import { Typography, TypographyVariant } from 'components/Typography';
import Callout, { CalloutVariant } from 'components/Callout';
import ListTiles from 'components/ListTiles';
import LoadingListTiles from 'components/LoadingListTiles';
import type { Entry } from 'modules/Lobby';
import './searchOverlay.css';

const noSubmit = (e: React.FormEvent<HTMLFormElement>) => {
  // prevents modifying the URL on pressing Enter
  e.preventDefault();
};

const AUTOFOCUS_DELAY = 550;

const BaseForm = reform()((props: any) => {
  // props: "any" until reform gets TS friendly
  const { handleChange, handleFocus, handleBlur, search } = props;

  const field = useRef<HTMLInputElement | null>(null);

  useEffect(() => {
    const timeout = setTimeout(() => {
      field.current?.focus();
    }, AUTOFOCUS_DELAY);

    return () => clearTimeout(timeout);
  }, []);

  return (
    <form className="searchForm" onSubmit={noSubmit} name="gameSearch">
      <Fieldset
        // @ts-expect-error -- complains about ref not being a prop on Fieldset
        ref={field}
        field={search}
        // From George suggestion:
        // Chrome stubbornly ignores this and uses its own heuristics.
        // We found that a nonsense value works best to disable autofill,
        // like in components/AddressPicker where autoComplete="falsy"
        autoComplete="falsy"
        editable
        inputType="search"
        maxLength={50}
        name="search"
        labelText="Search any game"
        onChange={handleChange}
        onFocus={handleFocus}
        onBlur={handleBlur}
      />
    </form>
  );
});

interface SearchFormProps {
  onChange: (refName: string, name: string, value: string) => void;
  fields: {
    search: {
      initial?: string;
    };
  };
}

const SearchForm = (props: SearchFormProps) => <BaseForm {...props} />;

export interface SearchOverlayProps {
  close: () => void;
  tiles: Entry[];
  sectionName: string;
  info: string;
  description: string;
  loading: boolean;
  isKycPassed: boolean;
  onSearchChange: (refName: string, name: string, value: string) => void;
  fields: {
    search: {
      initial?: string;
    };
  };
}

const SearchOverlay = (props: SearchOverlayProps) => {
  const {
    close,
    tiles,
    sectionName,
    info,
    description,
    loading,
    isKycPassed,
    onSearchChange,
    fields
  } = props;
  return (
    <div className="searchOverlay__content" data-amp-id="searchOverlay">
      <Button
        className="button__grey userBox__close"
        title="Close"
        name="close"
        id="searchOverlay-close"
        icon="/assets/images1/close-primary.svg"
        onClick={close}
      />
      <SearchForm onChange={onSearchChange} fields={fields} />

      {(info || description) && !loading ? (
        <Callout
          className="searchOverlay__callout"
          title={info}
          variant={CalloutVariant.NOT_FOUND}
          shouldShowIcon
        >
          <Typography variant={TypographyVariant.BodyMd}>
            <span>{description}</span>
          </Typography>
        </Callout>
      ) : null}

      {!sectionName || loading ? null : (
        <Typography variant={TypographyVariant.BodyMdStrong}>
          <Heading level={2} className="searchOverlay__sectionTitle">
            {sectionName}
          </Heading>
        </Typography>
      )}

      <div className="searchOverlay__tilesContainer">
        {loading ? (
          <LoadingListTiles count={3} />
        ) : (
          <div className="searchOverlay__tiles hide-scrollbar">
            <ListTiles tiles={tiles} isKycPassed={isKycPassed} />
          </div>
        )}
      </div>
    </div>
  );
};

export default memo(SearchOverlay);
