import * as Router from 'modules/Router';
import Wallet from 'modules/Wallet';
import Slot, { actionTypes as slotTypes } from 'modules/Slot';
import onIdle from 'lib/onIdle';
import { defaultMappings } from 'services/Translator';

// const MIN = 1000.01;
// const MAX = 9999.99;
// const INTERVAL_MS = 800;
const TIMEOUT_MS = 10_000;
const ANIMATION_MS = 2_000;

const overrideBalance = (dispatch, balance) => () =>
  dispatch(Wallet.actions.overrideBalance(balance));

export default function slotBalanceMiddleware() {
  // let intervalID;
  let timeoutID;

  const resetBalance = (dispatch) => () => {
    // clearInterval(intervalID);
    dispatch(Wallet.actions.cancelOverrideBalance);
  };

  return ({ dispatch, getState }) =>
    (next) =>
    (action) => {
      const state = getState();

      if (action.type === Wallet.actionTypes.AT.UPDATE_BALANCE.FULFILLED) {
        const refName = action.payload && action.payload.gameRef;
        const transactionType = action.payload && action.payload.transactionType;
        const slotRoom = Slot.selectors.getRoom(state, refName);
        const provider = slotRoom && slotRoom.provider;
        // Detect duplicate "SLOT_WIN" events by confirming the last transaction type and balance,
        // addressing issues from unmarked end-round transactions and provider inconsistencies.
        const isDuplicateSlotWinEvent =
          state.Wallet.lastTransactionType === 'SLOT_WIN' &&
          state.Wallet.balance === action.payload.value;
        const balanceUpdateDelay = slotRoom?.balanceUpdateDelay;

        if (balanceUpdateDelay) {
          if (transactionType === 'SLOT_WIN' && !isDuplicateSlotWinEvent) {
            overrideBalance(dispatch, state.Wallet.balance)();

            if (timeoutID) {
              clearTimeout(timeoutID);
            }

            timeoutID = setTimeout(() => {
              clearTimeout(timeoutID);
              onIdle(resetBalance(dispatch), { timeout: 100 });
            }, balanceUpdateDelay);
          }
        } else if (!Object.keys(defaultMappings).includes(provider)) {
          if (transactionType === 'SLOT_BET') {
            overrideBalance(dispatch, state.Wallet.balance)();
            timeoutID = setTimeout(() => {
              onIdle(resetBalance(dispatch), { timeout: 100 });
            }, ANIMATION_MS);
            return false;
          }
          if (transactionType === 'SLOT_WIN') {
            clearTimeout(timeoutID);
            onIdle(resetBalance(dispatch), { timeout: 100 });
          }
        }
      } else if (action.type === slotTypes.T.ROUND_STARTED) {
        onIdle(() => overrideBalance(dispatch, state.Wallet.balance)(), { timeout: 100 });
        timeoutID = setTimeout(resetBalance, TIMEOUT_MS);
      } else if (
        action.type === slotTypes.T.ROUND_ENDED ||
        action.type === Router.T.LOCATION_CHANGE
      ) {
        clearTimeout(timeoutID);
        onIdle(resetBalance(dispatch), { timeout: 100 });
      }
      return next(action);
    };
}
