import React, { memo, useState } from 'react';
import { shallowEqual } from 'react-redux';
import cc from 'classcat';
import { InView } from 'react-intersection-observer';
import { generatePath } from 'react-router-dom';
import Tab from 'components/Tab';

const BottomTabBar = ({
  bottomTabs,
  topTabs,
  topTabRefs,
  bottomTabRefs,
  activeTop,
  path,
  matchParams,
  name,
  override,
  getBottomPrependTab
}) => {
  const [top] = useState(() => document.getElementById('mainHead')?.offsetHeight || 0);
  const [inView, setInView] = useState(true);

  if (bottomTabRefs.length > 1) {
    const activeIndex = topTabRefs.indexOf(activeTop);
    return (
      <InView
        as="div"
        className={cc(['tabs-bottom', !inView && 'tabs-bottom--stuck'])}
        rootMargin={`-${top + 1}px 0px 0px 0px`}
        threshold={1}
        onChange={setInView}
        fallbackInView
      >
        <div
          className="movable"
          style={{
            transform: `translateX(-${activeIndex * 100}%)`
          }}
        >
          {topTabRefs.map((topTabRef) => (
            <div className={`tabcontainer ${topTabRef}`} key={`tabcontainer-${topTabRef}`}>
              {getBottomPrependTab && getBottomPrependTab({ topTabRef })}
              {topTabs[topTabRef].bottomTabs.map((bottomRef) => {
                const tab = bottomTabs[bottomRef];
                return (
                  <Tab
                    className={cc([
                      'tab',
                      bottomRef,
                      tab.active && 'selected',
                      override?.[bottomRef]?.tabClasses
                    ])}
                    key={bottomRef}
                    name={name}
                    refName={bottomRef}
                    href={generatePath(path, {
                      ...matchParams,
                      urlTopTab: topTabRef,
                      urlBottomTab: bottomRef
                    })}
                    counter={tab.counter}
                    replace
                  >
                    {override?.[bottomRef]?.component || tab.title}
                  </Tab>
                );
              })}
            </div>
          ))}
        </div>
      </InView>
    );
  } else {
    return null;
  }
};

export default memo(
  BottomTabBar,
  (prevProps, nextProps) =>
    prevProps.topTabs === nextProps.topTabs &&
    prevProps.topTabRefs === nextProps.topTabRefs &&
    prevProps.bottomTabs === nextProps.bottomTabs &&
    prevProps.bottomTabRefs === nextProps.bottomTabRefs &&
    prevProps.activeTop === nextProps.activeTop &&
    prevProps.name === nextProps.name &&
    prevProps.path === nextProps.path &&
    shallowEqual(prevProps.matchParams, nextProps.matchParams) &&
    prevProps.override === nextProps.override &&
    prevProps.getBottomPrependTab === nextProps.getBottomPrependTab
);
