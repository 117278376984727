export const NAME = 'Slot';
export const PLATFORMS = [
  'PARIPLAY',
  'THUNDERKICK',
  'EYECON',
  'RELAX',
  'pushgaming',
  'GOOSICORN',
  'PRAGMATIC_PLAY',
  'BLUEPRINT',
  'EVOLUTION',
  'MICROGAMING'
];
export const CATEGORIES = {
  ASSETS: 'ASSETS'
};
